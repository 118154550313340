import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const PHARMACY_API = process.env.REACT_APP_ORDERS_API;

// ACTIONS
export const LOAD_NEXT_REQUEST = "MANUAL_LINE_START/LOAD_NEXT_REQUEST";
export const LOAD_NEXT_RECEIVE = "MANUAL_LINE_START/LOAD_NEXT_RECEIVE";
export const LOAD_NEXT_FAILURE = "MANUAL_LINE_START/LOAD_NEXT_FAILURE";
export const TOGGLE_ORDER_SELECTION =
  "MANUAL_LINE_START/TOGGLE_ORDER_SELECTION";

// ACTION CREATORS
export const loadNext = (apiEndpoint = PHARMACY_API) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/orders/manual-dispense`,
    method: "GET",
    types: [LOAD_NEXT_REQUEST, LOAD_NEXT_RECEIVE, LOAD_NEXT_FAILURE],
    options: { addAuth: true }
  }
});

export const toggleOrderSelection = orderId => ({
  type: TOGGLE_ORDER_SELECTION,
  payload: {
    orderId
  }
});

// SELECTORS
export const getBarcode = state => state.selectedOrderIds.join(",");
export const getSelectedOrderIds = state => state.selectedOrderIds;
export const getUser = state => state.user;
export const getOrders = state => state.orders;
export const getIsLoadingNext = state => state.isLoadingNext;

// REDUCERS
const selectedOrderIds = handleActions(
  {
    [LOAD_NEXT_FAILURE]: () => [],
    [LOAD_NEXT_RECEIVE]: (_state, action) =>
      action.payload.orders.map(o => o.id),
    [TOGGLE_ORDER_SELECTION]: (state, action) => {
      const { orderId } = action.payload;
      const newState = [...state];
      const index = newState.indexOf(orderId);
      if (index >= 0) {
        newState.splice(index, 1);
      } else {
        newState.push(orderId);
      }
      return newState;
    }
  },
  []
);

const user = handleActions(
  {
    [LOAD_NEXT_FAILURE]: () => null,
    [LOAD_NEXT_RECEIVE]: (_state, action) => action.payload.user
  },
  null
);

const orders = handleActions(
  {
    [LOAD_NEXT_FAILURE]: () => [],
    [LOAD_NEXT_RECEIVE]: (_state, action) => action.payload.orders
  },
  []
);

const isLoadingNext = handleActions(
  {
    [LOAD_NEXT_REQUEST]: () => true,
    [LOAD_NEXT_RECEIVE]: () => false,
    [LOAD_NEXT_FAILURE]: () => false
  },
  false
);

export default combineReducers({
  selectedOrderIds,
  user,
  orders,
  isLoadingNext
});
