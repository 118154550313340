import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import s from "./style.module.scss";

const getAge = dob => moment().diff(moment(dob), "y");
const getFromattedDob = dob => moment(dob).format("DD/MM/YYYY");
const getFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

const PatientComponent = ({ dob, firstName, lastName, address }) => (
  <div className={s.header}>
    <div className={s.column}>
      <div className={s.row}>
        <h3>Age</h3>
        <p id="t_age">{getAge(dob)}</p>
      </div>
      <div className={s.row}>
        <h3>DoB</h3>
        <p id="t_dob">{getFromattedDob(dob)}</p>
      </div>
    </div>
    <div className={s.column}>
      <h3>Name and Address</h3>
      <p id="t_fullname">{getFullName({ firstName, lastName })}</p>
      <p id="t_address">{address}</p>
    </div>
  </div>
);

PatientComponent.propTypes = {
  dob: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string
};

PatientComponent.defaultProps = {
  dob: "",
  firstName: "",
  lastName: "",
  address: ""
};

export default PatientComponent;
