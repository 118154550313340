import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import {
  getBasketId,
  getConsolidateOrders,
  getConsolidateOrdersIsLoading,
  getConsolidateOrdersIsOpen,
  getSelectedOrderIds
} from "../../redux/root-reducer";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {
  hideConsolidateOrdersDialog,
  linkBasket
} from "../../redux/consolidate-orders-dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LoadingButton from "../common/loading-button";
import Orders from "components/orders";

export const ConsolidateOrdersDialog = ({
  open,
  close,
  consolidatedOrders,
  basketId,
  onSubmit,
  selectedOrderIds,
  isLoading
}) => (
  <Dialog open={open} maxWidth={"xl"} fullWidth>
    <DialogTitle>
      Do you want to consolidate orders for this patient?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        The following orders are available to print for this patient. Please use
        the checkbox to indicate which orders should be linked to this basket.
        Do not consolidate orders if the patient has requested delivery to
        different addresses.
      </DialogContentText>
      <TextField
        id={"t_basket_id"}
        label={"Basket ID"}
        disabled
        value={basketId}
        style={{ marginBottom: "24px" }}
      />
      <Orders
        orders={consolidatedOrders}
        showEvidenceColumn
        hideNewOrderBtn
        displaySettings={{
          showOrderId: true,
          showNotes: true,
          showPatient: true,
          showGP: true,
          showOrderStatus: true,
          showDeliveryAddress: true,
          showBarcodeColumn: true,
          showLatestEvent: true,
          showBarcodeForOrder: status => ["cf_rejected"].includes(status),
          showShippingClass: true,
          showShippingButtonsForOrder: status =>
            ["cf_rejected"].includes(status)
        }}
        canSelectMultiple
        requiredFetch={false}
      />
    </DialogContent>
    <DialogActions>
      <Button id={"t_close_button"} color={"secondary"} onClick={() => close()}>
        Back
      </Button>
      <LoadingButton
        id={"t_update"}
        color={"primary"}
        loading={isLoading}
        onClick={() => onSubmit(selectedOrderIds, basketId)}
        disabled={selectedOrderIds?.length <= 0 || !basketId}
      >
        LINK BASKET AND MARK PRINTED
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export const mapStateToProps = state => ({
  open: getConsolidateOrdersIsOpen(state),
  consolidatedOrders: getConsolidateOrders(state),
  basketId: getBasketId(state),
  isLoading: getConsolidateOrdersIsLoading(state),
  selectedOrderIds: getSelectedOrderIds(state)
});

const mapDispatchToProps = {
  close: hideConsolidateOrdersDialog,
  onSubmit: linkBasket
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidateOrdersDialog);
