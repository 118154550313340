import { PACKAGES } from "../../redux/dispatch-label/create-dialog";
import {
  BASKET_ORDER_ID,
  CF_ORDER_ID,
  PHARMACY_ORDER_ID,
  UNKNOWN_ORDER_ID
} from "./data";

export const checkForOrderBarcode = (barcodes, setValue) => {
  let found = false;
  for (let b of barcodes) {
    const type = getOrderIdentifierType(b);
    if (type !== UNKNOWN_ORDER_ID) {
      found = true;
      // Current keyboard mappings in the scanner output "#" as "£"
      const v = type === BASKET_ORDER_ID ? b.replace("£", "#") : b;
      setValue({
        type,
        value: v
      });
      break;
    }
    if (found) {
      break;
    }
  }
};

export const checkForShippingBarcode = (barcodes, setValue) => {
  let found = false;
  for (let b of barcodes) {
    for (let p of PACKAGES) {
      if (p.value === b) {
        found = true;
        setValue(p);
        break;
      }
    }
    if (found) {
      break;
    }
  }
};

const getOrderIdentifierType = identifier => {
  const orderType = orderIdentifierParsers
    .map(parser => parser(identifier))
    .filter(type => !!type);

  if (!orderType.length) {
    return UNKNOWN_ORDER_ID;
  }
  // Return the first - handle mutiple positives later or never
  return orderType[0];
};

const parseBasketIdentifier = id =>
  // Current keyboard mappings in the scanner output "#" as "£"
  // "£" is being replaced with "#" in checkForOrderBarcode
  id.startsWith("BASKET#") || id.startsWith("BASKET£")
    ? BASKET_ORDER_ID
    : false;
const parsePharmacyIdentifier = id =>
  isNaN(parseInt(id, 10)) ? false : PHARMACY_ORDER_ID;
const parseCFIdentifier = id => (id.startsWith("R") ? CF_ORDER_ID : false);

const orderIdentifierParsers = [
  parseBasketIdentifier,
  parseCFIdentifier,
  parsePharmacyIdentifier
];
