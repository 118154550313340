import { createMuiTheme } from "@material-ui/core/styles";

/* Set Inline Theme Styling for the Material UI Components */

const mainTheme = createMuiTheme({
  typography: {
    fontFamily: ['"Noto Sans"', "Helvetica", "Arial", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      light: "#15AA99",
      main: "#15AA99",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#448AFF",
      main: "#448AFF",
      dark: "#ba000d",
      contrastText: "#000"
    }
  }
});

export default mainTheme;
