import { RSAA } from "redux-api-middleware";
import * as fromRootReducer from "../../root-reducer";
import { ACCESS_COMPLETE, LOGOUT, REFRESH } from "../../auth";

// Adds data from the store to the body of accessComplete and logout API requests
// Insert before redux-api-middleware in chain
export const middleware = ({
  getAccessStartToken,
  getAccessToken,
  getRefreshToken
}) => store => next => action => {
  const callAPI = action[RSAA];

  if (!callAPI || !callAPI.options || !callAPI.options.authStage) {
    return next(action);
  }

  if (callAPI.options.authStage === ACCESS_COMPLETE) {
    const currentBody = JSON.parse(callAPI.body || "{}");
    callAPI.body = JSON.stringify({
      ...currentBody,
      token: getAccessStartToken(store.getState())
    });
  } else if (callAPI.options.authStage === LOGOUT) {
    const currentBody = JSON.parse(callAPI.body || "{}");
    callAPI.body = JSON.stringify({
      ...currentBody,
      accessToken: getAccessToken(store.getState())
    });
  } else if (callAPI.options.authStage === REFRESH) {
    const currentBody = JSON.parse(callAPI.body || "{}");
    callAPI.body = JSON.stringify({
      ...currentBody,
      refreshToken: getRefreshToken(store.getState())
    });
  }

  return next(action);
};

export default middleware({
  getAccessToken: fromRootReducer.getAccessToken,
  getAccessStartToken: fromRootReducer.getAccessStartToken,
  getRefreshToken: fromRootReducer.getRefreshToken
});
