import React, { FC } from "react";
import { GP } from "../../redux/gp-nhs-refresh/types";
import { TableRow, TableCell, Button, Box } from "@material-ui/core";
import commonS from "../common/common-styles.module.scss";
import { formatDateTimeString } from "../../helpers/date-helpers";
import s from "./gp-contact-check.module.scss";
import cx from "clsx";

export interface GPRowProps {
  gp: GP;
  openGPProfile: (ods: string) => void;
}

export const GPRow: FC<GPRowProps> = ({ gp, openGPProfile }) => {
  const openOurGP = (): void => {
    openGPProfile(gp.id);
  };

  return (
    <TableRow className={cx(s.row, s.gpRow)}>
      <TableCell>{gp.id}</TableCell>
      <TableCell>
        <Box>{gp.name}</Box>
        <Box className={s.secondary}>{gp.addressLine1}</Box>
        <Box className={s.secondary}>{gp.addressLine2}</Box>
        <Box className={s.secondary}>{gp.postcode}</Box>
        <Box className={s.secondary}>{gp.city}</Box>

        <Button
          className={cx(commonS.viewButton, "view")}
          variant={"text"}
          color={"secondary"}
          onClick={openOurGP}
        >
          View
        </Button>
      </TableCell>
      <TableCell>{gp.telephone}</TableCell>
      <TableCell>{gp.email}</TableCell>
      <TableCell>
        {gp.flagApplied ? formatDateTimeString(gp.flagApplied) : ""}
      </TableCell>
    </TableRow>
  );
};
