import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import * as evSelectors from "../../redux/selectors/event";
import { formatDateTimeString } from "../../helpers/date-helpers";
import {
  getEventsDialogEvents,
  getEventsDialogIsLoading,
  getEventsDialogIsOpen
} from "../../redux/root-reducer";
import { hideEventsDialog } from "../../redux/events-dialog";

const container = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
const CenteredContent = withStyles(container)(DialogContent);

const renderTable = events => (
  <Table id={"t_table"}>
    <TableHead>
      <TableRow>
        <TableCell>Event</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Notes</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {events.map(e => (
        <TableRow
          key={evSelectors.getFormattedName(e)}
          className={"t_event_row"}
        >
          <TableCell>{evSelectors.getFormattedName(e)}</TableCell>
          <TableCell>{formatDateTimeString(evSelectors.getDate(e))}</TableCell>
          <TableCell>
            {evSelectors.getMCSCustomerResponse(e)}
            {evSelectors.getNotes(e)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export const EventsDialog = ({ events, onClose, loading, open }) => (
  <Dialog open={open} maxWidth={"md"} fullWidth>
    <DialogTitle>Events</DialogTitle>
    <CenteredContent>
      {loading ? <CircularProgress id={"t_loading"} /> : renderTable(events)}
    </CenteredContent>
    <DialogActions>
      <Button id={"t_close_button"} color={"primary"} onClick={() => onClose()}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

EventsDialog.propTypes = {
  events: PropTypes.array,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  open: PropTypes.bool
};

EventsDialog.defaultProps = {
  events: [],
  onClose: () => {},
  loading: false,
  open: false
};

const mapStateToProps = state => ({
  events: getEventsDialogEvents(state),
  loading: getEventsDialogIsLoading(state),
  open: getEventsDialogIsOpen(state)
});

const mapDispatchToProps = {
  onClose: hideEventsDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsDialog);
