import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";
import { FETCH_PATIENTS_REQUEST } from "../create-order";
import { showConfirmDialog } from "../confirm-dialog";

const HOFF_API = process.env.REACT_APP_HOFF_STAFF_API;
const USERS_API = process.env.REACT_APP_PHARMACY_USERS_API;

// ACTIONS
export const SELECT_USER_TO_MATCH = "MATCH_USERS/SELECT_USER_TO_MATCH";

export const FETCH_AUTOCOMPLETE_USER_REQUEST =
  "MATCH_USERS/FETCH_AUTOCOMPLETE_USER_REQUEST";
export const FETCH_AUTOCOMPLETE_USER_RECEIVE =
  "MATCH_USERS/FETCH_AUTOCOMPLETE_USER_RECEIVE";
export const FETCH_AUTOCOMPLETE_USER_FAILURE =
  "MATCH_USERS/FETCH_AUTOCOMPLETE_USER_FAILURE";

export const MATCH_USER_REQUEST = "MATCH_USERS/MATCH_USER_REQUEST";
export const MATCH_USER_RECEIVE = "MATCH_USERS/MATCH_USER_RECEIVE";
export const MATCH_USER_FAILURE = "MATCH_USERS/MATCH_USER_FAILURE";

export const GET_UNMATCHED_USERS_REQUEST =
  "MATCH_USERS/GET_UNMATCHED_USERS_REQUEST";
export const GET_UNMATCHED_USERS_RECEIVE =
  "MATCH_USERS/GET_UNMATCHED_USERS_RECEIVE";
export const GET_UNMATCHED_USERS_FAILURE =
  "MATCH_USERS/GET_UNMATCHED_USERS_FAILURE";
export const GET_UNMATCHED_USERS_PAGED_REQUEST =
  "MATCH_USERS/GET_UNMATCHED_USERS_PAGED_REQUEST";
export const GET_UNMATCHED_USERS_PAGED_RECEIVE =
  "MATCH_USERS/GET_UNMATCHED_USERS_PAGED_RECEIVE";
export const GET_UNMATCHED_USERS_PAGED_FAILURE =
  "MATCH_USERS/GET_UNMATCHED_USERS_PAGED_FAILURE";

export const SELECT_MATCHED_USER = "MATCH_USERS/SELECT_MATCHED_USER";

export const CLEAR_USER_SUGGESTIONS = "MATCH_USERS/CLEAR_USER_SUGGESTIONS";
export const CLEAR_SELECTED_USER = "MATCH_USERS/CLEAR_SELECTED_USER";
export const CLEAR_MATCHED_USER = "MATCH_USERS/CLEAR_MATCHED_USER";

export const DELETE_UNMATCHED_USER_REQUEST =
  "MATCH_USERS/DELETE_UNMATCHED_USER_REQUEST";
export const DELETE_UNMATCHED_USER_RECEIVE =
  "MATCH_USERS/DELETE_UNMATCHED_USER_RECEIVE";
export const DELETE_UNMATCHED_USER_FAILURE =
  "MATCH_USERS/DELETE_UNMATCHED_USER_FAILURE";

// ACTION CREATORS
export const selectUser = user => ({
  type: SELECT_USER_TO_MATCH,
  payload: { user }
});
export const clearSelectedUser = () => ({
  type: CLEAR_SELECTED_USER
});

export const confirmUserMatch = (pmrUser, pharmacyUser) => {
  return showConfirmDialog({
    title: "Confirm user match",
    message:
      `This will match PMR patient ${pmrUser.name} with PharmOs user ` +
      `${pharmacyUser.firstName} ${pharmacyUser.lastName}.` +
      " This action cannot be undone.",
    positiveActionTitle: "Confirm",
    agreeActionCreator: () => matchUser(pmrUser, pharmacyUser)
  });
};

export const matchUser = (
  pmrUser,
  pharmacyUser,
  uniqueId = Date.now(),
  apiEndpoint = HOFF_API
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/${pmrUser.pmrId}/match`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        pharmacyUserId: pharmacyUser.userId
      }),
      types: [
        MATCH_USER_REQUEST,
        MATCH_USER_RECEIVE,
        MATCH_USER_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

export const getUnmatchedUsers = (
  uniqueId = Date.now(),
  apiEndpoint = HOFF_API
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/unmatched`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        GET_UNMATCHED_USERS_REQUEST,
        GET_UNMATCHED_USERS_RECEIVE,
        GET_UNMATCHED_USERS_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

export const getAdditionalUnmatchedUsers = (
  nextToken,
  uniqueId = Date.now(),
  apiEndpoint = HOFF_API
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/unmatched?pageToken=${nextToken}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        GET_UNMATCHED_USERS_PAGED_REQUEST,
        GET_UNMATCHED_USERS_PAGED_RECEIVE,
        GET_UNMATCHED_USERS_PAGED_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

export const changeUserSearch = (
  text,
  uniqueId = Date.now(),
  apiEndpoint = USERS_API
) => {
  if (text.length < 2)
    return { type: FETCH_PATIENTS_REQUEST, meta: { uniqueId, text } };
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name: text }),
      types: [
        FETCH_AUTOCOMPLETE_USER_REQUEST,
        FETCH_AUTOCOMPLETE_USER_RECEIVE,
        FETCH_AUTOCOMPLETE_USER_FAILURE
      ].map(type => ({ type, meta: { uniqueId, text } })),
      options: { addAuth: true }
    }
  };
};

export const selectUserSuggestion = user => ({
  type: SELECT_MATCHED_USER,
  payload: { user }
});

export const clearUserSuggestions = () => ({
  type: CLEAR_USER_SUGGESTIONS
});

export const clearMatchedUser = () => ({
  type: CLEAR_MATCHED_USER
});

export const confirmUnmatchedUserDelete = pmrUser => {
  return showConfirmDialog({
    title: "Confirm deletion of unmatched user",
    message:
      `This will delete PMR patient ${pmrUser.name} and ` +
      `any unmatched orders that have been received from the PMR for this patient` +
      " This action cannot be undone.",
    positiveActionTitle: "Confirm",
    agreeActionCreator: () => deleteUnmatchedUser(pmrUser)
  });
};

export const deleteUnmatchedUser = (
  pmrUser,
  uniqueId = Date.now(),
  apiEndpoint = HOFF_API
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/unmatched/${pmrUser.pmrId}/delete`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        DELETE_UNMATCHED_USER_REQUEST,
        DELETE_UNMATCHED_USER_RECEIVE,
        DELETE_UNMATCHED_USER_FAILURE
      ].map(type => ({
        type,
        meta: { uniqueId, pmrUser }
      })),
      options: { addAuth: true }
    }
  };
};

// SELECTORS
export const getAllUnmatchedUsers = state => state.unmatchedUsers;
export const getSelectedUser = state => state.selectedUser;
export const getSelectedMatchedUser = state => state.selectedMatchUser;
export const getUserSuggestions = state => state.userAutocomplete.suggestions;
export const getUserSearchText = state => state.userSearchText;
export const getNeedsUpdate = state => state.needsRefresh;
export const getFetching = state => state.fetching;
export const getFetchingAdditional = state => state.fetchingAdditional;
export const getMatching = state => state.matching;
export const getFirstPageFetched = state => state.firstPageFetched;
export const getAllPagesFetched = state =>
  state.firstPageFetched && state.nextToken === null;
export const getNextToken = state => state.nextToken;

// REDUCERS
const unmatchedUsers = handleActions(
  {
    [GET_UNMATCHED_USERS_RECEIVE]: (state, action) => action.payload.data,
    [GET_UNMATCHED_USERS_PAGED_RECEIVE]: (state, action) =>
      state.concat(action.payload.data),
    [DELETE_UNMATCHED_USER_RECEIVE]: (state, action) =>
      state.filter(u => u.pmrId !== action.meta.pmrUser.pmrId)
  },
  []
);

const selectedUser = handleActions(
  {
    [SELECT_USER_TO_MATCH]: (state, action) => action.payload.user,
    [GET_UNMATCHED_USERS_RECEIVE]: () => null,
    [MATCH_USER_RECEIVE]: () => null,
    [CLEAR_SELECTED_USER]: () => null,
    [DELETE_UNMATCHED_USER_RECEIVE]: () => null
  },
  null
);

const selectedMatchUser = handleActions(
  {
    [SELECT_MATCHED_USER]: (state, action) => action.payload.user,
    [CLEAR_MATCHED_USER]: () => null,
    [MATCH_USER_RECEIVE]: () => null,
    [DELETE_UNMATCHED_USER_RECEIVE]: () => null
  },
  null
);

const userSearchText = handleActions(
  {
    [CLEAR_MATCHED_USER]: () => "",
    [MATCH_USER_RECEIVE]: () => "",
    [FETCH_AUTOCOMPLETE_USER_REQUEST]: (state, action) =>
      action.meta.text || "",
    [DELETE_UNMATCHED_USER_RECEIVE]: () => ""
  },
  ""
);

const userAutocomplete = handleActions(
  {
    [SELECT_MATCHED_USER]: () => ({ reqId: null, suggestions: [] }),
    [CLEAR_USER_SUGGESTIONS]: () => ({ reqId: null, suggestions: [] }),
    [MATCH_USER_RECEIVE]: () => ({ reqId: null, suggestions: [] }),
    [FETCH_AUTOCOMPLETE_USER_REQUEST]: (state, action) => ({
      ...state,
      reqId: action.meta.uniqueId
    }),
    [FETCH_AUTOCOMPLETE_USER_RECEIVE]: (state, action) => {
      if (action.meta.uniqueId === state.reqId) {
        return {
          suggestions: action.payload.map(p => ({
            ...p,
            address: `${p.addressLine1}, ${p.postcode}`
          })),
          reqId: null
        };
      }
      return state;
    },
    [FETCH_AUTOCOMPLETE_USER_FAILURE]: (state, action) =>
      action.meta.uniqueId === state.reqId ? { ...state, reqId: null } : state
  },
  { reqId: null, suggestions: [] }
);

const needsRefresh = handleActions(
  {
    [MATCH_USER_RECEIVE]: () => true,
    [DELETE_UNMATCHED_USER_RECEIVE]: () => true,
    [GET_UNMATCHED_USERS_RECEIVE]: () => false
  },
  false
);

const fetching = handleActions(
  {
    [GET_UNMATCHED_USERS_REQUEST]: () => true,
    [GET_UNMATCHED_USERS_RECEIVE]: () => false,
    [GET_UNMATCHED_USERS_FAILURE]: () => false
  },
  false
);

const fetchingAdditional = handleActions(
  {
    [GET_UNMATCHED_USERS_PAGED_REQUEST]: () => true,
    [GET_UNMATCHED_USERS_PAGED_RECEIVE]: () => false,
    [GET_UNMATCHED_USERS_PAGED_FAILURE]: () => false
  },
  false
);

const matching = handleActions(
  {
    [MATCH_USER_REQUEST]: () => true,
    [MATCH_USER_RECEIVE]: () => false,
    [MATCH_USER_FAILURE]: () => false
  },
  false
);

const nextToken = handleActions(
  {
    [GET_UNMATCHED_USERS_RECEIVE]: (state, action) =>
      action.payload.next ? action.payload.next : null,
    [GET_UNMATCHED_USERS_PAGED_RECEIVE]: (state, action) =>
      action.payload.next ? action.payload.next : null
  },
  null
);

const firstPageFetched = handleActions(
  {
    [GET_UNMATCHED_USERS_RECEIVE]: (state, action) => state || true
  },
  false
);

export default combineReducers({
  unmatchedUsers,
  selectedUser,
  selectedMatchUser,
  userSearchText,
  userAutocomplete,
  needsRefresh,
  fetching,
  fetchingAdditional,
  matching,
  nextToken,
  firstPageFetched
});
