import { RSAA } from "redux-api-middleware";
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import moment from "moment";
import * as userSel from "../selectors/user";
import {
  NOMINATE_USER_REQUEST,
  NOMINATE_USER_RECEIVE,
  NOMINATE_USER_FAILURE
} from "../pending-users";
import { showConfirmDialog } from "../confirm-dialog/index";
import { APPROVE_EVIDENCE_RECEIVE } from "../evidence-dialog";

const GP_ENDPOINT = process.env.REACT_APP_GP_PROFILE_API;
const PHARMACY_USER_ENDPOINT = process.env.REACT_APP_PHARMACY_USERS_API;

// ACTIONS
export const CLEAR_GP_SUGGESTIONS = "CLEAR_GP_SUGGESTIONS";
export const FETCH_GP_REQUEST = "FETCH_GP_REQUEST";
export const FETCH_GP_RECEIVE = "FETCH_GP_RECEIVE";
export const FETCH_GP_FAILURE = "FETCH_GP_FAILURE";
export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_RECEIVE = "GET_USER_PROFILE_RECEIVE";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_RECEIVE = "UPDATE_USER_PROFILE_RECEIVE";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";
export const SELECT_GP = "SELECT_GP";
export const SET_ADDRESS_LINE1 = "SET_ADDRESS_LINE1";
export const SET_ADDRESS_LINE2 = "SET_ADDRESS_LINE2";
export const SET_ADDRESS_CITY = "SET_ADDRESS_CITY";
export const SET_ADDRESS_POSTCODE = "SET_ADDRESS_POSTCODE";
export const SET_DOB_DAY = "SET_DOB_DAY";
export const SET_DOB_MONTH = "SET_DOB_MONTH";
export const SET_DOB_YEAR = "SET_DOB_YEAR";
export const SET_NOTES = "SET_NOTES";
export const SET_USER_FIRST_NAME = "SET_USER_FIRST_NAME";
export const SET_USER_LAST_NAME = "SET_USER_LAST_NAME";
export const SET_PHONE = "SET_PHONE";
export const SELECT_USER_TAB = "SELECT_USER_TAB";
export const DELETE_USER_EXEMPTION_REQUEST = "DELETE_USER_EXEMPTION_REQUEST";
export const DELETE_USER_EXEMPTION_RECEIVE = "DELETE_USER_EXEMPTION_RECEIVE";
export const DELETE_USER_EXEMPTION_FAILURE = "DELETE_USER_EXEMPTION_FAILURE";

// ACTION CREATORS
export const fetchUserProfile = (
  userId,
  apiEndpoint = PHARMACY_USER_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/user/${userId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      GET_USER_PROFILE_REQUEST,
      GET_USER_PROFILE_RECEIVE,
      GET_USER_PROFILE_FAILURE
    ].map(type => ({ type, meta: { userId } })),
    options: { addAuth: true }
  }
});

export const selectGP = gp => ({
  type: SELECT_GP,
  gp
});

export const setUserFirstName = firstName => ({
  type: SET_USER_FIRST_NAME,
  firstName
});

export const setUserLastName = lastName => ({
  type: SET_USER_LAST_NAME,
  lastName
});

export const setAddressLine1 = addressLine1 => ({
  type: SET_ADDRESS_LINE1,
  addressLine1
});

export const setAddressLine2 = addressLine2 => ({
  type: SET_ADDRESS_LINE2,
  addressLine2
});

export const setAddressCity = addressCity => ({
  type: SET_ADDRESS_CITY,
  addressCity
});

export const setAddressPostcode = addressPostcode => ({
  type: SET_ADDRESS_POSTCODE,
  addressPostcode
});

export const setNotes = notes => ({
  type: SET_NOTES,
  notes
});

export const setDOBDay = value => ({
  type: SET_DOB_DAY,
  value
});

export const setDOBMonth = value => ({
  type: SET_DOB_MONTH,
  value
});

export const setDOBYear = value => ({
  type: SET_DOB_YEAR,
  value
});

export const setPhone = phone => ({
  type: SET_PHONE,
  phone
});

const sendGPSearchRequest = (
  text,
  uniqueId = Date.now(),
  apiEndpoint = GP_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practices?q=${encodeURIComponent(text.trim())}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    types: [FETCH_GP_REQUEST, FETCH_GP_RECEIVE, FETCH_GP_FAILURE].map(type => ({
      type,
      meta: { uniqueId, text }
    })),
    options: { addAuth: true }
  }
});

export const clearGPSuggestions = () => ({ type: CLEAR_GP_SUGGESTIONS });
export const setUserTabIndex = i => ({ type: SELECT_USER_TAB, tabIndex: i });
export const changeGPSearchText = (
  text,
  uniqueId = Date.now(),
  apiEndpoint = GP_ENDPOINT
) => {
  if (!text) return clearGPSuggestions();
  return sendGPSearchRequest(text, uniqueId, apiEndpoint);
};

export const updateUserProfile = (
  userId,
  {
    gpId,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    addressCity,
    addressPostcode,
    dob,
    notes,
    phone
  },
  apiEndpoint = PHARMACY_USER_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/user/${userId}/update`,
    method: "POST",
    body: JSON.stringify({
      practiceId: gpId,
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city: addressCity,
      postcode: addressPostcode,
      notes,
      dob,
      phone
    }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      UPDATE_USER_PROFILE_REQUEST,
      UPDATE_USER_PROFILE_RECEIVE,
      UPDATE_USER_PROFILE_FAILURE
    ],
    options: { addAuth: true }
  }
});

const padNumber = (number, size = 2) => {
  let s = String(number);
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
};

export const clearExemptions = userId => {
  return showConfirmDialog({
    title: "Clear exemption",
    message: "Are you sure you would like to clear all exemptions?",
    positiveActionTitle: "Confirm",
    agreeActionCreator: (apiEndpoint = PHARMACY_USER_ENDPOINT) => ({
      [RSAA]: {
        endpoint: `${apiEndpoint}/user/${userId}/exemption`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        types: [
          DELETE_USER_EXEMPTION_REQUEST,
          DELETE_USER_EXEMPTION_RECEIVE,
          DELETE_USER_EXEMPTION_FAILURE
        ],
        options: { addAuth: true }
      }
    })
  });
};

// SELECTORS
export const getGPSuggestions = state => state.gpAutocomplete.suggestions || [];
export const getIsLoadingGPs = state => state.gpAutocomplete.loading;
export const getGPSearchText = state => state.gpSearchText;
export const getIsSaving = state => state.isSaving;
export const getIsFetchingProfile = state => state.isFetchingProfile;
export const getSelectedGP = state => state.selectedGP;
export const getUserId = state => state.userId;
export const getUserFirstName = state => state.userFirstName;
export const getUserLastName = state => state.userLastName;
export const getUserDOBDay = state => state.userDOBDay;
export const getUserDOBMonth = state => state.userDOBMonth;
export const getUserDOBYear = state => state.userDOBYear;
export const getUserDOB = state =>
  `${padNumber(state.userDOBYear, 4)}-${padNumber(
    state.userDOBMonth
  )}-${padNumber(state.userDOBDay)}T00:00:00Z`;
export const getAddressLine1 = state => state.addressLine1;
export const getAddressLine2 = state => state.addressLine2;
export const getAddressCity = state => state.addressCity;
export const getAddressPostcode = state => state.addressPostcode;
export const getUserNotes = state => state.notes;
export const getSelectedTab = state => state.selectedTabIndex;
export const getShowNominationButton = state => !state.isNominated;
export const getIsNominating = state => state.isNominating;
export const getUserPhone = state => state.phone;
export const getUserEmail = state => state.email;
/** Retuns the user formatted as needed by the `openCreateOrder` action creator. */
export const getUserToCreateOrder = state => ({
  userId: getUserId(state),
  dob: getUserDOB(state),
  address: [
    getAddressLine1(state),
    getAddressLine2(state),
    getAddressCity(state),
    getAddressPostcode(state)
  ].join(", "),
  firstName: getUserFirstName(state),
  lastName: getUserLastName(state)
});
export const getUserExemptions = state => state.exemption;

// REDUCERS
const isLastRequest = (reqId, action) => action.meta.uniqueId === reqId;
const fetchGPReqId = handleActions(
  {
    [GET_USER_PROFILE_REQUEST]: () => null,
    [CLEAR_GP_SUGGESTIONS]: () => null,
    [SELECT_GP]: () => null,
    [FETCH_GP_REQUEST]: (state, action) => action.meta.uniqueId,
    [FETCH_GP_RECEIVE]: (state, action) =>
      isLastRequest(state, action) ? null : state,
    [FETCH_GP_FAILURE]: (state, action) =>
      isLastRequest(state, action) ? null : state
  },
  null
);
const gpSuggestions = reqId =>
  handleActions(
    {
      [GET_USER_PROFILE_REQUEST]: () => [],
      [CLEAR_GP_SUGGESTIONS]: () => [],
      [SELECT_GP]: () => [],
      [FETCH_GP_RECEIVE]: (state, action) =>
        isLastRequest(reqId, action) ? action.payload.data : state
    },
    []
  );
const gpSuggestionsLoading = reqId =>
  handleActions(
    {
      [GET_USER_PROFILE_REQUEST]: () => false,
      [CLEAR_GP_SUGGESTIONS]: () => false,
      [SELECT_GP]: () => false,
      [FETCH_GP_REQUEST]: () => true,
      [FETCH_GP_RECEIVE]: (state, action) =>
        isLastRequest(reqId, action) ? false : state,
      [FETCH_GP_FAILURE]: (state, action) =>
        isLastRequest(reqId, action) ? false : state
    },
    false
  );
const gpAutocomplete = (state = {}, action) =>
  combineReducers({
    reqId: fetchGPReqId,
    suggestions: gpSuggestions(state.reqId),
    loading: gpSuggestionsLoading(state.reqId)
  })(state, action);

const gpSearchText = (currentGP = {}) =>
  handleActions(
    {
      [CLEAR_GP_SUGGESTIONS]: () => (currentGP ? currentGP.name : ""),
      [SELECT_GP]: () => (currentGP ? currentGP.name : ""),
      [GET_USER_PROFILE_RECEIVE]: () => (currentGP ? currentGP.name : ""),
      [FETCH_GP_REQUEST]: (state, action) => action.meta.text || ""
    },
    ""
  );

const selectedGP = handleActions(
  {
    [SELECT_GP]: (state, action) => action.gp,
    [GET_USER_PROFILE_REQUEST]: () => null,
    [GET_USER_PROFILE_RECEIVE]: (state, action) => userSel.getGP(action.payload)
  },
  null
);

const isFetchingProfile = handleActions(
  {
    [GET_USER_PROFILE_REQUEST]: () => true,
    [GET_USER_PROFILE_RECEIVE]: () => false,
    [GET_USER_PROFILE_FAILURE]: () => false
  },
  false
);

const userId = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) => action.meta.userId
  },
  null
);

const userFirstName = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getFirstName(action.payload),
    [SET_USER_FIRST_NAME]: (state, action) => action.firstName
  },
  null
);

const userLastName = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getLastName(action.payload),
    [SET_USER_LAST_NAME]: (state, action) => action.lastName
  },
  null
);

const userDOBDay = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      `${moment(userSel.getDOB(action.payload)).get("date")}`,
    [SET_DOB_DAY]: (state, action) => action.value
  },
  null
);

const userDOBMonth = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      `${moment(userSel.getDOB(action.payload)).get("month") + 1}`,
    [SET_DOB_MONTH]: (state, action) => action.value
  },
  null
);

const userDOBYear = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      `${moment(userSel.getDOB(action.payload)).get("year")}`,
    [SET_DOB_YEAR]: (state, action) => action.value
  },
  null
);

const isSaving = handleActions(
  {
    [UPDATE_USER_PROFILE_REQUEST]: () => true,
    [UPDATE_USER_PROFILE_RECEIVE]: () => false,
    [UPDATE_USER_PROFILE_FAILURE]: () => false
  },
  false
);

const addressLine1 = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getAddressLine1(action.payload),
    [SET_ADDRESS_LINE1]: (state, action) => action.addressLine1
  },
  ""
);

const addressLine2 = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getAddressLine2(action.payload),
    [SET_ADDRESS_LINE2]: (state, action) => action.addressLine2
  },
  ""
);

const addressCity = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getAddressCity(action.payload),
    [SET_ADDRESS_CITY]: (state, action) => action.addressCity
  },
  ""
);

const addressPostcode = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getAddressPostcode(action.payload),
    [SET_ADDRESS_POSTCODE]: (state, action) => action.addressPostcode
  },
  ""
);

const notes = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getNotes(action.payload),
    [SET_NOTES]: (state, action) => action.notes
  },
  ""
);

const selectedTabIndex = handleActions(
  {
    [SELECT_USER_TAB]: (state, action) => action.tabIndex
  },
  0
);

const phone = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getPhone(action.payload),
    [SET_PHONE]: (state, action) => action.phone
  },
  null
);

const email = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getEmail(action.payload)
  },
  null
);

const isNominated = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getIsNominated(action.payload),
    [NOMINATE_USER_RECEIVE]: () => true
  },
  false
);

const isNominating = handleActions(
  {
    [NOMINATE_USER_REQUEST]: () => true,
    [NOMINATE_USER_RECEIVE]: () => false,
    [NOMINATE_USER_FAILURE]: () => false,
    [GET_USER_PROFILE_REQUEST]: () => false
  },
  false
);

const exemption = handleActions(
  {
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      userSel.getExemption(action.payload),
    [DELETE_USER_EXEMPTION_RECEIVE]: (state, action) => null,
    [APPROVE_EVIDENCE_RECEIVE]: (state, action) => ({
      hasExemption: true
    })
  },
  null
);

export default (state = {}, action) => {
  const currentGP = selectedGP(state.selectedGP || {}, action);
  return combineReducers({
    selectedGP: () => currentGP,
    gpAutocomplete,
    gpSearchText: gpSearchText(currentGP),
    isSaving,
    userId,
    userFirstName,
    userLastName,
    addressLine1,
    addressLine2,
    addressCity,
    addressPostcode,
    isFetchingProfile,
    notes,
    selectedTabIndex,
    userDOBYear,
    userDOBMonth,
    userDOBDay,
    phone,
    email,
    isNominated,
    isNominating,
    exemption
  })(state, action);
};
