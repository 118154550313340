import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { push } from "connected-react-router";
import ContactMethods from "./contact-methods";
import {
  closeContactDialog,
  sendGPContactRequest
} from "../../redux/contact-dialog";
import {
  getSelectedRequestId,
  getContactDialogIsOpen,
  getContactDialogSelectedMethod,
  getRequestGPDetails
} from "../../redux/root-reducer";
import s from "./contact-dialog.module.scss";

export const ContactDialog = ({
  isOpen,
  requestId,
  practiceId,
  selected,
  onCancel,
  onAdd,
  onSubmit
}) => (
  <Dialog className={s.root} open={isOpen} onClose={onCancel}>
    <DialogTitle>Contact GP</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This will send the relevant information to the GP to request the
        customer&apos;s prescription. This action cannot be undone.
      </DialogContentText>
      <ContactMethods value={selected} />
    </DialogContent>
    <DialogActions>
      <Button id="t_add" onClick={() => onAdd(practiceId)} color="secondary">
        Add Contact Method
      </Button>
      <Button id="t_cancel" onClick={onCancel} color="secondary">
        Cancel
      </Button>
      <Button
        id="t_contact"
        disabled={selected === ""}
        onClick={() => onSubmit(requestId, selected)}
        color="primary"
        autoFocus
      >
        Contact
      </Button>
    </DialogActions>
  </Dialog>
);
ContactDialog.propTypes = {
  isOpen: PropTypes.bool,
  requestId: PropTypes.number,
  practiceId: PropTypes.string,
  selected: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
ContactDialog.defaultProps = {
  requestId: null,
  isOpen: false,
  practiceId: ""
};

const mapStateToProps = state => ({
  isOpen: getContactDialogIsOpen(state),
  selected: getContactDialogSelectedMethod(state),
  requestId: getSelectedRequestId(state),
  practiceId: getRequestGPDetails(state).id
});

const mapDispatchToProps = {
  onCancel: closeContactDialog,
  pushRoute: push,
  onSubmit: sendGPContactRequest
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onAdd: id => {
    dispatchProps.pushRoute(`/gp/profile/${id}`);
    dispatchProps.onCancel();
  },
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ContactDialog);
