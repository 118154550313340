function tidy(value) {
  return value === null ? "" : value;
}

export enum MatchStatus {
  UPDATED = "UPDATED",
  VERIFIED = "VERIFIED",
  EMPTY = "EMPTY",
  UNVERIFIED = "UNVERIFIED"
}

// takes all the properties in T, makes them optional `?:` and returns a bool for them
export type MakerReturn<T> = {
  [Property in keyof T]?: MatchStatus;
};

export type UnverifiedMap<T> = {
  [Property in keyof T]?: boolean;
};

function mapMaker<T>(
  old?: T,
  unverified?: UnverifiedMap<T>,
  updated?: Partial<T> /* partial makes all the properties of T optional */
): MakerReturn<T> {
  const keysIgnored = [
    "lastUpdated",
    "lat",
    "lng",
    "contactPreference",
    "againstDigitalPharmacy",
    "eps",
    "email",
    "id"
  ];
  const hasChanged: MakerReturn<T> = {};
  // const hasNotChanged: MakerReturn<T> = {};
  if (old === undefined || updated === undefined) {
    return {};
  }

  for (const key in updated) {
    // some keys are excluded from comparison:
    if (keysIgnored.indexOf(key) !== -1) {
      continue;
    }

    // some fields are "unverfied" because the server tells us so:
    if (unverified !== undefined && unverified[key]) {
      hasChanged[key] = MatchStatus.UNVERIFIED;
      continue;
    }

    const oldValue = tidy(old[key]);
    const updatedValue = tidy(updated[key]);

    if (!oldValue && !updatedValue) {
      hasChanged[key] = MatchStatus.EMPTY;
    } else {
      hasChanged[key] =
        oldValue === updatedValue ? MatchStatus.VERIFIED : MatchStatus.UPDATED;
    }
  }
  return hasChanged;
}

export default mapMaker;
