import { RSAA } from "redux-api-middleware";

export const FETCH_MEDICINES_REQUEST = "FETCH_MEDICINES_REQUEST";
export const FETCH_MEDICINES_RECEIVE = "FETCH_MEDICINES_RECEIVE";
export const FETCH_MEDICINES_FAILURE = "FETCH_MEDICINES_FAILURE";
export const CLEAR_MEDS_SUGGESTIONS = "CLEAR_MEDS_SUGGESTIONS";

const MEDICINE_API = process.env.REACT_APP_MEDICINES_API;

export const changeMedicineSearch = (
  text = "",
  uniqueId = Date.now(),
  apiEndpoint = MEDICINE_API
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/search/${encodeURIComponent(text.trim())}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      FETCH_MEDICINES_REQUEST,
      FETCH_MEDICINES_RECEIVE,
      FETCH_MEDICINES_FAILURE
    ].map(type => ({ type, meta: { uniqueId, text } })),
    options: { addAuth: true }
  }
});

export const clearMedsSuggestions = () => ({ type: CLEAR_MEDS_SUGGESTIONS });
