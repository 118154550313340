import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "@material-ui/core/styles";

import {
  getBasketData,
  getBasketId,
  getBasketRefreshBasketData,
  getBasketScanFromRemoveBasket,
  getBasketStatus
} from "redux/root-reducer";
import {
  fetchBasketData,
  fetchBasketStatus,
  setAdminDialogOpen,
  setBasketId,
  smallScreenBack
} from "redux/scanner-wrapper";
import { sendNotification } from "redux/notification";
import { showConfirmDialog } from "redux/confirm-dialog";
import Orders from "./_Order";
import ScanBasket from "./_ScanBasket";
import useBarcodeScans from "../../hooks/useBarcodeScan";
import { isTouchDevice } from "helpers/validators";
import { push } from "connected-react-router";

const Screen = ({
  sendNotification,
  setBasketId,
  isBasket,
  isReadyToProcess,
  isBasketDetails,
  basketData,
  fetchBasketStatus,
  fetchBasketData,
  status,
  showConfirmDialog,
  match,
  location,
  setAdminDialogOpen,
  refreshBasketData,
  basketId,
  isRemoveBasketScan,
  smallScreenBack,
  isUser,
  push
}) => {
  const [smallScreen, setSmallScreen] = useState(!isTouchDevice());
  const [scans, count, resetScanOutput] = useBarcodeScans("Enter", 500);

  useEffect(() => {
    const initialBasketId = match?.params?.basketId || "";
    if (initialBasketId) {
      setBasketId(initialBasketId);
      fetchBasketData(initialBasketId);
    }
  }, [match?.params?.basketId]);

  useEffect(() => {
    if (refreshBasketData && (basketId || basketData?.id)) {
      if (
        match?.path === "/orders/basket" ||
        location?.pathname === "/admin/basket/remove-order"
      ) {
        fetchBasketData(basketId ? basketId : basketData?.id);
      } else {
        fetchBasketStatus(basketId ? basketId : basketData?.id);
      }
    }
  }, [refreshBasketData]);

  useEffect(() => {
    if (count) {
      const lastScanItem = scans.pop();
      resetScanOutput();
      setBasketId(lastScanItem);
      const isRemovePath =
        match?.path === "/orders/basket" ||
        location?.pathname === "/admin/basket/remove-order";

      if (!isRemoveBasketScan) {
        if (isRemovePath) {
          fetchBasketData(lastScanItem);
        } else {
          fetchBasketStatus(lastScanItem);
        }
      }

      sendNotification({
        message: `Basket ID: ${lastScanItem}`,
        duration: null,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        bgColor: "#15AA99"
      });
    }
  }, [count, scans]);

  useEffect(() => {
    if (match?.path === "/orders/cf-rejected" && status === "not_found") {
      showConfirmDialog({
        title: "Basket not found",
        message: `There’s no basket to link to. Please add the basket.`,
        positiveActionTitle: "GOTO ADD BASKET",
        agreeActionCreator: () => push("/admin/basket")
      });
    } else if (status && match?.path === "/admin/basket") {
      setAdminDialogOpen();
    }
  }, [status]);

  const OrdersMemo = useMemo(
    () => <Orders isReadyToProcess={isReadyToProcess} isUser={isUser} />,
    [isReadyToProcess, isUser]
  );

  if (
    basketData &&
    smallScreen &&
    location?.pathname === "/admin/basket/remove-order"
  ) {
    return (
      <Orders
        RemoveOrder
        basketData={basketData}
        smallScreenBack={smallScreenBack}
      />
    );
  }

  if (isBasket) {
    return (
      <ScanBasket
        smallScreen={smallScreen}
        isRemoveOrder={location?.pathname === "/admin/basket/remove-order"}
        setSmallScreen={setSmallScreen}
      />
    );
  }

  if (isBasketDetails && !basketData) {
    push("/orders/basket");
    return null;
  }

  if (isBasketDetails) {
    return null;
  }

  return OrdersMemo;
};

const mapStateToProps = state => ({
  basketId: getBasketId(state),
  basketData: getBasketData(state),
  status: getBasketStatus(state),
  isRemoveBasketScan: getBasketScanFromRemoveBasket(state),
  refreshBasketData: getBasketRefreshBasketData(state)
});

const mapDispatchToProps = {
  sendNotification,
  setBasketId,
  fetchBasketData,
  fetchBasketStatus,
  showConfirmDialog,
  setAdminDialogOpen,
  smallScreenBack,
  push
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(Screen))
);
