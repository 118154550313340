/* eslint-disable react/jsx-filename-extension */
import React, { FC } from "react";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  dev: {
    backgroundColor: theme.palette.error.main
  },
  uat: {
    backgroundColor: theme.palette.warning.main
  },
  banner: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "0px 30px 0 30px",
    height: "38px",
    borderRadius: "4px",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(15),
    color: theme.palette.primary.contrastText
  }
}));

export interface EnvBannerProps {
  env: string;
  showBanner: boolean;
  onToggle(): void;
}

const EnvBanner: FC<EnvBannerProps> = ({ env, showBanner, onToggle }) => {
  const classes = useStyles();

  if (!["dev", "uat"].includes(env)) {
    return null;
  }

  return (
    <div
      data-testid={"env-banner/toggle-btn"}
      className={classes.banner}
      onClick={onToggle}
    >
      {showBanner ? (
        <div
          data-testid={"env-banner/container"}
          className={clsx(classes[env], classes.banner)}
        >
          <Typography data-testid={"env-banner/text"}>
            {`${env === "dev" ? "DEV" : "UAT"}`}
          </Typography>
        </div>
      ) : (
        <div data-testid={"env-banner/show-env"} className={classes.banner}>
          <Typography variant={"body1"}>Show Environment</Typography>
        </div>
      )}
    </div>
  );
};

export default EnvBanner;
