import { RSAA } from "redux-api-middleware";

import { STATUS_API_ENDPOINT, GP_API_ENDPOINT } from "./index";

// ACTIONS
export const FETCH_STATUS_COUNTS_REQUEST = "FETCH_STATUS_COUNTS_REQUEST";
export const FETCH_STATUS_COUNTS_SUCCESS = "FETCH_STATUS_COUNTS_SUCCESS";
export const FETCH_STATUS_COUNTS_FAILURE = "FETCH_STATUS_COUNTS_FAILURE";
export const FETCH_GP_CONTACT_REVIEW_COUNT_REQUEST =
  "FETCH_GP_CONTACT_REVIEW_COUNT_REQUEST";
export const FETCH_GP_CONTACT_REVIEW_COUNT_RECEIVE =
  "FETCH_GP_CONTACT_REVIEW_COUNT_RECEIVE";
export const FETCH_GP_CONTACT_REVIEW_COUNT_FAILURE =
  "FETCH_GP_CONTACT_REVIEW_COUNT_FAILURE";
export const FETCH_ROBOT_COUNTS_REQUEST = "FETCH_ROBOT_COUNTS_REQUEST";
export const FETCH_ROBOT_COUNTS_SUCCESS = "FETCH_ROBOT_COUNTS_SUCCESS";
export const FETCH_ROBOT_COUNTS_FAILURE = "FETCH_ROBOT_COUNTS_FAILURE";

// ACTION CREATORS
export const fetchNavigationStatusCounts = (
  showExpired,
  apiEndpoint = STATUS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/statuscounts?showExpired=${showExpired}`,
    method: "GET",
    types: [
      FETCH_STATUS_COUNTS_REQUEST,
      FETCH_STATUS_COUNTS_SUCCESS,
      FETCH_STATUS_COUNTS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const fetchContactReviewGPCount = (apiEndpoint = GP_API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practices/contactreview/count`,
    method: "GET",
    types: [
      FETCH_GP_CONTACT_REVIEW_COUNT_REQUEST,
      FETCH_GP_CONTACT_REVIEW_COUNT_RECEIVE,
      FETCH_GP_CONTACT_REVIEW_COUNT_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const fetchRobotCounts = (apiEndpoint = STATUS_API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/robotlistcounts`,
    method: "GET",
    types: [
      FETCH_ROBOT_COUNTS_REQUEST,
      FETCH_ROBOT_COUNTS_SUCCESS,
      FETCH_ROBOT_COUNTS_FAILURE
    ],
    options: { addAuth: true }
  }
});
