import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

const SET_ENABLED = "WHISTL/SET_ENABLED";

export const setWhistlEnabled = enabled => ({ type: SET_ENABLED, enabled });

export const getEnabled = state => state.enabled;

const enabled = handleActions(
  {
    [SET_ENABLED]: (state, action) => action.enabled
  },
  false // Forceing RM as part of 8692zauxy
);

export default combineReducers({ enabled });
