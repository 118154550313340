import { createAction } from "@reduxjs/toolkit";
import { RSAA, getJSON } from "redux-api-middleware";
import { Action } from "redux";
import { State, GPNotFoundPayload, NonGPPayload } from "./types";
import { isErrorResponse } from "../../helpers/types";
const PROFILE_API_ENDPOINT: string = process.env.REACT_APP_GP_PROFILE_API || "";

// Action types:
export const CREATE_GP_START = "CREATE_GP_START";
export const CREATE_GP_ODS_UPDATE = "CREATE_GP_ODS_UPDATE";
export const GP_EXISTS_REQUEST = "GP_EXISTS_REQUEST";
export const GP_EXISTS_RECIEVE = "GP_EXISTS_RECIEVE";
export const GP_EXISTS_FAILURE = "GP_EXISTS_FAILURE";
export const CREATE_GP_FETCH_NHS_REQUEST = "CREATE_GP_FETCH_NHS_REQUEST";
export const CREATE_GP_FETCH_NHS_RECIEVE = "CREATE_GP_FETCH_NHS_RECIEVE";
export const CREATE_GP_FETCH_NHS_FAILURE = "CREATE_GP_FETCH_NHS_FAILURE";

// Action creators:
export const createGPStart = createAction<undefined>(CREATE_GP_START);
export const createGPODSUpdate = createAction<string>(CREATE_GP_ODS_UPDATE);

type JsonFunc = (res: any) => object;

export const handleExistsFailure = async (
  res: Record<string, unknown>,
  getJSONFunc: JsonFunc
) => {
  if (res.status === 404) {
    // get the body to confirm this is a "proper" practice not found:
    const body: unknown = await getJSONFunc(res);
    if (
      isErrorResponse(body) &&
      body.error.errorCode === "practice_not_found"
    ) {
      // wrapped as such will not trigger the error mechanism:
      const gpNotFound: GPNotFoundPayload = { gpNotFound: true };
      return gpNotFound;
    }
  }
  return res;
};

export const callGPExists = (
  id: string,
  apiEndpoint: string = PROFILE_API_ENDPOINT
): object => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practice/${id}`,
    method: "GET",
    types: [
      GP_EXISTS_REQUEST,
      GP_EXISTS_RECIEVE,
      {
        type: GP_EXISTS_FAILURE,
        payload: async (
          _action: Action,
          _state: State,
          res: Record<string, unknown>
        ) => handleExistsFailure(res, getJSON)
      }
    ],
    options: { addAuth: true }
  }
});

export const fetchNHSGP = (
  id: string,
  apiEndpoint: string = PROFILE_API_ENDPOINT
): object => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practice/${id}/nhsrefresh`,
    method: "GET",
    types: [
      CREATE_GP_FETCH_NHS_REQUEST,
      CREATE_GP_FETCH_NHS_RECIEVE,
      {
        type: CREATE_GP_FETCH_NHS_FAILURE,
        payload: async (
          _action: Action,
          _state: State,
          res: Record<string, unknown>
        ) => handleFetchFailure(res, getJSON)
      }
    ],
    options: { addAuth: true }
  }
});

export const handleFetchFailure = async (
  res: Record<string, unknown>,
  getJSONFunc: JsonFunc
) => {
  if (res && res.status === 404) {
    const body: unknown = await getJSONFunc(res);
    if (
      isErrorResponse(body) &&
      body.error.errorCode === "practice_not_found"
    ) {
      const gpNotFound: GPNotFoundPayload = { gpNotFound: true };
      return gpNotFound;
    }
    if (isErrorResponse(body) && body.error.errorCode === "not_gp") {
      const notGP: NonGPPayload = { notGP: true };
      return notGP;
    }
  }
  return res;
};
