import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getBasketIsLoading,
  getBasketData,
  getSelectedOrderIds,
  getBasketTransferDialogIsOpen,
  getBasketId,
  getSelectedOrder
} from "redux/root-reducer";
import Orders from "components/orders";
import LoadingButton from "components/common/loading-button";
import { linkBasket } from "../../redux/consolidate-orders-dialog";
import { hideTransferBasketDialog, setBasketId } from "redux/scanner-wrapper";

const TransferBasketDialog = ({
  open,
  close,
  basketData,
  basketId,
  onSubmit,
  selectedOrderIds,
  setBasketId,
  selectedOrder,
  isLoading
}) => {
  const handleBasketIdChange = e => {
    setBasketId(e.target.value);
  };

  const handleClose = () => {
    close(basketData?.id ?? "");
  };

  const handleSubmit = () => {
    onSubmit(selectedOrderIds, basketId);
  };

  const renderOrders = () => (
    <Orders
      orders={
        basketData
          ? basketData.orders?.filter(order =>
              selectedOrderIds.includes(order.id)
            )
          : [selectedOrder]
      }
      showEvidenceColumn
      hideNewOrderBtn
      displaySettings={{
        showOrderId: true,
        showNotes: true,
        showPatient: true,
        showGP: true,
        showOrderStatus: true,
        showDeliveryAddress: true,
        showBarcodeColumn: true,
        showLatestEvent: true,
        showBarcodeForOrder: status => ["cf_rejected"].includes(status),
        showShippingClass: true,
        showShippingButtonsForOrder: status => ["cf_rejected"].includes(status)
      }}
      requiredFetch={false}
      disableContextMenu
    />
  );

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>Scan an empty basket for this order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you don't have a basket scanner at your station, you can enter the
          basket ID manually.
        </DialogContentText>
        <TextField
          id={"t_basket_id"}
          label={"Basket ID"}
          value={basketId}
          onChange={handleBasketIdChange}
          style={{ marginBottom: "24px" }}
          fullWidth
        />
        {renderOrders()}
      </DialogContent>
      <DialogActions>
        <Button id={"t_close_button"} color={"secondary"} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          id={"t_update"}
          color={"primary"}
          loading={isLoading}
          onClick={handleSubmit}
          disabled={!basketId}
          fullWidth
        >
          LINK BASKET
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  open: getBasketTransferDialogIsOpen(state),
  basketData: getBasketData(state),
  selectedOrder: getSelectedOrder(state),
  basketId: getBasketId(state),
  isLoading: getBasketIsLoading(state),
  selectedOrderIds: getSelectedOrderIds(state)
});

const mapDispatchToProps = {
  close: hideTransferBasketDialog,
  onSubmit: linkBasket,
  setBasketId
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransferBasketDialog)
);
