import { handleActions } from "redux-actions";

// Actions
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

// Action Creators
export const sendNotification = ({
  message,
  duration = 3000,
  anchorOrigin,
  bgColor
}) => ({
  type: SEND_NOTIFICATION,
  message,
  duration,
  anchorOrigin,
  bgColor
});

export const closeNotification = () => ({
  type: HIDE_NOTIFICATION
});

// Selectors
export const getIsVisible = state => state.visible;
export const getMessage = state => state.message;
export const getDuration = state => state.duration;
export const getAnchorOrigin = state => state.anchorOrigin;
export const getBgColor = state => state.bgColor;

// Reducers
export default handleActions(
  {
    [SEND_NOTIFICATION]: (state, action) => ({
      ...state,
      visible: true,
      message: action.message,
      duration: action.duration,
      anchorOrigin: action.anchorOrigin,
      bgColor: action.bgColor
    }),
    [HIDE_NOTIFICATION]: state => ({ ...state, visible: false })
  },
  {
    visible: false,
    message: "",
    duration: 0
  }
);
