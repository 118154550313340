import React, { FC } from "react";
import { Box } from "@material-ui/core";
import s from "./refreshed.module.scss";
import { MatchStatus } from "./map-maker";

export interface RefreshedFieldProps {
  label: string;
  value: JSX.Element[] | string;
  status?: MatchStatus;
}

const valueOrNBSP = (value: JSX.Element[] | string): JSX.Element[] | string =>
  value !== "" ? value : "\u00A0";

const RefreshedField: FC<RefreshedFieldProps> = ({ label, value, status }) => {
  return (
    <div className={s.container}>
      <Box display={"inline"} color={"text.secondary"} className={s.label}>
        {" "}
        {label}{" "}
      </Box>
      {status === MatchStatus.UPDATED && (
        <Box className={s.updated} display={"inline"} color={"secondary.main"}>
          Updated
        </Box>
      )}
      {status === MatchStatus.VERIFIED && (
        <Box className={s.updated} display={"inline"} color={"primary.main"}>
          Verified
        </Box>
      )}
      {status === MatchStatus.UNVERIFIED && (
        <Box className={s.updated} display={"inline"} color={"warning.main"}>
          Unverified
        </Box>
      )}

      <div className={s.field}>{valueOrNBSP(value)}</div>
    </div>
  );
};

export default RefreshedField;
