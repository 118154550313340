import React, { Component } from "react";
import PropTypes from "prop-types";

class InfiniteScroll extends Component {
  componentDidMount() {
    this.io = new IntersectionObserver(this.onIntersection, {
      root: this.root,
      rootMargin: "250px"
    });

    this.io.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.io.disconnect();
  }

  onIntersection = entries => {
    const { isLoading, onReachThreshold } = this.props;

    if (isLoading) {
      return;
    }

    entries.forEach(entry => {
      if (entry.isIntersecting) {
        onReachThreshold(entry);
      }
    });
  };

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>
        {children}
        <tr
          style={{
            display: "block",
            height: "1px",
            width: "100%"
          }}
          ref={element => {
            this.sentinel = element;
          }}
        />
      </React.Fragment>
    );
  }
}

InfiniteScroll.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onReachThreshold: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default InfiniteScroll;
