import React, { FC, useCallback, useState } from "react";
import EnvBanner from "./env-banner.ui";

const EnvBannerContainer: FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const onToggle = useCallback(() => {
    setShowBanner(prev => !prev);
  }, [setShowBanner]);
  const env = process.env.REACT_APP_NAV_BANNER || "";

  return <EnvBanner env={env} onToggle={onToggle} showBanner={showBanner} />;
};

export default EnvBannerContainer;
