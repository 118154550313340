import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { showConfirmDialog } from "../confirm-dialog";
import { RSAA } from "redux-api-middleware";
import { FETCH_REQUEST_RECEIVE, FETCH_REQUEST_REQUEST } from "../requests";

const HOFF_API = process.env.REACT_APP_HOFF_STAFF_API;

// ACTIONS
export const SELECT_MATCH_SCRIPTS_USER =
  "MATCH-SCRIPTS/SELECT_MATCH_SCRIPTS_USER";
export const SELECT_MATCH_SCRIPTS_SCRIPT =
  "MATCH-SCRIPTS/SELECT_MATCH_SCRIPTS_SCRIPT";

export const MATCH_SCRIPT_REQUEST = "MATCH-SCRIPTS/MATCH_SCRIPT_REQUEST";
export const MATCH_SCRIPT_RECEIVE = "MATCH-SCRIPTS/MATCH_SCRIPT_RECEIVE";
export const MATCH_SCRIPT_FAILURE = "MATCH-SCRIPTS/MATCH_SCRIPT_FAILURE";

export const GET_UNMATCHED_SCRIPTS_REQUEST =
  "MATCH_SCRIPTS/GET_UNMATCHED_SCRIPTS_REQUEST";
export const GET_UNMATCHED_SCRIPTS_RECEIVE =
  "MATCH_SCRIPTS/GET_UNMATCHED_SCRIPTS_RECEIVE";
export const GET_UNMATCHED_SCRIPTS_FAILURE =
  "MATCH_SCRIPTS/GET_UNMATCHED_SCRIPTS_FAILURE";

export const CLEAR_MATCH_SCRIPTS_USER =
  "MATCH-SCRIPTS/CLEAR_MATCH_SCRIPTS_USER";
export const CLEAR_MATCH_SCRIPTS_SCRIPT =
  "MATCH-SCRIPTS/CLEAR_MATCH_SCRIPTS_SCRIPT";

// ACTION CREATORS
export const selectUser = user => ({
  type: SELECT_MATCH_SCRIPTS_USER,
  payload: { user }
});
export const selectScript = script => ({
  type: SELECT_MATCH_SCRIPTS_SCRIPT,
  payload: { script }
});
export const clearSelectedUser = () => ({
  type: CLEAR_MATCH_SCRIPTS_USER
});
export const clearSelectedScript = () => ({
  type: CLEAR_MATCH_SCRIPTS_SCRIPT
});

export const confirmScriptMatch = (script, request) => {
  return showConfirmDialog({
    title: "Confirm prescription match",
    message:
      "This will match the prescription with the selected request. This action cannot be undone.",
    positiveActionTitle: "Confirm",
    agreeActionCreator: () =>
      matchScriptToRequestOrCreateOrderFromScratch(script, request)
  });
};

export const confirmCreateOrder = (pmrUser, script) => {
  return showConfirmDialog({
    title: "Confirm order creation",
    message: `This will create a new order for ${pmrUser.name} from the prescription. This action cannot be undone.`,
    positiveActionTitle: "Confirm",
    agreeActionCreator: () =>
      matchScriptToRequestOrCreateOrderFromScratch(script)
  });
};

export const matchScriptToRequestOrCreateOrderFromScratch = (
  script,
  request,
  uniqueId = Date.now(),
  apiEndpoint = HOFF_API
) => {
  const requestId = request ? request.id : undefined;

  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/orders/${script.id}/match`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ requestId: requestId }),
      types: [
        MATCH_SCRIPT_REQUEST,
        MATCH_SCRIPT_RECEIVE,
        MATCH_SCRIPT_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

export const fetchUnmatchedScripts = (
  uniqueId = Date.now(),
  apiEndpoint = HOFF_API
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/orders/unmatched`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        GET_UNMATCHED_SCRIPTS_REQUEST,
        GET_UNMATCHED_SCRIPTS_RECEIVE,
        GET_UNMATCHED_SCRIPTS_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

// SELECTORS
export const getSelectedUser = state => state.selectedUser;
export const getSelectedScript = state => state.selectedScript;
export const getUserRequests = (state, pharmacyUserId) =>
  state.requestsByUserId[pharmacyUserId];
export const getNeedsUpdate = state => state.needsRefresh;

export const getUnmatchedScripts = (state, pmrUserId) =>
  state.unmatchedScripts
    .filter(v => v.user.pmrId === pmrUserId)
    .map(v => ({ id: v.id, medicines: v.medicines }));

export const getUnmatchedUsers = state => {
  const users = [];
  const userIds = new Set();
  state.unmatchedScripts.forEach(data => {
    if (!userIds.has(data.user.pmrId)) {
      users.push(data.user);
      userIds.add(data.user.pmrId);
    }
  });
  return users;
};
export const getFetching = state => state.fetching;
export const getMatching = state => state.matching;

// REDUCERS
const selectedUser = handleActions(
  {
    [SELECT_MATCH_SCRIPTS_USER]: (state, action) => action.payload.user,
    [MATCH_SCRIPT_RECEIVE]: () => null,
    [CLEAR_MATCH_SCRIPTS_USER]: () => null
  },
  null
);
const selectedScript = handleActions(
  {
    [SELECT_MATCH_SCRIPTS_SCRIPT]: (state, action) => action.payload.script,
    [SELECT_MATCH_SCRIPTS_USER]: () => null,
    [MATCH_SCRIPT_RECEIVE]: () => null,
    [CLEAR_MATCH_SCRIPTS_SCRIPT]: () => null
  },
  null
);
const unmatchedScripts = handleActions(
  {
    [GET_UNMATCHED_SCRIPTS_RECEIVE]: (state, action) => action.payload.data
  },
  []
);

const requestsByUserId = handleActions(
  {
    [FETCH_REQUEST_REQUEST]: () => {},
    [FETCH_REQUEST_RECEIVE]: (state, action) => ({
      ...state,
      ...action.payload.reduce((acc, req) => {
        if (acc[req.patient.userId]) {
          acc[req.patient.userId].push(req);
          return acc;
        }
        return { ...acc, [req.patient.userId]: [req] };
      }, {})
    })
  },
  {}
);

const needsRefresh = handleActions(
  {
    [MATCH_SCRIPT_RECEIVE]: () => true,
    [GET_UNMATCHED_SCRIPTS_RECEIVE]: () => false
  },
  false
);

const fetching = handleActions(
  {
    [GET_UNMATCHED_SCRIPTS_REQUEST]: () => true,
    [GET_UNMATCHED_SCRIPTS_RECEIVE]: () => false,
    [GET_UNMATCHED_SCRIPTS_FAILURE]: () => false
  },
  false
);

const matching = handleActions(
  {
    [MATCH_SCRIPT_REQUEST]: () => true,
    [MATCH_SCRIPT_RECEIVE]: () => false,
    [MATCH_SCRIPT_FAILURE]: () => false
  },
  false
);

export default combineReducers({
  selectedUser,
  selectedScript,
  unmatchedScripts,
  requestsByUserId,
  needsRefresh,
  fetching,
  matching
});
