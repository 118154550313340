import { connect } from "react-redux";
import {
  addMedicine,
  hideMarkOrderCustomerService,
  markCustomerService,
  removeMedicine
} from "../../redux/mark-order-customer-service";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import LoadingButton from "../common/loading-button";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getMarkOrderCSDialogCanSubmit,
  getMarkOrderCSDialogIsOpen,
  getMarkOrderCSDialogIsUpdating,
  getMarkOrderCSDialogMedicines,
  getMarkOrderCSDialogOrderId,
  getMarkOrderCSDialogSelectedMedicines
} from "../../redux/root-reducer";

export const MarkOrderCSDialog = ({
  open,
  medicines,
  onCancel,
  selectedMedicines,
  canSubmit,
  onSubmit,
  orderId,
  isLoading,
  onCheckboxChange
}) => (
  <Dialog open={open} fullWidth>
    <DialogTitle>Mark order as Customer Service</DialogTitle>
    <DialogContent>
      <DialogContentText>Select the medicines that are to be marked</DialogContentText>
      {medicines.map((medicine, i) => (
        <div key={i}>
          <FormControlLabel
            id={`t_cs_medicine_${i}`}
            control={
              <Checkbox
                className={`t_medicine_checkbox_${i}`}
                checked={selectedMedicines.includes(i)}
                onChange={() => onCheckboxChange(i, selectedMedicines)}
              />
            }
            label={`${medicine.desc}`}
          />
        </div>
      ))}
    </DialogContent>
    <DialogActions>
      <Button
        id="t_cancel"
        onClick={onCancel}
        color="secondary"
        disabled={isLoading}
      >
        Cancel
      </Button>
      <LoadingButton
        id="t_agree"
        onClick={() => onSubmit(orderId, medicines, selectedMedicines)}
        color="primary"
        autoFocus
        disabled={!canSubmit}
        loading={isLoading}
      >
        Mark As Customer Service
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export const mapDispatchToProps = dispatch => ({
  onCheckboxSelect: (medicine, selectedMedicines) =>
    dispatch(addMedicine(medicine, selectedMedicines)),
  onCheckboxDeselect: (medicine, selectedMedicines) =>
    dispatch(removeMedicine(medicine, selectedMedicines)),
  onCancel: () => dispatch(hideMarkOrderCustomerService()),
  onSubmit: (orderID, medicines, selectedMedicines) =>
    dispatch(markCustomerService(orderID, medicines, selectedMedicines))
});

export const mapStateToProps = state => ({
  open: getMarkOrderCSDialogIsOpen(state),
  medicines: getMarkOrderCSDialogMedicines(state),
  selectedMedicines: getMarkOrderCSDialogSelectedMedicines(state),
  canSubmit: getMarkOrderCSDialogCanSubmit(state),
  orderId: getMarkOrderCSDialogOrderId(state),
  isLoading: getMarkOrderCSDialogIsUpdating(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...stateProps,
  onCheckboxChange: (medicine, selectedMedicines) => {
    const isChecked = selectedMedicines.includes(medicine);
    if (isChecked) {
      dispatchProps.onCheckboxDeselect(medicine, selectedMedicines);
    } else {
      dispatchProps.onCheckboxSelect(medicine, selectedMedicines);
    }
  },
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MarkOrderCSDialog);
