import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import Cancel from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import AutoComplete from "../common/autocomplete";
import {
  clearSuggestions,
  fetchSuggestions,
  selectSuggestion
} from "../../redux/search";
import { getSearchSuggestions, getSearchText } from "../../redux/root-reducer";
import { formatDateString } from "../../helpers/date-helpers";
import s from "./search.module.scss";

export const Search = ({
  onTextChange,
  onClose,
  suggestions,
  textValue,
  onSuggestionSelect
}) => (
  <div className={s.root}>
    <div className={s.search}>
      <AutoComplete
        id="t_autocomplete_search"
        placeholder="Search for user name"
        onTextChange={t => onTextChange(t)}
        onFocus={() => onTextChange(textValue)}
        onOuterClick={() => onClose()}
        onSuggestionSelect={onSuggestionSelect}
        suggestions={suggestions.map(p => ({
          name: `${p.firstName} ${p.lastName}`,
          description: `${formatDateString(p.dob)}, ${p.addressLine1}, ${
            p.postcode
          }`
        }))}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {suggestions.length < 1 ? null : (
                <Tooltip title="cancel">
                  <Cancel
                    onClick={() => onClose()}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              )}
              <span />
            </InputAdornment>
          )
        }}
        text={textValue}
      />
    </div>
  </div>
);
Search.propTypes = {
  onTextChange: PropTypes.func,
  onSuggestionSelect: PropTypes.func,
  onClose: PropTypes.func,
  suggestions: PropTypes.array,
  textValue: PropTypes.string
};
Search.defaultProps = {
  textValue: "",
  onSuggestionSelect: () => {},
  onTextChange: () => {},
  onClose: () => {},
  suggestions: []
};

const mapStateToProps = state => ({
  textValue: getSearchText(state),
  suggestions: getSearchSuggestions(state)
});

const mapDispatchToProps = {
  onSelect: selectSuggestion,
  closeSuggestions: selectSuggestion,
  onTextChange: fetchSuggestions,
  onClose: clearSuggestions,
  navigate: u => push(`/user/${u.userId}`)
};

export const mergeProps = (stateProps, dispatchProps) => ({
  onSuggestionSelect: index => {
    const selection = stateProps.suggestions[index];
    dispatchProps.onSelect(selection);
    dispatchProps.navigate(selection);
  },
  ...dispatchProps,
  ...stateProps
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Search)
);
