import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const style = {
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
};

const LoadingButton = ({ classes, loading, children, ...other }) => (
  <div className={classes.root}>
    <Button {...other} disabled={loading || other.disabled}>
      {children}
    </Button>
    {loading && (
      <CircularProgress size={24} className={classes.buttonProgress} />
    )}
  </div>
);

LoadingButton.propTypes = {
  ...Button.propTypes,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool
};

LoadingButton.defaultProps = {
  loading: false
};

export default withStyles(style)(LoadingButton);
