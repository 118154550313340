import React from "react";
import PropTypes from "prop-types";
import { formatDateTimeString } from "../../helpers/date-helpers";
import s from "./common-styles.module.scss";

const LatestEventCell = ({ lastEventName, lastModifiedDate }) => (
  <div className={s.detailsCell}>
    {lastEventName || "last event"} on {formatDateTimeString(lastModifiedDate)}
  </div>
);
LatestEventCell.propTypes = {
  lastEventName: PropTypes.string.isRequired,
  lastModifiedDate: PropTypes.string.isRequired
};

export default LatestEventCell;
