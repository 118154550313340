/* eslint-disable @typescript-eslint/camelcase */
const STATUS_MAP = {
  invoiced: "Awaiting Exemption/Payment",
  invoice_settled: "Processing",
  exemption_provided: "Processing",
  cf_rejected: "Processing",
  hm_accepted: "Hub Manager Processing",
  hm_delivered: "Hub Manager Delivered",
  dispensed: "Ready to Pick-up",
  dispatched: "Posted",
  delivered: "Delivered",
  cancelled: "Cancelled",
  prescription_printed: "Prescription Printed",
  prescription_returned: "Prescription Returned",
  mcs: "MCS - Please handle on customer contact",
  cc_accepted: "Processing for collection",
  cc_transferred: "Transferred to store",
  cc_downloaded: "Downloaded at store",
  cc_dispensed: "Dispensed at store",
  cc_delivered: "Collected at store",
  cc_not_collected: "Not collected from store",
  checkoutbuffer_completed: "Processing",
  manual_check: "HRT Mix",
  mcs_pending: "Processing",
  customer_service: "Customer Service",
  not_dispensed: "Marked ND",
  part_processing: "Part Processing",
  part_dispatched: "Part Dispatched",
  part_delivered: "Part Delivered"
};

export const getFormattedStatus = ({ status }) => STATUS_MAP[status] || status;

export const getEvidenceNeedsExpiration = ({ invoice }) =>
  invoice.exemptionCode
    .split(",")
    .map(code => code.toUpperCase())
    .some(code => ["W", "L", "F", "E", "D"].includes(code));
