import React from "react";
import PropTypes from "prop-types";
import s from "./common-styles.module.scss";
import NeedsAttention from "components/svgs/needs-attention";

const MedicinesCell = ({ medicines }) => (
  <div className={s.detailsCell} style={{ maxWidth: 360 }}>
    {(medicines || []).map((medicine, i) => (
      <div key={i}>
        {medicine.isMcs && (
          <span
            className={s.mcsItemWarnIcon}
            style={{ marginBottom: "8px", display: "inline-block" }}
          >
            <NeedsAttention lastModifiedDate={null} /> <span>MCS item</span>
          </span>
        )}
        <p
          className={s.primary}
          style={{
            marginBottom: "4px",
            color: medicine.isMcs ? "rgb(209, 50, 66)" : undefined
          }}
        >
          {medicine.brandName || medicine.drugName}
        </p>
        <p className={s.small}>{medicine.brandDesc || medicine.prodDesc}</p>
        {medicine.quantity &&
          medicine.quantity.count &&
          medicine.quantity.unitOfMeasure && (
            <p className={s.small}>
              {medicine.quantity.count} {medicine.quantity.unitOfMeasure}
            </p>
          )}
      </div>
    ))}
  </div>
);
MedicinesCell.propTypes = {
  medicines: PropTypes.array
};
MedicinesCell.defaultProps = {
  medicines: []
};

export default MedicinesCell;
