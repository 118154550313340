import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import {
  getReturnPrescriptionDialogErrorMessage,
  getReturnPrescriptionDialogInputText,
  getReturnPrescriptionDialogIsLoading,
  getReturnPrescriptionDialogIsOpen,
  getReturnPrescriptionDialogOrder,
  getReturnPrescriptionDialogShowError
} from "../../redux/root-reducer";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {
  changeReturnPrescriptionInputText,
  hideReturnPrescriptionDialog,
  returnPrescription,
  returnPrescriptionCFRejected
} from "../../redux/return-prescription-dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import LoadingButton from "../common/loading-button";

const errorDialogContent = {
  root: {
    color: "red"
  }
};
const ErrorDialogContent = withStyles(errorDialogContent)(DialogContentText);

export const ReturnPrescriptionDialog = ({
  open,
  close,
  order,
  showError,
  errorMessage,
  onPrescriptionCodeChange,
  prescriptionCode,
  onSubmit,
  isLoading
}) => (
  <Dialog open={open} maxWidth={"md"} fullWidth>
    <DialogTitle>Return Prescription</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Enter the prescription code for this order below:
      </DialogContentText>
      {showError && (
        <ErrorDialogContent>
          Something went wrong:
          <br />
          {errorMessage}
        </ErrorDialogContent>
      )}
      <TextField
        id={"t_prescription_code"}
        label={"Prescription Code"}
        fullWidth
        onChange={e => onPrescriptionCodeChange(e.target.value)}
        value={prescriptionCode}
      />
    </DialogContent>
    <DialogActions>
      <Button id={"t_close_button"} color={"secondary"} onClick={() => close()}>
        Close
      </Button>
      <LoadingButton
        id={"t_update"}
        color={"primary"}
        loading={isLoading}
        onClick={() => onSubmit(order, prescriptionCode)}
      >
        Submit
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export const mapStateToProps = state => ({
  open: getReturnPrescriptionDialogIsOpen(state),
  order: getReturnPrescriptionDialogOrder(state),
  showError: getReturnPrescriptionDialogShowError(state),
  prescriptionCode: getReturnPrescriptionDialogInputText(state),
  errorMessage: getReturnPrescriptionDialogErrorMessage(state),
  isLoading: getReturnPrescriptionDialogIsLoading(state)
});

const mapDispatchToProps = {
  close: hideReturnPrescriptionDialog,
  onPrescriptionCodeChange: changeReturnPrescriptionInputText,
  onSubmit: window.location.pathname.includes("cf-rejected")
    ? returnPrescriptionCFRejected
    : returnPrescription
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnPrescriptionDialog);
