import { RSAA } from "redux-api-middleware";

import { ORDERS_API_ENDPOINT } from "./index";
import { Order } from "./orders.types";
import { buildFetchOrdersEndpoint } from "./orders.helpers";
import { MedSuggestion } from "redux/meds-search";
import { NEXT_ACTION } from "redux/middlewares/next";
import { getOrdersSelectedTabIndex } from "redux/root-reducer";

export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_RECEIVE = "FETCH_ORDERS_RECEIVE";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const FETCH_ORDERS_PAGED_REQUEST = "FETCH_ORDERS_PAGED_REQUEST";
export const FETCH_ORDERS_PAGED_RECEIVE = "FETCH_ORDERS_PAGED_RECEIVE";
export const FETCH_ORDERS_PAGED_FAILURE = "FETCH_ORDERS_PAGED_FAILURE";

export const DISPATCH_ORDERS_REQUEST = "DISPATCH_ORDERS_REQUEST";
export const DISPATCH_ORDERS_RECEIVE = "DISPATCH_ORDERS_RECEIVE";
export const DISPATCH_ORDERS_FAILURE = "DISPATCH_ORDERS_FAILURE";

export const DELIVER_ORDER_REQUEST = "DELIVER_ORDER_REQUEST";
export const DELIVER_ORDER_RECEIVE = "DELIVER_ORDER_RECEIVE";
export const DELIVER_ORDER_FAILURE = "DELIVER_ORDER_FAILURE";

export const MARK_ORDER_MCS_REQUEST = "MARK_ORDER_MCS_REQUEST";
export const MARK_ORDER_MCS_RECEIVE = "MARK_ORDER_MCS_RECEIVE";
export const MARK_ORDER_MCS_FAILURE = "MARK_ORDER_MCS_FAILURE";

export const RESOLVE_ORDER_MCS_REQUEST = "RESOLVE_ORDER_MCS_REQUEST";
export const RESOLVE_ORDER_MCS_RECEIVE = "RESOLVE_ORDER_MCS_RECEIVE";
export const RESOLVE_ORDER_MCS_FAILURE = "RESOLVE_ORDER_MCS_FAILURE";
export const RESOLVE_ORDER_CS_REQUEST = "RESOLVE_ORDER_CS_REQUEST";
export const RESOLVE_ORDER_CS_RECEIVE = "RESOLVE_ORDER_CS_RECEIVE";
export const RESOLVE_ORDER_CS_FAILURE = "RESOLVE_ORDER_CS_FAILURE";

export const MARK_ND_MCS_REQUEST = "MARK_ND_MCS_REQUEST";
export const MARK_ND_MCS_RECEIVE = "MARK_ND_MCS_RECEIVE";
export const MARK_ND_MCS_FAILURE = "MARK_ND_MCS_FAILURE";

export const RELEASE_ORDER_REQUEST = "RELEASE_ORDER_REQUEST";
export const RELEASE_ORDER_RECEIVE = "RELEASE_ORDER_RECEIVE";
export const RELEASE_ORDER_FAILURE = "RELEASE_ORDER_FAILURE";

export const UPDATE_ORDER_MCS_REQUEST = "UPDATE_ORDER_MCS_REQUEST";
export const UPDATE_ORDER_MCS_RECEIVE = "UPDATE_ORDER_MCS_RECEIVE";
export const UPDATE_ORDER_MCS_RECEIVE_REFRESH =
  "UPDATE_ORDER_MCS_RECEIVE_REFRESH";
export const UPDATE_ORDER_MCS_FAILURE = "UPDATE_ORDER_MCS_FAILURE";
export const UPDATE_ORDER_CS_REQUEST = "UPDATE_ORDER_CS_REQUEST";
export const UPDATE_ORDER_CS_RECEIVE = "UPDATE_ORDER_CS_RECEIVE";
export const UPDATE_ORDER_CS_FAILURE = "UPDATE_ORDER_CS_FAILURE";

export const PRINT_ORDERS_REQUEST = "PRINT_ORDERS_REQUEST";
export const PRINT_ORDERS_RECEIVE = "PRINT_ORDERS_RECEIVE";
export const PRINT_ORDERS_FAILURE = "PRINT_ORDERS_FAILURE";

export const DISPENSE_ORDERS_REQUEST = "DISPENSE_ORDERS_REQUEST";
export const DISPENSE_ORDERS_RECEIVE = "DISPENSE_ORDERS_RECEIVE";
export const DISPENSE_ORDERS_FAILURE = "DISPENSE_ORDERS_FAILURE";

export const HM_DELIVER_ORDERS_REQUEST = "HM_DELIVER_ORDERS_REQUEST";
export const HM_DELIVER_ORDERS_RECEIVE = "HM_DELIVER_ORDERS_RECEIVE";
export const HM_DELIVER_ORDERS_FAILURE = "HM_DELIVER_ORDERS_FAILURE";

export const CANCEL_ORDERS_REQUEST = "CANCEL_ORDERS_REQUEST";
export const CANCEL_ORDERS_RECEIVE = "CANCEL_ORDERS_RECEIVE";
export const CANCEL_ORDERS_FAILURE = "CANCEL_ORDERS_FAILURE";

export const UPDATE_ORDER_PRIORITY_REQUEST = "UPDATE_ORDER_PRIORITY_REQUEST";
export const UPDATE_ORDER_PRIORITY_RECEIVE = "UPDATE_ORDER_PRIORITY_RECEIVE";
export const UPDATE_ORDER_PRIORITY_FAILURE = "UPDATE_ORDER_PRIORITY_FAILURE";

export const SELECT_ORDERS = "SELECT_ORDERS";
export const SELECT_ORDERS_TAB = "SELECT_ORDERS_TAB";
export const SELECT_ORDERS_SUB_TAB = "SELECT_ORDERS_SUB_TAB";

export const SELECT_ORDER_SEQUENCE_NUMBER = "SELECT_ORDER_SEQUENCE_NUMBER";

export const SELECT_MEDICINE_FILTER = "SELECT_MEDICINE_FILTER";
export const DELETE_MEDICINE_FILTER = "DELETE_MEDICINE_FILTER";
export const CLEAR_ORDER_FILTERS = "CLEAR_ORDER_FILTERS";
export const TOGGLE_ORDERS_FILTER = "TOGGLE_ORDERS_FILTER";

export const FETCH_SEQ_COUNT_REQUEST = "FETCH_SEQ_COUNT_REQUEST";
export const FETCH_SEQ_COUNT_RECEIVE = "FETCH_SEQ_COUNT_RECEIVE";
export const FETCH_SEQ_COUNT_FAILURE = "FETCH_SEQ_COUNT_FAILURE";

export const READY_TO_PRINT_REQUEST = "READY_TO_PRINT_REQUEST";
export const READY_TO_PRINT_RECEIVE = "READY_TO_PRINT_RECEIVE";
export const READY_TO_PRINT_FAILURE = "READY_TO_PRINT_FAILURE";

export const fetchOrders = (
  filters: string[] = [],
  showExpired = false,
  vmpIds: string[] = [],
  ampIds: string[] = [],
  dateFilter: string[] = [],
  dateFilterPriority: boolean,
  userId: string,
  ordersPerPage: number,
  splitsCount?: number,
  splitIndex?: number,
  orderSequence?: number,
  tab?: string,
  splitAlpha?: string,
  apiEndpoint: string | undefined = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: buildFetchOrdersEndpoint({
      page: 0,
      ordersPerPage,
      userId,
      splitIndex,
      splitsCount,
      orderSequence,
      apiEndpoint,
      filters,
      showExpired,
      vmpIds,
      ampIds,
      dateFilter,
      dateFilterPriority,
      tab,
      splitAlpha
    }),
    method: "GET",
    types: [FETCH_ORDERS_REQUEST, FETCH_ORDERS_RECEIVE, FETCH_ORDERS_FAILURE],
    options: { addAuth: true }
  }
});

export const fetchAdditionalOrders = (
  page = 1,
  filters: string[] = [],
  showExpired = false,
  vmpIds: string[] = [],
  ampIds: string[] = [],
  dateFilter: string[] = [],
  dateFilterPriority: boolean,
  userId: string,
  ordersPerPage: number,
  splitsCount?: number,
  splitIndex?: number,
  orderSequence?: number,
  tab?: string,
  splitAlpha?: string,
  apiEndpoint: string | undefined = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: buildFetchOrdersEndpoint({
      page,
      ordersPerPage,
      userId,
      splitIndex,
      splitsCount,
      orderSequence,
      apiEndpoint,
      filters,
      showExpired,
      vmpIds,
      ampIds,
      dateFilter,
      dateFilterPriority,
      tab,
      splitAlpha
    }),
    method: "GET",
    types: [
      FETCH_ORDERS_PAGED_REQUEST,
      FETCH_ORDERS_PAGED_RECEIVE,
      FETCH_ORDERS_PAGED_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markDispatched = (
  orders: Order[],
  actOnAll = false,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/orders/dispatch`,
    method: "POST",
    body: JSON.stringify({
      orderIds: orders.map(o => o.id),
      actOnAll
    }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      DISPATCH_ORDERS_REQUEST,
      DISPATCH_ORDERS_RECEIVE,
      DISPATCH_ORDERS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markPrinted = (
  order: Order,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/printprescription`,
    method: "POST",
    types: [PRINT_ORDERS_REQUEST, PRINT_ORDERS_RECEIVE, PRINT_ORDERS_FAILURE],
    options: { addAuth: true }
  }
});

export const markPrintedCFRejected = (
  order: Order,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/printprescription`,
    method: "POST",
    types: [
      PRINT_ORDERS_REQUEST,
      {
        type: PRINT_ORDERS_RECEIVE,
        payload: (action, state, res) =>
          res.json().then(() => ({
            [NEXT_ACTION]: fetchSeqCount(getOrdersSelectedTabIndex(state) - 1)
          }))
      },
      PRINT_ORDERS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markDispensed = (
  order: Order,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/dispense`,
    method: "POST",
    types: [
      DISPENSE_ORDERS_REQUEST,
      DISPENSE_ORDERS_RECEIVE,
      DISPENSE_ORDERS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markHMDelivered = (
  order: Order,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/hmdeliver`,
    method: "POST",
    types: [
      HM_DELIVER_ORDERS_REQUEST,
      HM_DELIVER_ORDERS_RECEIVE,
      HM_DELIVER_ORDERS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markCancelled = (
  order: Order,
  notes: string,
  sendNotification?: boolean,
  apiEndpoint = ORDERS_API_ENDPOINT
) => {
  const basketId = order.basketId;
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/order/${order.id}/cancel`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        notes,
        sendNotification,
        basketId
      }),
      types: [
        CANCEL_ORDERS_REQUEST,
        CANCEL_ORDERS_RECEIVE,
        CANCEL_ORDERS_FAILURE
      ],
      options: { addAuth: true }
    }
  };
};

export const markCancelledCFRejected = (
  order: Order,
  notes: string,
  sendNotification?: boolean,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/cancel`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      notes,
      sendNotification
    }),
    types: [
      CANCEL_ORDERS_REQUEST,
      {
        type: CANCEL_ORDERS_RECEIVE,
        payload: (action, state, res) =>
          res.json().then(() => ({
            [NEXT_ACTION]: fetchSeqCount(getOrdersSelectedTabIndex(state) - 1)
          }))
      },
      CANCEL_ORDERS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markDelivered = (
  order: Order,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/deliver`,
    method: "POST",
    types: [
      DELIVER_ORDER_REQUEST,
      DELIVER_ORDER_RECEIVE,
      DELIVER_ORDER_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markMCS = (
  order: Order,
  notes: string,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/markmcs`,
    method: "POST",
    body: JSON.stringify({ notes }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      MARK_ORDER_MCS_REQUEST,
      MARK_ORDER_MCS_RECEIVE,
      MARK_ORDER_MCS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const resolveMCS = (
  order: Order,
  notes: string,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/resolvemcs`,
    method: "POST",
    body: JSON.stringify({ notes }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      RESOLVE_ORDER_MCS_REQUEST,
      RESOLVE_ORDER_MCS_RECEIVE,
      RESOLVE_ORDER_MCS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const releaseOrder = (
  order: Order,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/orders/${order.id}/release`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      RELEASE_ORDER_REQUEST,
      RELEASE_ORDER_RECEIVE,
      RELEASE_ORDER_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const updateMCS = (
  order: Order,
  notes: string,
  customerResponse: number,
  needsRefresh: boolean,
  splitOrder?: boolean,
  mcsMedicines?: Record<string, any>,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/updatemcs`,
    method: "POST",
    body: JSON.stringify({
      customerResponse,
      notes: notes ?? undefined,
      splitOrder,
      mcsMedicines
    }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      UPDATE_ORDER_MCS_REQUEST,
      needsRefresh
        ? UPDATE_ORDER_MCS_RECEIVE_REFRESH
        : UPDATE_ORDER_MCS_RECEIVE,
      UPDATE_ORDER_MCS_FAILURE
    ],
    options: { addAuth: true }
  }
});
export const resolveCS = (
  order: Order,
  notes: string,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/resolvecs`,
    method: "POST",
    body: JSON.stringify({ notes }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      RESOLVE_ORDER_CS_REQUEST,
      RESOLVE_ORDER_CS_RECEIVE,
      RESOLVE_ORDER_CS_FAILURE
    ],
    options: { addAuth: true }
  }
});
export const updateCS = (
  order: Order,
  notes: string,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/updatecs`,
    method: "POST",
    body: JSON.stringify({ notes }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      UPDATE_ORDER_CS_REQUEST,
      UPDATE_ORDER_CS_RECEIVE,
      UPDATE_ORDER_CS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const updatePriority = ({
  order,
  priority,
  apiEndpoint = ORDERS_API_ENDPOINT
}) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/updatepriority`,
    method: "POST",
    body: JSON.stringify({ priority }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      UPDATE_ORDER_PRIORITY_REQUEST,
      UPDATE_ORDER_PRIORITY_RECEIVE,
      UPDATE_ORDER_PRIORITY_FAILURE
    ],
    options: { addAuth: true }
  }
});
export const selectOrders = (orders: Order[], actOnAll) => ({
  type: SELECT_ORDERS,
  orders,
  actOnAll
});

export const setOrdersTabIndex = i => ({
  type: SELECT_ORDERS_TAB,
  tabIndex: i
});

export const setOrderSequenceNumber = i => ({
  type: SELECT_ORDER_SEQUENCE_NUMBER,
  orderIndex: i
});

export const setOrdersSubTabIndex = i => ({
  type: SELECT_ORDERS_SUB_TAB,
  tabIndex: i
});

export const selectMedicineFilter = (medicine: MedSuggestion) => ({
  type: SELECT_MEDICINE_FILTER,
  medicine
});

export const deleteMedicineFilter = (medicine: MedSuggestion) => ({
  type: DELETE_MEDICINE_FILTER,
  medicine
});

export const clearOrderFilters = () => ({
  type: CLEAR_ORDER_FILTERS
});

export const toggleOrdersFilter = () => ({
  type: TOGGLE_ORDERS_FILTER
});

export const fetchSeqCount = (
  selectedTabIndex: number,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/orders/seqcounts?showExpired=false&status=cf_rejected&ordersPerPage=60${
      selectedTabIndex != -1
        ? `&splitsCount=4&splitIndex=${selectedTabIndex}`
        : ``
    }`,
    method: "GET",
    types: [
      FETCH_SEQ_COUNT_REQUEST,
      FETCH_SEQ_COUNT_RECEIVE,
      FETCH_SEQ_COUNT_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markND = (
  order: Order,
  notes: string,
  splitOrder?: boolean,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/marknd`,
    method: "POST",
    body: JSON.stringify({ notes, splitOrder }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [MARK_ND_MCS_REQUEST, MARK_ND_MCS_RECEIVE, MARK_ND_MCS_FAILURE],
    options: { addAuth: true }
  }
});

export const markAsReadyToPrint = (
  order: Order,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${order.id}/markreadytoprint`,
    method: "PUT",
    types: [
      READY_TO_PRINT_REQUEST,
      READY_TO_PRINT_RECEIVE,
      READY_TO_PRINT_FAILURE
    ],
    options: { addAuth: true }
  }
});
