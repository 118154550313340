import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const ORDERS_API = process.env.REACT_APP_ORDERS_API;
// ACTIONS
export const SHOW_DATE_FILTER_DIALOG = "SHOW_DATE_FILTER_DIALOG";
export const HIDE_DATE_FILTER_DIALOG = "HIDE_DATE_FILTER_DIALOG";
export const FETCH_DATE_FILTER_COUNTS_REQUEST =
  "FETCH_DATE_FILTER_COUNTS_REQUEST";
export const FETCH_DATE_FILTER_COUNTS_RECEIVE =
  "FETCH_DATE_FILTER_COUNTS_RECEIVE";
export const FETCH_DATE_FILTER_COUNTS_FAILURE =
  "FETCH_DATE_FILTER_COUNTS_FAILURE";
export const SELECT_DATE_FILTERS = "SELECT_DATE_FILTERS";
export const SELECT_DATE_FILTERS_CHECKBOX = "SELECT_DATE_FILTERS_CHECKBOX";

// ACTION CREATORS

export const FetchDateFilterCounts = (apiEndpoint = ORDERS_API) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/dates?showExpired=false&status=prescription_printed`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      FETCH_DATE_FILTER_COUNTS_REQUEST,
      FETCH_DATE_FILTER_COUNTS_RECEIVE,
      FETCH_DATE_FILTER_COUNTS_FAILURE
    ],
    options: { addAuth: true }
  }
});
export const selectDateFilters = dateFilters => ({
  type: SELECT_DATE_FILTERS,
  dateFilters
});
export const selectDateFiltersCheckbox = dateFiltersCheckbox => ({
  type: SELECT_DATE_FILTERS_CHECKBOX,
  dateFiltersCheckbox
});

// SELECTORS
export const getDateFilterCounts = state => state.events.dateFilterCounts;
export const getIsDFCLoading = state => state.isDFCLoading;
export const getDateFilters = state =>
  state.DateFilterDialog.events.dateFilters;
export const getDateFiltersPriority = state =>
  state.DateFilterDialog.events.selectedPriority;
export const getDateFiltersCheckbox = state =>
  state.DateFilterDialog.events.dateFiltersCheckbox;

// REDUCERS
const INITIAL_STATE = {
  dateFilterCounts: null,
  isDFCLoading: false
};

const events = handleActions(
  {
    [FETCH_DATE_FILTER_COUNTS_REQUEST]: state => {
      return {
        ...state,
        dateFilterCounts: null
      };
    },
    [FETCH_DATE_FILTER_COUNTS_RECEIVE]: (state, action) => {
      return {
        ...state,
        dateFilterCounts: action.payload
      };
    },
    [FETCH_DATE_FILTER_COUNTS_FAILURE]: state => {
      return {
        ...state,
        dateFilterCounts: null
      };
    },
    [SELECT_DATE_FILTERS]: (state, action) => {
      return {
        ...state,
        dateFilters: action.dateFilters.selectedDates,
        selectedPriority: action.dateFilters.selectedPriority
      };
    },
    [SELECT_DATE_FILTERS_CHECKBOX]: (state, action) => {
      return {
        ...state,
        dateFiltersCheckbox: action.dateFiltersCheckbox
      };
    }
  },
  INITIAL_STATE
);

const isDFCLoading = handleActions(
  {
    [FETCH_DATE_FILTER_COUNTS_REQUEST]: () => true,
    [FETCH_DATE_FILTER_COUNTS_RECEIVE]: () => false,
    [FETCH_DATE_FILTER_COUNTS_FAILURE]: () => false
  },
  false
);

export default combineReducers({
  isDFCLoading,
  events
});
