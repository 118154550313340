import React from "react";
import PropTypes from "prop-types";

const AddressCell = ({ children }) => (
  <React.Fragment>
    {children.split("\n").map((row, i) => (
      <p key={row + i}>{row}</p>
    ))}
  </React.Fragment>
);

AddressCell.propTypes = {
  children: PropTypes.string.isRequired
};

export default AddressCell;
