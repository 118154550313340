import React, { useEffect, FC } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import InfiniteScroll from "../common/infinite-scroll";
import {
  fetchContactReviewGPs,
  contactReviewStart
} from "../../redux/gp-contact-review";
import { push } from "connected-react-router";
import { GP } from "../../redux/gp-nhs-refresh/types";
import {
  getContactReviewGPs,
  getContactReviewIsLoadingGPs,
  getContactReviewIsPageLoaded,
  getContactReviewGPCursor
} from "../../redux/root-reducer";
import { GPRow } from "./gp-row";
import s from "./gp-contact-check.module.scss";

export interface GPContactCheckProps {
  fetchGPs: (cursor?: string) => void;
  openGPProfile: (ods: string) => void;
  pageLoaded: () => void;
  isPageLoaded: boolean;
  isLoadingGPs: boolean;
  nextCursor: string;
  gps: GP[];
}

export const GPContactCheck: FC<GPContactCheckProps> = ({
  nextCursor,
  fetchGPs,
  gps,
  openGPProfile,
  isPageLoaded,
  isLoadingGPs,
  pageLoaded
}) => {
  useEffect(() => {
    if (!isPageLoaded) {
      fetchGPs();
    }
    pageLoaded();
  }, [isPageLoaded, fetchGPs, pageLoaded]);

  const items = gps.map(gp => (
    <GPRow gp={gp} openGPProfile={openGPProfile} key={gp.id} />
  ));

  const scrollLoad = (): void => {
    if (nextCursor !== "") {
      fetchGPs(nextCursor);
    }
  };

  return (
    <div className={s.scrollWrap}>
      <Table id={"t_gp_table"}>
        <TableHead>
          <TableRow>
            <TableCell>ODS Code</TableCell>
            <TableCell>GP Practice</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Confirmation Requested</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={s.gpBody}>
          {items.length > 0 ? (
            <InfiniteScroll
              isLoading={isLoadingGPs}
              onReachThreshold={scrollLoad}
            >
              {items}
            </InfiniteScroll>
          ) : isLoadingGPs ? (
            <TableRow>
              <TableCell colSpan={5}>
                <div className={s.loading}>
                  <p>Loading...</p>
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <div className={s.noGPs}>
                  <h2>No pending GPs</h2>
                  <p>You currently don't have any pending GPs</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const mapDispatchToProps = {
  fetchGPs: fetchContactReviewGPs,
  openGPProfile: (ods: string): Action => push(`/gp/profile/${ods}`),
  pageLoaded: contactReviewStart
};

const mapStateToProps = state => ({
  gps: getContactReviewGPs(state),
  isPageLoaded: getContactReviewIsPageLoaded(state),
  isLoadingGPs: getContactReviewIsLoadingGPs(state),
  nextCursor: getContactReviewGPCursor(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GPContactCheck);
