import { State } from "./types";
import { flatten } from "../../helpers/flatten";
import { GP } from "../gp-nhs-refresh/types";

export const getODSCode = (state: State): string => state.ods;
export const getGPAlreadyExists = (state: State): boolean =>
  state.gpAlreadyExists;
export const getGPChecked = (state: State): boolean => state.gpChecked;
export const getUnknownNHSODS = (state: State): boolean => state.unknownNHSODS;
export const getNotGP = (state: State): boolean => state.notGP;
export const getGPPreview = (state: State): GP =>
  state.gpPreview === null
    ? null
    : flatten(state.gpPreview as object, undefined);
