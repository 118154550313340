import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { withTheme, makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Dialog, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {
  getBasketData,
  getBasketDetailsIsOpen,
  getBasketId,
  getBasketIsLoading,
  getBasketWeight
} from "redux/root-reducer";
import { goBack } from "connected-react-router";
import {
  basketDispatch,
  hideBasketDetails,
  setBasketId,
  setEditBasketDialogOpen
} from "redux/scanner-wrapper";
import Orders from "components/orders";
import LoadingButton from "components/common/loading-button";
import { formatDateString } from "helpers/date-helpers";
import NextPageLoader from "components/common/next-page-loader";
import { isTouchDevice } from "helpers/validators";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: "flex",
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 3)
    }
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  headerText: {
    fontSize: "1.875rem",
    marginBottom: theme.spacing(2)
  },
  infoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#FDE3E2",
    border: "1px solid rgba(244, 54, 54, 0.14)",
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    color: "#B50E0E"
  },
  button: {
    padding: 0
  },
  gridItem: {
    fontSize: "1.875rem",
    wordBreak: "break-word"
  },
  detailsBox: {
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #DADADA",
    gap: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column"
  }
}));

const Basketdetails = ({
  basketId,
  goBack,
  clearBasketId,
  onDispatch,
  isLoading,
  basketData,
  location,
  hideBasketDetails,
  setEditBasketDialogOpen,
  basketWeight,
  open,
  theme
}) => {
  const classes = useStyles();
  const isTouchScreen = isTouchDevice();
  const isRemoveOrderPath = useMemo(
    () => location?.pathname === "/admin/basket/remove-order",
    [location]
  );

  const handleBack = () => {
    clearBasketId("");
    hideBasketDetails();
    if (
      !["/orders/basket", "/admin/basket/remove-order"].includes(
        location?.pathname
      )
    ) {
      goBack();
    }
  };

  const renderOrdersSection = useMemo(
    () =>
      !isLoading ? (
        <Orders
          orders={basketData.orders}
          showEvidenceColumn
          hideNewOrderBtn
          displaySettings={{
            showOrderId: true,
            showNotes: true,
            showPatient: true,
            showGP: true,
            showOrderStatus: true,
            showDeliveryAddress: true,
            showBarcodeColumn: true,
            showLatestEvent: true,
            showBarcodeForOrder: status =>
              ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
                status
              ),
            showShippingClass: true,
            showShippingButtonsForOrder: status =>
              ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
                status
              )
          }}
          requiredFetch={false}
          fromScanner
          disableContextMenu
        />
      ) : (
        <Box marginTop={theme.spacing(1)}>
          <NextPageLoader id={"t_nextPageLoader"} />
        </Box>
      ),
    [isLoading, basketData.orders, theme]
  );

  return (
    <Dialog
      id={"t_dialog"}
      fullScreen
      open={
        (open && !isRemoveOrderPath) ||
        (open && isRemoveOrderPath && isTouchScreen)
      }
    >
      <Box className={classes.dialogContent}>
        <Box className={classes.column} flex={3}>
          {isRemoveOrderPath && (
            <Typography className={classes.headerText}>
              Tap to select the order you want to remove from this basket
            </Typography>
          )}
          {basketData.orders && (
            <>
              {!isRemoveOrderPath && (
                <>
                  {basketData.isFirstOrder && (
                    <Box className={classes.infoBox}>
                      <Typography
                        style={{ fontSize: "2.5rem", fontWeight: 600 }}
                      >
                        First order
                      </Typography>
                    </Box>
                  )}
                  {basketData.isAddressUpdated && (
                    <Box className={classes.infoBox}>
                      <Typography
                        style={{ fontSize: "2.5rem", fontWeight: 600 }}
                      >
                        Patient has requested different delivery addresses for
                        orders in this batch.
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              {renderOrdersSection}
            </>
          )}
        </Box>
        <Box
          className={classes.column}
          style={{ padding: "0 1.25rem" }}
          gridGap={"2rem"}
          flex={1}
          minWidth={"44rem"}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button
              id={"t_emailsent_action"}
              variant={"text"}
              color={"secondary"}
              onClick={handleBack}
              className={classes.button}
            >
              <Typography
                style={{
                  fontSize: "4rem",
                  fontWeight: 500,
                  lineHeight: "4rem"
                }}
              >
                EXIT
              </Typography>
            </Button>
            {!isRemoveOrderPath && (
              <Button
                id={"t_emailsent_action"}
                variant={"text"}
                color={"secondary"}
                onClick={setEditBasketDialogOpen}
                className={classes.button}
              >
                <Typography
                  style={{
                    fontSize: "4rem",
                    fontWeight: 500,
                    lineHeight: "4rem"
                  }}
                >
                  EDIT WEIGHT
                </Typography>
              </Button>
            )}
          </Box>
          <Box className={classes.detailsBox}>
            {!isLoading && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    color={"textSecondary"}
                    className={classes.gridItem}
                  >
                    Basket
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.gridItem}>
                    {basketId}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={"textSecondary"}
                    className={classes.gridItem}
                  >
                    Patient
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.gridItem}>
                    {basketData.patient}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={"textSecondary"}
                    className={classes.gridItem}
                  >
                    DOB
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.gridItem}>
                    {formatDateString(basketData.dob)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={"textSecondary"}
                    className={classes.gridItem}
                  >
                    Order ID
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {basketData.orderIds?.map((orderId, index) => (
                    <Typography key={index} className={classes.gridItem}>
                      {orderId}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={"textSecondary"}
                    className={classes.gridItem}
                  >
                    Delivery address
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {basketData.deliveryAddress?.split("\n").map(
                    (address, index) =>
                      address.trim() && (
                        <Typography key={index} className={classes.gridItem}>
                          {address}
                        </Typography>
                      )
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={"textSecondary"}
                    className={classes.gridItem}
                  >
                    Shipping
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.gridItem}>
                    {`${basketData.shippingClass || ""}`}{" "}
                    {basketData.shippingSigned ? "signed" : "unsigned"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={"textSecondary"}
                    className={classes.gridItem}
                  >
                    Weight
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.gridItem}>
                    {basketWeight ? `${basketWeight}g` : "99g / 250g"}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {isLoading && (
              <Box marginTop={theme.spacing(1)}>
                <NextPageLoader id={"t_nextPageLoader"} />
              </Box>
            )}
          </Box>
          {!isRemoveOrderPath && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              gridGap={"3.75rem"}
            >
              <LoadingButton
                id={"t_letter_action"}
                variant={"contained"}
                color={"primary"}
                onClick={() =>
                  onDispatch("largeLetter", basketWeight, basketId)
                }
                loading={isLoading}
                fullWidth
              >
                <Typography
                  style={{ textTransform: "capitalize", fontSize: "4rem" }}
                >
                  Letter
                </Typography>
              </LoadingButton>
              <LoadingButton
                id={"t_parcel_action"}
                variant={"contained"}
                color={"primary"}
                onClick={() => onDispatch("parcel", basketWeight, basketId)}
                loading={isLoading}
                fullWidth
              >
                <Typography
                  style={{ textTransform: "capitalize", fontSize: "4rem" }}
                >
                  Parcel
                </Typography>
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  basketId: getBasketId(state),
  isLoading: getBasketIsLoading(state),
  basketData: getBasketData(state) ?? {},
  basketWeight: Number(getBasketWeight(state)) || undefined,
  open: getBasketDetailsIsOpen(state)
});

const mapDispatchToProps = {
  goBack,
  hideBasketDetails,
  clearBasketId: setBasketId,
  onDispatch: basketDispatch,
  setEditBasketDialogOpen
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(Basketdetails))
);
