import { combineReducers } from "redux";

import {
  byId,
  allIds,
  selectedIds,
  fetching,
  fetchingAdditional,
  resultsPage,
  lastPageSize,
  needsRefresh,
  error,
  selectedTabIndex,
  selectedSubTabIndex,
  willActOnAll,
  filters,
  showFilters,
  selectedOrderNumber,
  seqCount
} from "./orders.reducers";

export const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

export * from "./orders.actions";
export * from "./orders.selectors";
export * from "./orders.types";

export default combineReducers({
  byId,
  allIds,
  selectedIds,
  fetching,
  fetchingAdditional,
  resultsPage,
  lastPageSize,
  needsRefresh,
  error,
  selectedTabIndex,
  selectedSubTabIndex,
  willActOnAll,
  filters,
  showFilters,
  selectedOrderNumber,
  seqCount
});
