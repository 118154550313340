import { useCallback, useEffect, useRef, useState } from "react";
import useKeyStroke from "./useKeyStroke";

/*
 * The useKeyStrokeWithTimeout hook emits the `key` value of all `keydown` events within
 * X milliseconds of each other where X is defined by the consumer. These events should be
 * considered a sequence and the return `clear/reset` func should be called by the consumer
 * when the end of a sequence is identified. Failing to call this func will drop the first
 * event from the next sequence, the hook has no opinion on what a sequence looks like.
 *
 * An object is returned so reference equality will always fail during the react lifecycle,
 * otherwise two of the same letter consecutively will not trigger two renders.
 */
const useKeyStrokeWithTimeout = function(
  betweenKeyDownTimeout = 0,
  nowFunc = Date.now
) {
  const keyStroke = useKeyStroke();
  const [key, emitKey] = useState(null);
  const [timedOut, setTimedOut] = useState(false);
  const timestampActual = useRef(null);

  useEffect(() => {
    const now = nowFunc();
    if (
      timestampActual.current &&
      now - timestampActual.current > betweenKeyDownTimeout
    ) {
      setTimedOut(true);
      emitKey(null);
    } else {
      setTimedOut(false);
      emitKey(keyStroke);
    }
    timestampActual.current = now;
  }, [keyStroke, betweenKeyDownTimeout, nowFunc]);

  // Run on mount as last hook to ensure initial timeStamp is null
  useEffect(() => {
    clear();
  }, []);

  const clear = () => {
    timestampActual.current = null;
  };

  return [key, timedOut, useCallback(() => clear(), [])];
};

export default useKeyStrokeWithTimeout;
