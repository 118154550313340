import React, { FC } from "react";
import { MatchStatus } from "./map-maker";
import RefreshedField from "./refreshed-field";

export interface RefreshedMultiFieldProps {
  label: string;
  values: string[];
  updated?: MatchStatus;
}
const RefreshedMultiField: FC<RefreshedMultiFieldProps> = ({
  label,
  values,
  updated
}) => {
  const value = values.map((value, idx) => <p key={idx}>{value}</p>);
  return <RefreshedField label={label} value={value} status={updated} />;
};

export default RefreshedMultiField;
