import React, { FC } from "react";

import { FilterButtonProps } from "./index";
import FilterButton from "./filter-button.ui";

const FilterButtonContainer: FC<FilterButtonProps> = props => (
  <FilterButton {...props} />
);

export default FilterButtonContainer;
