import React, { FC } from "react";
import { createPortal } from "react-dom";
import { usePortal } from "@welldigital/components";

const ButtonsContainer: FC = ({ children }) => <div>{children}</div>;

const ButtonsContainerPortal: FC = ({ children }) => {
  const portal = usePortal("screen-action-buttons");

  return createPortal(<ButtonsContainer children={children} />, portal);
};

export default ButtonsContainerPortal;
