import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { formatDateString } from "../../helpers/date-helpers";
import s from "./common-styles.module.scss";
import { getIntercomLink } from "../../redux/selectors/user";

const PatientDetailsCell = ({
  userId,
  firstName,
  lastName,
  address,
  phone,
  dob,
  email,
  onViewButtonClick
}) => (
  <div className={s.detailsCell}>
    <p className={s.primary}>
      {firstName} {lastName}
    </p>
    <p>{formatDateString(dob)}</p>
    <p>{address}</p>
    <p>{email}</p>
    <p>{phone}</p>
    {onViewButtonClick !== null ? (
      <Button
        id="t_view_button"
        variant="text"
        color="secondary"
        className={s.viewButton}
        onClick={event => {
          if (event) {
            event.preventDefault();
            event.stopPropagation();
          }
          onViewButtonClick();
        }}
      >
        View
      </Button>
    ) : null}
    <Button
      id="t_chat_button"
      variant="text"
      color="secondary"
      className={s.chatButton}
      target="_blank"
      href={getIntercomLink(userId)}
      onClick={event => {
        if (event) {
          event.stopPropagation();
        }
      }}
    >
      CHAT
    </Button>
  </div>
);
PatientDetailsCell.propTypes = {
  userId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  dob: PropTypes.string,
  email: PropTypes.string,
  onViewButtonClick: PropTypes.func
};
PatientDetailsCell.defaultProps = {
  userId: "",
  firstName: "",
  lastName: "",
  address: "",
  phone: "",
  dob: "",
  email: "",
  onViewButtonClick: () => {}
};

export default PatientDetailsCell;
