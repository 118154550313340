export const flatten = (source: object, into: any | undefined) => {
  if (into === undefined) {
    into = {};
  }

  for (const key in source) {
    const val = source[key];

    if (typeof val === "object" && val !== null && !Array.isArray(val)) {
      flatten(val, into);
      continue;
    }

    into[key] = val;
  }

  return into;
};
