import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;
const REQUESTS_API_ENDPOINT = process.env.REACT_APP_REQUESTS_API;

// ACTIONS
export const HIDE_EVENTS_DIALOG = "HIDE_EVENTS_DIALOG";
export const FETCH_REQUEST_EVENTS_REQUEST = "FETCH_REQUEST_EVENTS_REQUEST";
export const FETCH_REQUEST_EVENTS_RECEIVE = "FETCH_REQUEST_EVENTS_RECEIVE";
export const FETCH_REQUEST_EVENTS_FAILURE = "FETCH_REQUEST_EVENTS_FAILURE";
export const FETCH_ORDER_EVENTS_REQUEST = "FETCH_ORDER_EVENTS_REQUEST";
export const FETCH_ORDER_EVENTS_RECEIVE = "FETCH_ORDER_EVENTS_RECEIVE";
export const FETCH_ORDER_EVENTS_FAILURE = "FETCH_ORDER_EVENTS_FAILURE";

// ACTION CREATORS
export const hideEventsDialog = () => ({
  type: HIDE_EVENTS_DIALOG
});
export const showEventsForRequest = (
  requestId,
  endpoint = REQUESTS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${endpoint}/request/${requestId}/events`,
    method: "GET",
    types: [
      FETCH_REQUEST_EVENTS_REQUEST,
      FETCH_REQUEST_EVENTS_RECEIVE,
      FETCH_REQUEST_EVENTS_FAILURE
    ],
    options: { addAuth: true }
  }
});
export const showEventsForOrder = (
  orderId,
  endpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${endpoint}/order/${orderId}/events`,
    method: "GET",
    types: [
      FETCH_ORDER_EVENTS_REQUEST,
      FETCH_ORDER_EVENTS_RECEIVE,
      FETCH_ORDER_EVENTS_FAILURE
    ],
    options: { addAuth: true }
  }
});

// SELECTORS
export const getIsOpen = state => state.isOpen;
export const getIsLoading = state => state.isLoading;
export const getEvents = state => state.events;

// REDUCERS
const isOpen = handleActions(
  {
    [FETCH_REQUEST_EVENTS_REQUEST]: () => true,
    [FETCH_ORDER_EVENTS_REQUEST]: () => true,
    [HIDE_EVENTS_DIALOG]: () => false
  },
  false
);

const isLoading = handleActions(
  {
    [FETCH_REQUEST_EVENTS_REQUEST]: () => true,
    [FETCH_ORDER_EVENTS_REQUEST]: () => true,
    [FETCH_REQUEST_EVENTS_RECEIVE]: () => false,
    [FETCH_ORDER_EVENTS_RECEIVE]: () => false,
    [FETCH_REQUEST_EVENTS_FAILURE]: () => false,
    [FETCH_ORDER_EVENTS_FAILURE]: () => false
  },
  false
);

const events = handleActions(
  {
    [FETCH_ORDER_EVENTS_REQUEST]: () => [],
    [FETCH_REQUEST_EVENTS_REQUEST]: () => [],
    [FETCH_ORDER_EVENTS_RECEIVE]: (state, action) => action.payload || [],
    [FETCH_REQUEST_EVENTS_RECEIVE]: (state, action) => action.payload || []
  },
  []
);

export default combineReducers({
  isOpen,
  isLoading,
  events
});
