import React from "react";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import Login from "../login";
import RoutesConfig from "../../routing/routes-config";
import Navigation from "../navigation";
import Search from "../search";
import ContextMenu from "../context-menu";
import Routes from "../../routing/generate-routes";
import { history, persistor } from "../../redux/store";
import mainTheme from "./themes";
import Notification from "../notification";
import ConfirmDialog from "../confirm-dialog";
import ContactDialog from "../contact-dialog";
import CreateOrder from "../create-order";
import RightClickMenu from "../context-menu/right-click";
import { getIsAuthenticated } from "../../redux/root-reducer";
import CreateDispatchLabelDialog from "../dispatch-label/create-dialog";
import s from "./app.module.scss";
import Events from "../events-dialog";
import ReturnPrescription from "../return-prescription-dialog";
import ConsolidateOrdersDialog from "../consolidate-orders-dialog";
import BasketDetailsDialog from "../basket-scanner-wrapper/_BasketDetails";
import BasketAdminDialog from "../basket-scanner-wrapper/_BasketAdminDialog";
import DeliveryAddress from "../delivery-address";
import ShippingClassDialog from "../shipping-class-dialog";
import MarkOrderMcsDialog from "../mark-order-mcs-dialog";
import MarkOrderCSDialog from "../mark-order-customer-service-dialog";

import GPRefresh from "../gp-nhs-refresh/index";
import EditBasketWeightDialog from "components/basket-scanner-wrapper/_EditBasketWeightDialog";
import SingleOrderDispatchDialog from "components/basket-scanner-wrapper/_SingleOrderDispatchDialog";
import RemoveOrderFromBasketDialog from "components/basket-scanner-wrapper/_RemoveOrderFromBasketDialog";
import TransferBasketDialog from "components/basket-scanner-wrapper/_SmallScreenTransferBasketdDialog";

export const App = ({ isAuthenticated }) => (
  <MuiThemeProvider theme={mainTheme}>
    {
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          {isAuthenticated ? (
            <div className={s.root}>
              <header>
                <ContextMenu>
                  <Search />
                </ContextMenu>
              </header>
              <main>
                <Navigation routes={RoutesConfig} />
                <Routes data={RoutesConfig} />
              </main>
              <ConfirmDialog />
              <ContactDialog />
              <Notification />
              <CreateDispatchLabelDialog />
              <RightClickMenu />
              <CreateOrder />
              <GPRefresh />
              <DeliveryAddress />
              <Events />
              <ReturnPrescription />
              <ConsolidateOrdersDialog />
              <BasketDetailsDialog />
              <BasketAdminDialog />
              <EditBasketWeightDialog />
              <SingleOrderDispatchDialog />
              <RemoveOrderFromBasketDialog />
              <TransferBasketDialog />
              <ShippingClassDialog />
              <MarkOrderMcsDialog />
              <MarkOrderCSDialog />
            </div>
          ) : (
            <Login />
          )}
        </ConnectedRouter>
      </PersistGate>
    }
  </MuiThemeProvider>
);

export const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state)
});

export default connect(mapStateToProps)(App);
