import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { checkForOrderBarcode, checkForShippingBarcode } from "./helpers";
import s from "./style.module.scss";
import useBarcodeScans from "../../hooks/useBarcodeScan";
import Order from "./_Order";
import Shipping from "./_Shipping";
import Typography from "@material-ui/core/Typography";
import { withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "../create-order/delete-button";
import usePrevProp from "@use-it/prev-prop";

const _Status = ({ theme, message, error }) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid
        item
        xs={12}
        style={{
          padding: theme.spacing(2),
          backgroundColor: error
            ? theme.palette.error.main
            : theme.palette.grey[200]
        }}
      >
        <Typography align="center" component="span">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

_Status.propTypes = {
  message: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  error: PropTypes.string
};
_Status.defaultProps = {
  message: "",
  theme: {}
};

// Because we are stuck on MaterialUI < v4 this is the v3 way of using withTheme which does not match the current docs
export const Status = withTheme(_Status);

const Screen = ({
  fetchAndPrintDispatchLabel,
  scannerReset,
  apiError,
  fetching,
  exemptionCodes,
  whistlEnabled,
  theme
}) => {
  const [shouldCheckScan, setShouldCheckScan] = useState(true);
  const [orderBarcode, setOrderBarcode] = useState();
  const [shippingBarcode, setShippingBarcode] = useState();
  const [scans, count, resetScanOutput] = useBarcodeScans();
  const prevFetching = usePrevProp(fetching);

  function resetAll() {
    resetScanOutput();
    setOrderBarcode();
    setShippingBarcode();
    scannerReset();
  }

  function getStatusMessage() {
    if (apiError) return apiError;
    if (fetching) return "Fetching label...";
    if (prevFetching && !fetching && !count) return "Printing...";
    return "Scanning...";
  }

  // Validate barcodes
  useEffect(() => {
    console.log("scans updated", scans, count);
    if (fetching || !shouldCheckScan) {
      console.log("not checking scans");
      return;
    }
    // Once an order barcode has been recognised don't try again
    if (!orderBarcode) {
      console.log("checking barcodes for order barcode");
      checkForOrderBarcode(scans, setOrderBarcode);
    }
    // Once an order barcode has been recognised don't try again
    if (!shippingBarcode) {
      console.log("checking barcodes for shipping barcode");
      checkForShippingBarcode(scans, setShippingBarcode);
    }
  }, [count, scans, shouldCheckScan, orderBarcode, shippingBarcode]);

  // Activate scanning and clear state on first scan after label was printed
  useEffect(() => {
    // Reset scanner output if receiving scans whilst fetching
    if (fetching && count) resetScanOutput();
    if (!fetching && !shouldCheckScan && count === 1) {
      console.log("activating scanning");
      setShouldCheckScan(true);
      setOrderBarcode();
      setShippingBarcode();
      scannerReset();
    }
  }, [
    count,
    resetScanOutput,
    scannerReset,
    shouldCheckScan,
    setShouldCheckScan
  ]);

  // Fetch a label and print
  useEffect(() => {
    // Once we have both barcodes (validated) try and get a shipping label
    if (!fetching && orderBarcode && shippingBarcode) {
      console.log("fetching label with: ", orderBarcode, shippingBarcode);
      fetchAndPrintDispatchLabel({
        orderId: orderBarcode.value,
        shippingPackage: shippingBarcode,
        whistlEnabled
      });
      // Hold state for UI but reset barcode hook
      setShouldCheckScan(false);
      resetScanOutput();
    }
  }, [orderBarcode, shippingBarcode, setShouldCheckScan]);

  return (
    <div className={s.root}>
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <Order
            order={orderBarcode}
            exemptionData={{
              codes: exemptionCodes
            }}
          ></Order>
        </Grid>
        <Grid item xs={6}>
          <Shipping format={shippingBarcode}></Shipping>
        </Grid>
        <Grid item xs={12}>
          <Status message={getStatusMessage()} error={apiError} />
        </Grid>
        <Grid item container>
          <Grid
            container
            style={{
              paddingRight: theme.spacing(3)
            }}
            justify="flex-end"
          >
            <Button
              id="t_reset_button"
              onClick={() => {
                resetAll();
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Screen.propTypes = {
  // State
  fetching: PropTypes.bool.isRequired,
  apiError: PropTypes.string,
  exemptionCodes: PropTypes.arrayOf(PropTypes.string),
  // Dispatch funcs
  fetchAndPrintDispatchLabel: PropTypes.func.isRequired,
  scannerReset: PropTypes.func.isRequired
};
Screen.defaultProps = {
  fetchAndPrintDispatchLabel: () => {},
  scannerReset: () => {},
  fetching: false
};

export default withTheme(Screen);
