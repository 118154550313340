import { RSAA } from "redux-api-middleware";
import * as fromRootReducer from "../../root-reducer";

const VERSION_NUMBER = process.env.REACT_APP_VERSION || "0";

// Adds idToken to API requests that have addAuth:true
// Insert before redux-api-middleware in chain
export const middleware = ({ getIdToken }) => store => next => action => {
  const callAPI = action[RSAA];

  if (callAPI !== undefined) {
    if (callAPI.options && callAPI.options.addAuth === true) {
      const state = store.getState();
      callAPI.headers = Object.assign({}, callAPI.headers, {
        Authorization: getIdToken(state)
      });
    }

    callAPI.headers = Object.assign({}, callAPI.headers, {
      "x-pharmos-version": VERSION_NUMBER
    });
  }

  return next(action);
};

export default middleware({ getIdToken: fromRootReducer.getIdToken });
