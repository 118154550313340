import React from "react";
import PropTypes from "prop-types";
import Home from "@material-ui/icons/Home";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { validateAsNumber } from "../../helpers/validators";
import s from "./profile.module.scss";

const GMAP_TOKEN = process.env.REACT_APP_GMAP_TOKEN;

const isValidCoordinate = (value, bound) =>
  validateAsNumber(value) && Number(value) <= bound && Number(value) >= -bound;

const createCoordinates = (lat, lng) => {
  if (isValidCoordinate(lat, 90) && isValidCoordinate(lng, 180)) {
    return {
      lat: Number(lat),
      lng: Number(lng)
    };
  }
  return null;
};

const GMap = withScriptjs(
  withGoogleMap(({ coordinates, zoom }) => (
    <GoogleMap defaultZoom={zoom} center={coordinates}>
      <MarkerWithLabel
        position={coordinates}
        opacity={0}
        labelOpacity={1}
        labelAnchor={new window.google.maps.Point(0, 0)}
      >
        <div className={s.customMarker}>
          <Home />
        </div>
      </MarkerWithLabel>
    </GoogleMap>
  ))
);

const GPMap = ({ lat = null, lng = null, zoom, key = GMAP_TOKEN }) => {
  const coords = createCoordinates(lat, lng);
  if (coords === null) {
    return (
      <div
        id="t_nomap"
        style={{ height: "100%", backgroundColor: "#d8d8d8" }}
      />
    );
  }
  return (
    <GMap
      id="t_gmap"
      coordinates={coords}
      zoom={zoom}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: "100%" }} />}
      containerElement={<div style={{ height: "100%" }} />}
      mapElement={<div style={{ height: "100%" }} />}
    />
  );
};
GPMap.propTypes = {
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoom: PropTypes.number,
  key: PropTypes.string
};
GPMap.defaultProps = {
  zoom: 15,
  key: GMAP_TOKEN,
  lat: null,
  lng: null
};

export default GPMap;
