import { connect } from "react-redux";
import React from "react";
import cx from "clsx";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import iconConfig from "./icon-config";
import { setRightClickMenuOptions } from "../../redux/context-menu";
import {
  getRightClickMenuOptions,
  getContextMenuGroupedActions,
  getContextMenuActions
} from "../../redux/root-reducer";
import s from "./context-menu.module.scss";

const createMenuActions = (actions = [], dispatchAction) =>
  actions.map((action, index) => {
    const { name, enabled } = action;
    const Icon = iconConfig[name];

    return (
      <MenuItem
        className={cx("t_action", s.menuItem)}
        id={`t_action_${index}`}
        key={index}
        disabled={!enabled}
        onClick={() => dispatchAction(action)}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText inset primary={action.tooltip} />
      </MenuItem>
    );
  });

export const RightClickMenu = ({
  options,
  handleClose,
  actions,
  dispatchAction
}) =>
  actions.length < 1 ? null : (
    <Popover
      open={options.isVisible}
      anchorReference="anchorPosition"
      anchorPosition={{ top: options.y, left: options.x }}
      onClose={handleClose}
      onContextMenu={handleClose}
    >
      <Paper>
        <MenuList>{createMenuActions(actions, dispatchAction)}</MenuList>
      </Paper>
    </Popover>
  );

RightClickMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
  options: PropTypes.object,
  actions: PropTypes.array.isRequired,
  dispatchAction: PropTypes.func
};
RightClickMenu.defaultProps = {
  dispatchAction: () => {},
  options: { isVisible: false, x: 0, y: 0 }
};

const mapStateToProps = state => ({
  options: getRightClickMenuOptions(state),
  actions: getContextMenuActions(state),
  groupedActions: getContextMenuGroupedActions(state)
});

const mapDispatchToProps = dispatch => ({
  handleClose: e => {
    e.preventDefault();
    dispatch(setRightClickMenuOptions({ isVisible: false }));
  },
  dispatchAction: item => {
    if (item.enabled === true) {
      dispatch(item.actionCreator());
      dispatch(setRightClickMenuOptions({ isVisible: false }));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RightClickMenu);
