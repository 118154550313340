import React, { useState } from "react";
import { Box, Typography, Button, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getBasketIsLoading,
  getBasketOrderListTapDialogIsOpen,
  getBasketData,
  getSelectedOrderIds,
  getBasketWeight,
  getBasketScanFromRemoveBasket,
  getBasketId
} from "redux/root-reducer";
import {
  createSingleOrderDispatchLabel,
  hideOrderListTapDialog,
  linkBasketFromRemoveOrder,
  removeOrderFromBasket,
  setBasketId,
  setBasketWeight,
  setEditBasketDialogOpen,
  setScanFromRemoveBasket
} from "redux/scanner-wrapper";
import Orders from "components/orders";
import LoadingButton from "components/common/loading-button";

const RemoveOrderFromBasketDialog = ({
  basketData,
  selectedOrderIds,
  hideOrderListTapDialog,
  removeOrderFromBasket,
  open,
  isLoading,
  isRemoveBasketScan,
  setScanFromRemoveBasket,
  basketId,
  setBasketId,
  linkBasketFromRemoveOrder,
  location
}) => {
  const [isRemoveAndPrint, setIsRemoveAndPrint] = useState(false);

  const handleCancel = () => {
    isRemoveAndPrint
      ? setIsRemoveAndPrint(false)
      : setBasketId(String(basketData.id)) && hideOrderListTapDialog();
  };

  const renderDialogTitle = () => {
    if (isRemoveBasketScan) {
      return basketId == basketData.id
        ? "Scan an empty basket for this order"
        : `Basket ID: ${basketId}`;
    }
    if (isRemoveAndPrint) {
      return "Return order to ready to print? This action cannot be undone.";
    }
    return "Remove order from basket?";
  };

  const renderOrdersComponent = () => {
    if (!basketData.orders) return null;

    return (
      <Orders
        orders={basketData.orders.filter(order =>
          selectedOrderIds.includes(order.id)
        )}
        showEvidenceColumn
        hideNewOrderBtn
        displaySettings={{
          showOrderId: true,
          showNotes: true,
          showPatient: true,
          showGP: true,
          showOrderStatus: true,
          showDeliveryAddress: true,
          showBarcodeColumn: true,
          showLatestEvent: true,
          showBarcodeForOrder: status =>
            ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
              status
            ),
          showShippingClass: true,
          showShippingButtonsForOrder: status =>
            ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
              status
            )
        }}
        requiredFetch={false}
        disableContextMenu
      />
    );
  };

  return (
    <Dialog
      id={"t_dialog"}
      fullWidth
      maxWidth={"xl"}
      open={open && location?.pathname === "/admin/basket/remove-order"}
    >
      <Box
        display={"flex"}
        py={"1.5rem"}
        px={"1.25rem"}
        flexDirection={"column"}
        gridGap={"1.5rem"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          flex={3}
          gridGap={"1.5rem"}
        >
          <Typography
            variant={"h3"}
            style={{ fontWeight: 500, textAlign: "center" }}
          >
            {renderDialogTitle()}
          </Typography>
          {renderOrdersComponent()}
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          gridGap={"3.75rem"}
          pb={"2.5rem"}
          px={"2.5rem"}
        >
          {!isRemoveBasketScan && !isRemoveAndPrint && (
            <Button
              style={{ height: "16.25rem" }}
              color={"primary"}
              variant={"contained"}
              onClick={() => setScanFromRemoveBasket()}
            >
              <Typography variant={"h2"} style={{ fontWeight: 500 }}>
                Remove
              </Typography>
            </Button>
          )}
          {isRemoveBasketScan && (
            <LoadingButton
              style={{ height: "16.25rem" }}
              color={"primary"}
              variant={"contained"}
              disabled={basketId == basketData.id}
              loading={isLoading}
              fullWidth
              onClick={() =>
                linkBasketFromRemoveOrder(selectedOrderIds, basketId)
              }
            >
              <Typography variant={"h2"} style={{ fontWeight: 500 }}>
                Link
              </Typography>
            </LoadingButton>
          )}
          {!isRemoveBasketScan && (
            <LoadingButton
              fullWidth
              style={{ height: "16.25rem" }}
              color={"primary"}
              variant={"outlined"}
              onClick={() =>
                !isRemoveAndPrint
                  ? setIsRemoveAndPrint(true)
                  : removeOrderFromBasket(basketData.id, selectedOrderIds, true)
              }
              loading={isLoading}
            >
              <Typography variant={"h2"} style={{ fontWeight: 500 }}>
                {!isRemoveAndPrint
                  ? "Remove and return to ready to print"
                  : "Mark ready to print"}
              </Typography>
            </LoadingButton>
          )}
          <Button
            style={{ padding: "2.5rem" }}
            color={"primary"}
            variant={"outlined"}
            onClick={handleCancel}
          >
            <Typography variant={"h2"} style={{ fontWeight: 500 }}>
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  isLoading: getBasketIsLoading(state),
  basketWeight: Number(getBasketWeight(state)) || undefined,
  selectedOrderIds: getSelectedOrderIds(state),
  basketData: getBasketData(state) ?? {},
  isRemoveBasketScan: getBasketScanFromRemoveBasket(state),
  open: getBasketOrderListTapDialogIsOpen(state),
  basketId: getBasketId(state)
});

const mapDispatchToProps = {
  setBasketWeight,
  hideOrderListTapDialog,
  setEditBasketDialogOpen,
  createSingleOrderDispatchLabel,
  setScanFromRemoveBasket,
  removeOrderFromBasket,
  linkBasketFromRemoveOrder,
  setBasketId
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RemoveOrderFromBasketDialog)
);
