import React, { FC } from "react";
import { connect } from "react-redux";

import { Tab, Tabs } from "@material-ui/core";
import Orders from "../orders";

import * as selectors from "../../redux/root-reducer";
import {
  selectTab,
  CC_ACCEPTED,
  CC_DOWNLOADED,
  CC_TRANSFERRED,
  CC_DISPENSED,
  CC_NOT_COLLECTED
} from "../../redux/click-and-collect";
import s from "./click-and-collect.module.scss";

const tabs = [
  { label: "Scripts to be returned", value: CC_ACCEPTED },
  { label: "Transferred to Store", value: CC_TRANSFERRED },
  { label: "Store downloaded", value: CC_DOWNLOADED },
  { label: "Scripts dispensed", value: CC_DISPENSED },
  { label: "Not Collected", value: CC_NOT_COLLECTED }
];

interface ClickAndCollectProps {
  selectedTab: string;
  selectTab: (tab: string) => void;
}

export const ClickAndCollect: FC<ClickAndCollectProps> = ({
  selectedTab,
  selectTab
}) => {
  return (
    <div className={s.root}>
      <Tabs
        value={selectedTab}
        indicatorColor={"primary"}
        textColor={"primary"}
        onChange={(e, v) => selectTab(v)}
      >
        {tabs.map((tab, i) => (
          <Tab key={i} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      {tabs.map(
        (tab, i) =>
          selectedTab === tab.value && (
            <Orders
              key={i}
              filters={[selectedTab]}
              ordersPerPage={50}
              showEvidenceColumn
              hideDeliveryAddressEditButton
              displaySettings={{
                ...Orders.getDisplayDefaultSettings(),
                showShippingClass: false
              }}
            />
          )
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  selectedTab: selectors.getClickAndCollectSelectedTab(state)
});

const mapDispatchToProps = {
  selectTab
};

export default connect(mapStateToProps, mapDispatchToProps)(ClickAndCollect);
