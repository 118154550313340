import React from "react";

export default () => (
  <svg width="214px" height="214px">
    <defs />
    <g
      id="Login"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-423.000000, -180.000000)"
    >
      <g id="Group" transform="translate(423.000000, 180.000000)">
        <rect
          id="Rectangle-5"
          fill="#15AA99"
          x="0.922733125"
          y="71.1602742"
          width="212.78643"
          height="70.92881"
          rx="35.464405"
        />
        <rect
          id="Rectangle-5"
          fill="#7FBA2A"
          transform="translate(107.315948, 106.624679) rotate(-270.000000) translate(-107.315948, -106.624679) "
          x="0.922733125"
          y="71.1602742"
          width="212.78643"
          height="70.92881"
          rx="35.464405"
        />
        <path
          d="M71.8515431,71.1602742 L142.780353,71.1602742 L142.780353,142.089084 L71.8515431,142.089084 L71.8515431,71.1602742 Z"
          id="Combined-Shape"
          fill="#009A3A"
        />
      </g>
    </g>
  </svg>
);
