import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { ADD_ITEM_OP, REMOVE_ITEM_OP } from "./data";
import s from "./style.module.scss";

const getConfirmDialogMessage = (op, ampId) => {
  switch (op) {
    case ADD_ITEM_OP:
      return `Adding AMP: ${ampId}`;
    case REMOVE_ITEM_OP:
      return `Removing AMP: ${ampId}`;
    default:
      return `Unrecognised operation: ${op}`;
  }
};

const medKeyCopyMap = {
  variantName: "Variant name",
  brandName: "Brand name",
  brandDesc: "Brand description",
  prodDesc: "Product description",
  form: "Form",
  drugName: "Drug name",
  iq: "iq"
};

const ConfirmActionDialog = ({ ampId, onSearch, ...props }) => {
  useEffect(() => {
    onSearch(ampId);
  }, [ampId, onSearch]);
  // TODO: Render error if no ampID, unknown op type
  return (
    <div>
      <Dialog
        id="t_confirm_dialog"
        fullWidth={false}
        open
        onBackdropClick={props.onCancel}
      >
        <DialogTitle>{getConfirmDialogMessage(props.op, ampId)}</DialogTitle>
        <DialogContent>
          {props.loading ? (
            <Grid
              item
              container
              xs={12}
              justify="center"
              className={s.dialogLoading}
            >
              <CircularProgress />
            </Grid>
          ) : (
            props.medicine && (
              <Grid container>
                {Object.keys(props.medicine).reduce((toRender, key) => {
                  if (Object.keys(medKeyCopyMap).includes(key)) {
                    toRender.push(
                      <Grid item xs={12}>
                        <Typography
                          inline
                        >{`${medKeyCopyMap[key]}: `}</Typography>
                        <Typography inline>{props.medicine[key]}</Typography>
                      </Grid>
                    );
                  }
                  return toRender;
                }, [])}
              </Grid>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button
            id="t_confirm_dialog_cancel_button"
            onClick={props.onCancel}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            id="t_confirm_dialog_confirm_button"
            disabled={props.confirmDisabled}
            onClick={() => props.onConfirm(props.ampId)}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UNKNOWN_OP_TYPE = "______UNKNOWN_OP_TYPE______";

ConfirmActionDialog.propTypes = {
  ampId: PropTypes.string.isRequired,
  confirmDisabled: PropTypes.bool.isRequired,
  medicine: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  op: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
ConfirmActionDialog.defaultProps = {
  ampId: "",
  confirmDisabled: false,
  loading: false,
  medicine: {},
  op: UNKNOWN_OP_TYPE,
  onLoad: () => {},
  onConfirm: () => {},
  onCancel: () => {}
};

export default ConfirmActionDialog;
