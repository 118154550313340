import React, { Component } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import PatientDetailsCell from "../common/patient-details-cell";
import { setRightClickMenuOptions } from "../../redux/context-menu";
import GPDetailsCell from "../common/gp-details-cell";
import InfiniteScroll from "../common/infinite-scroll";
import NextPageLoader from "../common/next-page-loader";
import s from "./style.module.scss";
import * as userSel from "../../redux/selectors/user";
import * as gpSel from "../../redux/selectors/gp";
import {
  getPendingUsersIsFetchingFirstPage,
  getPendingUsersIsFetchingNextPage,
  getPendingUsersAllPagesFetched,
  getPendingUsersNeedsRefresh,
  getPendingUsers,
  getPendingUsersNextPageNumber,
  getPendingUsersSelectedUserId,
  getPendingUsersSelectedTabIndex
} from "../../redux/root-reducer";
import {
  fetchUsersPendingNomination,
  selectUser,
  changeTab
} from "../../redux/pending-users";
import { fetchGPProfile } from "../../redux/gp-profile";

export class PendingUsersComponent extends Component {
  componentDidMount() {
    this.props.fetchFirstPage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.needsUpdate && !prevProps.needsUpdate) {
      this.props.fetchFirstPage();
    }
  }

  handleContextMenu(e, index, isSelected) {
    if (e) e.preventDefault();
    if (!isSelected) this.props.onRowClick(index);
    this.props.onRowRightClick({
      isVisible: true,
      x: e ? e.pageX : 0,
      y: e ? e.pageY : 0
    });
  }

  renderTableBody() {
    const rows = this.renderRows();
    const { allPagesFetched, fetchNewPage, isLoadingNextPage } = this.props;
    return (
      <InfiniteScroll
        id="t_infScroll"
        onReachThreshold={() => (allPagesFetched ? () => {} : fetchNewPage())}
        isLoading={isLoadingNextPage}
      >
        {rows}
      </InfiniteScroll>
    );
  }

  renderRows() {
    const { users, onViewGPClick, onViewUserClick, onRowClick } = this.props;
    return users.map((user, index) => (
      <TableRow
        key={user.id}
        id={`t_row_${index}`}
        className={cx("t_row", s.row)}
        selected={user.selected}
        onContextMenu={e => this.handleContextMenu(e, index, user.selected)}
        onClick={() => onRowClick(index)}
      >
        <TableCell>
          <PatientDetailsCell
            {...user}
            userId={user.id}
            id={`t_usercell_${index}`}
            onViewButtonClick={() => onViewUserClick(index)}
          />
        </TableCell>
        <TableCell>
          <GPDetailsCell
            {...user.gp}
            id={`t_gpcell_${index}`}
            onClick={() => onViewGPClick(index)}
          />
        </TableCell>
      </TableRow>
    ));
  }

  renderTable() {
    return (
      <div>
        <Table id="t_table" stickyHeader style={{ backgroundColor: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>GP Practice</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderTableBody()}</TableBody>
        </Table>
        {this.props.isLoadingNextPage && (
          <NextPageLoader id="t_nextPageLoader" />
        )}
      </div>
    );
  }

  renderContent() {
    const { isLoadingFirstPage, users } = this.props;
    if (isLoadingFirstPage) {
      return (
        <div className={s.progress}>
          <CircularProgress id="t_progress" size={50} />
        </div>
      );
    }
    if (users.length < 1) {
      return this.renderNoDataContent();
    }
    return this.renderTable();
  }

  renderContentWithTabs() {
    const { selectedTabIndex, onTabSelected } = this.props;
    return (
      <React.Fragment>
        <Tabs
          id="t_tabs"
          className={s.tabs}
          value={selectedTabIndex}
          indicatorColor="primary"
          textColor="primary"
          fullWidth
          onChange={(e, v) => {
            e.preventDefault();
            onTabSelected(v);
          }}
        >
          <Tab label="A - Z" value={0} />
          <Tab label="Z - A" value={1} />
        </Tabs>
        {this.renderContent()}
      </React.Fragment>
    );
  }

  renderNoDataContent = () => (
    <div id="#t_noData" className={s.noData}>
      <h2>There are currently no users waiting to be nominated.</h2>
    </div>
  );

  render() {
    return <div className={s.root}>{this.renderContentWithTabs()}</div>;
  }
}

const GPShape = PropTypes.shape({
  organisationCode: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string
});

const UserShape = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  dob: PropTypes.string,
  email: PropTypes.string,
  selected: PropTypes.bool,
  gp: GPShape
});

PendingUsersComponent.propTypes = {
  users: PropTypes.arrayOf(UserShape),
  isLoadingFirstPage: PropTypes.bool,
  isLoadingNextPage: PropTypes.bool,
  needsUpdate: PropTypes.bool,
  allPagesFetched: PropTypes.bool,
  fetchFirstPage: PropTypes.func,
  fetchNewPage: PropTypes.func,
  onViewGPClick: PropTypes.func, // called with row index
  onViewUserClick: PropTypes.func, // called with row index
  onRowClick: PropTypes.func,
  onRowRightClick: PropTypes.func,
  selectedTabIndex: PropTypes.number,
  onTabSelected: PropTypes.func
};

PendingUsersComponent.defaultProps = {
  users: [],
  isLoadingFirstPage: false,
  isLoadingNextPage: false,
  needsUpdate: false,
  allPagesFetched: false,
  fetchFirstPage: () => {},
  fetchNewPage: () => {},
  onViewGPClick: () => {},
  onViewUserClick: () => {},
  onRowClick: () => {},
  onRowRightClick: () => {},
  onTabSelected: () => {},
  selectedTabIndex: 0
};

const mapStateToProps = state => ({
  users: getPendingUsers(state).map(user => ({
    id: userSel.getId(user),
    firstName: userSel.getFirstName(user),
    lastName: userSel.getLastName(user),
    address: userSel.getFullAddress(user),
    phone: userSel.getPhone(user),
    dob: userSel.getDOB(user),
    email: userSel.getEmail(user),
    selected: userSel.getId(user) === getPendingUsersSelectedUserId(state),
    gp: {
      organisationCode: gpSel.getId(userSel.getGP(user)),
      name: gpSel.getName(userSel.getGP(user)),
      address: gpSel.getFullAddress(userSel.getGP(user)),
      phone: gpSel.getTelephone(userSel.getGP(user))
    }
  })),
  isLoadingFirstPage: getPendingUsersIsFetchingFirstPage(state),
  isLoadingNextPage: getPendingUsersIsFetchingNextPage(state),
  needsUpdate: getPendingUsersNeedsRefresh(state),
  allPagesFetched: getPendingUsersAllPagesFetched(state),
  nextPageNumber: getPendingUsersNextPageNumber(state),
  selectedTabIndex: getPendingUsersSelectedTabIndex(state)
});

const mapDispatchToProps = {
  fetchUsersPendingNomination,
  pushRoute: push,
  fetchGPProfile,
  selectUser,
  onRowRightClick: setRightClickMenuOptions,
  onTabSelected: changeTab
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  fetchFirstPage: () =>
    dispatchProps.fetchUsersPendingNomination({
      page: 0,
      reverse: stateProps.selectedTabIndex === 1
    }),
  fetchNewPage: () =>
    dispatchProps.fetchUsersPendingNomination({
      page: stateProps.nextPageNumber,
      reverse: stateProps.selectedTabIndex === 1
    }),
  onViewGPClick: index => {
    dispatchProps.pushRoute(
      `/gp/profile/${stateProps.users[index].gp.organisationCode}`
    );
  },
  onViewUserClick: index =>
    dispatchProps.pushRoute(`/user/${stateProps.users[index].id}`),
  onRowClick: index => dispatchProps.selectUser(index, stateProps.users),
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PendingUsersComponent);
