import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { logout } from "../auth";
import { showConfirmDialog } from "../confirm-dialog";
import { showContactDialog } from "../contact-dialog";
import { openCreateOrder } from "../create-order";
import { showCreateDispatchLabel } from "../dispatch-label/create-dialog";
import {
  fetchAndPrintDispatchLabel,
  printRawDeliveryAddress
} from "../dispatch-label/print";
import { showEventsForOrder, showEventsForRequest } from "../events-dialog";
import { showEvidenceDialog } from "../evidence-dialog";
import { GET_GP_PROFILE_REQUEST } from "../gp-profile";
import { showMarkOrderMcs } from "../mark-order-mcs-dialog";
import { showMarkOrderCustomerService } from "../mark-order-customer-service";
import { FETCH_STATUS_COUNTS_SUCCESS } from "../navigation";
import {
  FETCH_ORDERS_REQUEST,
  markCancelled,
  markDelivered,
  markDispatched,
  markDispensed,
  // markPrinted,
  // resolveMCS,
  SELECT_ORDERS,
  SELECT_ORDERS_TAB,
  SELECT_ORDER_SEQUENCE_NUMBER,
  updateMCS,
  updatePriority,
  markHMDelivered,
  releaseOrder,
  resolveCS,
  updateCS,
  markCancelledCFRejected,
  markND,
  markAsReadyToPrint
} from "../orders";
import {
  FETCH_PENDING_USERS_REQUEST,
  nominateUser,
  requestUpdatedDetails,
  SELECT_PENDING_USER
} from "../pending-users";
import {
  FETCH_REQUEST_REQUEST,
  markAsSentToGP,
  rejectRequest,
  SELECT_REQUEST
} from "../requests";
import {
  returnPrescription,
  showReturnPrescriptionDialog
} from "../return-prescription-dialog";
import { SELECT_USER_TAB } from "../user-profile";
import {
  MCSCustomerResponse,
  MCSGPAltTitle,
  MCSActionResponse,
  MCSRTSTitle,
  MCSPendingNestedOrderCustomerResponse,
  MCSPendingSingleOrderCustomerResponse
} from "redux/selectors/event";
import {
  fetchConsolidateOrders,
  linkBasket
} from "redux/consolidate-orders-dialog";
import { getBasketId } from "redux/root-reducer";
import {
  markCancelledFromScanner,
  removeOrderFromBasket,
  setBasketId,
  setTransferBasketDialogOpen
} from "redux/scanner-wrapper";

// ACTIONS
export const SET_RIGHT_CLICK_MENU_OPTIONS = "SET_RIGHT_CLICK_MENU_OPTIONS";
export const SET_GROUPED_MENU_VISIBLE = "SET_GROUPED_MENU_VISIBLE";
export const SET_ANCHOR_ELEMENT = "SET_ANCHOR_ELEMENT";
export const SET_RIGHT_CLICK_MENU_COORDINATES =
  "SET_RIGHT_CLICK_MENU_COORDINATES";

// ACTION CREATORS
export const setRightClickMenuOptions = ({
  isVisible = false,
  x = 0,
  y = 0
}) => ({
  type: SET_RIGHT_CLICK_MENU_OPTIONS,
  isVisible,
  x,
  y
});

export const setGroupedActionsVisibility = isVisible => ({
  type: SET_GROUPED_MENU_VISIBLE,
  isVisible
});

export const setAnchorElement = element => ({
  type: SET_ANCHOR_ELEMENT,
  element
});

const makePrintDispatchLabel = ({ enabled = true, order }) => ({
  name: "print-dispatch-label",
  enabled,
  tooltip: "Print dispatch label",
  actionCreator: () => {
    if (order.trackingCode) return fetchAndPrintDispatchLabel(order.id);
    return showCreateDispatchLabel(order);
  }
});

const makeViewEvidence = ({ enabled = true, order }) => ({
  name: "view-evidence",
  enabled,
  tooltip: "View evidence",
  actionCreator: () => showEvidenceDialog(order)
});

const makePrintPMR = ({ enabled = true, order, qStatus }) => ({
  name: "print-pmr",
  enabled,
  tooltip: "Mark as printed from the PMR",
  actionCreator: () => linkBasketThunk(order, qStatus)
});

export const linkBasketThunk = (order, qStatus) => {
  return (dispatch, getState) => {
    const basketId = getBasketId(getState());
    const showInput = ["dev", "uat"].includes(
      process.env.REACT_APP_NAV_BANNER || ""
    );
    dispatch(
      basketId
        ? order.orderCount > 1
          ? fetchConsolidateOrders(order.patient?.userId, qStatus)
          : linkBasket([order.id], basketId)
        : showConfirmDialog({
            title: "Basket not found",
            message: "There’s no basket to link to. Please try again.",
            requiresInput: showInput,
            inputPlaceholder: "Basket ID input available only for Dev & Uat",
            positiveActionTitle: "TRY AGAIN",
            agreeActionCreator: basketId =>
              showInput ? setBasketId(basketId) : null
          })
    );
  };
};

const makePrintPMRCFRejected = ({ enabled = true, order, qStatus }) => ({
  name: "print-pmr",
  enabled,
  tooltip: "Mark as printed from the PMR",
  actionCreator: () => linkBasketThunk(order, qStatus)
});

const makeMarkDispensed = ({ enabled = true, order }) => ({
  name: "mark-dispensed",
  enabled,
  tooltip: "Mark as Ready to pickup",
  actionCreator: () =>
    showConfirmDialog({
      title: "Are you sure?",
      message:
        "Make sure the items have been labelled, checked by a pharmacist, packed and sealed in a delivery box, and placed into the Royal Mail collection sack.",
      positiveActionTitle: "Confirm",
      agreeActionCreator: () => markDispensed(order)
    })
});

const makeMarkHMDelivered = ({ enabled = true, order }) => ({
  name: "mark-hm-delivered",
  enabled,
  tooltip: "Mark as HM Delivered",
  actionCreator: () =>
    showConfirmDialog({
      title: "Are you sure?",
      message: "This will mark the order as HM Delivered",
      positiveActionTitle: "Confirm",
      agreeActionCreator: () => markHMDelivered(order)
    })
});

const makeMarkDelivered = ({ enabled = true, order }) => ({
  name: "mark-delivered",
  enabled,
  tooltip: "Mark as Delivered",
  actionCreator: () =>
    showConfirmDialog({
      title: "Delivered",
      message:
        "Confirm that the order has been delivered. This will notify the patient. This cannot be undone.",
      positiveActionTitle: "Confirm",
      agreeActionCreator: () => markDelivered(order)
    })
});

const makeMarkCancelled = ({
  enabled = true,
  order,
  requiresCheck = false,
  isScanner = false
}) => ({
  name: "mark-cancelled",
  enabled,
  tooltip: enabled ? "Cancel Order" : "You can only cancel one order at a time",
  actionCreator: () =>
    showConfirmDialog({
      title: "Cancel order",
      requiresInput: true,
      inputPlaceholder: "Cancellation reason",
      requiresCheck: requiresCheck,
      message:
        "Cancelling an order will refund the user. This action cannot be undone.",
      positiveActionTitle: "Cancel order",
      checkBoxLabel: "Send customer notification",
      agreeActionCreator: (notes, sendNotification) =>
        isScanner
          ? markCancelledFromScanner(order, notes, sendNotification)
          : markCancelled(order, notes, sendNotification)
    })
});

const makeMarkCancelledCFRejected = ({
  enabled = true,
  order,
  requiresCheck = false
}) => ({
  name: "mark-cancelled",
  enabled,
  tooltip: enabled ? "Cancel Order" : "You can only cancel one order at a time",
  actionCreator: () =>
    showConfirmDialog({
      title: "Cancel order",
      requiresInput: true,
      inputPlaceholder: "Cancellation reason",
      requiresCheck: requiresCheck,
      message:
        "Cancelling an order will refund the user. This action cannot be undone.",
      positiveActionTitle: "Cancel order",
      checkBoxLabel: "Send customer notification",
      agreeActionCreator: (notes, sendNotification) =>
        markCancelledCFRejected(order, notes, sendNotification)
    })
});

const makeConvertRequestToOrder = ({ enabled = true, request }) => ({
  name: "convert-request",
  enabled,
  tooltip: "Convert to order",
  actionCreator: () =>
    openCreateOrder({
      medicines: request.medicines,
      patient: request.patient,
      requestId: request.id,
      clickAndCollect: request.clickAndCollect
    })
});

const makeMarkDispatched = ({ enabled = true, orders, actOnAll = false }) => ({
  name: "mark-dispatched",
  enabled,
  tooltip: "Mark as picked up by Royal Mail",
  actionCreator: () => {
    let message;
    if (actOnAll) {
      message =
        "Confirm that ALL dispensed items has been posted. This will notify the patient that their medicine has been dispatched. This cannot be undone.";
    } else if (orders.length > 1) {
      message = `Confirm that ${orders.length} items have been posted. This will notify the patients that their medicine has been dispatched. This cannot be undone.`;
    } else {
      message =
        "Confirm that 1 item has been posted. This will notify the patient that their medicine has been dispatched. This cannot be undone.";
    }
    return showConfirmDialog({
      title: "Posted",
      message,
      positiveActionTitle: "Confirm",
      agreeActionCreator: () => markDispatched(orders, actOnAll)
    });
  }
});

const makeMarkMCS = ({ enabled = true, order }) => ({
  name: "mark-mcs",
  enabled,
  tooltip: "Mark MCS",
  actionCreator: () =>
    showMarkOrderMcs(order.id, order.medicines, order.basketId)
});
const makeMarkCustomerService = ({ enabled = true, order }) => ({
  name: "mark-cs",
  enabled,
  tooltip: "Mark Customer Service",
  actionCreator: () => showMarkOrderCustomerService(order.id, order.medicines)
});

// const makeResolveMCS = ({ enabled = true, order }) => ({
//   name: "resolve-mcs",
//   enabled,
//   tooltip: "Resolve MCS",
//   actionCreator: () =>
//     showConfirmDialog({
//       title: "Get customer preferences to resolve MCS",
//       requiresInput: true,
//       inputPlaceholder: "Type the customers preferences to resolve MCS",
//       positiveActionTitle: "Resolve MCS",
//       agreeActionCreator: notes => resolveMCS(order, notes)
//     })
// });

const makeNDMCS = ({ enabled = true, order }) => ({
  name: "nd-mcs",
  enabled,
  tooltip: "Mark ND",
  actionCreator: () => {
    const { medicines } = order;
    const mcsItems = medicines;
    return showConfirmDialog({
      title: "Confirm this item will not be dispensed",
      positiveActionTitle: "MARK ND",
      message:
        "Please arrange for this item to be refunded if necessary. This order must be manually claimed in Analyst.",
      mcsItems,
      agreeActionCreator: notes => markND(order, notes)
    });
  }
});

const makeUpdateMCSPending = ({ enabled = true, order }) => ({
  name: "update-mcs-pending",
  enabled,
  tooltip: "Update/Resolve MCS",
  actionCreator: () => {
    const { medicines, rxToken, parentOrderId } = order;
    const mcsItems = medicines;
    return showConfirmDialog({
      title: "Update/resolve MCS",
      message: "Select customer response",
      positiveActionTitle: "UPDATE MCS",
      requiresRadioButton: true,
      radioButtonOptions: parentOrderId
        ? MCSPendingNestedOrderCustomerResponse
        : MCSPendingSingleOrderCustomerResponse,
      mcsItems,
      agreeActionCreator: (notes, checkValue, MSCCustomerResponse) => {
        if (MSCCustomerResponse === 4) {
          return showConfirmDialog({
            title: "Confirm this item will not be dispensed",
            positiveActionTitle: "MARK ND",
            message:
              "Please arrange for this item to be refunded if necessary. This order must be manually claimed in Analyst.",
            mcsItems,
            agreeActionCreator: notes => markND(order, notes)
          });
        } else if (MSCCustomerResponse === 1) {
          return showConfirmDialog({
            title: MCSRTSTitle,
            message: "Has the prescription been returned to spine?",
            requiresRadioButton: true,
            radioButtonOptions: MCSActionResponse,
            mcsItems,
            rxToken,
            agreeActionCreator: (
              notes,
              sendNotification,
              RTSCustomerResponse
            ) => {
              if (RTSCustomerResponse === 1) {
                return returnPrescription(order, rxToken ?? notes);
              } else if (RTSCustomerResponse === 2) {
                return updateMCS(order, notes, MSCCustomerResponse, false);
              }
            }
          });
        } else if (MSCCustomerResponse === 5) {
          return updateMCS(order, notes, MSCCustomerResponse, false);
        } else if (MSCCustomerResponse === 3) {
          return showConfirmDialog({
            title: MCSGPAltTitle,
            message: "Has the GP agreed to prescribe an alternative?",
            mcsItems,
            nonMcsItems: parentOrderId ? [""] : null,
            requiresRadioButton: true,
            radioButtonOptions: MCSActionResponse,
            rxToken,
            agreeActionCreator: (
              notes,
              sendNotification,
              GPAltCustomerResponse,
              GPAltSecondaryCustomerResponse
            ) => {
              if (mcsItems?.length > 0 && parentOrderId) {
                if (GPAltCustomerResponse === 1) {
                  return showConfirmDialog({
                    title: "Confirm this item will not be dispensed",
                    positiveActionTitle: "MARK ND",
                    message:
                      "Please arrange for this item to be refunded if necessary. This order must be manually claimed in Analyst.",
                    mcsItems,
                    agreeActionCreator: notes => markND(order, notes)
                  });
                } else if (GPAltCustomerResponse === 2) {
                  return updateMCS(order, notes, MSCCustomerResponse, false);
                }
              } else if (mcsItems?.length > 0 && !parentOrderId) {
                if (
                  GPAltCustomerResponse === 1 &&
                  GPAltSecondaryCustomerResponse === 1
                ) {
                  return returnPrescription(order, rxToken ?? notes);
                } else if (
                  GPAltCustomerResponse === 1 &&
                  GPAltSecondaryCustomerResponse === 2
                ) {
                  return updateMCS(order, notes, MSCCustomerResponse, false);
                } else if (GPAltCustomerResponse === 2) {
                  return updateMCS(order, notes, MSCCustomerResponse, false);
                }
              }
            }
          });
        }
      }
    });
  }
});

const showISMSC = (
  order,
  fromUpdateMCS,
  rxToken,
  mcsItems,
  nonMcsItems,
  mcsMedicines
) => {
  return showConfirmDialog({
    title: "Update/resolve MCS",
    message: "Select customer response",
    positiveActionTitle: "NEXT",
    requiresRadioButton: true,
    radioButtonOptions: MCSCustomerResponse,
    mcsItems,
    nonMcsItems,
    agreeActionCreator: (notes, checkValue, MSCCustomerResponse) => {
      if (MSCCustomerResponse === 1) {
        return showConfirmDialog({
          title: MCSRTSTitle,
          message: "Has the prescription been returned to spine?",
          requiresRadioButton: true,
          radioButtonOptions: MCSActionResponse,
          mcsItems,
          nonMcsItems,
          rxToken,
          agreeActionCreator: (
            notes,
            sendNotification,
            RTSCustomerResponse
          ) => {
            if (RTSCustomerResponse === 1) {
              return returnPrescription(order, rxToken ?? notes, fromUpdateMCS);
            } else if (RTSCustomerResponse === 2) {
              return updateMCS(
                order,
                notes,
                MSCCustomerResponse,
                false,
                false,
                mcsMedicines
              );
            }
          }
        });
      } else if (MSCCustomerResponse === 2) {
        return showConfirmDialog({
          title: "Confirm you want to split this order",
          message:
            "The MCS item(s) will be sent to the waiting for stock list. Other items will be sent to manual dispensing.",
          mcsItems,
          nonMcsItems,
          positiveActionTitle: "SPLIT ORDER",
          agreeActionCreator: () => {
            // split and refresh
            return updateMCS(
              order,
              null,
              MSCCustomerResponse,
              true,
              mcsItems?.length + nonMcsItems?.length > 1,
              mcsMedicines
            );
          }
        });
      } else if (MSCCustomerResponse === 3) {
        return showConfirmDialog({
          title: MCSGPAltTitle,
          message: "Has the GP agreed to prescribe an alternative?",
          mcsItems,
          nonMcsItems,
          requiresRadioButton: true,
          radioButtonOptions: MCSActionResponse,
          rxToken,
          agreeActionCreator: (
            notes,
            sendNotification,
            GPAltCustomerResponse,
            GPAltSecondaryCustomerResponse
          ) => {
            if (mcsItems?.length > 0 && nonMcsItems?.length > 0) {
              if (GPAltCustomerResponse === 1) {
                return showConfirmDialog({
                  title: "Confirm you want to split this order",
                  message:
                    "The MCS item(s) will be mark ND. Other items will be sent to manual dispensing.",
                  mcsItems,
                  nonMcsItems,
                  positiveActionTitle: "SPLIT ORDER",
                  agreeActionCreator: () => {
                    //split ND API
                    return markND(order, notes, true);
                  }
                });
              } else if (GPAltCustomerResponse === 2) {
                return updateMCS(
                  order,
                  notes,
                  MSCCustomerResponse,
                  false,
                  false,
                  mcsMedicines
                );
              }
            } else if (mcsItems?.length > 0 || nonMcsItems?.length > 0) {
              if (
                GPAltCustomerResponse === 1 &&
                GPAltSecondaryCustomerResponse === 1
              ) {
                return returnPrescription(
                  order,
                  rxToken ?? notes,
                  fromUpdateMCS
                );
              } else if (
                GPAltCustomerResponse === 1 &&
                GPAltSecondaryCustomerResponse === 2
              ) {
                return updateMCS(
                  order,
                  notes,
                  MSCCustomerResponse,
                  false,
                  false,
                  mcsMedicines
                );
              } else if (GPAltCustomerResponse === 2) {
                return updateMCS(
                  order,
                  notes,
                  MSCCustomerResponse,
                  false,
                  false,
                  mcsMedicines
                );
              }
            }
          }
        });
      }
    }
  });
};
const makeUpdateMCS = ({ enabled = true, order }) => ({
  name: "update-mcs",
  enabled,
  tooltip: "Update/Resolve MCS",
  actionCreator: () => {
    const { medicines, rxToken } = order;
    let mcsItems = medicines?.filter(item => item.isMcs) ?? [];
    let nonMcsItems = medicines?.filter(item => !item.isMcs) ?? [];
    const fromUpdateMCS = true;

    return mcsItems.length === 0
      ? showConfirmDialog({
          title: "Update/resolve MCS",
          message: "You must select MCS items before you can update this order",
          positiveActionTitle: "CONTINUE",
          requiresMultiCheckbox: true,
          medicines,
          agreeActionCreator: (
            notes,
            checkValue,
            MSCCustomerResponse,
            radio,
            selectedMedicines
          ) => {
            const updatedMedicines = [];
            medicines.map((medicine, i) => {
              if (selectedMedicines.includes(i)) {
                updatedMedicines.push({ ...medicine, isMcs: true });
              } else {
                updatedMedicines.push(medicine);
              }
            });
            mcsItems = updatedMedicines?.filter(item => item.isMcs) ?? [];
            nonMcsItems = updatedMedicines?.filter(item => !item.isMcs) ?? [];
            const mcsMedicines = [];
            mcsItems.map(med => {
              const mscMed = {
                ampId: med.packages[0].ampId,
                vmpId: med.vmpId,
                medicineItemId: med.medicineItemId
              };
              mcsMedicines.push(mscMed);
            });
            return showISMSC(
              order,
              fromUpdateMCS,
              rxToken,
              mcsItems,
              nonMcsItems,
              mcsMedicines
            );
          }
        })
      : showISMSC(order, fromUpdateMCS, rxToken, mcsItems, nonMcsItems, null);
  }
});

const makeResolveCS = ({ enabled = true, order }) => ({
  name: "resolve-cs",
  enabled,
  tooltip: "Resolve Customer Service",
  actionCreator: () =>
    showConfirmDialog({
      title: "Get customer preferences to resolve Customer Service",
      requiresInput: true,
      inputPlaceholder:
        "Type the customers preferences to resolve Customer Service",
      positiveActionTitle: "Resolve Customer Service",
      agreeActionCreator: notes => resolveCS(order, notes)
    })
});

const makeUpdateCS = ({ enabled = true, order }) => ({
  name: "update-cs",
  enabled,
  tooltip: "Update Customer Service",
  actionCreator: () =>
    showConfirmDialog({
      title: "Update Customer Service",
      inputPlaceholder: "Enter a description of the latest update",
      message: "Add an update to the status of this Customer Service order.",
      positiveActionTitle: "Update",
      requiresInput: true,
      agreeActionCreator: notes => updateCS(order, notes)
    })
});

const makeSendRequestToGP = ({ enabled = true, request }) => ({
  name: "send-request-to-gp",
  enabled,
  tooltip: "Mark request as sent to GP",
  actionCreator: () =>
    showConfirmDialog({
      title: "Contacted GP",
      message:
        "Confirm that you have contacted the GP and asked for a prescription. This action cannot be undone.",
      positiveActionTitle: "Confirm",
      agreeActionCreator: () => markAsSentToGP(request)
    })
});

const makeReadyToPrint = ({ enabled = true, order }) => ({
  name: "ready-to-print",
  enabled,
  tooltip: "Stock available",
  actionCreator: () =>
    showConfirmDialog({
      title: "Ready to print",
      message: "This action cannot be undone.",
      positiveActionTitle: "Confirm",
      agreeActionCreator: () => markAsReadyToPrint(order)
    })
});

const makeRejectRequest = ({ enabled = true, request }) => ({
  name: "reject-request",
  enabled,
  tooltip: "Reject request",
  actionCreator: () =>
    showConfirmDialog({
      title: "Reject request",
      inputPlaceholder: "Rejection reason",
      message:
        "Make sure you explain to the patient the reason for rejecting their request. This action cannot be undone.",
      positiveActionTitle: "Reject request",
      requiresInput: true,
      agreeActionCreator: notes => rejectRequest(request, notes)
    })
});

const makeGPContactDialog = ({ enabled = true }) => ({
  name: "open-contact-dialog",
  tooltip: "Contact GP",
  enabled,
  actionCreator: () => showContactDialog()
});

const makeViewEvents = ({ enabled = true, order, request }) => ({
  name: "view-events",
  tooltip: "View events",
  enabled,
  actionCreator: () =>
    order ? showEventsForOrder(order.id) : showEventsForRequest(request.id)
});

const makeReturnPrescription = ({ enabled = false, order }) => ({
  name: "return-prescription",
  tooltip: "Return Prescription",
  enabled,
  actionCreator: () => showReturnPrescriptionDialog(order, order.rxToken)
});

const makeReleaseOrder = ({ enabled = true, order }) => ({
  name: "release-order",
  tooltip: "Release Order",
  enabled,
  actionCreator: () =>
    showConfirmDialog({
      title: "Release Order",
      message:
        "This will e-mail the customer requesting that they update their details. Are you sure you wish to do this?",
      positiveActionTitle: "Release Order",
      agreeActionCreator: () => releaseOrder(order)
    })
});

const makeRemoveAndScan = ({ enabled = true }) => ({
  name: "remove-basket-order",
  tooltip: "Remove and scan to new basket",
  enabled,
  actionCreator: () => setTransferBasketDialogOpen()
});

const makeRemoveAndReadyToPrint = ({ enabled = true, order }) => ({
  name: "remove-return-basket-order",
  tooltip: "Remove and return to ready to print",
  enabled,
  actionCreator: () =>
    showConfirmDialog({
      title: "Return order to ready to print?",
      message: "This action cannot be undone.",
      positiveActionTitle: "Mark ready to print",
      agreeActionCreator: () =>
        removeOrderFromBasket(order.basketId, [order.id], true)
    })
});

const makePrintRawDeliveryAddress = ({ enabled = true, order }) => ({
  name: "print-raw-delivery-address",
  enabled,
  tooltip: "Print raw delivery address",
  actionCreator: () =>
    printRawDeliveryAddress({
      name: `${order.patient.firstName} ${order.patient.lastName}`,
      address: order.deliveryAddress
    })
});

const makeUpdatePriorityHigh = ({ enabled = true, order }) => ({
  name: "update-priority-high",
  enabled,
  tooltip: "Set order priority to high",
  actionCreator: () => updatePriority({ priority: true, order })
});

const makeUpdatePriorityLow = ({ enabled = true, order }) => ({
  name: "update-priority-low",
  enabled,
  tooltip: "Set order priority to low",
  actionCreator: () => updatePriority({ priority: false, order })
});

const makeUpdatePriority = ({ order }) => {
  if (order.priority) {
    return makeUpdatePriorityLow({ order });
  }

  return makeUpdatePriorityHigh({ order });
};

const createOrderItems = ({ orders = [], order, actOnAll = false }) => {
  order = order || orders[0];
  if (!order || order === null) return [];
  if (order.basketId && order.status !== "dispensed") {
    return [
      makeRemoveAndScan({ enabled: true, order }),
      makeRemoveAndReadyToPrint({ order, requiresCheck: true }),
      makeMarkCancelled({ order, requiresCheck: true, isScanner: true }),
      makeReturnPrescription({ enabled: true, order }),
      makeMarkMCS({ order }),
      makeUpdatePriority({ order }),
      makeViewEvents({ order })
    ];
  }
  switch (order.status) {
    case "invoiced":
      return [
        makeMarkCancelled({ order }),
        makeReturnPrescription({ enabled: true, order }),
        makeUpdatePriority({ order }),
        makeViewEvents({ order })
      ];
    case "exemption_provided":
      return [
        makeViewEvidence({
          order,
          enabled:
            !order.invoice.exemptionEvidenceSkipped &&
            order.invoice.exemptionCode !== ""
        }),
        makeMarkCancelled({ order }),
        makeReturnPrescription({ enabled: true, order }),
        makeUpdatePriority({ order }),
        makeViewEvents({ order })
      ];
    case "invoice_settled":
    case "checkoutbuffer_completed":
      return [
        makePrintPMR({
          order,
          qStatus: ["invoice_settled", "checkoutbuffer_completed"]
        }),
        makeReturnPrescription({ enabled: true, order }),
        makeViewEvents({ order }),
        makeMarkCancelled({ order }),
        makeUpdatePriority({ order })
      ];

    case "cf_rejected":
      return [
        makePrintPMRCFRejected({ order, qStatus: ["cf_rejected"] }),
        makeReturnPrescription({ enabled: true, order }),
        makeViewEvents({ order }),
        makeMarkCancelledCFRejected({ order }),
        makeUpdatePriority({ order }),
        makeMarkMCS({ order })
      ];

    case "prescription_printed":
      return [
        makeMarkDispensed({ order }),
        makePrintDispatchLabel({ order }),
        makeMarkCancelled({ order }),
        makeReturnPrescription({ enabled: true, order }),
        makeViewEvents({ order }),
        makePrintRawDeliveryAddress({ order }),
        makeUpdatePriority({ order }),
        makeMarkMCS({ order }),
        makeMarkCustomerService({ order })
      ];
    case "hm_accepted":
      return [
        makeMarkDispensed({ order }),
        makeMarkCancelled({ order }),
        makeReturnPrescription({ enabled: true, order }),
        makeUpdatePriority({ order }),
        makeViewEvents({ order }),
        makeMarkHMDelivered({ order })
      ];
    case "hm_delivered":
      return [
        makeMarkDispensed({ order }),
        makePrintDispatchLabel({ order }),
        makeMarkCancelled({ order }),
        makeUpdatePriority({ order }),
        makePrintRawDeliveryAddress({ order }),
        makeViewEvents({ order })
      ];
    case "dispensed":
      return [
        makeMarkDispatched({ orders, actOnAll }),
        makeMarkCancelled({
          order,
          enabled: orders.length < 2
        }),
        makeViewEvents({
          order,
          enabled: orders.length < 2
        })
      ];
    case "mcs":
      return [
        // makeResolveMCS({ order }),
        makeUpdateMCS({ order }),
        makeMarkCancelled({ order }),
        makeUpdatePriority({ order }),
        makeViewEvents({ order }),
        makeReadyToPrint({ order })
      ];
    case "customer_service":
      return [
        makeResolveCS({ order }),
        makeUpdateCS({ order }),
        makeMarkCancelled({ order }),
        makeUpdatePriority({ order }),
        makeViewEvents({ order })
      ];
    case "dispatched":
      return [makeMarkDelivered({ order }), makeViewEvents({ order })];
    case "cc_accepted":
    case "cc_transferred":
    case "cc_downloaded":
    case "cc_dispensed":
    case "cc_not_collected":
      return [makeMarkCancelled({ order }), makeViewEvents({ order })];
    case "manual_check":
      return [
        makeReleaseOrder({ enabled: true, order }),
        makeMarkCancelled({ order, requiresCheck: true }),
        makeViewEvents({ order })
      ];
    case "mcs_pending":
      return [
        makeUpdateMCSPending({ order }),
        makeMarkDispensed({ order }),
        makePrintDispatchLabel({ order }),
        order?.parentOrderId ? makeNDMCS({ order }) : null,
        makeViewEvents({ order }),
        makePrintRawDeliveryAddress({ order }),
        makeUpdatePriority({ order }),
        makeMarkCustomerService({ order }),
        makeReadyToPrint({ order })
      ].filter(item => item);
    default:
      return [makeViewEvents({ order })];
  }
};

const createRequestItems = ({ request = null }) => {
  if (request === null) return [];
  switch (request.status) {
    case "open":
      return [
        makeGPContactDialog({ request }),
        makeSendRequestToGP({ request }),
        makeRejectRequest({ request }),
        makeViewEvents({ request })
      ];
    case "sent_to_gp":
      return [
        makeGPContactDialog({ request }),
        makeConvertRequestToOrder({ request }),
        makeRejectRequest({ request }),
        makeViewEvents({ request })
      ];
    default:
      return [makeViewEvents({ request })];
  }
};

const makeRequestDetailsUpdate = userId => ({
  name: "nominate-update-details",
  tooltip: "Request updated details from customer",
  enabled: true,
  actionCreator: () =>
    showConfirmDialog({
      title: "E-mail customer",
      message:
        "This will e-mail the customer requesting that they update their details. Are you sure you wish to do this?",
      positiveActionTitle: "Confirm",
      agreeActionCreator: () => requestUpdatedDetails(userId)
    })
});

const makeNominateUser = userId => ({
  name: "nominate-user",
  tooltip: "Nominate User",
  enabled: true,
  actionCreator: () => nominateUser(userId)
});

const createPendingUserItems = action => {
  const user = action.users[action.index];
  if (user.selected === true) return [];
  return [makeRequestDetailsUpdate(user.id), makeNominateUser(user.id)];
};

// SELECTORS
export const getContextOpenState = state => state.isGroupedMenuOpen;
export const getContextMenuGroupedActions = state => state.groupedMenuActions;
export const getContextMenuAnchorElement = state => state.anchorElement;
export const getContextMenuActions = state => state.menuActions;
export const getRightClickMenuOptions = state => state.rightClickMenuOptions;

// REDUCERS
const menuActions = handleActions(
  {
    [SELECT_REQUEST]: (state, action) => createRequestItems(action),
    [SELECT_ORDERS]: (state, action) => createOrderItems(action),
    [SELECT_PENDING_USER]: (state, action) => createPendingUserItems(action),
    [FETCH_REQUEST_REQUEST]: () => [],
    [FETCH_ORDERS_REQUEST]: () => [],
    [FETCH_PENDING_USERS_REQUEST]: () => [],
    [GET_GP_PROFILE_REQUEST]: () => [],
    [SELECT_USER_TAB]: () => [],
    [FETCH_STATUS_COUNTS_SUCCESS]: () => [],
    [SELECT_ORDERS_TAB]: () => [],
    [SELECT_ORDER_SEQUENCE_NUMBER]: () => []
  },
  []
);

const isGroupedMenuOpen = handleActions(
  {
    [SET_GROUPED_MENU_VISIBLE]: (state, action) => action.isVisible
  },
  false
);

const anchorElement = handleActions(
  {
    [SET_ANCHOR_ELEMENT]: (state, action) => action.element
  },
  null
);

const rightClickMenuOptions = handleActions(
  {
    [SET_RIGHT_CLICK_MENU_OPTIONS]: (state, action) => action
  },
  { isVisible: false, x: 0, y: 0 }
);

export default combineReducers({
  menuActions,
  groupedMenuActions: () => [
    {
      enabled: true,
      name: "sign-out",
      title: "Sign out",
      actionCreator: () => logout()
    }
  ],
  isGroupedMenuOpen,
  anchorElement,
  rightClickMenuOptions
});
