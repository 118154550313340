import { SvgIcon } from "@material-ui/core";
import React from "react";

const VariableRemoveIcon = props => (
  <SvgIcon
    width={"24"}
    height={"24"}
    viewBox={"0 0 24 24"}
    fill={"none"}
    {...props}
  >
    <path
      d={
        "M14 17H3V7H21V10H19V9H5V15H14V17ZM16 17.6L18.1 15.5L16 13.4L17.4 12L19.5 14.1L21.6 12L23 13.4L20.925 15.5L23 17.6L21.6 19L19.5 16.925L17.4 19L16 17.6Z"
      }
    />
  </SvgIcon>
);

export default VariableRemoveIcon;
