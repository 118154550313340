import { RSAA } from "redux-api-middleware";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import moment from "moment";

export const ACCESS_START_REQUEST = "ACCESS_START_REQUEST";
export const ACCESS_START_FAILURE = "ACCESS_START_FAILURE";
export const ACCESS_START_RECEIVE = "ACCESS_START_RECEIVE";

export const ACCESS_COMPLETE_REQUEST = "ACCESS_COMPLETE_REQUEST";
export const ACCESS_COMPLETE_FAILURE = "ACCESS_COMPLETE_FAILURE";
export const ACCESS_COMPLETE_RECEIVE = "ACCESS_COMPLETE_RECEIVE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_RECEIVE = "LOGOUT_RECEIVE";

export const REFRESH_REQUEST = "REFRESH_REQUEST";
export const REFRESH_FAILURE = "REFRESH_FAILURE";
export const REFRESH_RECEIVE = "REFRESH_RECEIVE";

export const BYPASS_AUTH_REQUEST = "BYPASS_AUTH_REQUEST";
export const BYPASS_AUTH_RECEIVE = "BYPASS_AUTH_RECEIVE";
export const BYPASS_AUTH_FAILURE = "BYPASS_AUTH_FAILURE";

export const ACCESS_START = Symbol("ACCESS_START");
export const ACCESS_COMPLETE = Symbol("ACCESS_COMPLETE");
export const LOGOUT = Symbol("LOGOUT");
export const REFRESH = Symbol("REFRESH");

export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";

const API_ENDPOINT = process.env.REACT_APP_USERS_API;
const BYPASS_API_ENDPOINT = process.env.REACT_APP_BYPASS_AUTH_API;

export const accessStart = (email, apiEndpoint = API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/access/start`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      target: "pharmos"
    }),
    types: [
      {
        type: ACCESS_START_REQUEST,
        meta: { email }
      },
      ACCESS_START_RECEIVE,
      ACCESS_START_FAILURE
    ],
    options: { authStage: ACCESS_START }
  }
});

export const accessComplete = (rnd, apiEndpoint = API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/access/complete/${rnd}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    // Body assigned by middleware
    types: [
      ACCESS_COMPLETE_REQUEST,
      ACCESS_COMPLETE_RECEIVE,
      ACCESS_COMPLETE_FAILURE
    ],
    options: { authStage: ACCESS_COMPLETE }
  }
});

export const logout = (apiEndpoint = API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/logout`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    // Body assigned by middleware
    types: [LOGOUT_REQUEST, LOGOUT_RECEIVE, LOGOUT_FAILURE],
    options: { addAuth: true, authStage: LOGOUT }
  }
});

export const refresh = (apiEndpoint = API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/refresh`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    // Body assigned by middleware
    types: [REFRESH_REQUEST, REFRESH_RECEIVE, REFRESH_FAILURE],
    options: { authStage: REFRESH }
  }
});

export const bypassAuth = (email, apiEndpoint = BYPASS_API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: apiEndpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email }),
    types: [BYPASS_AUTH_REQUEST, BYPASS_AUTH_RECEIVE, BYPASS_AUTH_FAILURE]
  }
});

export const authenticationError = () => ({
  type: AUTHENTICATION_ERROR
});

export const getIsAuthenticated = state => state.idToken !== null;
export const getAccessStartToken = state => state.accessStartToken;
export const getAccessToken = state => state.accessToken;
export const getIdToken = state => state.idToken;
export const getIdTokenExpiration = state => state.idTokenExpiration;
export const getRefreshToken = state => state.refreshToken;
export const getRefreshTokenExpriration = state => state.refreshTokenExpiration;

export const accessStartToken = handleActions(
  {
    [ACCESS_START_RECEIVE]: (state, action) => action.payload.token,
    [LOGOUT_RECEIVE]: () => null,
    [AUTHENTICATION_ERROR]: () => null
  },
  null
);

export const idToken = handleActions(
  {
    [ACCESS_COMPLETE_RECEIVE]: (state, action) =>
      action.payload.idToken || null,
    [BYPASS_AUTH_RECEIVE]: (state, action) => action.payload.idToken || null,
    [REFRESH_RECEIVE]: (state, action) => action.payload.idToken || null,
    [LOGOUT_RECEIVE]: () => null,
    [AUTHENTICATION_ERROR]: () => null
  },
  null
);

export const accessToken = handleActions(
  {
    [ACCESS_COMPLETE_RECEIVE]: (state, action) =>
      action.payload.accessToken || null,
    [BYPASS_AUTH_RECEIVE]: (state, action) =>
      action.payload.accessToken || null,
    [REFRESH_RECEIVE]: (state, action) => action.payload.accessToken || null,
    [LOGOUT_RECEIVE]: () => null,
    [AUTHENTICATION_ERROR]: () => null
  },
  null
);

export const idTokenExpiration = handleActions(
  {
    [ACCESS_COMPLETE_RECEIVE]: (state, action) =>
      moment(action.payload.expirationDate),
    [BYPASS_AUTH_RECEIVE]: (state, action) =>
      moment(action.payload.expirationDate),
    [REFRESH_RECEIVE]: (state, action) => moment(action.payload.expirationDate),
    [LOGOUT_RECEIVE]: () => null,
    [AUTHENTICATION_ERROR]: () => null
  },
  null
);

export const refreshToken = handleActions(
  {
    [ACCESS_COMPLETE_RECEIVE]: (state, action) => action.payload.refreshToken,
    [BYPASS_AUTH_RECEIVE]: (state, action) => action.payload.refreshToken,
    [LOGOUT_RECEIVE]: () => null,
    [AUTHENTICATION_ERROR]: () => null
  },
  null
);

export const refreshTokenExpiration = handleActions(
  {
    [ACCESS_COMPLETE_RECEIVE]: () => moment().add(12, "hours"),
    [BYPASS_AUTH_RECEIVE]: () => moment().add(12, "hours"),
    [LOGOUT_RECEIVE]: () => null,
    [AUTHENTICATION_ERROR]: () => null
  },
  null
);

export default combineReducers({
  accessStartToken,
  accessToken,
  idToken,
  idTokenExpiration,
  refreshToken,
  refreshTokenExpiration
});
