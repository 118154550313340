import React, { useCallback } from "react";
import { withTheme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Grid
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  getBasketEditWeightDialogIsOpen,
  getBasketWeight
} from "redux/root-reducer";
import { hideEditBasketDialog, setBasketWeight } from "redux/scanner-wrapper";
import NumberPad from "components/number-pad";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: "2.5rem"
  },
  headerText: {
    fontSize: "2rem",
    color: theme.palette.text.secondary
  },
  textField: {
    fontSize: "25px"
  },
  cancelButton: {
    padding: "2.5rem",
    marginTop: "5rem",
    fontWeight: 500
  }
}));

const EditBasketWeightDialog = ({
  basketWeight,
  setBasketWeight,
  hideEditBasketDialog,
  open
}) => {
  const classes = useStyles();

  const handleNumberClick = useCallback(
    number => {
      if (number === -1) {
        // Remove last character
        setBasketWeight(basketWeight.slice(0, -1));
      } else if (number === 13) {
        // Enter key - hide dialog
        hideEditBasketDialog();
      } else if (number >= 0 && number <= 9) {
        // Append number to basketWeight
        setBasketWeight(`${basketWeight}${number}`);
      }
    },
    [setBasketWeight, hideEditBasketDialog, basketWeight]
  );

  const handleInputChange = useCallback(e => setBasketWeight(e.target.value), [
    setBasketWeight
  ]);

  const handleKeyDown = useCallback(
    e => e.key === "Enter" && hideEditBasketDialog(),
    [hideEditBasketDialog]
  );

  const handleCancelClick = useCallback(() => {
    setBasketWeight("");
    hideEditBasketDialog();
  }, [setBasketWeight, hideEditBasketDialog]);

  return (
    <Dialog id={"t_dialog"} open={open} maxWidth={"md"} fullWidth>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Box display={"flex"} flexDirection={"column"} flex={1} mb={"2.5rem"}>
            <Typography variant={"h4"} className={classes.headerText}>
              Weight
            </Typography>
            <TextField
              fullWidth
              id={"t_basket_weight"}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={basketWeight || ""}
              autoFocus
              inputProps={{ className: classes.textField }}
            />
          </Box>
          <NumberPad onClick={handleNumberClick} />
        </Grid>
        <Button
          fullWidth
          id={"t_cancel"}
          color={"primary"}
          variant={"outlined"}
          onClick={handleCancelClick}
          className={classes.cancelButton}
        >
          <Typography variant={"h2"}>Cancel</Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  basketWeight: getBasketWeight(state),
  open: getBasketEditWeightDialogIsOpen(state)
});

const mapDispatchToProps = {
  setBasketWeight,
  hideEditBasketDialog
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(EditBasketWeightDialog));
