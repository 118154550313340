import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

const style = {
  root: {
    padding: "24px 0 24px 0"
  }
};

export default withStyles(style)(TableCell);
