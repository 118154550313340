import React, { FC } from "react";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { OrdersFilterProps, FILTERS_WIDTH } from ".";
import MedicineSearch from "../../../common/medicine-search";
import { getMedicineDescription, getMedicineName } from "redux/root-reducer";
import DateFilterDialog from "components/date-filter-dialog";

const useStyles = makeStyles(theme => ({
  filter: {
    width: FILTERS_WIDTH,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRadius: 0
  },
  filtersContainer: {
    // flex: 1
  },
  medicineSearchContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2)
  },
  medicineList: {
    overflow: "auto"
  },
  buttonContainer: {
    padding: theme.spacing(2)
  }
}));

const OrdersFilter: FC<OrdersFilterProps> = ({
  isOpen,
  medicines,
  currentUrl,
  selectMedicine,
  deleteMedicine,
  resetFilters
}) => {
  const classes = useStyles();

  if (isOpen) {
    return (
      <Paper className={classes.filter} elevation={0}>
        <div className={classes.filtersContainer}>
          <div className={classes.medicineSearchContainer}>
            <MedicineSearch onMedSuggestionSelect={selectMedicine} />
          </div>
          <List className={classes.medicineList}>
            {medicines.length > 0 &&
              medicines.map((medicine, index) => (
                <ListItem
                  data-testid={"medicine-item"}
                  key={`medicine-${medicine.vmpId}`}
                  dense
                >
                  <ListItemText
                    primary={getMedicineName(medicine)}
                    secondary={getMedicineDescription(medicine)}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      data-testid={`btn/delete-medicine-${index}`}
                      edge={"end"}
                      aria-label={"delete medicine"}
                      onClick={() => deleteMedicine(medicine)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
        {medicines.length > 1 && (
          <div className={classes.buttonContainer}>
            <Button
              data-testid={"btn/reset-filters"}
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={resetFilters}
            >
              Clear filters
            </Button>
          </div>
        )}
        {currentUrl == "/orders/dispense" ? <DateFilterDialog /> : ""}
      </Paper>
    );
  }

  return null;
};

export default OrdersFilter;
