import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Orders from "components/orders";
import { formatDateString } from "helpers/date-helpers";
import UserProfile from "components/user-profile";

const BasketOrder = ({
  RemoveOrder,
  basketData,
  isReadyToProcess,
  isUser,
  smallScreenBack
}) => {
  if (isUser) return <UserProfile />;

  if (RemoveOrder) {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gridGap={"1.25rem"}
        pt={"1.25rem"}
        flex={1}
      >
        <Button
          variant={"text"}
          color={"secondary"}
          style={{ width: "fit-content" }}
          onClick={smallScreenBack}
        >
          Back
        </Button>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gridGap={"1.25rem"}
          px={"2.5rem"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gridGap={"0.75rem"}
            p={"2.5rem 2.5rem 2.5rem 1.25rem"}
            borderRadius={"0.5rem"}
            border={"1px solid #DADADA"}
            maxWidth={"50rem"}
            alignSelf={"center"}
            maxHeight={"20rem"}
            overflow={"auto"}
          >
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      color={"textSecondary"}
                      style={{ fontSize: "1.25rem" }}
                    >
                      Basket
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "1.25rem" }}>
                      {basketData.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      color={"textSecondary"}
                      style={{ fontSize: "1.25rem" }}
                    >
                      Patient
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "1.25rem" }}>
                      {basketData.patient}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      color={"textSecondary"}
                      style={{ fontSize: "1.25rem" }}
                    >
                      DOB
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "1.25rem" }}>
                      {formatDateString(basketData.dob)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      color={"textSecondary"}
                      style={{ fontSize: "1.25rem" }}
                    >
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {basketData.orderIds?.map((orderId, index) => (
                      <Typography key={index} style={{ fontSize: "1.25rem" }}>
                        {orderId}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      color={"textSecondary"}
                      style={{ fontSize: "1.25rem" }}
                    >
                      Delivery address
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {basketData.deliveryAddress
                      ?.split("\n")
                      .map((address, index) =>
                        address.trim() !== "" ? (
                          <Typography
                            key={index}
                            style={{ fontSize: "1.25rem" }}
                          >
                            {address}
                          </Typography>
                        ) : null
                      )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      color={"textSecondary"}
                      style={{ fontSize: "1.25rem" }}
                    >
                      Shipping
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "1.25rem" }}>
                      {basketData.shippingClass || ""}{" "}
                      {basketData.shippingSigned ? "signed" : "unsigned"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Typography
            variant={"body1"}
            style={{ fontSize: "1.125rem", fontWeight: 500 }}
          >
            Select the order you want to remove from this basket
          </Typography>
        </Box>
        <Orders
          orders={basketData?.orders}
          showEvidenceColumn
          hideNewOrderBtn
          displaySettings={{
            showOrderId: true,
            showNotes: true,
            showPatient: true,
            showGP: true,
            showOrderStatus: true,
            showDeliveryAddress: true,
            showBarcodeColumn: true,
            showLatestEvent: true,
            showBarcodeForOrder: status =>
              ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
                status
              ),
            showShippingClass: true,
            showShippingButtonsForOrder: status =>
              ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
                status
              )
          }}
          requiredFetch={false}
        />
      </Box>
    );
  }

  if (isReadyToProcess)
    return (
      <Orders
        filters={["invoice_settled", "checkoutbuffer_completed"]}
        ordersPerPage={20}
        showEvidenceColumn
        warningSettings={{
          threshold: 1,
          type: "WD"
        }}
      />
    );

  return (
    <Orders
      filters={["cf_rejected"]}
      splits={4}
      subSplit
      ordersPerPage={60}
      displaySettings={{
        showOrderId: true,
        showPatient: true,
        showGP: true,
        showLatestEvent: true,
        showNotes: true,
        showDeliveryAddress: true,
        showShippingClass: true,
        showBarcodeColumn: true,
        showBarcodeForOrder: () => true
      }}
      showEvidenceColumn
      warningSettings={{
        threshold: 1,
        type: "WD"
      }}
    />
  );
};

export default BasketOrder;
