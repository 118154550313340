import { OrdersEndointOptions, OrdersQuery } from "./orders.types";
import { ORDERS_API_ENDPOINT } from "./index";

export const buildFetchOrdersEndpoint = ({
  page = 0,
  filters = [],
  showExpired = false,
  vmpIds = [],
  ampIds = [],
  dateFilter,
  dateFilterPriority,
  userId,
  ordersPerPage,
  splitsCount = 0,
  splitIndex = 0,
  orderSequence = 1,
  tab,
  splitAlpha,
  apiEndpoint = ORDERS_API_ENDPOINT
}: OrdersEndointOptions) => {
  const query: OrdersQuery = {};
  query.showExpired = showExpired;
  if (page) {
    query.page = page;
  }
  if (filters && filters.length) {
    query.status = filters.join(",");
  }
  if (vmpIds && vmpIds.length) {
    query.vmpId = vmpIds.join(",");
  }
  if (ampIds && ampIds.length) {
    query.ampId = ampIds.join(",");
  }
  if (userId) {
    query.userId = userId;
  }
  if (ordersPerPage) {
    query.ordersPerPage = ordersPerPage;
  }
  if (splitsCount) {
    query.splitsCount = splitsCount;
    query.splitIndex = splitIndex;
  }
  if (orderSequence && query.status === "cf_rejected") {
    query.orderSequence = orderSequence;
  }
  if (tab) {
    query.tab = tab;
    if (splitAlpha && tab === "standard") {
      query.splitAlpha = splitAlpha;
    }
  }
  if (dateFilterPriority && dateFilterPriority === true) {
    if (query.status == "prescription_printed") {
      query.priorityFilter = true;
    }
  }

  if (dateFilter && dateFilter.length > 0) {
    if (query.status == "prescription_printed") {
      const ds = dateFilter.map(ele => `"${ele}"`).join(",");
      query.datesFilter = ds;
    }
  }

  let url = `${apiEndpoint}/orders`;
  if (Object.keys(query).length > 0) {
    const qs = Object.entries(query)
      .map(([k, v]) => `${k}=${v}`)
      .join("&");
    url = `${url}?${qs}`;
  }
  return url;
};
