import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import React from "react";
import ScanInfo from "./_ScanInfo";

// theme props is injected by withTheme HOC that the export is wrapped in
const _ScanCheckIcon = ({ scanType, scanned, theme }) => {
  const iconSize = 100;

  return scanned ? (
    <CheckCircleOutlinedIcon
      id={`t_positive_icon_${scanType}`}
      className="t_positive_icon"
      style={{
        fontSize: iconSize,
        color: theme.palette.primary.main
      }}
    />
  ) : (
    <RemoveCircleOutlineIcon
      id={`t_negative_icon_${scanType}`}
      className="t_negative_icon"
      style={{
        fontSize: iconSize,
        color: theme.palette.grey[200]
      }}
    />
  );
};

// Because we are stuck on MaterialUI < v4 this is the v3 way of using withTheme which does not match the current docs
const ScanCheckIcon = withTheme(_ScanCheckIcon);

const ScanCheck = ({ type, value, title, children }) => {
  const infoItemValues = [
    {
      text: !!value ? "Scanned" : "Not scanned",
      typographyProps: {
        color: "textSecondary"
      }
    },
    {
      text: title,
      typographyProps: {
        variant: "h6"
      }
    },
    { text: value || "-" }
  ];
  return (
    <Grid container>
      <Grid item md={3}>
        <ScanCheckIcon scanned={!!type} scanType={type} />
      </Grid>
      <Grid item container md={4}>
        <ScanInfo values={infoItemValues} />
      </Grid>
      <Grid item container md={5}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ScanCheck;
