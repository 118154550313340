import React, { useEffect, FC } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import s from "./gpcreate.module.scss";
import {
  fetchNHSGP,
  createGPStart,
  createGPODSUpdate,
  callGPExists
} from "../../redux/gp-nhs-create";
import { GP, completeNHSUpdateGP } from "../../redux/gp-nhs-refresh";

import {
  getNHSGPCreateODSCode,
  getNHSGPChecked,
  getNHSGPPreview,
  getGPIsRefreshed,
  getNHSGPCreateUnknownODS,
  getNHSGPCreateNotGP,
  getNHSGPCreateAlreadyExists
} from "../../redux/root-reducer";
import CreateForm from "./create-form";
import Preview from "./preview";

export interface GPNHSCreateProps {
  odsCode: string;
  odsUpdate: (ods: string) => void;
  gpChecked: boolean;
  gpRefreshed: boolean;
  gpAlreadyExists: boolean;
  unknownODS: boolean;
  notGP: boolean;
  openGPProfile: (ods: string) => void;
  callGPExists: (ods: string) => void;
  fetchNHSGP: (ods: string) => void;
  completeCreateGP: (ods: string) => void;
  gpPreview: GP | null;
  pushRoute: (path: string) => void;
  reset: () => void;
}
export const GPNHSCreate: FC<GPNHSCreateProps> = (props: GPNHSCreateProps) => {
  const {
    odsCode,
    gpChecked,
    fetchNHSGP,
    gpPreview,
    gpRefreshed,
    pushRoute,
    reset
  } = props;
  useEffect(() => {
    if (gpChecked) {
      fetchNHSGP(odsCode);
    }
  }, [gpChecked, fetchNHSGP, odsCode]);

  useEffect(() => {
    if (gpRefreshed) {
      reset();
      pushRoute("/gp/profile/" + odsCode);
    }
  }, [gpRefreshed, pushRoute, reset, odsCode]);

  return (
    <div className={s.frame}>
      <h1>Create GP</h1>
      <CreateForm
        odsCode={odsCode}
        odsUpdate={props.odsUpdate}
        gpAlreadyExists={props.gpAlreadyExists}
        unknownODS={props.unknownODS}
        notGP={props.notGP}
        openGPProfile={props.openGPProfile}
        callGPExists={props.callGPExists}
      />
      {gpPreview !== null && (
        <Preview
          gp={gpPreview}
          restart={reset}
          completeCreateGP={props.completeCreateGP}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  odsUpdate: createGPODSUpdate,
  fetchNHSGP,
  pushRoute: push,
  reset: createGPStart,
  callGPExists: callGPExists,
  openGPProfile: (ods: string): Action => push(`/gp/profile/${ods}`),
  completeCreateGP: completeNHSUpdateGP
};

const mapStateToProps = state => ({
  odsCode: getNHSGPCreateODSCode(state),
  gpChecked: getNHSGPChecked(state),
  gpPreview: getNHSGPPreview(state),
  gpRefreshed: getGPIsRefreshed(state),
  gpAlreadyExists: getNHSGPCreateAlreadyExists(state),
  unknownODS: getNHSGPCreateUnknownODS(state),
  notGP: getNHSGPCreateNotGP(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GPNHSCreate);
