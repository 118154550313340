import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Home } from "@material-ui/icons";
import LoadingButton from "../common/loading-button";
import Cell from "../user-profile/cell";
import {
  getDeliveryAddressIsOpen,
  getDeliveryAddressIsLoading,
  getDeliveryAddressLine1,
  getDeliveryAddressLine2,
  getDeliveryAddressCity,
  getDeliveryAddressPostcode,
  getDeliveryAddressRequestId,
  getDeliveryAddressOrderId
} from "../../redux/root-reducer";
import {
  setLine1,
  setLine2,
  setCity,
  setPostcode,
  closeDeliveryAddress,
  updateDeliveryAddress
} from "../../redux/delivery-address";
import { addressLineMaxLen } from "helpers/validators";

export const UpdateDeliveryAddressDialog = ({
  isOpen,
  isLoading,
  line1,
  line2,
  city,
  postcode,
  onCancelClick,
  onLine1Change,
  onLine2Change,
  onCityChange,
  onPostcodeChange,
  onUpdateClick
}) => (
  <Dialog open={isOpen} maxWidth="md">
    <DialogTitle>Update Delivery Address</DialogTitle>
    <DialogContent style={{ width: "500px" }}>
      <Cell icon={<Home />}>
        <TextField
          fullWidth
          id="t_address1"
          label="1st Line of Address"
          type="text"
          value={line1}
          error={line1?.length > addressLineMaxLen}
          helperText={line1?.length > addressLineMaxLen ? 'Character limit exceeded. Please continue address on line 2.' : ''}
          inputProps={{ maxLength: addressLineMaxLen + 1}}
          onChange={e => onLine1Change(e.currentTarget.value)}
        />
      </Cell>
      <Cell>
        <TextField
          fullWidth
          id="t_address2"
          label="2nd Line of Address"
          type="text"
          value={line2}
          error={line2?.length > addressLineMaxLen}
          helperText={line2?.length > addressLineMaxLen ? 'Character limit exceeded' : ''}
          inputProps={{ maxLength: addressLineMaxLen + 1}}
          onChange={e => onLine2Change(e.currentTarget.value)}
        />
      </Cell>
      <Cell>
        <TextField
          fullWidth
          id="t_address_city"
          label="City"
          type="text"
          value={city}
          error={city?.length > addressLineMaxLen}
          helperText={city?.length > addressLineMaxLen ? 'Character limit exceeded' : ''}
          inputProps={{ maxLength: addressLineMaxLen + 1}}
          onChange={e => onCityChange(e.currentTarget.value)}
        />
      </Cell>
      <Cell>
        <TextField
          fullWidth
          id="t_address_postcode"
          label="Postcode"
          type="text"
          value={postcode}
          onChange={e => onPostcodeChange(e.currentTarget.value)}
        />
      </Cell>
    </DialogContent>
    <DialogActions>
      <Button
        id="t_cancel"
        color="secondary"
        onClick={onCancelClick}
        disabled={isLoading}
      >
        Cancel
      </Button>
      <LoadingButton
        id="t_update"
        color="primary"
        autoFocus
        disabled={city?.length > addressLineMaxLen || line2?.length > addressLineMaxLen || line1?.length > addressLineMaxLen}
        loading={isLoading}
        onClick={onUpdateClick}
      >
        Update Address
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

UpdateDeliveryAddressDialog.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
  onLine1Change: PropTypes.func,
  onLine2Change: PropTypes.func,
  onCityChange: PropTypes.func,
  onPostcodeChange: PropTypes.func,
  onUpdateClick: PropTypes.func
};

UpdateDeliveryAddressDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  onCancelClick: () => {},
  onLine1Change: () => {},
  onLine2Change: () => {},
  onCityChange: () => {},
  onPostcodeChange: () => {},
  canUpdate: false,
  onUpdateClick: () => {}
};

export const mapStateToProps = state => ({
  isOpen: getDeliveryAddressIsOpen(state),
  isLoading: getDeliveryAddressIsLoading(state),
  line1: getDeliveryAddressLine1(state),
  line2: getDeliveryAddressLine2(state),
  city: getDeliveryAddressCity(state),
  postcode: getDeliveryAddressPostcode(state),
  requestId: getDeliveryAddressRequestId(state),
  orderId: getDeliveryAddressOrderId(state)
});

export const mapDispatchToProps = {
  onCancelClick: closeDeliveryAddress,
  onLine1Change: setLine1,
  onLine2Change: setLine2,
  onCityChange: setCity,
  onPostcodeChange: setPostcode,
  updateDeliveryAddress: updateDeliveryAddress
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onUpdateClick: () =>
    dispatchProps.updateDeliveryAddress({
      line1: stateProps.line1,
      line2: stateProps.line2,
      city: stateProps.city,
      postcode: stateProps.postcode,
      requestId: stateProps.requestId,
      orderId: stateProps.orderId
    }),
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UpdateDeliveryAddressDialog);
