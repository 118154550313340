import { RSAA } from "redux-api-middleware";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { GET_USER_PROFILE_RECEIVE } from "../user-profile";
import { FETCH_REQUEST_REQUEST } from "../requests";
import { FETCH_ORDERS_REQUEST } from "../orders";
import { FETCH_PENDING_USERS_REQUEST } from "../pending-users";
import moment from "moment";

const SEARCH_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

// ACTIONS
export const FETCH_SUGGESTIONS_REQUEST = "FETCH_SUGGESTIONS_REQUEST";
export const FETCH_SUGGESTIONS_RECEIVE = "FETCH_SUGGESTIONS_RECEIVE";
export const FETCH_SUGGESTIONS_FAILURE = "FETCH_SUGGESTIONS_FAILURE";
export const SEARCH_SUGGESTIONS_CLEAR = "SEARCH_SUGGESTIONS_CLEAR";
export const SEARCH_SUGGESTIONS_SELECT = "SEARCH_SUGGESTIONS_SELECT";
export const SEARCH_SELECTION_CLEAR = "SEARCH_SELECTION_CLEAR";

// ACTION CREATORS
export const fetchSuggestions = (
  text = "",
  uniqueId = Date.now(),
  apiEndpoint = SEARCH_API_ENDPOINT
) => {
  if (text.length < 2) {
    return { type: FETCH_SUGGESTIONS_REQUEST, meta: { uniqueId, text } };
  }

  const fragments = text.split(" ");
  const invalidStringRegExp = new RegExp(/\d/);
  const invalidTextFragments = fragments.filter(
    fragment =>
      invalidStringRegExp.test(fragment) &&
      !moment.utc(fragment, "D/M/YYYY", true).isValid()
  );

  if (invalidTextFragments.length > 0) {
    return { type: FETCH_SUGGESTIONS_REQUEST, meta: { uniqueId, text } };
  }
  const dateFragments = fragments.filter(fragment =>
    moment(fragment, "D/M/YYYY", true).isValid()
  );

  // only take first search for date
  let dob;
  if (dateFragments.length > 0) {
    dob = dateFragments[0];
  }

  const searchName = fragments.filter(fragment => fragment !== dob).join(" ");

  let searchDate;
  if (dob) {
    searchDate = moment.utc(dob, "DD/MM/YYYY").toISOString();
  }

  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: searchName ? searchName.trim() : searchName,
        dob: searchDate ? searchDate.trim() : ""
      }),
      types: [
        FETCH_SUGGESTIONS_REQUEST,
        FETCH_SUGGESTIONS_RECEIVE,
        FETCH_SUGGESTIONS_FAILURE
      ].map(type => ({ type, meta: { uniqueId, text } })),
      options: { addAuth: true }
    }
  };
};

export const clearSuggestions = () => ({ type: SEARCH_SUGGESTIONS_CLEAR });
export const selectSuggestion = item => ({
  type: SEARCH_SUGGESTIONS_SELECT,
  payload: item
});

// SELECTORS
export const getSuggestions = state => state.searchAutoComplete.suggestions;
export const getSearchText = state => state.searchText;

// REDUCERS
const searchText = handleActions(
  {
    [FETCH_SUGGESTIONS_REQUEST]: (state, action) => action.meta.text || "",
    [SEARCH_SUGGESTIONS_CLEAR]: () => "",
    [FETCH_REQUEST_REQUEST]: () => "",
    [FETCH_ORDERS_REQUEST]: () => "",
    [FETCH_PENDING_USERS_REQUEST]: () => "",
    [GET_USER_PROFILE_RECEIVE]: (state, action) =>
      `${action.payload.user.firstName} ${action.payload.user.lastName}`
  },
  ""
);

const searchAutoComplete = handleActions(
  {
    [SEARCH_SUGGESTIONS_CLEAR]: () => ({ reqId: null, suggestions: [] }),
    [SEARCH_SUGGESTIONS_SELECT]: () => ({ reqId: null, suggestions: [] }),
    [FETCH_SUGGESTIONS_REQUEST]: (state, action) => ({
      ...state,
      reqId: action.meta.uniqueId
    }),
    [FETCH_SUGGESTIONS_RECEIVE]: (state, action) =>
      action.meta.uniqueId === state.reqId
        ? {
            suggestions: action.payload.map(p => ({
              ...p,
              address: `${p.addressLine1}, ${p.postcode}`
            })),
            reqId: null
          }
        : { ...state },
    [FETCH_SUGGESTIONS_FAILURE]: () => ({ reqId: null, suggestions: [] })
  },
  { reqId: null, suggestions: [] }
);

export default combineReducers({
  searchText,
  searchAutoComplete
});
