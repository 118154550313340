import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ContentAdd from "@material-ui/icons/Add";
import ContentRemove from "@material-ui/icons/Remove";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    width: 24,
    height: 24,
    margin: 8,
    padding: 0
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground
  }
});

const StyledButton = withStyles(styles)(IconButton);

const Button = ({ icon, ...other }) => (
  <StyledButton {...other}>
    {icon === "plus" ? (
      <ContentAdd style={{ color: "#ffffff" }} />
    ) : (
      <ContentRemove style={{ color: "#ffffff" }} />
    )}
  </StyledButton>
);

Button.propTypes = {
  ...IconButton.propTypes,
  icon: PropTypes.oneOf(["plus", "minus"]).isRequired
};

export default Button;
