import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { selectContactMethod } from "../../redux/contact-dialog";
import {
  getRequestGPDetails,
  getRequestGPDetailsNoContact,
  getRequestGPDetailsPreference
} from "../../redux/root-reducer";
import s from "./contact-dialog.module.scss";

const contactConfig = ["email", "fax"];

export const getFilteredDetails = (GPDetails, filters = contactConfig) => {
  let methods = [];
  if (GPDetails.additional) {
    methods = Object.keys(GPDetails.additional).filter(
      key =>
        filters.includes(key) &&
        GPDetails.additional[key] &&
        GPDetails.additional[key] !== ""
    );
  }
  methods.push("docman");
  return methods;
};

export class ContactMethods extends Component {
  componentDidMount() {
    const { onSelect, methods, preference } = this.props;
    if (methods.length > 0) {
      onSelect(methods.find(k => k === preference) || methods[0]);
    } else {
      onSelect("");
    }
  }
  render() {
    const {
      methods,
      onSelect,
      noContact = false,
      preference,
      value
    } = this.props;
    return (
      <FormControl
        fullWidth
        className={s.formControl}
        disabled={methods.length < 1}
      >
        {methods.length > 0 ? (
          <InputLabel htmlFor="method">
            Please select a Contact Method...
          </InputLabel>
        ) : (
          <InputLabel htmlFor="method">
            Please Add a Contact Method...
          </InputLabel>
        )}
        <Select
          fullWidth
          value={value}
          inputProps={{
            name: "method",
            id: "method"
          }}
          onChange={e => onSelect(e.target.value)}
        >
          {methods
            .filter(k => k === preference)
            .map(name => (
              <MenuItem key={name} className="t_contact" value={name}>
                {name} ( Preferred )
              </MenuItem>
            ))}
          {methods
            .filter(k => k !== preference)
            .map(name => (
              <MenuItem key={name} className="t_contact" value={name}>
                {name}{" "}
              </MenuItem>
            ))}
        </Select>
        {noContact ? (
          <FormHelperText style={{ color: "red" }}>
            This GP has requested that they not be contacted.
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}
ContactMethods.propTypes = {
  onSelect: PropTypes.func.isRequired,
  noContact: PropTypes.bool,
  value: PropTypes.string.isRequired,
  methods: PropTypes.array.isRequired,
  preference: PropTypes.string
};
ContactMethods.defaultProps = {
  noContact: false,
  preference: ""
};

const mapStateToProps = state => ({
  methods: getFilteredDetails(getRequestGPDetails(state)),
  noContact: getRequestGPDetailsNoContact(state),
  preference: getRequestGPDetailsPreference(state)
});

const mapDispatchToProps = {
  onSelect: selectContactMethod
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactMethods);
