import { handleActions } from "redux-actions";
import { combineReducers } from "redux";

// constants
export const CC_ACCEPTED = "cc_accepted";
export const CC_DOWNLOADED = "cc_downloaded";
export const CC_TRANSFERRED = "cc_transferred";
export const CC_DISPENSED = "cc_dispensed";
export const CC_DELIVERED = "cc_delivered";
export const CC_NOT_COLLECTED = "cc_not_collected";

// action types
export const SELECT_TAB = "CLICK_AND_COLLECT/SELECT_TAB";

// action creators
export const selectTab = tab => ({ type: SELECT_TAB, tab });

// selectors
export const getSelectedTab = state => state.selectedTab;

// reducers
const selectedTab = handleActions(
  {
    [SELECT_TAB]: (state, action) => action.tab
  },
  CC_ACCEPTED
);

export default combineReducers({ selectedTab });
