import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth, * as fromAuth from "./auth";
import confirmDialog, * as fromConfirmDialog from "./confirm-dialog";
import DateFilterDialog, * as fromDateFilterDialog from "./date-filter-dialog";
import contactDialog, * as fromContactDialog from "./contact-dialog";
import contextMenu, * as fromContextMenu from "./context-menu";
import createOrder, * as fromCreateOrder from "./create-order";
import deliveryAddress, * as fromDeliveryAddress from "./delivery-address";
import createDispatchLabel, * as fromCreateDispatchLabel from "./dispatch-label/create-dialog";
import eventsDialog, * as fromEventsDialog from "./events-dialog";
import evidenceDialog, * as fromEvidenceDialog from "./evidence-dialog";
import profile, * as fromGPProfile from "./gp-profile";
import nhsRefresh, * as fromNHSRefresh from "./gp-nhs-refresh";
import login, * as fromLogin from "./login";
import manualLineStart, * as fromManualLineStart from "./manual-line-start";
import markOrderMcsDialog, * as fromMarkOrderMcsDialog from "./mark-order-mcs-dialog";
import markOrderCSDialog, * as fromMarkOrderCSDialog from "./mark-order-customer-service";

import matchScripts, * as fromMatchScripts from "./match-scripts";
import matchUsers, * as fromMatchUsers from "./match-users";
import mcsMedicineList, * as fromMCSMedicineList from "./mcs-medicine-list";
import navigation, * as fromNav from "./navigation";
import notification, * as fromNotification from "./notification";
import orders, * as fromOrders from "./orders/index";
import pendingUsers, * as fromPendingUsers from "./pending-users";
import requests, * as fromRequests from "./requests/index";
import returnPrescriptionDialog, * as fromReturnPrescriptionDialog from "./return-prescription-dialog";
import consolidateOrdersDialog, * as fromConsolidateOrdersDialog from "./consolidate-orders-dialog";
import scannerWrapper, * as fromScannerWrapper from "./scanner-wrapper";
import search, * as fromSearch from "./search";
import shippingClassDialog, * as fromShippingClassDialog from "./shipping-class-dialog";
import userProfile, * as fromUserProfile from "./user-profile";
import clickAndCollect, * as fromClickAndCollect from "./click-and-collect";
import whistl, * as fromWhistl from "./whistl";
import medsSearch, * as fromMedsSearch from "./meds-search";
import nhsGPCreate, * as fromNHSGPCreate from "./gp-nhs-create";
import gpContactReview, * as fromContactReview from "./gp-contact-review";
import { permGPRefresh } from "../permissions";

const COST_UNIT_PENCE =
  new Date() >= new Date(process.env.REACT_APP_COST_UNIT_PENCE_EFFECTIVE_DATE)
    ? process.env.REACT_APP_COST_UNIT_PENCE
    : process.env.REACT_APP_COST_UNIT_PENCE_BEFORE;
// Context Menu
export const getContextOpenState = state =>
  fromContextMenu.getContextOpenState(state.contextMenu);
export const getContextMenuGroupedActions = state =>
  fromContextMenu.getContextMenuGroupedActions(state.contextMenu);
export const getContextMenuAnchorElement = state =>
  fromContextMenu.getContextMenuAnchorElement(state.contextMenu);
export const getContextMenuActions = state =>
  fromContextMenu.getContextMenuActions(state.contextMenu);

// Right-click Context Menu
export const getRightClickMenuOptions = state =>
  fromContextMenu.getRightClickMenuOptions(state.contextMenu);

// Orders
export const getSelectedOrderIds = state =>
  fromOrders.getSelectedOrderIds(state.orders);
export const getSelectedOrder = state =>
  fromOrders.getSelectedOrder(state.orders);
export const getOrders = state => fromOrders.getOrders(state.orders);
export const getOrdersNeedsUpdate = state =>
  fromOrders.getOrdersNeedsUpdate(state.orders);
export const getOrdersIsFetchingFirstPage = state =>
  fromOrders.getFetching(state.orders);
export const getOrdersIsFetchingNextPage = state =>
  fromOrders.getFetchingAdditional(state.orders);
export const getOrdersResultsPage = state =>
  fromOrders.getResultsPage(state.orders);
export const getOrdersLastPageSize = state =>
  fromOrders.getLastPageSize(state.orders);
export const getOrdersSelectedTabIndex = state =>
  fromOrders.getSelectedTabIndex(state.orders);
export const getOrdersSelectedSubTabIndex = state =>
  fromOrders.getSelectedSubTabIndex(state.orders);
export const getOrderSelectedIndex = state =>
  fromOrders.getSelectedOrderNumber(state);
export const getOrderSeqCount = state => fromOrders.getSeqCount(state);
export const getOrdersActingOnAll = state =>
  fromOrders.getActingOnAll(state.orders);
export const getOrdersMedicineFilters = state =>
  fromOrders.getMedicinesFilter(state.orders);
export const getOrdersIsFiltering = state =>
  fromOrders.getIsFiltering(state.orders);
export const getShowOrdersFilters = state =>
  fromOrders.getshowFilters(state.orders);

// Evidence dialog
export const getEvidenceError = state =>
  fromEvidenceDialog.getError(state.evidenceDialog);
export const getEvidenceExpiration = state =>
  fromEvidenceDialog.getExpiration(state.evidenceDialog);
export const getEvidenceIsFetching = state =>
  fromEvidenceDialog.getIsFetching(state.evidenceDialog);
export const getEvidenceURL = state =>
  fromEvidenceDialog.getURL(state.evidenceDialog);
export const getEvidenceIsVisible = state =>
  fromEvidenceDialog.getDialogVisible(state.evidenceDialog);
export const getEvidenceInvoiceId = state =>
  fromEvidenceDialog.getInvoiceId(state.evidenceDialog);
export const getCanApproveOrRejectEvidence = state =>
  fromEvidenceDialog.getCanApproveOrReject(state.evidenceDialog);
export const getCanSetExpirationForEvidence = state =>
  fromEvidenceDialog.getCanSetExpiration(state.evidenceDialog);
export const getIsEvidenceExpirationValid = state =>
  fromEvidenceDialog.getIsExpirationValid(state.evidenceDialog);
export const getRejectionReasons = state =>
  fromEvidenceDialog.getRejectionReasons(state.evidenceDialog);
export const getSelectedRejectionReason = state =>
  fromEvidenceDialog.getSelectedRejectionReason(state.evidenceDialog);
export const getReasonNotes = state =>
  fromEvidenceDialog.getReasonNotes(state.evidenceDialog);
export const getShowRejectionReasons = state =>
  fromEvidenceDialog.getShowRejectionReasons(state.evidenceDialog);

// Requests
export const getRequests = state => fromRequests.getRequests(state.requests);
export const getSelectedRequestId = state =>
  fromRequests.getSelectedId(state.requests);
export const getRequestsError = state => fromRequests.getError(state.requests);
export const getRequestsIsLoadingFirstPage = state =>
  fromRequests.getFetching(state.requests);
export const getRequestsIsLoadingNextPage = state =>
  fromRequests.getFetchingAdditional(state.requests);
export const getRequestsNeedsUpdate = state =>
  fromRequests.getNeedsUpdate(state.requests);
export const getRequestGPDetails = state =>
  fromRequests.getRequestGPDetails(state.requests);
export const getRequestGPDetailsNoContact = state =>
  fromRequests.getRequestGPDetailsNoContact(state.requests);
export const getRequestGPDetailsPreference = state =>
  fromRequests.getRequestGPDetailsPreference(state.requests);
export const getRequestResultsPage = state =>
  fromRequests.getResultsPage(state.requests);
export const getAllRequestsPagesFetched = state =>
  fromRequests.getAllPagesFetched(state.requests);
// datefilter dialog
export const getDateFilterDialogCount = state =>
  fromDateFilterDialog.getDateFilterCounts(state.DateFilterDialog);
export const getIsDateFilterCountLoading = state =>
  fromDateFilterDialog.getIsDFCLoading(state.DateFilterDialog);
// Confirm dialog
export const getConfirmDialogIsVisible = state =>
  fromConfirmDialog.getIsVisible(state.confirmDialog);
export const getInputMessage = state =>
  fromConfirmDialog.getInputText(state.confirmDialog);
export const getCheckBoxValue = state =>
  fromConfirmDialog.getCheckBox(state.confirmDialog);
export const getRadioButtonValue = state =>
  fromConfirmDialog.getRadioButton(state.confirmDialog);
export const getConfirmDialogTitle = state =>
  fromConfirmDialog.getTitle(state.confirmDialog);
export const getConfirmDialogCheckboxLabel = state =>
  fromConfirmDialog.getCheckBoxLabel(state.confirmDialog);
export const getConfirmDialogMessage = state =>
  fromConfirmDialog.getMessage(state.confirmDialog);
export const getConfirmDialogPositiveActionTitle = state =>
  fromConfirmDialog.getPositiveActionTitle(state.confirmDialog);
export const getConfirmDialogAgreeActionCreator = state =>
  fromConfirmDialog.getAgreeActionCreator(state.confirmDialog);
export const getConfirmDialogCancelActionCreator = state =>
  fromConfirmDialog.getCancelActionCreator(state.confirmDialog);
export const getConfirmDialogHasInput = state =>
  fromConfirmDialog.getRequiresInput(state.confirmDialog);
export const getConfirmDialogHasRadioButton = state =>
  fromConfirmDialog.getRequiresRadioButton(state.confirmDialog);
export const getConfirmDialogRadioButtonOptions = state =>
  fromConfirmDialog.getRadioButtonOptions(state.confirmDialog);
export const getConfirmDialogHasCheck = state =>
  fromConfirmDialog.getRequiresCheck(state.confirmDialog);
export const getConfirmDialogCanSubmit = state =>
  fromConfirmDialog.getCanSubmit(state.confirmDialog);
export const getConfirmDialogInputPlaceholder = state =>
  fromConfirmDialog.getInputPlaceholder(state.confirmDialog);
export const getConfirmDialogMcsItems = state =>
  fromConfirmDialog.getMcsItems(state.confirmDialog);
export const getConfirmDialogNonMcsItems = state =>
  fromConfirmDialog.getNonMcsItems(state.confirmDialog);
export const getConfirmDialogRxToken = state =>
  fromConfirmDialog.getRxToken(state.confirmDialog);
export const getSecondaryRadioButtonValue = state =>
  fromConfirmDialog.getSecondaryRadioButton(state.confirmDialog);
export const getConfirmDialogShowRTSError = state =>
  fromConfirmDialog.getShowRTSError(state.confirmDialog);
export const getConfirmDialogRTSErrorMessage = state =>
  fromConfirmDialog.getRTSErrorMessage(state.confirmDialog);
export const getConfirmDialogMedicinesItem = state =>
  fromConfirmDialog.getMedicines(state.confirmDialog);
export const getConfirmDialogMedicinesItemSelected = state =>
  fromConfirmDialog.getSelectedMedicines(state.confirmDialog);
export const getConfirmDialogHasMultiCheckbox = state =>
  fromConfirmDialog.getRequiresMultiCheckbox(state.confirmDialog);
// Create order
export const getCreateOrderRequestId = state =>
  fromCreateOrder.getRequestId(state.createOrder);
export const getCreateOrderClickAndCollect = state =>
  fromCreateOrder.getClickAndCollect(state.createOrder);
export const getCreateOrderIsOpen = state =>
  fromCreateOrder.getIsOpen(state.createOrder);
export const getCreateOrderIsEditingMedicine = state =>
  fromCreateOrder.getIsEditingMedicine(state.createOrder);
export const getCreateOrderPatient = state =>
  fromCreateOrder.getPatient(state.createOrder);
export const getCreateOrderMedicines = state =>
  fromCreateOrder.getMedicines(state.createOrder);
export const getMedsSuggestions = state =>
  fromMedsSearch.getMedsSuggestions(state.medsSearch);
export const getMedsSearchText = state =>
  fromMedsSearch.getMedsSearchText(state.medsSearch);
export const getCreateOrderCanCreateOrder = state =>
  fromCreateOrder.getCanCreateOrder(state.createOrder);
export const getCreateOrderCanAddMedicine = state =>
  fromCreateOrder.getCanAddMedicine(state.createOrder);
export const getCreateOrderIsCreatingOrder = state =>
  fromCreateOrder.getIsCreatingOrder(state.createOrder);
export const getCreateOrderCanUpdateMedicines = state =>
  fromCreateOrder.getCanUpdateMedicines(state.createOrder);
export const getCreateOrderPatientsSearchText = state =>
  fromCreateOrder.getPatientsSearchText(state.createOrder);
export const getCreateOrderPatientsSuggestions = state =>
  fromCreateOrder.getPatientsSuggestions(state.createOrder);
export const getCreateOrderCanChangePatient = state =>
  fromCreateOrder.getCanChangePatient(state.createOrder);
export const getCreateOrderRxToken = state =>
  fromCreateOrder.getRxToken(state.createOrder);

// Update Delivery Address
export const getDeliveryAddressLine1 = state =>
  fromDeliveryAddress.getLine1(state.deliveryAddress);
export const getDeliveryAddressLine2 = state =>
  fromDeliveryAddress.getLine2(state.deliveryAddress);
export const getDeliveryAddressCity = state =>
  fromDeliveryAddress.getCity(state.deliveryAddress);
export const getDeliveryAddressPostcode = state =>
  fromDeliveryAddress.getPostcode(state.deliveryAddress);
export const getDeliveryAddressIsOpen = state =>
  fromDeliveryAddress.getIsOpen(state.deliveryAddress);
export const getDeliveryAddressRequestId = state =>
  fromDeliveryAddress.getRequestId(state.deliveryAddress);
export const getDeliveryAddressOrderId = state =>
  fromDeliveryAddress.getOrderId(state.deliveryAddress);
export const getDeliveryAddressIsLoading = state =>
  fromDeliveryAddress.getIsLoading(state.deliveryAddress);

// notification
export const getIsNotificationVisible = state =>
  fromNotification.getIsVisible(state.notification);
export const getNotificationMessage = state =>
  fromNotification.getMessage(state.notification);
export const getNotificationDuration = state =>
  fromNotification.getDuration(state.notification);
export const getAnchorOrigin = state =>
  fromNotification.getAnchorOrigin(state.notification);
export const getBgColor = state =>
  fromNotification.getBgColor(state.notification);

// Medicines
export const getMedicineName = med => med.brandName || med.drugName;
export const getMedicineDescription = med =>
  med.brandDescription || med.brandDesc || med.drugDescription || med.prodDesc;
export const getMedicineCost = ({ costUnits }) =>
  ((costUnits || 0) * (COST_UNIT_PENCE / 100)).toFixed(2);

// authorised
export const getIsAuthenticated = state =>
  fromAuth.getIsAuthenticated(state.auth);
export const getAccessStartToken = state =>
  fromAuth.getAccessStartToken(state.auth);
export const getAccessToken = state => fromAuth.getAccessToken(state.auth);
export const getIdToken = state => fromAuth.getIdToken(state.auth);
export const getIdTokenExpiration = state =>
  fromAuth.getIdTokenExpiration(state.auth);
export const getRefreshToken = state => fromAuth.getRefreshToken(state.auth);
export const getRefreshTokenExpriration = state =>
  fromAuth.getRefreshTokenExpriration(state.auth);

// create dispatch label
export const getCreateDispatchLabelIsOpen = state =>
  fromCreateDispatchLabel.getIsOpen(state.createDispatchLabel);
export const getCreateDispatchLabelFullName = state =>
  fromCreateDispatchLabel.getFullName(state.createDispatchLabel);
export const getCreateDispatchLabelAddress = state =>
  fromCreateDispatchLabel.getAddress(state.createDispatchLabel);
export const getCreateDispatchLabelWeight = state =>
  fromCreateDispatchLabel.getWeight(state.createDispatchLabel);
export const getCreateDispatchLabelUrl = state =>
  fromCreateDispatchLabel.getLabelUrl(state.createDispatchLabel);
export const getCreateDispatchLabelSelectedPackageIndex = state =>
  fromCreateDispatchLabel.getSelectedPackageIndex(state.createDispatchLabel);
export const getCreateDispatchLabelPackageNames = state =>
  fromCreateDispatchLabel.getPackageNames(state.createDispatchLabel);
export const getCreateDispatchLabelIsCreatingLabel = state =>
  fromCreateDispatchLabel.getIsCreatingLabel(state.createDispatchLabel);
export const getCreateDispatchLabelCanCreateLabel = state =>
  fromCreateDispatchLabel.getCanCreateLabel(state.createDispatchLabel);
export const getCreateDispatchLabelOrderId = state =>
  fromCreateDispatchLabel.getOrderId(state.createDispatchLabel);
export const getCreateDispatchLabelSelectedPackage = state =>
  fromCreateDispatchLabel.getSelectedPackage(state.createDispatchLabel);
export const getCreateDispatchLabelSelectedService = state =>
  fromCreateDispatchLabel.getSelectedService(state.createDispatchLabel);
export const getCreateDispatchLabelSelectedServiceIndex = state =>
  fromCreateDispatchLabel.getSelectedServiceIndex(state.createDispatchLabel);
export const getCreateDispatchLabelServiceNames = state =>
  fromCreateDispatchLabel.getServiceNames(state.createDispatchLabel);
export const getCreateDispatchLabelSignedDelivery = state =>
  fromCreateDispatchLabel.getSignedDelivery(state);
export const getCreateDispatchLabelNeedsSignedDelivery = state =>
  fromCreateDispatchLabel.getNeedsSignedDelivery(state);
export const getCreateDispatchLabelError = state =>
  fromCreateDispatchLabel.getError(state.createDispatchLabel);
export const getCreateDispatchLabelExemptionCodes = state =>
  fromCreateDispatchLabel.getExemptionCodes(state.createDispatchLabel);

// GP profile
export const getGPProfileIsLoading = state =>
  fromGPProfile.getIsLoading(state.profile);
export const getGPProfileDialogIsOpen = state =>
  fromGPProfile.getGPProfileDialogIsOpen(state.profile);
export const getGPNameEditable = state =>
  fromGPProfile.getGPNameEditable(state.profile);
export const getGPFieldValue = (field, state) =>
  fromGPProfile.getFieldValue(field, state.profile);
export const getGPFieldIsValid = (field, state) =>
  fromGPProfile.getFieldIsValid(field, state.profile);
export const getGPId = state => fromGPProfile.getId(state.profile);
export const getGPLatitude = state => fromGPProfile.getLatitude(state.profile);
export const getGPLongitude = state =>
  fromGPProfile.getLongitude(state.profile);
export const getGPCanSave = state => fromGPProfile.getCanSave(state.profile);
export const getGPAPISetObject = state =>
  fromGPProfile.getAPISetObject(state.profile);
export const getGPError = state => fromGPProfile.getError(state.profile);
export const getGPLastUpdated = state =>
  fromGPProfile.getLastUpdated(state.profile);

// NHS GP Refresh
export const getCanGPRefresh = state =>
  fromNHSRefresh.getOfferGPRefresh(state.nhsRefresh);
export const getRefreshedGP = state =>
  fromNHSRefresh.getRefreshedGP(state.nhsRefresh);
export const getRefreshedFlatGP = state =>
  fromNHSRefresh.getFlatGP(state.nhsRefresh);
export const getRefreshGPIsOpen = state =>
  fromNHSRefresh.getIsOpen(state.nhsRefresh);
export const getRefreshGPCode = state =>
  fromNHSRefresh.getODSCode(state.nhsRefresh);
export const getRefeshUnverifiedFields = state =>
  fromNHSRefresh.getUnverifiedFields(state.nhsRefresh);
export const getGPIsRefreshed = state =>
  fromNHSRefresh.getGPIsRefreshed(state.nhsRefresh);

// NHS GP Create
export const getNHSGPCreateAlreadyExists = state =>
  fromNHSGPCreate.getGPAlreadyExists(state.nhsGPCreate);
export const getNHSGPCreateODSCode = state =>
  fromNHSGPCreate.getODSCode(state.nhsGPCreate);
export const getNHSGPChecked = state =>
  fromNHSGPCreate.getGPChecked(state.nhsGPCreate);
export const getNHSGPCreateUnknownODS = state =>
  fromNHSGPCreate.getUnknownNHSODS(state.nhsGPCreate);
export const getNHSGPCreateNotGP = state =>
  fromNHSGPCreate.getNotGP(state.nhsGPCreate);
export const getNHSGPPreview = state =>
  fromNHSGPCreate.getGPPreview(state.nhsGPCreate);

// GP contact review
export const getContactReviewGPs = state =>
  fromContactReview.getGPs(state.gpContactReview);
export const getContactReviewGPCursor = state =>
  fromContactReview.getGPCursor(state.gpContactReview);
export const getContactReviewIsPageLoaded = state =>
  fromContactReview.getIsPageLoaded(state.gpContactReview);
export const getContactReviewIsLoadingGPs = state =>
  fromContactReview.getIsLoadingGPs(state.gpContactReview);

// Contact Details Dialog
export const getContactDialogIsOpen = state =>
  fromContactDialog.getDialogIsOpen(state.contactDialog);
export const getContactDialogSelectedMethod = state =>
  fromContactDialog.getContactDialogSelectedMethod(state.contactDialog);

// Events Dialog
export const getEventsDialogIsOpen = state =>
  fromEventsDialog.getIsOpen(state.eventsDialog);
export const getEventsDialogIsLoading = state =>
  fromEventsDialog.getIsLoading(state.eventsDialog);
export const getEventsDialogEvents = state =>
  fromEventsDialog.getEvents(state.eventsDialog);

// User Profile
export const getUserProfileGPSuggestions = state =>
  fromUserProfile.getGPSuggestions(state.userProfile);
export const getUserProfileGPSearchText = state =>
  fromUserProfile.getGPSearchText(state.userProfile);
export const getUserProfileSelectedGP = state =>
  fromUserProfile.getSelectedGP(state.userProfile);
export const getUserProfileIsSaving = state =>
  fromUserProfile.getIsSaving(state.userProfile);
export const getUserProfileIsLoadingGPs = state =>
  fromUserProfile.getIsLoadingGPs(state.userProfile);
export const getUserProfileUserId = state =>
  fromUserProfile.getUserId(state.userProfile);
export const getUserProfileUserFirstName = state =>
  fromUserProfile.getUserFirstName(state.userProfile);
export const getUserProfileUserLastName = state =>
  fromUserProfile.getUserLastName(state.userProfile);
export const getUserProfileDOB = state =>
  fromUserProfile.getUserDOB(state.userProfile);
export const getUserProfileDOBDay = state =>
  fromUserProfile.getUserDOBDay(state.userProfile);
export const getUserProfileDOBMonth = state =>
  fromUserProfile.getUserDOBMonth(state.userProfile);
export const getUserProfileDOBYear = state =>
  fromUserProfile.getUserDOBYear(state.userProfile);
export const getUserProfileAddressLine1 = state =>
  fromUserProfile.getAddressLine1(state.userProfile);
export const getUserProfileAddressLine2 = state =>
  fromUserProfile.getAddressLine2(state.userProfile);
export const getUserProfileAddressCity = state =>
  fromUserProfile.getAddressCity(state.userProfile);
export const getUserProfileAddressPostcode = state =>
  fromUserProfile.getAddressPostcode(state.userProfile);
export const getUserProfileIsFetchingProfile = state =>
  fromUserProfile.getIsFetchingProfile(state.userProfile);
export const getUserProfileNotes = state =>
  fromUserProfile.getUserNotes(state.userProfile);
export const getUserProfileTab = state =>
  fromUserProfile.getSelectedTab(state.userProfile);
export const getUserProfileUserToCreateOrder = state =>
  fromUserProfile.getUserToCreateOrder(state.userProfile);
export const getUserProfilePhone = state =>
  fromUserProfile.getUserPhone(state.userProfile);
export const getUserProfileEmail = state =>
  fromUserProfile.getUserEmail(state.userProfile);
export const getUserProfileShowNominationButton = state =>
  fromUserProfile.getShowNominationButton(state.userProfile);
export const getUserProfileIsNominating = state =>
  fromUserProfile.getIsNominating(state.userProfile);
export const getUserProfileExemptions = state =>
  fromUserProfile.getUserExemptions(state.userProfile);

// Search
export const getSearchSuggestions = state =>
  fromSearch.getSuggestions(state.search);
export const getSearchText = state => fromSearch.getSearchText(state.search);

// Login
export const getLoginEmail = state => fromLogin.getEmail(state.login);
export const getLoginIsEmailValid = state =>
  fromLogin.getIsEmailValid(state.login);
export const getLoginIsSendingEmail = state =>
  fromLogin.getIsSendingEmail(state.login);
export const getLoginIsWaitingCompletion = state =>
  fromLogin.getIsWaitingCompletion(state.login);
export const getLoginIsCompleting = state =>
  fromLogin.getIsCompleting(state.login);
export const getLoginIsShowingValidation = state =>
  fromLogin.getIsShowingValidation(state.login);
export const getLoginError = state => fromLogin.getError(state.login);
export const getLoginBypassEnabled = state =>
  fromLogin.getIsBypassEnabled(state.login);

// Routing
export const getCurrentUrl = state => state.router.location.pathname;

// Navigation
export const getNavigationStatusCounts = state =>
  fromNav.getStatusCounts(state.navigation);
export const getNavigationNeedsRefresh = state =>
  fromNav.getNeedsRefresh(state.navigation);
export const getNavigationNeedsGPRefresh = state =>
  fromNav.getNeedsGPRefresh(state.navigation);
export const getNavigationRobotCounts = state =>
  fromNav.getRobotCounts(state.navigation);

// Version
export const getVersionNumber = state => state.versionNumber;

// Pending Users
export const getPendingUsers = state =>
  fromPendingUsers.getUsers(state.pendingUsers);
export const getPendingUsersIsFetchingFirstPage = state =>
  fromPendingUsers.getIsFetchingFirstPage(state.pendingUsers);
export const getPendingUsersIsFetchingNextPage = state =>
  fromPendingUsers.getIsFetchingNextPage(state.pendingUsers);
export const getPendingUsersError = state =>
  fromPendingUsers.getError(state.pendingUsers);
export const getPendingUsersAllPagesFetched = state =>
  fromPendingUsers.getAllPagesFetched(state.pendingUsers);
export const getPendingUsersNextPageNumber = state =>
  fromPendingUsers.getNextPageNumber(state.pendingUsers);
export const getPendingUsersNeedsRefresh = state =>
  fromPendingUsers.getNeedsRefresh(state.pendingUsers);
export const getPendingUsersSelectedUserId = state =>
  fromPendingUsers.getSelectedUserId(state.pendingUsers);
export const getPendingUsersSelectedTabIndex = state =>
  fromPendingUsers.getSelectedTabIndex(state.pendingUsers);

// Match Users
export const getUserMatchUnmatchedUsers = state =>
  fromMatchUsers.getAllUnmatchedUsers(state.matchUsers);
export const getUserMatchSelectedUser = state =>
  fromMatchUsers.getSelectedUser(state.matchUsers);
export const getUserMatchSelectedMatchedUser = state =>
  fromMatchUsers.getSelectedMatchedUser(state.matchUsers);
export const getUserMatchSuggestions = state =>
  fromMatchUsers.getUserSuggestions(state.matchUsers);
export const getUserMatchSearchText = state =>
  fromMatchUsers.getUserSearchText(state.matchUsers);
export const getUserMatchNeedsUpdate = state =>
  fromMatchUsers.getNeedsUpdate(state.matchUsers);
export const getUserMatchIsLoading = state =>
  fromMatchUsers.getFetching(state.matchUsers);
export const getUserMatchIsLoadingAdditional = state =>
  fromMatchUsers.getFetchingAdditional(state.matchUsers);
export const getUserMatchIsMatching = state =>
  fromMatchUsers.getMatching(state.matchUsers);
export const getUserMatchAllPagesFetched = state =>
  fromMatchUsers.getAllPagesFetched(state.matchUsers);
export const getUserMatchNextToken = state =>
  fromMatchUsers.getNextToken(state.matchUsers);

// Match Scripts
export const getScriptMatchSelectedUser = state =>
  fromMatchScripts.getSelectedUser(state.matchScripts);
export const getScriptMatchSelectedScript = state =>
  fromMatchScripts.getSelectedScript(state.matchScripts);
export const getScriptMatchUnmatchedScripts = (state, userId) =>
  fromMatchScripts.getUnmatchedScripts(state.matchScripts, userId);
export const getScriptMatchUnmatchedUsers = state =>
  fromMatchScripts.getUnmatchedUsers(state.matchScripts);
export const getScriptMatchRequests = (state, pharmacyUserId) =>
  fromMatchScripts.getUserRequests(state.matchScripts, pharmacyUserId);
export const getScriptMatchNeedsUpdate = state =>
  fromMatchScripts.getNeedsUpdate(state.matchScripts);
export const getScriptMatchIsLoading = state =>
  fromMatchScripts.getFetching(state.matchScripts);
export const getScriptMatchIsMatching = state =>
  fromMatchScripts.getMatching(state.matchScripts);

// Return Prescription Dialog
export const getReturnPrescriptionDialogIsOpen = state =>
  fromReturnPrescriptionDialog.getIsOpen(state.returnPrescriptionDialog);
export const getReturnPrescriptionDialogOrder = state =>
  fromReturnPrescriptionDialog.getOrder(state.returnPrescriptionDialog);
export const getReturnPrescriptionDialogShowError = state =>
  fromReturnPrescriptionDialog.getShowError(state.returnPrescriptionDialog);
export const getReturnPrescriptionDialogInputText = state =>
  fromReturnPrescriptionDialog.getInputText(state.returnPrescriptionDialog);
export const getReturnPrescriptionDialogErrorMessage = state =>
  fromReturnPrescriptionDialog.getErrorMessage(state.returnPrescriptionDialog);
export const getReturnPrescriptionDialogIsLoading = state =>
  fromReturnPrescriptionDialog.getIsLoading(state.returnPrescriptionDialog);

// Consolidate Orders Dialog
export const getConsolidateOrdersIsOpen = state =>
  fromConsolidateOrdersDialog.getIsOpen(state.consolidateOrdersDialog);
export const getConsolidateOrders = state =>
  fromConsolidateOrdersDialog.getConsolidatedOrders(
    state.consolidateOrdersDialog
  );
export const getConsolidateOrdersIsLoading = state =>
  fromConsolidateOrdersDialog.getIsLoading(state.consolidateOrdersDialog);

// Scanner Wrapper
export const getBasketId = state =>
  fromScannerWrapper.getBasketId(state.scannerWrapper);
export const getBasketWeight = state =>
  fromScannerWrapper.getWeight(state.scannerWrapper);
export const getBasketData = state =>
  fromScannerWrapper.getBasketData(state.scannerWrapper);
export const getBasketIsLoading = state =>
  fromScannerWrapper.getIsLoading(state.scannerWrapper);
export const getBasketDetailsIsOpen = state =>
  fromScannerWrapper.getIsOpen(state.scannerWrapper);
export const getBasketAdminDialogIsOpen = state =>
  fromScannerWrapper.getAdminDialogIsOpen(state.scannerWrapper);
export const getBasketEditWeightDialogIsOpen = state =>
  fromScannerWrapper.getEditWeightDialogIsOpen(state.scannerWrapper);
export const getBasketOrderListTapDialogIsOpen = state =>
  fromScannerWrapper.getOrderListTapDialogIsOpen(state.scannerWrapper);
export const getBasketTransferDialogIsOpen = state =>
  fromScannerWrapper.getTransferDialogIsOpen(state.scannerWrapper);
export const getBasketStatus = state =>
  fromScannerWrapper.getStatus(state.scannerWrapper);
export const getBasketRefreshBasketData = state =>
  fromScannerWrapper.getRefreshBasketData(state.scannerWrapper);
export const getBasketScanFromRemoveBasket = state =>
  fromScannerWrapper.getScanFromRemoveBasket(state.scannerWrapper);

// Update Shipping Class Dialog
export const getUpdateShippingClassIsOpen = state =>
  fromShippingClassDialog.getIsOpen(state.shippingClassDialog);
export const getUpdateShippingClassSelectedServiceIndex = state =>
  fromShippingClassDialog.getSelectedServiceIndex(state.shippingClassDialog);
export const getUpdateShippingClassSelectedService = state =>
  fromShippingClassDialog.getSelectedService(state.shippingClassDialog);
export const getUpdateShippingClassshippingSigned = state =>
  fromShippingClassDialog.getshippingSigned(state.shippingClassDialog);
export const getUpdateShippingClassOrderId = state =>
  fromShippingClassDialog.getOrderId(state.shippingClassDialog);
export const getUpdateShippingClassIsUpdating = state =>
  fromShippingClassDialog.getIsUpdating(state.shippingClassDialog);
export const getUpdateShippingClassShowError = state =>
  fromShippingClassDialog.getShowError(state.shippingClassDialog);
export const getUpdateShippingClassErrorMessage = state =>
  fromShippingClassDialog.getErrorMessage(state.shippingClassDialog);

// Mark Order MCS Dialog
export const getMarkOrderMcsDialogIsOpen = state =>
  fromMarkOrderMcsDialog.getIsOpen(state.markOrderMcsDialog);
export const getMarkOrderMcsDialogMedicines = state =>
  fromMarkOrderMcsDialog.getMedicines(state.markOrderMcsDialog);
export const getMarkOrderMcsDialogSelectedMedicines = state =>
  fromMarkOrderMcsDialog.getSelectedMedicines(state.markOrderMcsDialog);
export const getMarkOrderMcsDialogCanSubmit = state =>
  fromMarkOrderMcsDialog.getCanSubmit(state.markOrderMcsDialog);
export const getMarkOrderMcsDialogOrderId = state =>
  fromMarkOrderMcsDialog.getOrderId(state.markOrderMcsDialog);
export const getMarkOrderMcsDialogBasketId = state =>
  fromMarkOrderMcsDialog.getBasketId(state.markOrderMcsDialog);
export const getMarkOrderMcsDialogIsUpdating = state =>
  fromMarkOrderMcsDialog.getIsUpdating(state.markOrderMcsDialog);

// Mark Order CustomerService Dialog
export const getMarkOrderCSDialogIsOpen = state =>
  fromMarkOrderCSDialog.getIsOpen(state.markOrderCSDialog);
export const getMarkOrderCSDialogMedicines = state =>
  fromMarkOrderCSDialog.getMedicines(state.markOrderCSDialog);
export const getMarkOrderCSDialogSelectedMedicines = state =>
  fromMarkOrderCSDialog.getSelectedMedicines(state.markOrderCSDialog);
export const getMarkOrderCSDialogCanSubmit = state =>
  fromMarkOrderCSDialog.getCanSubmit(state.markOrderCSDialog);
export const getMarkOrderCSDialogOrderId = state =>
  fromMarkOrderCSDialog.getOrderId(state.markOrderCSDialog);
export const getMarkOrderCSDialogIsUpdating = state =>
  fromMarkOrderCSDialog.getIsUpdating(state.markOrderCSDialog);

// Manual line start
export const getManualLineStartOrders = state =>
  fromManualLineStart.getOrders(state.manualLineStart);
export const getManualLineStartIsLoadingNext = state =>
  fromManualLineStart.getIsLoadingNext(state.manualLineStart);
export const getManualLineStartBarcode = state =>
  fromManualLineStart.getBarcode(state.manualLineStart);
export const getManualLineStartUser = state =>
  fromManualLineStart.getUser(state.manualLineStart);
export const getManualLineStartSelectedOrderIds = state =>
  fromManualLineStart.getSelectedOrderIds(state.manualLineStart);

// MCS medicine list
export const getMCSMedicineListAddingMCS = state =>
  fromMCSMedicineList.getAddingMCS(state.mcsMedicineList);
export const getMCSMedicineListRemovingMCS = state =>
  fromMCSMedicineList.getRemovingMCS(state.mcsMedicineList);
export const getMCSMedicineListAmpIds = state =>
  fromMCSMedicineList.getMCSAmpIds(state.mcsMedicineList);
export const getMCSMedicineListMedsByAmp = state =>
  fromMCSMedicineList.getMedsByAmp(state.mcsMedicineList);
export const getMCSMedicineListSearchingDMD = state =>
  fromMCSMedicineList.getSearchingDMD(state.mcsMedicineList);
export const getMCSMedicineListDMDAmpResult = state =>
  fromMCSMedicineList.getDMDAmpResult(state.mcsMedicineList);
export const getMCSMedicineListReload = state =>
  fromMCSMedicineList.getReload(state.mcsMedicineList);
export const getMCSMedicineListGettingMCS = state =>
  fromMCSMedicineList.getGettingMCS(state.mcsMedicineList);

// Click & Collect
export const getClickAndCollectSelectedTab = state =>
  fromClickAndCollect.getSelectedTab(state.clickAndCollect);

// Whistl
export const getWhistlEnabled = state => fromWhistl.getEnabled(state.whistl);

// Permissions
export const getPermissions = state => {
  const perms = [];
  if (getCanGPRefresh(state)) {
    perms.push(permGPRefresh);
  }
  return perms;
};

export default history =>
  combineReducers({
    auth: persistReducer(
      {
        key: "auth",
        storage,
        blacklist: ["email", "error"]
      },
      auth
    ),
    router: connectRouter(history),
    login,
    contextMenu,
    scannerWrapper,
    navigation,
    orders,
    evidenceDialog,
    requests,
    confirmDialog,
    DateFilterDialog,
    profile,
    nhsRefresh,
    contactDialog,
    notification,
    createOrder,
    deliveryAddress,
    createDispatchLabel,
    eventsDialog,
    userProfile,
    search,
    pendingUsers,
    matchUsers,
    matchScripts,
    medsSearch,
    returnPrescriptionDialog,
    consolidateOrdersDialog,
    shippingClassDialog,
    markOrderMcsDialog,
    markOrderCSDialog,
    manualLineStart,
    mcsMedicineList,
    clickAndCollect,
    whistl,
    nhsGPCreate,
    gpContactReview,
    versionNumber: () => process.env.REACT_APP_VERSION || "0"
  });
