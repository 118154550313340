import { RSAA } from "redux-api-middleware";
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import { fetchSeqCount } from "redux/orders";
import { getOrdersSelectedTabIndex } from "redux/root-reducer";
import { NEXT_ACTION } from "redux/middlewares/next";
import { GET_USER_PROFILE_RECEIVE } from "redux/user-profile";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

// ACTIONS
export const HIDE_CONSOLIDATE_ORDERS_DIALOG = "CONSOLIDATE_ORDERS/HIDE_DIALOG";
export const LINK_BASKET_REQUEST = "LINK_BASKET/REQUEST";
export const LINK_BASKET_RECEIVE = "LINK_BASKET/RECEIVE";
export const LINK_BASKET_FAILURE = "LINK_BASKET/FAILURE";
export const FETCH_CONSOLIDATE_ORDERS_REQUEST =
  "FETCH_CONSOLIDATE_ORDERS/REQUEST";
export const FETCH_CONSOLIDATE_ORDERS_RECEIVE =
  "FETCH_CONSOLIDATE_ORDERS/RECEIVE";
export const FETCH_CONSOLIDATE_ORDERS_FAILURE =
  "FETCH_CONSOLIDATE_ORDERS/FAILURE";

// ACTION CREATORS
export const hideConsolidateOrdersDialog = () => ({
  type: HIDE_CONSOLIDATE_ORDERS_DIALOG
});

export const linkBasket = (
  orderIds,
  basketId,
  endpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${endpoint}/basket/${basketId}/link`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ orderIds }),
    types: [
      LINK_BASKET_REQUEST,
      {
        type: LINK_BASKET_RECEIVE,
        payload: (action, state, res) =>
          res.json().then(() => ({
            [NEXT_ACTION]: fetchSeqCount(getOrdersSelectedTabIndex(state) - 1)
          }))
      },
      LINK_BASKET_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const fetchConsolidateOrders = (
  userId,
  qStatus,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/basket/customer/${userId}/orders?status=${qStatus?.join()}`,
    method: "GET",
    types: [
      FETCH_CONSOLIDATE_ORDERS_REQUEST,
      FETCH_CONSOLIDATE_ORDERS_RECEIVE,
      FETCH_CONSOLIDATE_ORDERS_FAILURE
    ],
    options: { addAuth: true }
  }
});

// SELECTORS
export const getIsOpen = state => state.isOpen;
export const getIsLoading = state => state.isLoading;
export const getConsolidatedOrders = state => state.consolidatedOrders;

// REDUCERS
const isOpen = handleActions(
  {
    [HIDE_CONSOLIDATE_ORDERS_DIALOG]: () => false,
    [FETCH_CONSOLIDATE_ORDERS_RECEIVE]: () => true,
    [LINK_BASKET_RECEIVE]: () => false,
    [GET_USER_PROFILE_RECEIVE]: () => false
  },
  false
);
const isLoading = handleActions(
  {
    [LINK_BASKET_REQUEST]: () => true,
    [LINK_BASKET_RECEIVE]: () => false,
    [LINK_BASKET_FAILURE]: () => false
  },
  false
);
const consolidatedOrders = handleActions(
  {
    [FETCH_CONSOLIDATE_ORDERS_RECEIVE]: (state, action) => action.payload,
    [FETCH_CONSOLIDATE_ORDERS_FAILURE]: () => [],
    [FETCH_CONSOLIDATE_ORDERS_REQUEST]: () => []
  },
  []
);

export default combineReducers({
  isOpen,
  isLoading,
  consolidatedOrders
});
