import { getField } from "./utils";

export const CONTACT_PREFERENCE_FAX = "fax";
export const CONTACT_PREFERENCE_EMAIL = "email";
export const CONTACT_PREFERENCE_NONE = "";

export const ADDRESS_FIELDS_GEO_VALIDATE = [
  "addressLine1",
  "addressLine2",
  "city",
  "postcode",
  "country"
];

export const getId = getField("id");
export const getName = getField("name");
export const getAddressLine1 = getField("addressLine1");
export const getAddressLine2 = getField("addressLine2");
export const getCity = getField("city");
export const getPostcode = getField("postcode");
export const getCountry = getField("country");
export const getLatitude = getField("lat");
export const getLongitude = getField("lng");
export const getTelephone = getField("additional.telephone");
export const getEmail = getField("additional.email");
export const getFax = getField("additional.fax");
export const getHasEPS = getField("additional.eps");
export const getIsAgainstDigitalPharmacy = getField(
  "additional.againstDigitalPharmacy"
);
export const getContactPreference = getField("additional.contactPreference");
export const getLastUpdated = getField("additional.lastUpdated");
export const getNotes = getField("additional.notes");
export const getFlag = getField("additional.flag");
export const getFlagApplied = getField("additional.flagApplied");
export const getSurgeryType = getField("additional.surgeryType");
export const getStatus = getField("additional.status");
export const getUpdateLock = getField("additional.updateLock");
export const getIM1Provider = getField("additional.im1Provider");

export const getFullAddress = gp =>
  [getAddressLine1(gp), getAddressLine2(gp), getPostcode(gp), getCity(gp)]
    .filter(a => a !== "")
    .join("\n");
