import { flatten } from "../../helpers/flatten";
import { UnverifiedFields } from "./types";

// SELECTORS
export const getOfferGPRefresh = state => state.offerGPRefresh;
export const getRefreshedGP = state => state.refreshedGP;
export const getIsOpen = state => state.isOpen;
export const getODSCode = state => state.odsCode;
export const getFlatGP = state => flatten(state.refreshedGP, undefined);
export const getUnverifiedFields = (state): UnverifiedFields | null =>
  state.unverifiedFields;
export const getGPIsRefreshed = (state): boolean => state.gpIsRefreshed;
