import React from "react";
import PT from "prop-types";
import StoreIcon from "@material-ui/icons/Store";

const StatusIcon = ({ status }) => {
  switch (status) {
    case "cc_accepted":
    case "cc_transferred":
    case "cc_dispensed":
    case "cc_delivered":
      return <StoreIcon style={{ color: "#0061F2" }} />;
    default:
      return null;
  }
};

StatusIcon.propTypes = {
  status: PT.string.isRequired
};

export default StatusIcon;
