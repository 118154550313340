import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import {
  fetchNavigationStatusCounts,
  fetchContactReviewGPCount,
  fetchRobotCounts
} from "../../redux/navigation";
import {
  getCurrentUrl,
  getVersionNumber,
  getNavigationStatusCounts,
  getNavigationNeedsRefresh,
  getNavigationNeedsGPRefresh,
  getPermissions
} from "../../redux/root-reducer";
import { fetchCanRefreshGP } from "../../redux/gp-nhs-refresh";
import RouteList from "./routes-list";
import s from "./navigation.module.scss";
import { ListItem, ListItemText } from "@material-ui/core";

export class Navigation extends Component {
  static propTypes = {
    style: PropTypes.object.isRequired,
    fetchStatusCounts: PropTypes.func.isRequired,
    fetchRobotCounts: PropTypes.func.isRequired,
    fetchContactReviewGPCount: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    routes: PropTypes.array.isRequired,
    changePage: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    statusCounts: PropTypes.object,
    needsUpdate: PropTypes.bool,
    needsGPUpdate: PropTypes.bool,
    perms: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    statusCounts: {},
    needsUpdate: false,
    needsGPUpdate: false,
    perms: []
  };

  componentDidMount() {
    this.props.fetchStatusCounts(false);
    this.props.fetchCanRefreshGP();
    this.props.fetchContactReviewGPCount();
    this.props.fetchRobotCounts();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.needsUpdate && nextProps.needsUpdate) {
      this.props.fetchStatusCounts(false);
    }
    if (!this.props.needsGPUpdate && nextProps.needsGPUpdate) {
      this.props.fetchContactReviewGPCount();
    }
  }
  render() {
    return (
      <Drawer
        className={s.root}
        variant={"permanent"}
        classes={{
          paper: s.innerDrawer
        }}
      >
        <RouteList {...this.props} />
        <ListItem component={"div"}>
          <ListItemText>v{this.props.version}</ListItemText>
        </ListItem>
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  changePage: push,
  fetchStatusCounts: fetchNavigationStatusCounts,
  fetchRobotCounts,
  fetchContactReviewGPCount,
  fetchCanRefreshGP
};

const mapStateToProps = state => ({
  style: s,
  currentUrl: getCurrentUrl(state),
  version: getVersionNumber(state),
  statusCounts: getNavigationStatusCounts(state),
  needsUpdate: getNavigationNeedsRefresh(state),
  needsGPUpdate: getNavigationNeedsGPRefresh(state),
  perms: getPermissions(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
