import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  withStyles
} from "@material-ui/core";
import {
  getConfirmDialogIsVisible,
  getInputMessage,
  getConfirmDialogTitle,
  getConfirmDialogMessage,
  getConfirmDialogPositiveActionTitle,
  getConfirmDialogCancelActionCreator,
  getConfirmDialogAgreeActionCreator,
  getConfirmDialogHasInput,
  getConfirmDialogCanSubmit,
  getConfirmDialogInputPlaceholder,
  getConfirmDialogHasCheck,
  getCheckBoxValue,
  getConfirmDialogCheckboxLabel,
  getConfirmDialogHasRadioButton,
  getRadioButtonValue,
  getConfirmDialogRadioButtonOptions,
  getConfirmDialogMcsItems,
  getConfirmDialogRxToken,
  getConfirmDialogNonMcsItems,
  getSecondaryRadioButtonValue,
  getConfirmDialogShowRTSError,
  getConfirmDialogRTSErrorMessage,
  getConfirmDialogMedicinesItem,
  getConfirmDialogMedicinesItemSelected,
  getConfirmDialogHasMultiCheckbox
} from "../../redux/root-reducer";
import {
  hideConfirmDialog,
  setInputMessage,
  setCheckBoxValue,
  setRadioButtonValue,
  setSecondaryRadioButtonValue,
  addMedicineItems,
  removeMedicineItems
} from "../../redux/confirm-dialog";
import NeedsAttention from "components/svgs/needs-attention";
import s from "../common/common-styles.module.scss";
import {
  MCSActionResponse,
  MCSGPAltTitle,
  MCSRTSTitle
} from "redux/selectors/event";

const errorDialogContent = {
  root: {
    color: "red"
  }
};
const ErrorDialogContent = withStyles(errorDialogContent)(DialogContentText);

export const ConfirmDialog = ({
  open,
  onCancel,
  onAgree,
  title,
  message,
  positiveActionTitle,
  requiresInput,
  requiresRadioButton,
  radioButtonOptions,
  onTextChange,
  textValue,
  canSubmit,
  inputPlaceholder,
  requiresCheck,
  checkValue,
  selectedRadio,
  onCheckBoxChange,
  onRadioButtonChange,
  checkboxLabel,
  mcsItems,
  nonMcsItems,
  rxToken,
  selectedSecondaryRadio,
  onSecondaryRadioButtonChange,
  showRTSError,
  rtsErrorMessage,
  requiresMultiCheckbox,
  medicines,
  selectedMedicines,
  onMultiCheckboxChange
}) => {
  const [positiveTitle, setPositiveTitle] = useState("");
  useEffect(() => {
    if (
      (mcsItems?.length > 0 || nonMcsItems?.length > 0) &&
      title === MCSRTSTitle
    ) {
      if (selectedRadio === 1) {
        onTextChange(rxToken);
      } else if (selectedRadio === 2) {
        onTextChange("Waiting for RTS");
      }
    }
    if (
      mcsItems?.length > 0 &&
      (!nonMcsItems || nonMcsItems?.length <= 0) &&
      title === MCSGPAltTitle &&
      selectedRadio === 1
    ) {
      if (selectedSecondaryRadio === 1) {
        onTextChange(rxToken);
      } else if (selectedSecondaryRadio === 2) {
        onTextChange("Waiting for RTS");
      }
    }
    setPositiveTitle(getPositiveActionTitle());
  }, [selectedRadio, selectedSecondaryRadio, positiveActionTitle]);

  const getPositiveActionTitle = () => {
    if (
      (mcsItems?.length > 0 || nonMcsItems?.length > 0) &&
      title === MCSRTSTitle &&
      (selectedRadio === 1 || selectedRadio === null)
    )
      return "RETURN TO SPINE";
    if (
      (mcsItems?.length > 0 || nonMcsItems?.length > 0) &&
      title === MCSRTSTitle &&
      selectedRadio === 2
    )
      return "UPDATE MCS";
    if (
      mcsItems?.length > 0 &&
      nonMcsItems?.length > 0 &&
      title === MCSGPAltTitle &&
      (selectedRadio === 1 || selectedRadio === null)
    )
      return "MARK ND";
    if (
      (mcsItems?.length > 0 || nonMcsItems?.length > 0) &&
      title === MCSGPAltTitle &&
      selectedRadio === 2
    )
      return "UPDATE MCS";
    if (
      ((mcsItems?.length > 0 && (!nonMcsItems || nonMcsItems?.length <= 0)) ||
        (nonMcsItems?.length > 0 && (!mcsItems || mcsItems?.length <= 0))) &&
      title === MCSGPAltTitle &&
      (selectedRadio === 2 ||
        (selectedRadio === 1 && selectedSecondaryRadio === 2))
    )
      return "UPDATE MCS";
    if (
      mcsItems?.length > 0 &&
      (!nonMcsItems || nonMcsItems?.length <= 0) &&
      title === MCSGPAltTitle &&
      (selectedRadio === 1 || selectedRadio === null)
    )
      return "RETURN TO SPINE";
    if (
      (!mcsItems || mcsItems?.length <= 0) &&
      nonMcsItems?.length > 0 &&
      title === MCSGPAltTitle &&
      (selectedRadio === 1 || selectedRadio === null)
    )
      return "RETURN TO SPINE";
    return positiveActionTitle || "";
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth>
      <DialogTitle>{title || ""}</DialogTitle>
      {requiresCheck ? (
        <DialogContent>
          <FormControlLabel
            id={"t_requires_email_sent_form"}
            control={
              <Checkbox
                checked={checkValue || false}
                onChange={() => onCheckBoxChange(!checkValue)}
              />
            }
            label={checkboxLabel}
          />
        </DialogContent>
      ) : null}
      {nonMcsItems?.length > 0 && (
        <DialogContent>
          {nonMcsItems.map((item, i) => (
            <Fragment key={i}>
              <div style={{ marginBottom: "6px" }}>
                {item.brandName || item.drugName}
              </div>
              <div
                className={s.t_reason_option}
                style={{ marginBottom: "8px" }}
              >
                {item.brandDesc}
              </div>
            </Fragment>
          ))}
        </DialogContent>
      )}
      {mcsItems?.length > 0 && (
        <DialogContent>
          <DialogContentText>
            <span id={"t_warn"} className={s.mcsItemWarnIcon}>
              <NeedsAttention lastModifiedDate={null} />{" "}
              <span>MCS item(s)</span>
            </span>
          </DialogContentText>
          {mcsItems.map((item, i) => (
            <Fragment key={i}>
              <div style={{ marginBottom: "6px" }}>
                {item.brandName || item.drugName}
              </div>
              <div
                className={s.t_reason_option}
                style={{ marginBottom: "8px" }}
              >
                {item.brandDesc}
              </div>
            </Fragment>
          ))}
        </DialogContent>
      )}
      <DialogContent>
        <DialogContentText>{message || ""}</DialogContentText>
        {requiresRadioButton ? (
          <FormControl
            component={"fieldset"}
            fullWidth
            style={{ height: "100%" }}
          >
            <RadioGroup
              id={"t_radio_group"}
              aria-label={"rejection reasons"}
              name={"radio_options"}
              value={selectedRadio}
              onChange={e => onRadioButtonChange(Number(e.target.value))}
            >
              {radioButtonOptions.map(options => (
                <FormControlLabel
                  id={`t_reasons_option_${options.code}`}
                  className={s.t_reason_option}
                  value={options.code}
                  control={<Radio color={"primary"} />}
                  label={options.copy}
                  key={options.code}
                />
              ))}
            </RadioGroup>
          </FormControl>
        ) : null}
        {requiresInput ? (
          <TextField
            id={"t_input"}
            style={{ marginTop: 10 }}
            fullWidth
            multiline
            value={textValue || ""}
            onChange={e => onTextChange(e.currentTarget.value)}
            placeholder={inputPlaceholder}
            rows={"6"}
          />
        ) : null}
      </DialogContent>
      {(mcsItems?.length > 0 || nonMcsItems?.length > 0) &&
        title === MCSRTSTitle && (
          <DialogContent>
            {selectedRadio === 1 && (
              <>
                {showRTSError && (
                  <ErrorDialogContent>
                    Something went wrong:
                    <br />
                    {rtsErrorMessage}
                  </ErrorDialogContent>
                )}
                <DialogContentText>
                  Return to spine an order will refund the user. This action
                  cannot be undone.
                </DialogContentText>
                <TextField
                  id={"t_input"}
                  fullWidth
                  disabled={Boolean(rxToken)}
                  defaultValue={rxToken || ""}
                  onChange={e => onTextChange(e.currentTarget.value)}
                  label={"Prescription code"}
                />
              </>
            )}
            {selectedRadio === 2 && (
              <>
                <DialogContentText>
                  Please resolve this MCS order after the prescription has been
                  returned to spine
                </DialogContentText>
                <TextField
                  id={"t_input"}
                  style={{ marginTop: 10 }}
                  fullWidth
                  multiline
                  inputProps={{ style: { color: "#747474" } }}
                  defaultValue={"Waiting for RTS"}
                  onChange={e => onTextChange(e.currentTarget.value)}
                  label={"Additional notes"}
                  rows={"2"}
                />
              </>
            )}
          </DialogContent>
        )}
      {title === MCSGPAltTitle && (
        <DialogContent>
          {nonMcsItems?.length > 0 && mcsItems?.length > 0 && (
            <>
              {selectedRadio === 1 && (
                <>
                  <DialogContentText>
                    Please arrange for this item to be refunded if necessary.
                    This order must be manually claimed in Analyst.
                  </DialogContentText>
                  <TextField
                    id={"t_input"}
                    style={{ marginTop: 10 }}
                    fullWidth
                    multiline
                    inputProps={{ style: { color: "#747474" } }}
                    value={textValue || ""}
                    onChange={e => onTextChange(e.currentTarget.value)}
                    label={"Additional notes (Optional)s"}
                    rows={"2"}
                  />
                </>
              )}
            </>
          )}
          {((mcsItems.length > 0 &&
            (!nonMcsItems || nonMcsItems?.length <= 0)) ||
            (nonMcsItems?.length > 0 && (!mcsItems || mcsItems.length <= 0))) &&
            selectedRadio === 1 && (
              <>
                <DialogContentText>
                  Has the prescription been returned to spine?
                </DialogContentText>
                <FormControl
                  component={"fieldset"}
                  fullWidth
                  style={{ height: "100%" }}
                >
                  <RadioGroup
                    id={"t_secondary_radio_group"}
                    aria-label={"rejection reasons"}
                    name={"secondary_radio_options"}
                    value={selectedSecondaryRadio}
                    onChange={e =>
                      onSecondaryRadioButtonChange(Number(e.target.value))
                    }
                  >
                    {MCSActionResponse.map(options => (
                      <FormControlLabel
                        id={`t_secondary_reasons_option_${options.code}`}
                        className={s.t_reason_option}
                        value={options.code}
                        control={<Radio color={"primary"} />}
                        label={options.copy}
                        key={options.code}
                      />
                    ))}
                  </RadioGroup>
                  {selectedSecondaryRadio === 1 && (
                    <>
                      <DialogContentText>
                        Return to spine an order will refund the user. This
                        action cannot be undone.
                      </DialogContentText>
                      <TextField
                        id={"t_input"}
                        fullWidth
                        disabled={Boolean(rxToken)}
                        defaultValue={rxToken || ""}
                        onChange={e => onTextChange(e.currentTarget.value)}
                        label={"Prescription code"}
                      />
                    </>
                  )}
                  {selectedSecondaryRadio === 2 && (
                    <>
                      <DialogContentText>
                        Please resolve this MCS order after the prescription has
                        been returned to spine
                      </DialogContentText>
                      <TextField
                        id={"t_input"}
                        style={{ marginTop: 10 }}
                        fullWidth
                        multiline
                        inputProps={{ style: { color: "#747474" } }}
                        defaultValue={"Waiting for RTS"}
                        onChange={e => onTextChange(e.currentTarget.value)}
                        label={"Additional notes"}
                        rows={"2"}
                      />
                    </>
                  )}
                </FormControl>
              </>
            )}
          {selectedRadio === 2 && (
            <>
              <DialogContentText>
                Please contact the patient to discuss.
              </DialogContentText>
              <TextField
                id={"t_input"}
                style={{ marginTop: 10 }}
                fullWidth
                multiline
                inputProps={{ style: { color: "#747474" } }}
                value={textValue || ""}
                onChange={e => onTextChange(e.currentTarget.value)}
                label={"Additional notes"}
                rows={"2"}
              />
            </>
          )}
        </DialogContent>
      )}
      {requiresMultiCheckbox && (
        <DialogContent>
          {/* <DialogContentText>Select the medicines that are MCS</DialogContentText> */}
          {medicines.map((medicine, i) => (
            <div key={i}>
              <FormControlLabel
                id={`t_mcs_medicine_${i}`}
                control={
                  <Checkbox
                    className={`t_medicine_checkbox_${i}`}
                    checked={selectedMedicines.includes(i)}
                    onChange={() => onMultiCheckboxChange(i, selectedMedicines)}
                  />
                }
                label={`${medicine.brandDesc || medicine.prodDesc}`}
              />
            </div>
          ))}
        </DialogContent>
      )}
      <DialogActions>
        <Button id={"t_cancel"} onClick={onCancel} color={"secondary"}>
          Cancel
        </Button>
        <Button
          id={"t_agree"}
          onClick={onAgree}
          color={"primary"}
          autoFocus
          disabled={
            !canSubmit ||
            (title === MCSGPAltTitle &&
            ((mcsItems.length > 0 &&
              (!nonMcsItems || nonMcsItems?.length <= 0)) ||
              (nonMcsItems?.length > 0 &&
                (!mcsItems || mcsItems.length <= 0))) &&
            selectedRadio === 1
              ? !selectedSecondaryRadio
              : false)
          }
        >
          {positiveTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  title: PropTypes.string,
  requiresInput: PropTypes.bool,
  requiresRadioButton: PropTypes.bool,
  radioButtonOptions: PropTypes.array,
  canSubmit: PropTypes.bool,
  onTextChange: PropTypes.func,
  message: PropTypes.string,
  textValue: PropTypes.string,
  positiveActionTitle: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  requiresCheck: PropTypes.bool,
  checkValue: PropTypes.bool,
  selectedRadio: PropTypes.number,
  onCheckBoxChange: PropTypes.func,
  onRadioButtonChange: PropTypes.func,
  checkboxLabel: PropTypes.string,
  mcsItems: PropTypes.array,
  nonMcsItems: PropTypes.array,
  rxToken: PropTypes.string,
  selectedSecondaryRadio: PropTypes.number,
  onSecondaryRadioButtonChange: PropTypes.func,
  requiresMultiCheckbox: PropTypes.bool,
  medicines: PropTypes.array,
  selectedMedicines: PropTypes.array,
  onMultiCheckboxChange: PropTypes.func
};

ConfirmDialog.defaultProps = {
  title: null,
  requiresInput: false,
  requiresRadioButton: false,
  radioButtonOptions: [],
  canSubmit: true,
  message: null,
  textValue: "",
  onTextChange: () => {},
  positiveActionTitle: null,
  inputPlaceholder: "",
  requiresCheck: false,
  checkValue: false,
  selectedRadio: null,
  onCheckBoxChange: () => {},
  onRadioButtonChange: () => {},
  checkboxLabel: null,
  mcsItems: [],
  nonMcsItems: [],
  rxToken: "",
  selectedSecondaryRadio: null,
  onSecondaryRadioButtonChange: () => {},
  requiresMultiCheckbox: false,
  medicines: [],
  selectedMedicines: [],
  onMultiCheckboxChange: () => {}
};

export const mapDispatchToProps = dispatch => ({
  dispatchAndHide: action => {
    dispatch(hideConfirmDialog());
    if (action) dispatch(action);
  },
  onTextChange: text => dispatch(setInputMessage(text)),
  onCheckBoxChange: value => dispatch(setCheckBoxValue(value)),
  onRadioButtonChange: value => dispatch(setRadioButtonValue(value)),
  onSecondaryRadioButtonChange: value =>
    dispatch(setSecondaryRadioButtonValue(value)),
  onCheckboxSelect: (medicine, selectedMedicines) =>
    dispatch(addMedicineItems(medicine, selectedMedicines)),
  onCheckboxDeselect: (medicine, selectedMedicines) =>
    dispatch(removeMedicineItems(medicine, selectedMedicines))
});

export const mapStateToProps = state => ({
  open: getConfirmDialogIsVisible(state),
  textValue: getInputMessage(state),
  requiresInput: getConfirmDialogHasInput(state),
  requiresRadioButton: getConfirmDialogHasRadioButton(state),
  radioButtonOptions: getConfirmDialogRadioButtonOptions(state),
  canSubmit: getConfirmDialogCanSubmit(state),
  title: getConfirmDialogTitle(state),
  message: getConfirmDialogMessage(state),
  positiveActionTitle: getConfirmDialogPositiveActionTitle(state),
  cancelActionCreator: getConfirmDialogCancelActionCreator(state),
  agreeActionCreator: getConfirmDialogAgreeActionCreator(state),
  inputPlaceholder: getConfirmDialogInputPlaceholder(state),
  requiresCheck: getConfirmDialogHasCheck(state),
  checkValue: getCheckBoxValue(state),
  selectedRadio: getRadioButtonValue(state),
  checkboxLabel: getConfirmDialogCheckboxLabel(state),
  mcsItems: getConfirmDialogMcsItems(state),
  nonMcsItems: getConfirmDialogNonMcsItems(state),
  rxToken: getConfirmDialogRxToken(state),
  selectedSecondaryRadio: getSecondaryRadioButtonValue(state),
  showRTSError: getConfirmDialogShowRTSError(state),
  rtsErrorMessage: getConfirmDialogRTSErrorMessage(state),
  medicines: getConfirmDialogMedicinesItem(state),
  selectedMedicines: getConfirmDialogMedicinesItemSelected(state),
  requiresMultiCheckbox: getConfirmDialogHasMultiCheckbox(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onTextChange: dispatchProps.onTextChange,
  onCheckBoxChange: dispatchProps.onCheckBoxChange,
  onRadioButtonChange: dispatchProps.onRadioButtonChange,
  onSecondaryRadioButtonChange: dispatchProps.onSecondaryRadioButtonChange,
  onCancel: () =>
    dispatchProps.dispatchAndHide(
      stateProps.cancelActionCreator && stateProps.cancelActionCreator()
    ),
  onAgree: () => {
    if (
      stateProps.requiresInput ||
      stateProps.requiresRadioButton ||
      stateProps.requiresMultiCheckbox
    ) {
      dispatchProps.dispatchAndHide(
        stateProps.agreeActionCreator(
          stateProps.textValue,
          stateProps.requiresCheck ? stateProps.checkValue : true,
          stateProps.requiresRadioButton ? stateProps.selectedRadio : null,
          stateProps.selectedSecondaryRadio,
          stateProps.selectedMedicines
        )
      );
    } else {
      dispatchProps.dispatchAndHide(stateProps.agreeActionCreator());
    }
  },
  onMultiCheckboxChange: (medicine, selectedMedicines) => {
    const isChecked = selectedMedicines.includes(medicine);
    if (isChecked) {
      dispatchProps.onCheckboxDeselect(medicine, selectedMedicines);
    } else {
      dispatchProps.onCheckboxSelect(medicine, selectedMedicines);
    }
  },
  ...stateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConfirmDialog);
