import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import s from "./common-styles.module.scss";
import Grid from "@material-ui/core/Grid";

const GPDetailsCell = ({
  name,
  address,
  phone,
  organisationCode = "",
  onClick
}) => (
  <div className={s.detailsCell}>
    <p className={s.primary}>{name}</p>
    {address.split("\n").map((row, i) => (
      <p key={row + i}>{row}</p>
    ))}
    <p>{phone}</p>

    <Grid justify="space-between" alignItems="center" container>
      <Grid item>
        <Button
          id="t_view_button"
          variant="text"
          color="secondary"
          className={s.viewButton}
          onClick={event => {
            if (event) {
              event.preventDefault();
              event.stopPropagation();
            }
            onClick(organisationCode);
          }}
        >
          View
        </Button>
      </Grid>
    </Grid>
  </div>
);
GPDetailsCell.propTypes = {
  organisationCode: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  onClick: PropTypes.func
};
GPDetailsCell.defaultProps = {
  organisationCode: "",
  name: "",
  address: "",
  phone: "",
  onClick: () => {}
};

export default GPDetailsCell;
