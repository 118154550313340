import React from "react";
import PropTypes from "prop-types";
import CheckCircle from "@material-ui/icons/CheckCircle";
import moment from "moment";
import WarnIcon from "../../components/svgs/needs-attention";
import {
  formatDateTimeString,
  getUKDateTime,
  getUKWorkingDaysSince
} from "../../helpers/date-helpers";
import s from "./common-styles.module.scss";

const haveDaysExpired = (expiry, lastModifiedDate, currentDateString) => {
  const diff = getUKWorkingDaysSince(lastModifiedDate, currentDateString);
  return diff > expiry;
};

const haveHoursExpired = (expiry, lastModifiedDate, currentDateString) => {
  const currentMoment = currentDateString
    ? moment(getUKDateTime(currentDateString))
    : moment(getUKDateTime());

  const lastModdedMoment = moment(getUKDateTime(lastModifiedDate));
  const expiration = lastModdedMoment.add(expiry, "hours");
  return !currentMoment.isBefore(expiration);
};

const shouldFlagWithWarning = (
  warningSettings = null,
  lastModifiedDate,
  currentDateString
) => {
  if (warningSettings !== null) {
    if (
      (warningSettings.type === "WD" &&
        haveDaysExpired(
          warningSettings.threshold,
          lastModifiedDate,
          currentDateString
        )) ||
      (warningSettings.type === "HR" &&
        haveHoursExpired(
          warningSettings.threshold,
          lastModifiedDate,
          currentDateString
        ))
    ) {
      return true;
    }
  }
  return false;
};

const StatusCell = ({
  warningSettings,
  lastModifiedDate,
  currentDateString
}) => (
  <div className={s.statusCell}>
    {shouldFlagWithWarning(
      warningSettings,
      lastModifiedDate,
      currentDateString
    ) ? (
      <span id="t_warn" className={s.warn}>
        <WarnIcon lastModifiedDate={formatDateTimeString(lastModifiedDate)} />
      </span>
    ) : (
      <span id="t_accept">
        <CheckCircle />
      </span>
    )}
  </div>
);
StatusCell.propTypes = {
  currentDateString: PropTypes.string,
  warningSettings: PropTypes.object.isRequired,
  lastModifiedDate: PropTypes.string.isRequired
};
StatusCell.defaultProps = {
  currentDateString: null
};

export default StatusCell;
