import React from "react";

export default () => (
  <svg width="74px" height="32px">
    <defs />
    <g
      id="Login"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-570.000000, -331.000000)"
    >
      <g
        id="Logos-/-Well-/-Full-/-Dark"
        transform="translate(570.000000, 327.000000)"
      >
        <path
          d="M55.1382578,29.4262286 C55.5226679,28.9978188 56.079952,28.7284209 56.7,28.7284209 C57.859798,28.7284209 58.8,29.6709793 58.8,30.833684 C58.8,31.381566 58.5912357,31.8805662 58.2491034,32.2550994 C56.3258343,34.4234729 53.5220416,35.7894737 50.4,35.7894737 C44.6010101,35.7894737 39.9,31.0766816 39.9,25.2631579 C39.9,19.4496342 44.6010101,14.7368421 50.4,14.7368421 C56.1989899,14.7368421 60.9,19.4496342 60.9,25.2631579 C60.9,25.2818772 60.8999513,25.300585 60.8998539,25.3192813 L60.8992691,25.319248 C60.869611,26.4560253 59.9410899,27.3684211 58.8,27.3684211 L44.458482,27.3684211 C45.3233384,29.8214576 47.6569405,31.5789474 50.4,31.5789474 C52.285265,31.5789474 53.977118,30.7487772 55.1316644,29.4331935 C55.1337994,29.4307608 55.1359974,29.4284395 55.1382578,29.4262286 Z M44.458482,23.1578947 L56.341518,23.1578947 C55.4766616,20.7048582 53.1430595,18.9473684 50.4,18.9473684 C47.6569405,18.9473684 45.3233384,20.7048582 44.458482,23.1578947 Z"
          id="Combined-Shape"
          fill="#7FBA2A"
        />
        <rect
          id="Rectangle"
          fill="#15AA99"
          x="63"
          y="10.5263158"
          width="4.2"
          height="25.2631579"
          rx="2.1"
        />
        <rect
          id="Rectangle"
          fill="#15AA99"
          x="69.3"
          y="10.5263158"
          width="4.2"
          height="25.2631579"
          rx="2.1"
        />
        <path
          d="M11.866551,14.8706131 C12.9540291,14.4637951 14.1652117,15.0160705 14.5718032,16.1041547 L20.8663035,32.9489428 C21.2728949,34.037027 20.7209271,35.2488846 19.633449,35.6557026 C18.5459709,36.0625206 17.3347883,35.5102453 16.9281968,34.4221611 L10.6336965,17.577373 C10.2271051,16.4892888 10.7790729,15.2774311 11.866551,14.8706131 Z"
          id="Rectangle-13"
          fill="#0080A4"
        />
        <path
          d="M18.166551,14.8706131 C19.2540291,14.4637951 20.4652117,15.0160705 20.8718032,16.1041547 L27.1663035,32.9489428 C27.5728949,34.037027 27.0209271,35.2488846 25.933449,35.6557026 C24.8459709,36.0625206 23.6347883,35.5102453 23.2281968,34.4221611 L16.9336965,17.577373 C16.5271051,16.4892888 17.0790729,15.2774311 18.166551,14.8706131 Z"
          id="Rectangle-13"
          fill="#0080A4"
          transform="translate(22.050000, 25.263158) scale(-1, 1) translate(-22.050000, -25.263158) "
        />
        <path
          d="M24.466551,14.8706131 C25.5540291,14.4637951 26.7652117,15.0160705 27.1718032,16.1041547 L33.4663035,32.9489428 C33.8728949,34.037027 33.3209271,35.2488846 32.233449,35.6557026 C31.1459709,36.0625206 29.9347883,35.5102453 29.5281968,34.4221611 L23.2336965,17.577373 C22.8271051,16.4892888 23.3790729,15.2774311 24.466551,14.8706131 Z"
          id="Rectangle-13"
          fill="#00A9D5"
        />
        <path
          d="M30.766551,14.8706131 C31.8540291,14.4637951 33.0652117,15.0160705 33.4718032,16.1041547 L39.7663035,32.9489428 C40.1728949,34.037027 39.6209271,35.2488846 38.533449,35.6557026 C37.4459709,36.0625206 36.2347883,35.5102453 35.8281968,34.4221611 L29.5336965,17.577373 C29.1271051,16.4892888 29.6790729,15.2774311 30.766551,14.8706131 Z"
          id="Rectangle-13"
          fill="#00A9D5"
          transform="translate(34.650000, 25.263158) scale(-1, 1) translate(-34.650000, -25.263158) "
        />
        <path
          d="M44.458482,23.1578947 L42,23.1578947 C40.840202,23.1578947 39.9,24.1004532 39.9,25.2631579 C39.9,26.4258626 40.840202,27.3684211 42,27.3684211 L44.458482,27.3684211 C44.2263239,26.7099391 44.1,26.0013377 44.1,25.2631579 C44.1,24.5249781 44.2263239,23.8163767 44.458482,23.1578947 Z M56.341518,23.1578947 L58.8,23.1578947 C59.959798,23.1578947 60.9,24.1004532 60.9,25.2631579 C60.9,25.8445103 60.6649495,26.3708261 60.2849242,26.7518037 C59.904899,27.1327814 59.379899,27.3684211 58.8,27.3684211 C57.640202,27.3684211 56.7,26.4258626 56.7,25.2631579 C56.7,24.5249781 56.5736761,23.8163767 56.341518,23.1578947 Z"
          id="Combined-Shape"
          fill="#009A3A"
        />
        <path
          d="M18.9,27.6842105 L20.8663035,32.9480029 C21.2728949,34.0364477 20.7209271,35.2487069 19.633449,35.6556598 C19.3916801,35.7461338 19.1437969,35.7891875 18.9,35.7894737 C18.6562031,35.7891875 18.4083199,35.7461338 18.166551,35.6556598 C17.0790729,35.2487069 16.5271051,34.0364477 16.9336965,32.9480029 L18.9,27.6842105 Z"
          id="Combined-Shape"
          fill="#005571"
        />
        <path
          d="M31.5,27.6842105 L33.4663035,32.9480029 C33.8728949,34.0364477 33.3209271,35.2487069 32.233449,35.6556598 C31.9916801,35.7461338 31.7437969,35.7891875 31.5,35.7894737 C31.2562031,35.7891875 31.0083199,35.7461338 30.766551,35.6556598 C29.6790729,35.2487069 29.1271051,34.0364477 29.5336965,32.9480029 L31.5,27.6842105 Z"
          id="Combined-Shape"
          fill="#0080A4"
        />
        <path
          d="M25.2,22.8421053 L23.2336965,17.5783129 C22.8271051,16.4898681 23.3790729,15.2776089 24.466551,14.870656 C24.7083199,14.780182 24.9562031,14.7371283 25.2,14.7368421 C25.4437969,14.7371283 25.6916801,14.780182 25.933449,14.870656 C27.0209271,15.2776089 27.5728949,16.4898681 27.1663035,17.5783129 L25.2,22.8421053 Z"
          id="Combined-Shape"
          fill="#005571"
        />
        <rect
          id="Rectangle-5"
          fill="#15AA99"
          x="0"
          y="8.42105263"
          width="12.6"
          height="4.21052632"
          rx="2.10526316"
        />
        <rect
          id="Rectangle-5"
          fill="#7FBA2A"
          transform="translate(6.300000, 10.526316) rotate(-270.000000) translate(-6.300000, -10.526316) "
          x="0"
          y="8.42105263"
          width="12.6"
          height="4.21052632"
          rx="2.10526316"
        />
        <path
          d="M4.19473684,8.42105263 L8.40526316,8.42105263 L8.40526316,12.6315789 L4.19473684,12.6315789 L4.19473684,8.42105263 Z"
          id="Combined-Shape"
          fill="#009A3A"
        />
      </g>
    </g>
  </svg>
);
