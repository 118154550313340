import React from "react";
import PropTypes from "prop-types";
import s from "./common-styles.module.scss";
import { Button } from "@material-ui/core";

const ShippingClassCell = ({
  shippingClass,
  shippingSigned,
  showEditButton,
  onEditButtonClicked
}) => (
  <React.Fragment>
    <div className={s.detailsCell}>
      <p className={s.primary}>{shippingClass}</p>
      <p className={s.primary}>{shippingSigned ? "signed" : "unsigned"}</p>
    </div>
    {showEditButton ? (
      <Button
        id="t_delivery_edit_button"
        variant="text"
        color="secondary"
        className={s.editButton}
        onClick={onEditButtonClicked}
      >
        EDIT
      </Button>
    ) : null}
  </React.Fragment>
);
ShippingClassCell.propTypes = {
  shippingClass: PropTypes.string,
  shippingSigned: PropTypes.bool
};
ShippingClassCell.defaultProps = {
  shippingClass: "0",
  shippingSigned: false
};

export default ShippingClassCell;
