import { State } from "./types";
import { GP } from "../gp-nhs-refresh/types";

export const getGPs = (state: State): GP[] => {
  return state.gps || [];
};

export const getIsPageLoaded = (state: State): boolean => {
  return state.isPageLoaded || false;
};

export const getGPCursor = (state: State): string => {
  return state.gpCursor || "";
};

export const getIsLoadingGPs = (state: State): boolean => {
  return state.isLoadingGPs || false;
};
