import React, { FC, useMemo } from "react";

import {
  getMedicineDescription,
  getMedicineName
} from "../../../redux/root-reducer";

import { FormattedMedSuggestion, MedicineSearchProps } from ".";
import MedicineSearch from "./medicine-search.ui";

const MedicineSearchContainer: FC<MedicineSearchProps> = ({
  medsSuggestions,
  ...rest
}) => {
  const formattedSuggestions: FormattedMedSuggestion[] = useMemo(
    () =>
      medsSuggestions.map(med => ({
        name: getMedicineName(med),
        description: getMedicineDescription(med)
      })),
    [medsSuggestions]
  );
  return (
    <MedicineSearch
      {...rest}
      medsSuggestions={medsSuggestions}
      formattedSuggestions={formattedSuggestions}
    />
  );
};

export default MedicineSearchContainer;
