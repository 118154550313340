export const PHARMACY_ORDER_ID = "PHARMACY_ORDER_ID";
export const CF_ORDER_ID = "CF_ORDER_ID";
export const BASKET_ORDER_ID = "BASKET_ORDER_ID";
export const UNKNOWN_ORDER_ID = "UNKNOWN_ORDER_ID";

export const ORDER_IDENTIFIER_TYPES = [
  {
    type: PHARMACY_ORDER_ID,
    copy: "Pharmacy order"
  },
  {
    type: BASKET_ORDER_ID,
    copy: "Basket order"
  },
  {
    type: CF_ORDER_ID,
    copy: "CF order"
  }
];

export const EXEMPTION_DESCRIPTION = {
  A: "Under 16, 60+",
  B: "Under 19, in FTE",
  C: "60+",
  D: "Maternity",
  E: "Medical",
  F: "Pre-payment",
  G: "War Pension",
  H: "Income Support",
  K: "Income- based jobseekers",
  L: "HC2",
  M: "NHS Tax Credits",
  S: "Pension Credit",
  U: "Universal credit",
  X: "Contraceptive"
};
