import { ResponseGP } from "../gp-nhs-refresh/types";

export interface State {
  gpAlreadyExists: boolean;
  gpChecked: boolean;
  unknownNHSODS: boolean;
  notGP: boolean;
  ods: string;
  gpPreview: ResponseGP | undefined;
}

export interface GPNotFoundPayload {
  gpNotFound: boolean;
}

export const isGPNotFound = (
  payload: GPNotFoundPayload | unknown
): payload is GPNotFoundPayload => {
  return (payload as GPNotFoundPayload).gpNotFound !== undefined;
};

export interface NonGPPayload {
  notGP: boolean;
}

export const isNonGP = (
  payload: NonGPPayload | unknown
): payload is NonGPPayload => {
  return (payload as NonGPPayload).notGP !== undefined;
};

export interface ResponseGPAction {
  type: string;
  payload: {
    practice: ResponseGP;
  };
}

export interface OdsUpdateAction {
  type: string;
  payload: string;
}
