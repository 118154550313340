import { connect } from "react-redux";
import {
  createDispatchLabel,
  shippingScannerReset
} from "../../redux/dispatch-label/create-dialog";
import ShippingScanner from "./Screen";
import {
  getCreateDispatchLabelError,
  getCreateDispatchLabelExemptionCodes,
  getCreateDispatchLabelIsCreatingLabel,
  getWhistlEnabled
} from "../../redux/root-reducer";

const mapStateToProps = state => ({
  apiError: getCreateDispatchLabelError(state),
  fetching: getCreateDispatchLabelIsCreatingLabel(state),
  exemptionCodes: getCreateDispatchLabelExemptionCodes(state),
  whistlEnabled: getWhistlEnabled(state)
});

const mapDispatchToProps = {
  fetchAndPrintDispatchLabel: ({ orderId, shippingPackage, whistlEnabled }) => {
    return createDispatchLabel(
      orderId,
      0,
      shippingPackage,
      { value: null },
      null,
      whistlEnabled
    );
  },
  scannerReset: shippingScannerReset
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingScanner);
