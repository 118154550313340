import React from "react";
import PropTypes from "prop-types";
import s from "./common-styles.module.scss";

const NotesCell = ({ notes }) => (
  <div className={s.detailsCell}>
    <p>{notes}</p>
  </div>
);

NotesCell.propTypes = {
  notes: PropTypes.string.isRequired
};

export default NotesCell;
