import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingButton from "../common/loading-button";
import {
  getUpdateShippingClassErrorMessage,
  getUpdateShippingClassIsOpen,
  getUpdateShippingClassIsUpdating,
  getUpdateShippingClassOrderId,
  getUpdateShippingClassSelectedService,
  getUpdateShippingClassSelectedServiceIndex,
  getUpdateShippingClassshippingSigned,
  getUpdateShippingClassShowError
} from "../../redux/root-reducer";
import {
  getServiceNames,
  hideUpdateShippingClass,
  selectService,
  updateShippingClass,
  toggleshippingSigned
} from "../../redux/shipping-class-dialog";
import { withStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";

const errorDialogContent = {
  root: {
    color: "red"
  }
};
const ErrorDialogContent = withStyles(errorDialogContent)(DialogContentText);

export class ShippingClassDialog extends Component {
  render() {
    return (
      <Dialog open={this.props.open} maxWidth="md">
        <DialogTitle>Shipping Class</DialogTitle>
        <DialogContent>
          <TextField
            id="t_select_service"
            select
            fullWidth
            type="number"
            label="Shipment type"
            value={this.props.selectedServiceIndex || 0}
            onChange={e => this.props.onSelectService(e.target.value)}
            margin="normal"
          >
            {this.props.serviceNames.map((name, index) => (
              <MenuItem
                id={`t_service_${index}`}
                className="t_service"
                key={name}
                value={index}
              >
                {name}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            id="t_signed_delivery_form"
            control={
              <Checkbox
                checked={this.props.shippingSigned}
                onChange={this.props.onToggleshippingSigned}
              />
            }
            label="Signed Delivery"
          />
          {this.props.showError && (
            <ErrorDialogContent>
              Something went wrong:
              <br />
              {this.props.errorMessage}
            </ErrorDialogContent>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            id="t_cancel"
            color="secondary"
            onClick={this.props.onCancelClick}
            disabled={this.props.isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            id="t_create"
            color="primary"
            autoFocus
            loading={this.props.isLoading}
            onClick={this.props.onUpdateClick}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

ShippingClassDialog.propTypes = {
  open: PropTypes.bool,
  selectedServiceIndex: PropTypes.number,
  shippingSigned: PropTypes.bool,
  serviceNames: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  onSelectService: PropTypes.func.isRequired,
  onToggleshippingSigned: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

ShippingClassDialog.defaultProps = {
  open: false,
  selectedServiceIndex: 0,
  serviceNames: [],
  isLoading: false,
  shippingSigned: false
};

export const mapDispatchToProps = {
  onSelectService: selectService,
  onToggleshippingSigned: toggleshippingSigned,
  onCancelClick: hideUpdateShippingClass,
  updateShippingClass
};

export const mapStateToProps = state => ({
  open: getUpdateShippingClassIsOpen(state),
  selectedServiceIndex: getUpdateShippingClassSelectedServiceIndex(state),
  shippingSigned: getUpdateShippingClassshippingSigned(state),
  serviceNames: getServiceNames(),
  orderId: getUpdateShippingClassOrderId(state),
  selectedService: getUpdateShippingClassSelectedService(state),
  isLoading: getUpdateShippingClassIsUpdating(state),
  showError: getUpdateShippingClassShowError(state),
  errorMessage: getUpdateShippingClassErrorMessage(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onUpdateClick: () =>
    dispatchProps.updateShippingClass(
      stateProps.orderId,
      stateProps.selectedService,
      stateProps.shippingSigned
    ),
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ShippingClassDialog);
