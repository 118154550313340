import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import s from "./style.module.scss";
import ScanCheck from "./_ScanCheck";
import { PACKAGES } from "../../redux/dispatch-label/create-dialog";

const getTitle = (have, types) => {
  const type = types.find(t => t.value === have.value);
  if (type) {
    return type.name;
  }
};

const getValue = (have, types) => {
  return types.map(t => t.value).includes(have.value) ? have.value : undefined;
};

const Shipping = ({ format }) => {
  return (
    <Grid container>
      <Paper className={s.scanInfo}>
        <ScanCheck
          scanned={!!getValue(format, PACKAGES)}
          type={getValue(format, PACKAGES)}
          title={"Shipping barcode"}
          value={getTitle(format, PACKAGES)}
        />
      </Paper>
    </Grid>
  );
};

Shipping.propTypes = {
  format: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })
};
Shipping.defaultProps = {
  format: {}
};

export default Shipping;
