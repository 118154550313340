import { compose } from "redux";
import headersMiddleware from "./headers";
import refreshMiddleware from "./refresh";
import loginMiddleware from "./login";

export default store => next =>
  compose(
    refreshMiddleware(store),
    headersMiddleware(store),
    loginMiddleware(store)
  )(next);
