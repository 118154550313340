import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import BackspaceIcon from "@material-ui/icons/Backspace";

const option = {
  padding: "4px 0",
  borderTop: "1px solid grey",
  marginLeft: "0px"
};

const reasonsContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70vh",
  width: "100%",
  backgroundColor: "white",
  maxWidth: "100%",
  position: "relative",
  paddingTop: "55px"
};

const backButtonStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 100,
  left: 0,
  backgroundColor: "white",
  color: "black"
};

export const renderReasonsView = props => {
  const {
    rejectionReasons,
    onReasonSelected,
    selectedReason,
    reasonNotes,
    onReasonNotesUpdated
  } = props;

  return (
    <DialogContent style={reasonsContainer}>
      {selectedReason === "other" && (
        <IconButton
          id="t_rejection_back_button"
          style={backButtonStyle}
          onClick={() => onReasonSelected(null)}
        >
          <BackspaceIcon style={{ color: "black", marginRight: "5px" }} />
          Choose another reason
        </IconButton>
      )}
      <FormControl component="fieldset" fullWidth style={{ height: "100%" }}>
        <FormLabel component="legend">Rejection Reason</FormLabel>
        <RadioGroup
          id="t_reasons_group"
          aria-label="rejection reasons"
          name="rejection_reasons"
          value={selectedReason}
          onChange={e => onReasonSelected(e.target.value)}
        >
          {rejectionReasons.map(reason =>
            reason.code === "other" ? (
              <FormControlLabel
                id={`t_reasons_option_${reason.code}`}
                className="t_reason_option"
                value={reason.code}
                control={<Radio color="primary" />}
                label={reason.copy}
                style={option}
                key={reason.code}
              />
            ) : selectedReason === "other" ? null : (
              <FormControlLabel
                id={`t_reasons_option_${reason.code}`}
                className="t_reason_option"
                fullWidth
                value={reason.code}
                control={<Radio color="primary" />}
                label={reason.copy}
                style={option}
                key={reason.code}
              />
            )
          )}
        </RadioGroup>
        {selectedReason === "other" && (
          <React.Fragment>
            <Typography id="t_instructions" variant="subtitle1" paragraph>
              Please enter a reason below. Please clearly explain to the
              customer why you have rejected their evidence and what they should
              submit instead. This message will be added to the following email
              text:
            </Typography>
            <Typography
              id="t_email_sample"
              variant="subtitle1"
              style={{ fontStyle: "italic" }}
            >
              We've reviewed the image you uploaded and unfortunately we've had
              to reject it. This is because
            </Typography>
            <TextField
              id="t_reason_notes"
              type="text"
              display="inline"
              value={reasonNotes}
              error={false}
              fullWidth
              autoFocus
              multiline={true}
              maxRows={5}
              onChange={e => onReasonNotesUpdated(e.target.value)}
            />
            <Typography
              id="t_view_email_title"
              variant="h4"
              component="p"
              style={{ margin: "50px 0 30px 0" }}
            >
              This is your message to the customer:
            </Typography>
            <Typography
              id="t_view_email_content"
              style={{ fontWeight: "bold" }}
            >
              We've reviewed the image you uploaded and unfortunately we've had
              to reject it. This is because
              <span style={{ fontStyle: "italic" }}>{` ${reasonNotes}`}</span>.
            </Typography>
          </React.Fragment>
        )}
      </FormControl>
    </DialogContent>
  );
};
