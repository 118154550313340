import Button from "@material-ui/core/Button";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";
import React from "react";
import s from "./common-styles.module.scss";

const VerificationStatus = ({ verified = false }) => {
  const iconColor = verified ? "#8BC34A" : "#F44336";
  const text = verified ? "Approved" : "Not approved";
  const Icon = verified ? CheckCircle : Cancel;
  return (
    <div className={s.exemptionStatus}>
      <Icon style={{ color: iconColor }} />
      <p style={{ color: iconColor }}>{text}</p>
    </div>
  );
};

VerificationStatus.propTypes = {
  verified: PropTypes.bool.isRequired
};

const exemptionRequiresEvidence = exemptionCode => {
  switch (exemptionCode.toLowerCase()) {
    // A and C don't require evidence, but need to be approved.
    // We don't currently handle this scenario properly,
    // so we'll let the pharmacist open the dialog (no image)
    // and approve/reject the evidence.
    case "x":
      return false;
    default:
      return true;
  }
};
const isEvidenceRequired = exemptions =>
  exemptions.reduce((acc, cur) => acc || exemptionRequiresEvidence(cur), false);

const isAutoExemption = exemptionCode => {
  switch (exemptionCode.toLowerCase()) {
    case "*":
    case "#":
    case "$":
      return true;
    default:
      return false;
  }
};
const canView = exemptions =>
  exemptions.reduce((acc, cur) => acc || !isAutoExemption(cur), false);

const getExemptionStatus = (exemptions, evidenceSkipped, paid, approved) => {
  if (evidenceSkipped) {
    return (
      <p id="t_skipped" className={s.primary}>
        Evidence not provided
      </p>
    );
  } else if (paid) {
    return (
      <p id="t_paid" className={s.primary}>
        Payment provided
      </p>
    );
  } else if (exemptions.length && !isEvidenceRequired(exemptions)) {
    return (
      <p id="t_not_required" className={s.primary}>
        Evidence not required
      </p>
    );
  } else if (exemptions.length) {
    return <VerificationStatus id="t_verification" verified={approved} />;
  }
  return null;
};

const EXEMPTION_DESCRIPTIONS = {
  a: "Is 60 years of age or over, or is under 16 years of age",
  b: "Is 16, 17 or 18, and in full time education",
  c: "Is 60 years of age or over",
  d: "Maternity exemption certificate",
  e: "Medical exemption certificate",
  f: "Prescription prepayment certificate",
  g: "Prescription prepayment certificate issued by Ministry od Defence",
  h: "Income Support, or income-related Employment and Support Allowance",
  k: "Income-based Jobseeker's Allowance",
  l: "HC2 (full help) certificate",
  m: "Tax Credit exemption certificate",
  s: "Pension Credit Guarantee Credit (including partners)",
  u:
    "Universal Credit, and meets the criteria. Find out more at www.nhsbsa.nhs.uk/UC",
  w: "HRT prescription prepayment certificate",
  x: "Free-of-charge contraceptives",
  "*": "RTEC approved via web scrape",
  "#": "Analyst auto-exempt",
  $: "Analyst RTEC"
};

const getExemptionDescription = code => {
  const desc =
    EXEMPTION_DESCRIPTIONS[code.toLowerCase()] ||
    `Unknown exemption code "${code}"`;
  return `${code} - ${desc}`;
};

const getExemptionsDescriptions = exemptions =>
  exemptions.map(code => <p key={code}>{getExemptionDescription(code)}</p>);

const getViewEvidenceButton = (exemptions, evidenceSkipped, onViewEvidence) => {
  if (
    exemptions.length &&
    !evidenceSkipped &&
    isEvidenceRequired(exemptions) &&
    canView(exemptions)
  ) {
    return (
      <Button
        id="t_view_button"
        variant="text"
        color="secondary"
        className={s.viewButton}
        onClick={event => {
          if (event) {
            // event is not defined when testing
            // intercept and block the event so that it doens't select the table row
            event.preventDefault();
            event.stopPropagation();
          }
          onViewEvidence();
        }}
      >
        View
      </Button>
    );
  }
  return null;
};

const ExemptionCell = ({
  exemptions = [],
  paid,
  approved,
  evidenceSkipped,
  onViewEvidence
}) => (
  <div className={s.detailsCell}>
    {getExemptionStatus(exemptions, evidenceSkipped, paid, approved)}
    {getExemptionsDescriptions(exemptions)}
    {getViewEvidenceButton(exemptions, evidenceSkipped, onViewEvidence)}
  </div>
);

ExemptionCell.propTypes = {
  exemptions: PropTypes.array,

  paid: PropTypes.bool.isRequired,
  approved: PropTypes.bool,
  evidenceSkipped: PropTypes.bool,

  onViewEvidence: PropTypes.func
};

ExemptionCell.defaultProps = {
  exemptions: [],
  evidenceSkipped: false,
  approved: false,
  onViewEvidence: () => {}
};

export default ExemptionCell;
