import { handleActions } from "redux-actions";

import {
  OPEN_REFRESH_GP,
  CLOSE_REFRESH_GP,
  GET_CAN_NHSREFRESH_REQUEST,
  GET_CAN_NHSREFRESH_RECIEVE,
  GET_CAN_NHSREFRESH_FAILURE,
  FETCH_NHSREFRESH_REQUEST,
  FETCH_NHSREFRESH_RECIEVE,
  FETCH_NHSREFRESH_FAILURE,
  COMPLETE_NHSREFRESH_REQUEST,
  COMPLETE_NHSREFRESH_RECIEVE
} from "./actions";

import { CREATE_GP_START } from "../gp-nhs-create";

import {
  OdsCodeAction,
  OfferGPRefreshAction,
  ResponseGP,
  RefreshedGPAction,
  UnverifiedFields
} from "./types";

// REDUCERS
export const odsCode = handleActions(
  {
    [OPEN_REFRESH_GP]: (_state: string, action: OdsCodeAction) =>
      action.payload.odsCode,
    [CLOSE_REFRESH_GP]: () => ""
  },
  ""
);

export const isOpen = handleActions(
  {
    [OPEN_REFRESH_GP]: () => true,
    [CLOSE_REFRESH_GP]: () => false,
    [COMPLETE_NHSREFRESH_REQUEST]: () => false,
    [FETCH_NHSREFRESH_FAILURE]: () => false
  },
  false
);

export const offerGPRefresh = handleActions(
  {
    [GET_CAN_NHSREFRESH_REQUEST]: () => false,
    [GET_CAN_NHSREFRESH_RECIEVE]: (
      _state: boolean,
      action: OfferGPRefreshAction
    ) => action.payload.OK,
    [GET_CAN_NHSREFRESH_FAILURE]: () => false
  },
  false
);

export const refreshedGP = handleActions(
  {
    [FETCH_NHSREFRESH_REQUEST]: () => null,
    [FETCH_NHSREFRESH_FAILURE]: () => null,
    [FETCH_NHSREFRESH_RECIEVE]: (
      _state: ResponseGP | null,
      action: RefreshedGPAction
    ) => action.payload.practice
  },
  null
);

export const unverifiedFields = handleActions(
  {
    [FETCH_NHSREFRESH_REQUEST]: () => null,
    [FETCH_NHSREFRESH_FAILURE]: () => null,
    [FETCH_NHSREFRESH_RECIEVE]: (
      _state: UnverifiedFields | null,
      action: RefreshedGPAction
    ): UnverifiedFields => action.payload.unverified
  },
  null
);

export const gpIsRefreshed = handleActions(
  {
    [COMPLETE_NHSREFRESH_REQUEST]: (): boolean => false,
    [COMPLETE_NHSREFRESH_RECIEVE]: (): boolean => true,
    [CREATE_GP_START]: (): boolean => false,
    [OPEN_REFRESH_GP]: (): boolean => false
  },
  false
);
