/**
 * Returns a function that gets the (deep) property called name on the object it is called with.
 * Returns null when the property does not exist.
 * Example
 * const getAddress = getField('user.address');
 * const address = getAddress({ user: { address: '123' } }); // address is '123'.
 */
export const getField = name => obj =>
  name
    .split(".")
    .reduce(
      (prev, key) =>
        prev != null
          ? Object.prototype.hasOwnProperty.call(prev, key)
            ? prev[key]
            : null
          : null,
      obj
    );
