import { connect } from "react-redux";

import {
  getMedsSearchText,
  getMedsSuggestions
} from "../../../redux/root-reducer";
import { changeMedicineSearch } from "../../../redux/create-order";
import {
  clearMedsSuggestions,
  MedSuggestion
} from "../../../redux/meds-search";

import Container from "./medicine-search.container";

export interface MedicineSearchStateProps {
  medsSearchText: string;
  medsSuggestions: MedSuggestion[];
}

export interface MedicineSearchDispatchProps {
  onMedsSuggestionTextChange(text: string): void;
  onCloseMedsSuggestions(): void;
}

export interface MedicineSearchOwnProps {
  onMedSuggestionSelect(medicine: MedSuggestion): void;
}

export type MedicineSearchProps = MedicineSearchStateProps &
  MedicineSearchDispatchProps &
  MedicineSearchOwnProps;

export interface FormattedMedSuggestion {
  name: string;
  description: string;
}

const mapStateToProps = state => ({
  medsSearchText: getMedsSearchText(state),
  medsSuggestions: getMedsSuggestions(state)
});

const mapDispatchToProps = {
  onMedsSuggestionTextChange: changeMedicineSearch,
  onCloseMedsSuggestions: clearMedsSuggestions
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
