import { RSAA } from "redux-api-middleware";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { CREATE_ORDER_RECEIVE } from "../create-order";
import { SET_GP_PROFILE_RECEIVE, GET_GP_PROFILE_REQUEST } from "../gp-profile";
import { CONTACT_REQUEST_RECEIVE } from "../contact-dialog";
import { SELECT_USER_TAB, UPDATE_USER_PROFILE_RECEIVE } from "../user-profile";
import { DELIVERY_ADDRESS_UPDATE_RECEIVE } from "../delivery-address";

const REQUESTS_API_ENDPOINT = process.env.REACT_APP_REQUESTS_API;

// ACTIONS
export const FETCH_REQUEST_REQUEST = "FETCH_REQUEST_REQUEST";
export const FETCH_REQUEST_RECEIVE = "FETCH_REQUEST_RECEIVE";
export const FETCH_REQUEST_FAILURE = "FETCH_REQUEST_FAILURE";
export const FETCH_REQUEST_PAGED_REQUEST = "FETCH_REQUEST_PAGED_REQUEST";
export const FETCH_REQUEST_PAGED_RECEIVE = "FETCH_REQUEST_PAGED_RECEIVE";
export const FETCH_REQUEST_PAGED_FAILURE = "FETCH_REQUEST_PAGED_FAILURE";
export const SEND_TO_GP_REQUEST = "SEND_TO_GP_REQUEST";
export const SEND_TO_GP_RECEIVE = "SEND_TO_GP_RECEIVE";
export const SEND_TO_GP_FAILURE = "SEND_TO_GP_FAILURE";
export const SELECT_REQUEST = "SELECT_REQUEST";
export const REJECT_REQUEST_REQUEST = "REJECT_REQUEST_REQUEST";
export const REJECT_REQUEST_RECEIVE = "REJECT_REQUEST_RECEIVE";
export const REJECT_REQUEST_FAILURE = "REJECT_REQUEST_FAILURE";

// ACTION CREATORS
export const fetchRequests = (
  filters = [],
  userId,
  apiEndpoint = REQUESTS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/requests?status=${filters.join(",")}${
      userId ? `&userId=${userId}` : ""
    }`,
    method: "GET",
    types: [
      FETCH_REQUEST_REQUEST,
      FETCH_REQUEST_RECEIVE,
      FETCH_REQUEST_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const fetchAdditionalRequests = (
  page = 1,
  filter = [],
  userId,
  apiEndpoint = REQUESTS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/requests?page=${page}&status=${filter.join(",")}${
      userId ? `&userId=${userId}` : ""
    }`,
    method: "GET",
    types: [
      FETCH_REQUEST_PAGED_REQUEST,
      FETCH_REQUEST_PAGED_RECEIVE,
      FETCH_REQUEST_PAGED_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const markAsSentToGP = (
  request,
  apiEndpoint = REQUESTS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/request/${request.id}/send/prescriptionrequestphone`,
    method: "POST",
    types: [SEND_TO_GP_REQUEST, SEND_TO_GP_RECEIVE, SEND_TO_GP_FAILURE],
    options: { addAuth: true }
  }
});

export const rejectRequest = (
  request,
  notes,
  apiEndpoint = REQUESTS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/request/${request.id}/reject`,
    method: "POST",
    types: [
      REJECT_REQUEST_REQUEST,
      REJECT_REQUEST_RECEIVE,
      REJECT_REQUEST_FAILURE
    ],
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ notes }),
    options: { addAuth: true }
  }
});

export const selectRequest = request => ({
  type: SELECT_REQUEST,
  request
});

// SELECTOR
export const getRequests = state => state.allIds.map(id => state.byId[id]);
export const getSelectedId = state => state.selectedId;
export const getError = state => state.error;
export const getFetching = state => state.fetching;
export const getFetchingAdditional = state => state.fetchingAdditional;
export const getNeedsUpdate = state => state.needsRefresh;
export const getCurrentRequest = state =>
  getRequests(state).find(item => item.id === state.selectedId);
export const getRequestGPDetails = state =>
  ({ ...{ gp: {} }, ...getCurrentRequest(state) }.gp);
export const getRequestGPDetailsNoContact = state =>
  ({
    ...{ againstDigitalPharmacy: false },
    ...getRequestGPDetails(state).additional
  }.againstDigitalPharmacy);
export const getRequestGPDetailsPreference = state =>
  ({ ...{ contactPreference: "" }, ...getRequestGPDetails(state).additional }
    .contactPreference);
export const getResultsPage = state => state.resultsPage;
export const getAllPagesFetched = state => state.allPagesFetched;

// REDUCERS
const byId = handleActions(
  {
    [FETCH_REQUEST_RECEIVE]: (state, action) =>
      action.payload.reduce(
        (acc, req) => Object.assign(acc, { [req.id]: req }),
        {}
      ),
    [FETCH_REQUEST_PAGED_RECEIVE]: (state, action) =>
      action.payload.reduce(
        (acc, req) => Object.assign(acc, { [req.id]: req }),
        { ...state }
      ),
    [DELIVERY_ADDRESS_UPDATE_RECEIVE]: (state, action) => {
      if (!action.meta.requestId) return state;
      const req = state[action.meta.requestId];
      if (!req) return state;
      const { address } = action.meta;
      const deliveryAddress = [
        address.line1,
        address.line2,
        address.city,
        address.postcode
      ].join("\n");
      return {
        ...state,
        [action.meta.requestId]: { ...req, deliveryAddress }
      };
    }
  },
  {}
);

const allIds = handleActions(
  {
    [FETCH_REQUEST_RECEIVE]: (state, action) =>
      action.payload.map(req => req.id),
    [FETCH_REQUEST_PAGED_RECEIVE]: (state, action) =>
      state.concat(action.payload.map(req => req.id))
  },
  []
);

const selectedId = handleActions(
  {
    [SELECT_REQUEST]: (state, action) =>
      action.request ? action.request.id : null,
    [FETCH_REQUEST_REQUEST]: () => null,
    [GET_GP_PROFILE_REQUEST]: () => null,
    [SELECT_USER_TAB]: () => null
  },
  null
);

const needsRefresh = handleActions(
  {
    [FETCH_REQUEST_RECEIVE]: () => false,
    [CREATE_ORDER_RECEIVE]: () => true,
    [SEND_TO_GP_RECEIVE]: () => true,
    [REJECT_REQUEST_RECEIVE]: () => true,
    [SET_GP_PROFILE_RECEIVE]: () => true,
    [CONTACT_REQUEST_RECEIVE]: () => true,
    [UPDATE_USER_PROFILE_RECEIVE]: () => true
  },
  true
);

const error = handleActions(
  {
    [FETCH_REQUEST_REQUEST]: () => null,
    [FETCH_REQUEST_RECEIVE]: () => null,
    [FETCH_REQUEST_FAILURE]: (state, action) => action.payload,
    [FETCH_REQUEST_PAGED_REQUEST]: () => null,
    [FETCH_REQUEST_PAGED_RECEIVE]: () => null,
    [FETCH_REQUEST_PAGED_FAILURE]: (state, action) => action.payload
  },
  null
);

const fetching = handleActions(
  {
    [FETCH_REQUEST_REQUEST]: () => true,
    [FETCH_REQUEST_RECEIVE]: () => false,
    [FETCH_REQUEST_FAILURE]: () => false
  },
  false
);

const fetchingAdditional = handleActions(
  {
    [FETCH_REQUEST_PAGED_REQUEST]: () => true,
    [FETCH_REQUEST_PAGED_RECEIVE]: () => false,
    [FETCH_REQUEST_PAGED_FAILURE]: () => false
  },
  false
);

const resultsPage = handleActions(
  {
    [FETCH_REQUEST_RECEIVE]: () => 1,
    [FETCH_REQUEST_PAGED_RECEIVE]: state => state + 1
  },
  0
);

const allPagesFetched = handleActions(
  {
    [FETCH_REQUEST_RECEIVE]: (state, action) => action.payload.length < 20,
    [FETCH_REQUEST_PAGED_RECEIVE]: (state, action) => action.payload.length < 20
  },
  false
);

export default combineReducers({
  byId,
  allIds,
  selectedId,
  fetching,
  fetchingAdditional,
  resultsPage,
  allPagesFetched,
  needsRefresh,
  error
});
