import React from "react";
import PropTypes from "prop-types";
import AddressCell from "./address-cell";
import { Button } from "@material-ui/core";
import s from "./common-styles.module.scss";

const DeliveryAddressCell = ({
  children,
  showEditButton,
  pharmacyId,
  onEditButtonClicked
}) => (
  <>
    <AddressCell>{children}</AddressCell>
    {pharmacyId ? (
      <Button
        id={"t_delivery_pharmacy_button"}
        variant={"text"}
        color={"secondary"}
        className={s.editButton}
        target={"_blank"}
        href={`https://finder.well.co.uk/store/${pharmacyId}`}
      >
        VIEW
      </Button>
    ) : null}
    {showEditButton ? (
      <Button
        id={"t_delivery_edit_button"}
        variant={"text"}
        color={"secondary"}
        className={s.editButton}
        onClick={onEditButtonClicked}
      >
        EDIT
      </Button>
    ) : null}
  </>
);

DeliveryAddressCell.propTypes = {
  children: PropTypes.string.isRequired,
  showEditButton: PropTypes.bool,
  pharmacyId: PropTypes.string,
  onEditButtonClicked: PropTypes.func
};

DeliveryAddressCell.defaultProps = {
  showEditButton: true,
  pharmacyId: "",
  onEditButtonClicked: () => {}
};

export default DeliveryAddressCell;
