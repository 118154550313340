// UTILITIES
// This wraps fetch, but intercepts "forbidden" to stop it from being an error,
// as we don't want it displayed as such
export const fetchWithErrorMiddleware = async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  const res = await fetch(input, init);

  if (res.status === 403) {
    return new Response(JSON.stringify({ OK: false }), {
      status: 200,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  return res;
};
