import React, { useEffect, FC } from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";

import GpDetails from "./gp-details";
import Grid from "@material-ui/core/Grid";
import { fetchGPProfile } from "../../redux/gp-profile";
import {
  getGPAPISetObject,
  getRefreshedFlatGP,
  getRefreshGPIsOpen,
  getRefreshGPCode,
  getGPLastUpdated,
  getRefeshUnverifiedFields
} from "../../redux/root-reducer";
import {
  fetchNHSUpdatedGP,
  closeRefreshGP,
  completeNHSUpdateGP
} from "../../redux/gp-nhs-refresh";
import { GP, UnverifiedFields } from "../../redux/gp-nhs-refresh/types";
import mapMaker from "./map-maker";
import noUpdated from "./no-updated";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import s from "./refreshed.module.scss";
import { formatDateTimeString } from "../../helpers/date-helpers";

export interface GPRefreshProps {
  existing: GP;
  refreshed: GP;
  isOpen: boolean;
  odsCode: string;
  fetchNHSUpdatedGP: (ods: string) => void;
  fetchGPProfile: (ods: string) => void;
  closeRefreshGP: () => void;
  completeNHSUpdateGP: (ods: string) => void;
  lastUpdated: string;
  unverifiedFields: UnverifiedFields;
}

export const GPRefresh: FC<GPRefreshProps> = ({
  existing,
  refreshed,
  isOpen,
  odsCode,
  fetchNHSUpdatedGP,
  fetchGPProfile,
  closeRefreshGP,
  completeNHSUpdateGP,
  lastUpdated,
  unverifiedFields
}) => {
  const updatedFields = mapMaker(existing, unverifiedFields, refreshed);
  useEffect(() => {
    if (isOpen) {
      fetchNHSUpdatedGP(odsCode);
      fetchGPProfile(odsCode);
    }
  }, [isOpen, fetchGPProfile, odsCode, fetchNHSUpdatedGP]);

  return (
    <Dialog fullWidth maxWidth={"md"} open={isOpen}>
      <DialogContent>
        <Grid className={s.topGrid} container>
          <Grid item xs={12}>
            <h4 className={s.title}>
              Please check these details are correct before updating
            </h4>
          </Grid>
          <Grid className={s.currentGP} item xs={6}>
            <GpDetails title={"Current"} gp={existing} />
            <span className={s.lastUpdated}>
              <span>Last updated: </span>
              <span className={"lastUpdatedDisplay"}>
                {formatDateTimeString(lastUpdated)}
              </span>
            </span>
          </Grid>
          <Grid className={s.updatedGP} item xs={6}>
            <GpDetails
              title={"Updated"}
              gp={refreshed}
              updatedFields={updatedFields}
              showUpToDate={noUpdated(updatedFields)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id={"cancelRefresh"}
          onClick={() => closeRefreshGP()}
          color={"secondary"}
        >
          Cancel
        </Button>
        <Button
          id={"doRefresh"}
          onClick={() => completeNHSUpdateGP(odsCode)}
          color={"secondary"}
        >
          Update GP Details
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const mapDispatchToProps = {
  fetchGPProfile,
  fetchNHSUpdatedGP,
  closeRefreshGP,
  completeNHSUpdateGP
};

export const mapStateToProps = state => ({
  existing: getGPAPISetObject(state),
  refreshed: getRefreshedFlatGP(state),
  isOpen: getRefreshGPIsOpen(state),
  odsCode: getRefreshGPCode(state),
  lastUpdated: getGPLastUpdated(state),
  unverifiedFields: getRefeshUnverifiedFields(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GPRefresh);
