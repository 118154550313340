import { authenticationError } from "../../auth";

// Insert after redux-api-middleware in chain
export const middleware = ({ authError }) => () => next => action => {
  if (action.payload && action.payload.status === 401) {
    return next(authError());
  }
  return next(action);
};

export default middleware({
  authError: authenticationError
});
