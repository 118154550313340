import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LoadingButton from "../../common/loading-button";
import {
  getCreateDispatchLabelIsOpen,
  getCreateDispatchLabelAddress,
  getCreateDispatchLabelWeight,
  getCreateDispatchLabelPackageNames,
  getCreateDispatchLabelIsCreatingLabel,
  getCreateDispatchLabelCanCreateLabel,
  getCreateDispatchLabelSelectedPackageIndex,
  getCreateDispatchLabelSelectedPackage,
  getCreateDispatchLabelOrderId,
  getCreateDispatchLabelFullName,
  getCreateDispatchLabelUrl,
  getCreateDispatchLabelSelectedServiceIndex,
  getCreateDispatchLabelServiceNames,
  getCreateDispatchLabelSignedDelivery,
  getCreateDispatchLabelNeedsSignedDelivery,
  getCreateDispatchLabelSelectedService,
  getWhistlEnabled
} from "../../../redux/root-reducer";
import {
  selectPackage,
  updateWeight,
  hideCreateDispatchLabel,
  createDispatchLabel,
  selectService,
  toggleSignedDelivery
} from "../../../redux/dispatch-label/create-dialog";
import { printDispatchLabel } from "../../../redux/dispatch-label/print";

export class CreateLabelDialog extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.printingRequired && this.props.printingRequired) {
      this.props.printLabel();
    }
  }

  render() {
    return (
      <Dialog open={this.props.open} maxWidth="md">
        <DialogTitle>Create Label</DialogTitle>
        <DialogContent>
          <h4 style={{ marginBottom: 10, padding: 0, fontWeight: 800 }}>
            Name & Address
          </h4>
          <p>{this.props.fullName}</p>
          {this.props.address.split("\n").map((row, index) => (
            <p key={index}>{row}</p>
          ))}
          <div style={{ margin: 32 }} />
          <TextField
            id="t_weight"
            fullWidth
            value={this.props.weight || ""}
            onChange={e => this.props.onWeightChange(e.currentTarget.value)}
            placeholder="Weight of package (g)"
          />
          <TextField
            id="t_select_package"
            select
            fullWidth
            type="number"
            label="Package type"
            value={this.props.selectedPackageIndex || 0}
            onChange={e => this.props.onSelectPackage(e.target.value)}
            margin="normal"
          >
            {this.props.packageNames.map((name, index) => (
              <MenuItem className="t_pack" key={name} value={index}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="t_select_service"
            select
            fullWidth
            type="number"
            label="Shipment type"
            value={this.props.selectedServiceIndex || 0}
            onChange={e => this.props.onSelectService(e.target.value)}
            margin="normal"
          >
            {this.props.serviceNames.map((name, index) => (
              <MenuItem className="t_service" key={name} value={index}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            id="t_signed_delivery_form"
            control={
              <Checkbox
                checked={this.props.shippingSignedDelivery}
                onChange={this.props.onToggleSignedDelivery}
                disabled={this.props.shippingNeedsSignedDelivery}
              />
            }
            label="Signed Delivery"
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="t_cancel"
            color="secondary"
            onClick={this.props.onCancelClick}
            disabled={this.props.isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            id="t_create"
            color="primary"
            autoFocus
            loading={this.props.isLoading}
            disabled={!this.props.canCreateLabel}
            onClick={this.props.onCreateClick}
          >
            Create Label
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateLabelDialog.propTypes = {
  open: PropTypes.bool,
  address: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  weight: PropTypes.number,
  selectedPackageIndex: PropTypes.number,
  selectedServiceIndex: PropTypes.number,
  packageNames: PropTypes.arrayOf(PropTypes.string),
  serviceNames: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  canCreateLabel: PropTypes.bool,
  printingRequired: PropTypes.bool,
  shippingSignedDelivery: PropTypes.bool,
  whistlEnabled: PropTypes.bool.isRequired,

  onSelectPackage: PropTypes.func.isRequired, // input is the selected index
  onSelectService: PropTypes.func.isRequired, // input is the selected index
  onToggleSignedDelivery: PropTypes.func.isRequired,
  onWeightChange: PropTypes.func.isRequired,
  onCreateClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  printLabel: PropTypes.func.isRequired
};

CreateLabelDialog.defaultProps = {
  open: false,
  selectedPackageIndex: 0,
  selectedServiceIndex: 0,
  packageNames: [],
  serviceNames: [],
  isLoading: false,
  canCreateLabel: false,
  weight: null,
  printingRequired: false,
  shippingSignedDelivery: false
};

export const mapDispatchToProps = {
  onSelectPackage: selectPackage,
  onSelectService: selectService,
  onToggleSignedDelivery: toggleSignedDelivery,
  onWeightChange: updateWeight,
  onCancelClick: hideCreateDispatchLabel,
  createDispatchLabel,
  printDispatchLabel
};

export const mapStateToProps = state => ({
  open: getCreateDispatchLabelIsOpen(state),
  fullName: getCreateDispatchLabelFullName(state),
  address: getCreateDispatchLabelAddress(state),
  weight: getCreateDispatchLabelWeight(state),
  selectedPackageIndex: getCreateDispatchLabelSelectedPackageIndex(state),
  packageNames: getCreateDispatchLabelPackageNames(state),
  selectedServiceIndex: getCreateDispatchLabelSelectedServiceIndex(state),
  serviceNames: getCreateDispatchLabelServiceNames(state),
  isLoading: getCreateDispatchLabelIsCreatingLabel(state),
  canCreateLabel: getCreateDispatchLabelCanCreateLabel(state),
  orderId: getCreateDispatchLabelOrderId(state),
  printingRequired: getCreateDispatchLabelUrl(state) !== null,
  labelUrl: getCreateDispatchLabelUrl(state),
  shippingSignedDelivery: getCreateDispatchLabelSignedDelivery(state),
  shippingNeedsSignedDelivery: getCreateDispatchLabelNeedsSignedDelivery(state),
  whistlEnabled: getWhistlEnabled(state),
  selectedPackage: getCreateDispatchLabelSelectedPackage(state),
  selectedService: getCreateDispatchLabelSelectedService(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onCreateClick: () =>
    dispatchProps.createDispatchLabel(
      stateProps.orderId,
      stateProps.weight,
      stateProps.selectedPackage,
      stateProps.selectedService,
      stateProps.shippingSignedDelivery,
      stateProps.whistlEnabled
    ),
  printLabel: () => dispatchProps.printDispatchLabel(stateProps.labelUrl),
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateLabelDialog);
