import { RSAA } from "redux-api-middleware";
import * as fromAuth from "../../auth";
import * as fromRootReducer from "../../root-reducer";

const isAuthenticatedAPIAction = action => {
  const callAPI = action[RSAA];
  return callAPI && callAPI.options && callAPI.options.addAuth;
};

export const middleware = ({
  refresh,
  authError,
  getIsAuthenticated,
  getRefreshTokenExpriration,
  getIdTokenExpiration
}) => store => next => action => {
  // If this isn't an authenticated API call, we skip this middleware
  if (!isAuthenticatedAPIAction(action)) {
    return next(action);
  }

  const state = store.getState();

  // If we don't have an auth token, we just automatically fail this action
  if (!getIsAuthenticated(state)) {
    const failureType = action[RSAA].types[2];
    return next({
      type: failureType.type || failureType,
      error: true,
      payload: new Error("Not logged in")
    });
  }
  // Make sure the refresh token is still valid
  const refTokenTimeToExpire =
    new Date(getRefreshTokenExpriration(state)).getTime() - Date.now();
  if (refTokenTimeToExpire <= 0) return next(authError()); // nothing to do here. Force a logout.

  // If token is expired or will expire within 30 seconds, refresh then make the request
  const timeToExpire =
    new Date(getIdTokenExpiration(state)).getTime() - Date.now();

  if (timeToExpire < 30e3) {
    return next(refresh()).then(refRespAction => {
      if (refRespAction.error) return next(authError());
      return next(action);
    });
    // If the token expires in less than 30 minutes but is still valid, refresh in parallel
  } else if (timeToExpire < 30 * 60e3) {
    next(refresh());
    return next(action);
  }
  // Otherwise, just make the request
  return next(action);
};

export default middleware({
  refresh: fromAuth.refresh,
  authError: fromAuth.authenticationError,
  getIsAuthenticated: fromRootReducer.getIsAuthenticated,
  getIdTokenExpiration: fromRootReducer.getIdTokenExpiration,
  getRefreshTokenExpriration: fromRootReducer.getRefreshTokenExpriration,
  getRefreshToken: fromRootReducer.getRefreshToken
});
