import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Favourite from "@material-ui/icons/Favorite";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import Print from "@material-ui/icons/Print";
import Home from "@material-ui/icons/Home";
// import Tv from "@material-ui/icons/Tv";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Assignment from "@material-ui/icons/Assignment";
import s from "./profile.module.scss";
import { VALID_CONTACT_PREFERENCES } from "../../redux/gp-profile";
import { formatDateTimeString } from "../../helpers/date-helpers";

const ProfileForm = ({
  onSubmit,
  onChange,
  organisationCode,
  name,
  nameValid,
  status,
  statusValid,
  email,
  emailValid,
  telephone,
  telephoneValid,
  fax,
  faxValid,
  addressLine1,
  addressLine1Valid,
  addressLine2,
  addressLine2Valid,
  city,
  cityValid,
  postcode,
  postcodeValid,
  country,
  countryValid,
  againstDigitalPharmacy,
  contactPreference,
  canSave,
  offerGPRefresh,
  notes,
  notesValid,
  openRefreshGP,
  lastUpdated,
  surgeryType,
  surgeryTypeValid,
  im1Provider
}) => (
  <div className={s.profile}>
    <div className={s.lastUpdated}>
      <span>Last Updated: </span>
      <span id={"lastUpdatedDisplay"}>
        {lastUpdated !== "" ? formatDateTimeString(lastUpdated) : ""}
      </span>
    </div>
    <TextField
      id={"t_name"}
      className={s.title}
      style={{ marginBottom: 10, marginLeft: 35, marginTop: 0 }}
      inputProps={{
        style: { fontSize: "25px", width: `${name.length}ch` }
      }}
      value={name}
      error={!nameValid}
      onChange={e => onChange("name", e.currentTarget.value)}
      margin={"normal"}
      disabled
    />
    <div style={{ marginBottom: 20, marginLeft: 35 }}>#{organisationCode}</div>
    <div className={s.grid}>
      <div className={s.cell}>
        <div className={s.icon}>
          <Favourite />
        </div>
        <FormControl className={s.field} style={{ paddingBottom: 0 }}>
          <InputLabel htmlFor={"t_method"}>
            Preferred method of contact
          </InputLabel>
          <Select
            fullWidth
            value={contactPreference || "none"}
            onChange={e =>
              onChange(
                "contactPreference",
                e.target.value === "none" ? "" : e.target.value
              )
            }
            inputProps={{
              name: "preferred method of contact",
              id: "t_method"
            }}
          >
            {VALID_CONTACT_PREFERENCES.map(p => (
              <MenuItem value={p || "none"} key={p}>
                {p || "none"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={s.cell}>
        <div className={s.icon} />
        <FormControl className={s.field} style={{ paddingBottom: 0 }}>
          <InputLabel htmlFor={"t_against"}>Wants to work with us</InputLabel>
          <Select
            fullWidth
            value={againstDigitalPharmacy ? "false" : "true"}
            onChange={e =>
              onChange("againstDigitalPharmacy", e.target.value === "false")
            }
            inputProps={{
              name: "againstDigitalPharmacy",
              id: "t_against"
            }}
          >
            <MenuItem value={"false"}>No</MenuItem>
            <MenuItem value={"true"}>Yes</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={s.cell}>
        <div className={s.icon}>
          <Email />
        </div>
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_email"}
            label={"email address"}
            error={!emailValid}
            type={"email"}
            className={s.textField}
            value={email}
            onChange={e => onChange("email", e.currentTarget.value)}
            margin={"normal"}
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon}>
          <Phone />
        </div>
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_phone"}
            label={"phone number"}
            InputProps={{
              type: "tel"
            }}
            className={s.textField}
            error={!telephoneValid}
            value={telephone}
            onChange={e => onChange("telephone", e.currentTarget.value)}
            margin={"normal"}
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon}>
          <Print />
        </div>
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_fax"}
            label={"fax number"}
            className={s.textField}
            error={!faxValid}
            value={fax}
            onChange={e => onChange("fax", e.currentTarget.value)}
            margin={"normal"}
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon}>
          <Home />
        </div>
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_add1"}
            label={"address, line 1"}
            className={s.textField}
            error={!addressLine1Valid}
            value={addressLine1}
            onChange={e => onChange("addressLine1", e.currentTarget.value)}
            margin={"normal"}
            disabled
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon} />
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_add2"}
            label={"address, line 2"}
            className={s.textField}
            error={!addressLine2Valid}
            value={addressLine2}
            onChange={e => onChange("addressLine2", e.currentTarget.value)}
            margin={"normal"}
            disabled
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon} />
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_city"}
            label={"city"}
            className={s.textField}
            error={!cityValid}
            value={city}
            onChange={e => onChange("city", e.currentTarget.value)}
            margin={"normal"}
            disabled
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon} />
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_postcode"}
            label={"postcode"}
            className={s.textField}
            error={!postcodeValid}
            value={postcode}
            onChange={e => onChange("postcode", e.currentTarget.value)}
            margin={"normal"}
            disabled
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon} />
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_country"}
            label={"country"}
            className={s.textField}
            error={!countryValid}
            value={country}
            onChange={e => onChange("country", e.currentTarget.value)}
            margin={"normal"}
            disabled
          />
        </div>
      </div>
      <div className={s.cell}>
        <div className={s.icon} />
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_type"}
            label={"surgery type"}
            className={s.textField}
            error={!surgeryTypeValid}
            value={surgeryType}
            onChange={e => onChange("surgeryType", e.currentTarget.value)}
            margin={"normal"}
            disabled
          />
        </div>
      </div>

      <div className={s.cell}>
        <div className={s.icon} />
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_status"}
            label={"status"}
            className={s.textField}
            error={!statusValid}
            value={status}
            onChange={e => onChange("status", e.currentTarget.value)}
            margin={"normal"}
            disabled
          />
        </div>
      </div>

      <div className={s.cell}>
        <div className={s.icon} />
        <div className={s.field}>
          <TextField
            fullWidth
            id={"t_im1Provider"}
            label={"IM1 Provider"}
            className={s.textField}
            error={false}
            value={im1Provider || "None"}
            margin={"normal"}
            disabled
          />
        </div>
      </div>

      <div className={s.notesArea}>
        <div className={s.cell}>
          <div className={s.icon}>
            <Assignment />
          </div>
          <TextField
            fullWidth
            rows={2}
            rowsMax={5}
            id={"t_notes"}
            label={"notes"}
            multiline
            className={s.textField}
            error={!notesValid}
            value={notes}
            onChange={e => onChange("notes", e.currentTarget.value)}
            margin={"normal"}
          />
        </div>
      </div>
    </div>
    <div className={s.actions}>
      {refreshButton(offerGPRefresh, openRefreshGP, organisationCode)}
      <Button
        id={"t_submit_btn"}
        disabled={!canSave}
        variant={"contained"}
        color={"primary"}
        onClick={onSubmit}
      >
        save
      </Button>
    </div>
  </div>
);

const refreshButton = (offerGPRefresh, openRefreshGP, organisationCode) => {
  return offerGPRefresh ? (
    <Button
      id={"t_refresh_btn"}
      key={"refresh"}
      color={"secondary"}
      style={{ marginRight: 20 }}
      onClick={() => openRefreshGP({ odsCode: organisationCode })}
    >
      update GP details
    </Button>
  ) : null;
};

ProfileForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organisationCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameValid: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  emailValid: PropTypes.bool.isRequired,
  telephone: PropTypes.string.isRequired,
  telephoneValid: PropTypes.bool.isRequired,
  fax: PropTypes.string.isRequired,
  faxValid: PropTypes.bool.isRequired,
  addressLine1: PropTypes.string.isRequired,
  addressLine1Valid: PropTypes.bool.isRequired,
  addressLine2: PropTypes.string.isRequired,
  addressLine2Valid: PropTypes.bool.isRequired,
  city: PropTypes.string.isRequired,
  cityValid: PropTypes.bool.isRequired,
  postcode: PropTypes.string.isRequired,
  postcodeValid: PropTypes.bool.isRequired,
  eps: PropTypes.bool.isRequired,
  againstDigitalPharmacy: PropTypes.bool.isRequired,
  contactPreference: PropTypes.string.isRequired,
  canSave: PropTypes.bool.isRequired,
  notes: PropTypes.string,
  notesValid: PropTypes.bool,
  offerGPRefresh: PropTypes.bool,
  lastUpdated: PropTypes.string,
  openRefreshGP: PropTypes.func.isRequired
};
ProfileForm.defaultProps = {
  notes: "",
  notesValid: true
};

export default ProfileForm;
