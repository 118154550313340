import { combineReducers } from "redux";
import { isPageLoaded, gps, isLoadingGPs, gpCursor } from "./reducers";

export * from "./actions";
export * from "./selectors";
/*
 *
 * export * from "./types"; */

export default combineReducers({
  isPageLoaded,
  gps,
  isLoadingGPs,
  gpCursor
});
