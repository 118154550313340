import React from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import cx from "clsx";
import ChildRouteList from "./child-routes-list";

const RoutesList = props => {
  const { routes, style, perms } = props;

  return routes ? (
    <List id={"t_routes"} component={"nav"} className={style.drawerNav}>
      {routes.map((route, i) => {
        if (route.hideInMenu) return null;

        if (
          route.needsPermission &&
          (perms === undefined || !perms.some(e => route.needsPermission === e))
        ) {
          return null;
        }

        return [
          <ListSubheader
            id={`t_route_${i}`}
            key={"a"}
            className={cx(style.subHeader, "t_route")}
          >
            {route.text}
          </ListSubheader>,
          <ChildRouteList key={"b"} {...props} route={route} />
        ];
      })}
    </List>
  ) : null;
};

export default RoutesList;
