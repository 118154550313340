import printJS from "print-js";
export const PRINT_IMAGE = "PRINT_IMAGE";

/**
 * Prints a pdf or text.
 * You should set either pdfURL or textLines and optinally bottomRightLines.
 */
export const printImage = ({ pdfURL, textLines, bottomRightLines }) => ({
  type: PRINT_IMAGE,
  pdfURL,
  textLines,
  bottomRightLines
});

export default () => next => action => {
  if (action.type !== PRINT_IMAGE) return next(action);

  if (action.pdfURL) {
    printJS({
      printable: action.pdfURL,
      type: "pdf"
    });
  }
  if (action.textLines) {
    const ID = "print-text";
    function cleanUp() {
      const content = document.getElementById(ID);
      if (content) {
        document.body.removeChild(content);
      }
    }
    cleanUp();
    const content = document.createElement("div");
    content.id = ID;
    content.innerHTML = `
      ${action.textLines.map(line => `<p>${line}</p>`).join("\n")}
      <div style="position: absolute; bottom: 0; right: 0;">
        <p style="font-size: 12;">
        ${(action.bottomRightLines || [])
          .map(line => `<span>${line}</span><br />`)
          .join("\n")}
        </p>
      </div>
    `;
    document.body.appendChild(content);
    printJS({
      type: "html",
      printable: "print-text",
      onLoadingEnd: () => cleanUp()
    });
  }
  return next(action);
};
