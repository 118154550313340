import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { openCreateOrder } from "../../redux/create-order";
import PatientDetailsCell from "../common/patient-details-cell";
import GPDetailsCell from "../common/gp-details-cell";
import MedicinesCell from "../common/medicines-cell";
import NotesCell from "../common/notes-cell";
import LatestEventCell from "../common/latest-event-cell";
import StatusCell from "../common/status-cell";
import InfiniteScroll from "../common/infinite-scroll";
import {
  fetchRequests,
  fetchAdditionalRequests,
  selectRequest
} from "../../redux/requests";
import { setRightClickMenuOptions } from "../../redux/context-menu";
import { getDescription } from "../../redux/descriptions";
import {
  getRequestResultsPage,
  getRequests,
  getSelectedRequestId,
  getRequestsNeedsUpdate,
  getRequestsIsLoadingFirstPage,
  getRequestsIsLoadingNextPage,
  getAllRequestsPagesFetched
} from "../../redux/root-reducer";
import s from "./style.module.scss";
import * as gpSelectors from "../../redux/selectors/gp";
import NextPageLoader from "../common/next-page-loader";
import { getFormattedStatus } from "../../redux/selectors/request";
import GPStatusCell from "../common/gp-status-cell";
import DeliveryAddressCell from "../common/delivery-address-cell";
import { openDeliveryAddress } from "../../redux/delivery-address";
import GenericStatusIconCell from "../common/generic-status-icon";

export class Requests extends Component {
  static propTypes = {
    disableDefaultFetch: PropTypes.bool,
    userId: PropTypes.string,
    requests: PropTypes.array.isRequired,

    warningSettings: PropTypes.object,
    showGPStatus: PropTypes.bool,

    selectedId: PropTypes.number,

    isLoadingFirstPage: PropTypes.bool.isRequired,
    isLoadingNextPage: PropTypes.bool.isRequired,
    needsUpdate: PropTypes.bool.isRequired,
    displaySettings: PropTypes.shape({
      showRequestStatus: PropTypes.bool,
      showPatient: PropTypes.bool,
      showGP: PropTypes.bool,
      showLatestEvent: PropTypes.bool,
      showDeliveryAddress: PropTypes.bool
    }),
    fetchRequests: PropTypes.func.isRequired,
    fetchAdditionalRequests: PropTypes.func.isRequired,
    allPagesFetched: PropTypes.bool,
    selectRequest: PropTypes.func.isRequired,
    setRightClickMenuOptions: PropTypes.func.isRequired,
    onViewGPProfile: PropTypes.func.isRequired,
    onViewUserProfile: PropTypes.func.isRequired,

    contentDescription: PropTypes.string.isRequired,
    filters: PropTypes.array,
    onClickFab: PropTypes.func,
    hideNewOrderBtn: PropTypes.bool,
    hideDeliveryAddressEditButton: PropTypes.bool,
    onEditDeliveryAddress: PropTypes.func
  };

  static defaultProps = {
    displaySettings: {
      showRequestStatus: false,
      showGPStatus: false,
      showPatient: true,
      showGP: true,
      showLatestEvent: true,
      showDeliveryAddress: true
    },
    userId: "",
    disableDefaultFetch: false,
    selectedId: null,
    warningSettings: null,
    showGPStatus: false,
    allPagesFetched: false,
    filters: [],
    hideNewOrderBtn: false,
    hideDeliveryAddressEditButton: false,
    onClickFab: () => {},
    onEditDeliveryAddress: () => {}
  };

  componentDidMount() {
    this.props.fetchRequests();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.needsUpdate && nextProps.needsUpdate) {
      this.props.fetchRequests();
    }
  }

  onRowClicked(request, wasSelected) {
    this.props.selectRequest(wasSelected ? null : request);
  }

  handleContextMenu(e, request, isSelected) {
    if (e) e.preventDefault();
    if (!isSelected) this.onRowClicked(request, isSelected);
    this.props.setRightClickMenuOptions({
      isVisible: true,
      x: e.pageX,
      y: e.pageY
    });
  }

  mapGPData = gp => ({
    organisationCode: gpSelectors.getId(gp),
    name: gpSelectors.getName(gp),
    address: gpSelectors.getFullAddress(gp),
    phone: gpSelectors.getTelephone(gp)
  });

  mapGPStatusData = gp => ({
    dontContact: gpSelectors.getIsAgainstDigitalPharmacy(gp),
    hasContactPreference:
      gpSelectors.getContactPreference(gp) !==
      gpSelectors.CONTACT_PREFERENCE_NONE
  });

  renderTableRow(index, request, isSelected) {
    const {
      warningSettings,
      displaySettings,
      onViewUserProfile,
      onViewGPProfile,
      showGPStatus,
      showNominatedStatus,
      hideDeliveryAddressEditButton,
      onEditDeliveryAddress
    } = this.props;
    return (
      <TableRow
        id={`t_row_${index}`}
        key={index}
        className={cx("t_row", s.row)}
        onContextMenu={e => this.handleContextMenu(e, request, isSelected)}
        onClick={() => this.onRowClicked(request, isSelected)}
        selected={isSelected}
      >
        <TableCell className="t_request_id">#{request.id}</TableCell>
        {displaySettings.showRequestStatus ? (
          <TableCell id={`t_status_${index}`}>
            {getFormattedStatus(request)}
          </TableCell>
        ) : null}
        {warningSettings ? (
          <TableCell className={s.statusCell}>
            <StatusCell
              warningSettings={warningSettings}
              lastModifiedDate={request.lastModifiedDate}
            />
          </TableCell>
        ) : null}
        {showGPStatus ? (
          <TableCell className={s.gpStatusCell}>
            <GPStatusCell {...this.mapGPStatusData(request.gp)} />
          </TableCell>
        ) : null}
        {showNominatedStatus ? (
          <TableCell>
            <GenericStatusIconCell
              status={Boolean(request.patient.nominatedPharmacyId)}
            />
          </TableCell>
        ) : null}
        {displaySettings.showPatient ? (
          <TableCell>
            <PatientDetailsCell
              id={`t_patient_details_${index}`}
              {...request.patient}
              onViewButtonClick={() => onViewUserProfile(request)}
            />
          </TableCell>
        ) : null}
        {displaySettings.showDeliveryAddress && (
          <TableCell>
            <DeliveryAddressCell
              id={`t_delivery_address_${index}`}
              onEditButtonClicked={() => onEditDeliveryAddress(request)}
              showEditButton={!hideDeliveryAddressEditButton}
            >
              {request.deliveryAddress || ""}
            </DeliveryAddressCell>
          </TableCell>
        )}
        <TableCell>
          <MedicinesCell medicines={request.medicines} />
        </TableCell>
        <TableCell>
          <NotesCell {...request} />
        </TableCell>

        {displaySettings.showGP ? (
          <TableCell>
            <GPDetailsCell
              id={`t_gp_details_${index}`}
              {...this.mapGPData(request.gp)}
              onClick={onViewGPProfile}
            />
          </TableCell>
        ) : null}
        {displaySettings.showLatestEvent ? (
          <TableCell>
            <LatestEventCell
              id={`t_latest_event_${index}`}
              warningSettings={warningSettings}
              lastEventName={request.lastEventName}
              lastModifiedDate={request.lastModifiedDate}
            />
          </TableCell>
        ) : null}
      </TableRow>
    );
  }

  renderBodyRows(requests, selectedId) {
    const items = requests.map((request, i) => {
      const rowSelected = request.id === selectedId;
      return this.renderTableRow(i, request, rowSelected);
    });

    return (
      <InfiniteScroll
        onReachThreshold={
          !this.props.allPagesFetched
            ? this.props.fetchAdditionalRequests
            : () => {}
        }
        isLoading={
          this.props.isLoadingFirstPage || this.props.isLoadingNextPage
        }
      >
        {items}
      </InfiniteScroll>
    );
  }

  renderNoDataContent = () => (
    <div className={s.noData} id="#t_noData">
      <h2>There are currently no {this.props.contentDescription}.</h2>
    </div>
  );

  renderContent() {
    if (this.props.isLoadingFirstPage) {
      return (
        <div className={s.progress}>
          <CircularProgress id="t_progress" size={50} />
        </div>
      );
    }
    if (this.props.requests.length < 1) {
      return this.renderNoDataContent();
    }
    return this.renderTable();
  }

  renderTable() {
    const {
      displaySettings,
      warningSettings,
      showGPStatus,
      showNominatedStatus,
      requests,
      selectedId,
      isLoadingNextPage
    } = this.props;
    return (
      <div>
        <Table id="t_table" stickyHeader style={{ backgroundColor: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {displaySettings.showRequestStatus ? (
                <TableCell id="t_status_title">Status</TableCell>
              ) : null}
              {warningSettings ? (
                <TableCell className={s.statusCell}>Status</TableCell>
              ) : null}
              {showGPStatus ? (
                <TableCell className={s.statusCell}>GP Status</TableCell>
              ) : null}
              {showNominatedStatus ? (
                <TableCell className={s.statusCell}>Nominated</TableCell>
              ) : null}
              {displaySettings.showPatient ? (
                <TableCell id="t_patient_details_title">Name</TableCell>
              ) : null}
              {displaySettings.showDeliveryAddress ? (
                <TableCell id="t_delivery_address_title">
                  Delivery Address
                </TableCell>
              ) : null}
              <TableCell>Medicines</TableCell>
              <TableCell>Notes &amp; Allergies</TableCell>
              {displaySettings.showGP ? (
                <TableCell id="t_gp_details_title">GP Practice</TableCell>
              ) : null}
              {displaySettings.showLatestEvent ? (
                <TableCell id="t_latest_event_title">Last Event</TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>{this.renderBodyRows(requests, selectedId)}</TableBody>
        </Table>
        {isLoadingNextPage && <NextPageLoader id="t_nextPageLoader" />}
      </div>
    );
  }

  render() {
    return (
      <div className={s.root}>
        {this.renderContent()}
        {this.props.hideNewOrderBtn === false ? (
          <Fab
            className={s.createOrderBtn}
            id="t_fab"
            style={{ right: 24, bottom: 24, position: "fixed" }}
            color="primary"
            onClick={() => this.props.onClickFab()}
          >
            <AddIcon style={{ color: "white" }} />
          </Fab>
        ) : null}
      </div>
    );
  }
}

export const mapDispatchToProps = {
  internalFetchRequests: fetchRequests,
  internalFetchAdditionalRequests: fetchAdditionalRequests,
  selectRequest,
  setRightClickMenuOptions,
  pushRoute: push,
  onClickFab: openCreateOrder,
  openDeliveryAddress: openDeliveryAddress
};

export const mapStateToProps = state => ({
  isLoadingFirstPage: getRequestsIsLoadingFirstPage(state),
  isLoadingNextPage: getRequestsIsLoadingNextPage(state),
  requests: getRequests(state),
  selectedId: getSelectedRequestId(state),
  needsUpdate: getRequestsNeedsUpdate(state),
  contentDescription: getDescription(state),
  currentPage: getRequestResultsPage(state),
  allPagesFetched: getAllRequestsPagesFetched(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onViewUserProfile: req =>
    dispatchProps.pushRoute(`/user/${req.patient.userId}`),
  onViewGPProfile: id => {
    dispatchProps.pushRoute(`/gp/profile/${id}`);
  },
  fetchRequests: () =>
    ownProps.disableDefaultFetch
      ? () => {}
      : dispatchProps.internalFetchRequests(ownProps.filters, ownProps.userId),
  fetchAdditionalRequests: () =>
    dispatchProps.internalFetchAdditionalRequests(
      stateProps.currentPage,
      ownProps.filters,
      ownProps.userId
    ),
  onEditDeliveryAddress: request =>
    dispatchProps.openDeliveryAddress({
      address: request.deliveryAddress,
      requestId: request.id
    }),
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Requests)
);
