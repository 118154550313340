import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Autosuggest from "react-autosuggest";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash.debounce";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    marginTop: theme.spacing(1),
    left: 0,
    top: 0,
    background: "white",
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionHighlighted: {
    display: "block",
    backgroundColor: theme.palette.grey[200]
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing(2)
  },
  popperRoot: {
    zIndex: 1500
  },
  popperContent: {
    overflowY: "auto",
    maxHeight: 300
  }
});

const AutoCompleteComponent = ({
  id = "",
  text = "",
  onTextChange,
  inputChangeDebouncePeriod = 500,
  onSuggestionSelect,
  onOuterClick,
  inputVariant = "",
  InputProps = {},
  inputProps = {},
  classes,
  placement = "bottom",
  placeholder = "",
  suggestions = []
}) => {
  const inputRef = useRef({});
  const [state, setState] = useState({ value: text });

  const onTextChangeDebounced = useCallback(
    debounce(onTextChange, inputChangeDebouncePeriod, {
      leading: true,
      trailing: true
    }),
    []
  );

  const onChange = (event, { newValue }) => setState({ value: newValue });
  const onSuggestionSelected = (event, { suggestionIndex }) =>
    onSuggestionSelect(suggestionIndex);
  const onSuggestionsFetchRequested = ({ value }) =>
    onTextChangeDebounced(value);
  const onSuggestionsClearRequested = () => onOuterClick();
  const renderInputComponent = renderProps => (
    <TextField
      variant={inputVariant}
      fullWidth
      InputProps={InputProps}
      inputProps={inputProps}
      inputRef={inputRef}
      {...renderProps}
    />
  );
  const renderSuggestionsContainer = options => {
    const inputRef2 = inputRef.current;
    const popperWidth = inputRef2 ? inputRef2.clientWidth : undefined;

    return (
      <Popper
        className={classes.popperRoot}
        placement={placement}
        anchorEl={inputRef2}
        open={!!options.children}
      >
        <Paper
          {...options.containerProps}
          className={classes.popperContent}
          style={{ width: popperWidth }}
        >
          {options.children}
        </Paper>
      </Popper>
    );
  };
  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <ListItem
        classes={{
          root: isHighlighted ? classes.suggestionHighlighted : undefined
        }}
        button
      >
        <ListItemText
          primary={suggestion.name}
          secondary={suggestion.description}
        />
      </ListItem>
    );
  };
  const { value } = state;
  return (
    <div className={classes.root}>
      <Autosuggest
        id={id}
        suggestions={suggestions}
        inputProps={{
          placeholder,
          value,
          onChange: onChange
        }}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={suggestion => suggestion.name}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={renderSuggestion}
      />
    </div>
  );
};

const suggestionType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
});

AutoCompleteComponent.propTypes = {
  id: PropTypes.string,
  placement: PropTypes.string,
  placeholder: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onSuggestionSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(suggestionType),
  classes: PropTypes.object.isRequired,
  onOuterClick: PropTypes.func,
  text: PropTypes.string,
  inputChangeDebouncePeriod: PropTypes.number,
  InputProps: PropTypes.object, // Passed to the underlying MaterialUI Input component
  inputProps: PropTypes.object, // Passed to the underlying HTML5 input component
  inputVariant: PropTypes.string
};

AutoCompleteComponent.defaultProps = {
  id: "",
  placement: "bottom",
  placeholder: "",
  suggestions: [],
  onFocus: () => undefined,
  onOuterClick: () => undefined,
  text: "",
  InputProps: {},
  inputProps: {},
  inputVariant: "standard",
  inputChangeDebouncePeriod: 500
};

export default withStyles(styles)(AutoCompleteComponent);
