import { RSAA } from "redux-api-middleware";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import * as userSel from "../selectors/user";

const USERS_API_ENDPOINT = process.env.REACT_APP_PHARMACY_USERS_API;

export const FETCH_PENDING_USERS_REQUEST = "FETCH_PENDING_USERS_REQUEST";
export const FETCH_PENDING_USERS_RECEIVE = "FETCH_PENDING_USERS_RECEIVE";
export const FETCH_PENDING_USERS_FAILURE = "FETCH_PENDING_USERS_FAILURE";
export const NOMINATE_USER_REQUEST = "NOMINATE_USER_REQUEST";
export const NOMINATE_USER_RECEIVE = "NOMINATE_USER_RECEIVE";
export const NOMINATE_USER_FAILURE = "NOMINATE_USER_FAILURE";
export const NOMINATE_USER_DETAILS_UPDATE_REQUEST =
  "NOMINATE_USER_DETAILS_UPDATE_REQUEST";
export const NOMINATE_USER_DETAILS_UPDATE_RECEIVE =
  "NOMINATE_USER_DETAILS_UPDATE_RECEIVE";
export const NOMINATE_USER_DETAILS_UPDATE_FAILURE =
  "NOMINATE_USER_DETAILS_UPDATE_FAILURE";
export const SELECT_PENDING_USER = "SELECT_PENDING_USER";
export const CHANGE_PENDING_USER_GROUP = "CHANGE_PENDING_USER_GROUP";

// ACTIONS
export const fetchUsersPendingNomination = ({
  page = 0,
  reverse = false,
  apiEndpoint = USERS_API_ENDPOINT
}) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/users/pendingnomination?page=${page}&reverse=${reverse}`,
    method: "GET",
    types: [
      FETCH_PENDING_USERS_REQUEST,
      FETCH_PENDING_USERS_RECEIVE,
      FETCH_PENDING_USERS_FAILURE
    ].map(type => ({ type, meta: { page } })),
    options: { addAuth: true }
  }
});

export const requestUpdatedDetails = (
  userId,
  apiEndpoint = USERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/user/${userId}/cannotnominate`,
    method: "POST",
    types: [
      NOMINATE_USER_DETAILS_UPDATE_REQUEST,
      NOMINATE_USER_DETAILS_UPDATE_RECEIVE,
      NOMINATE_USER_DETAILS_UPDATE_FAILURE
    ].map(type => ({ type, meta: { userId } })),
    options: { addAuth: true }
  }
});

export const nominateUser = (userId, apiEndpoint = USERS_API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/user/${userId}/nominate`,
    method: "POST",
    types: [
      NOMINATE_USER_REQUEST,
      NOMINATE_USER_RECEIVE,
      NOMINATE_USER_FAILURE
    ].map(type => ({ type, meta: { userId } })),
    options: { addAuth: true }
  }
});

export const selectUser = (index = null, users = []) => ({
  type: SELECT_PENDING_USER,
  index,
  users
});

export const changeTab = i => ({
  type: CHANGE_PENDING_USER_GROUP,
  payload: i
});

// SELECTORS
export const getUsers = state => state.allIds.map(id => state.byId[id]);
export const getIsFetchingFirstPage = state => state.fetchingPage === 0;
export const getIsFetchingNextPage = state => state.fetchingPage > 0;
export const getError = state => state.error;
export const getAllPagesFetched = state => state.allPagesFetched;
export const getNextPageNumber = state => state.nextPageNumber;
export const getNeedsRefresh = state => state.needsRefresh;
export const getSelectedUserId = state => state.selectedId;
export const getSelectedTabIndex = state => state.selectedTabIndex;

// REDUCERS
const byId = handleActions(
  {
    [FETCH_PENDING_USERS_RECEIVE]: (state, action) =>
      action.payload.reduce(
        (acc, user) => Object.assign(acc, { [userSel.getId(user)]: user }),
        action.meta.page === 0 ? {} : { ...state }
      )
  },
  {}
);

const allIds = handleActions(
  {
    [FETCH_PENDING_USERS_RECEIVE]: (state, action) => {
      const newUsers = action.payload.map(userSel.getId);
      if (action.meta.page > 0) {
        return state.concat(newUsers);
      }
      return newUsers;
    },
    [NOMINATE_USER_RECEIVE]: (state, action) =>
      state.filter(id => id !== action.meta.userId)
  },
  []
);

const fetchingPage = handleActions(
  {
    [FETCH_PENDING_USERS_REQUEST]: (state, action) => action.meta.page,
    [FETCH_PENDING_USERS_RECEIVE]: () => null,
    [FETCH_PENDING_USERS_FAILURE]: () => null
  },
  null
);

const error = handleActions(
  {
    [FETCH_PENDING_USERS_REQUEST]: () => null,
    [FETCH_PENDING_USERS_RECEIVE]: () => null,
    [FETCH_PENDING_USERS_FAILURE]: (state, action) => action.payload
  },
  null
);

const needsRefresh = handleActions(
  {
    [FETCH_PENDING_USERS_RECEIVE]: () => false,
    [CHANGE_PENDING_USER_GROUP]: () => true
  },
  true
);

const nextPageNumber = handleActions(
  {
    [FETCH_PENDING_USERS_RECEIVE]: (state, action) => action.meta.page + 1
  },
  0
);

const allPagesFetched = handleActions(
  {
    [FETCH_PENDING_USERS_RECEIVE]: (state, action) => action.payload.length < 10
  },
  false
);

const selectedId = handleActions(
  {
    [FETCH_PENDING_USERS_REQUEST]: () => null,
    [SELECT_PENDING_USER]: (state, action) =>
      state === (action.index !== null ? action.users[action.index].id : null)
        ? null
        : action.users[action.index].id
  },
  null
);

const selectedTabIndex = handleActions(
  {
    [CHANGE_PENDING_USER_GROUP]: (state, action) => action.payload
  },
  0
);

export default combineReducers({
  byId,
  allIds,
  fetchingPage,
  error,
  needsRefresh,
  nextPageNumber,
  allPagesFetched,
  selectedId,
  selectedTabIndex
});
