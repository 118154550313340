import { RSAA } from "redux-api-middleware";
import { printImage } from "../../middlewares/print";
import { NEXT_ACTION } from "../../middlewares/next";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

// ACTIONS
export const PRINT_DISPATCH_LABEL = "PRINT_DISPATCH_LABEL";
export const FETCH_DISPATCH_LABEL_REQUEST = "FETCH_DISPATCH_LABEL_REQUEST";
export const FETCH_DISPATCH_LABEL_RECEIVE = "FETCH_DISPATCH_LABEL_RECEIVE";
export const FETCH_DISPATCH_LABEL_FAILURE = "FETCH_DISPATCH_LABEL_FAILURE";

// ACTION CREATORS
export const printDispatchLabel = pdfURL =>
  printImage({
    pdfURL,
    pageMargin: "0.25in 0.3in 0.25in 0.25in",
    pageSize: "4in 6in",
    contentHeight: "5.6in",
    contentWidth: "3.6in"
  });

export const fetchAndPrintDispatchLabel = (
  orderId,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${orderId}/dispatchlabel`,
    method: "GET",
    types: [
      FETCH_DISPATCH_LABEL_REQUEST,
      {
        type: "TRIGGER_PRINT",
        payload: (action, state, res) =>
          res.json().then(json => ({
            [NEXT_ACTION]: printDispatchLabel(json.labelUrl)
          }))
      },
      FETCH_DISPATCH_LABEL_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const printRawDeliveryAddress = ({ name, address }) =>
  printImage({
    textLines: [name, ...splitAddress(address)],
    bottomRightLines: [
      "DO NOT LEAVE IN A SAFE PLACE",
      "Return address:",
      "Healthcare Service Centre",
      "Meir Park",
      "Enterprise Way",
      "Stoke-on-Trent",
      "ST3 7UN"
    ],
    pageMargin: "0.25in 0.3in 0.25in 0.25in",
    pageSize: "4in 6in",
    contentHeight: "5.6in",
    contentWidth: "3.6in"
  });

const splitAddress = (addr = "") => addr.replace("\n\n", "\n").split("\n");
