import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { apiMiddleware, RSAA } from "redux-api-middleware";
import { persistStore } from "redux-persist";
import { createBrowserHistory } from "history";
import rootReducer from "./root-reducer";
import authMiddleware from "./middlewares/auth";
import apiErrorMiddleware from "./middlewares/auth/apiError";
import printMiddleware from "./middlewares/print";
import errorNotificationMiddleware from "./middlewares/error-notification";
import nextActionMiddleware from "./middlewares/next";
import thunkMiddleware from "redux-thunk";

export const history = createBrowserHistory();

// Helper function to determine if the endpoint is abortable
const isAbortableEndpoint = url => {
  try {
    const { origin, pathname } = new URL(url);
    return pathname.endsWith("/requests") || pathname.endsWith("/orders")
      ? `${origin}${pathname}`
      : null;
  } catch {
    return null; // Ignore invalid URLs
  }
};

const controllers = {};

const abortPreviousRequestMiddleware = () => next => action => {
  if (action[RSAA]) {
    const baseEndpoint = isAbortableEndpoint(action[RSAA].endpoint);

    if (baseEndpoint) {
      // Abort the previous request if it exists
      const existingController = controllers[baseEndpoint];
      if (existingController) {
        existingController.abort();
      }

      // Create a new AbortController for the new request
      const controller = new AbortController();
      controllers[baseEndpoint] = controller;

      // Override the fetch method to include the abort signal
      action[RSAA].fetch = (url, options) => {
        return fetch(url, { ...options, signal: controller.signal }).finally(
          () => {
            // Cleanup the controller after the request completes
            delete controllers[baseEndpoint];
          }
        );
      };
    }
  }

  return next(action); // Pass action to the next middleware/reducer
};

const initialState = {};
const enhancers = [];
const middleware = [
  authMiddleware,
  abortPreviousRequestMiddleware,
  apiMiddleware,
  apiErrorMiddleware,
  errorNotificationMiddleware,
  printMiddleware,
  nextActionMiddleware,
  thunkMiddleware,
  routerMiddleware(history)
];

// if (process.env.NODE_ENV === 'development') {
// Temporary enable dev tools in prod.
const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

if (typeof __REDUX_DEVTOOLS_EXTENSION__ === "function") {
  enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
}
// }

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);

export const persistor = persistStore(store);

export default store;
