import { combineReducers } from "redux";
import { autoComplete, searchText } from "./meds-search.reducers";

export * from "./meds-search.actions";
export * from "./meds-search.selectors";
export * from "./meds-search.types";
export default combineReducers({
  autoComplete,
  searchText
});
