import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const MEDICINE_API = process.env.REACT_APP_MEDICINES_API;
const ORDERS_API = process.env.REACT_APP_ORDERS_API;
const USERS_API = process.env.REACT_APP_PHARMACY_USERS_API;

// ACTIONS
export const OPEN_CREATE_ORDER = "OPEN_CREATE_ORDER";
export const CLOSE_CREATE_ORDER = "CLOSE_CREATE_ORDER";
export const INCREMENT_COST = "INCREMENT_COST";
export const DECREMENT_COST = "DECREMENT_COST";
export const DELETE_MEDICINE = "DELETE_MEDICINE";
export const ADD_MEDICINE_FIELD = "ADD_MEDICINE_FIELD";
export const REMOVE_MEDICINE_FIELD = "REMOVE_MEDICINE_FIELD";
export const SELECT_MEDICINE = "SELECT_MEDICINE";
export const MARK_CONTRACEPTIVE = "MARK_CONTRACEPTIVE";
export const CREATE_ORDER = "CREATE_ORDER";
export const FETCH_MEDICINES_REQUEST = "FETCH_MEDICINES_REQUEST";
export const FETCH_MEDICINES_RECEIVE = "FETCH_MEDICINES_RECEIVE";
export const FETCH_MEDICINES_FAILURE = "FETCH_MEDICINES_FAILURE";
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_RECEIVE = "CREATE_ORDER_RECEIVE";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
export const FETCH_PATIENTS_REQUEST = "FETCH_PATIENTS_REQUEST";
export const FETCH_PATIENTS_RECEIVE = "FETCH_PATIENTS_RECEIVE";
export const FETCH_PATIENTS_FAILURE = "FETCH_PATIENTS_FAILURE";
export const CLEAR_PATIENTS_SUGGESTIONS = "CLEAR_PATIENTS_SUGGESTIONS";
export const SELECT_PATIENT = "SELECT_PATIENT";
export const CLEAR_PATIENT = "CLEAR_PATIENT";
export const RX_TOKEN_TEXT_CHANGE = "RX_TOKEN_TEXT_CHANGE";
// ACTION CREATORS
export const openCreateOrder = ({
  medicines,
  patient,
  requestId,
  clickAndCollect
} = {}) => ({
  type: OPEN_CREATE_ORDER,
  medicines: medicines || [],
  patient: patient || null,
  requestId: requestId || null,
  clickAndCollect: clickAndCollect || false
});
export const onRxTokenTextChange = rxToken => ({
  type: RX_TOKEN_TEXT_CHANGE,
  rxToken
});
export const closeCreateOrder = () => ({
  type: CLOSE_CREATE_ORDER
});

export const incrementCost = index => ({
  type: INCREMENT_COST,
  index
});

export const decrementCost = index => ({
  type: DECREMENT_COST,
  index
});

export const addMedicineField = () => ({
  type: ADD_MEDICINE_FIELD
});

export const removeMedicineField = () => ({
  type: REMOVE_MEDICINE_FIELD
});

export const deleteMedicine = index => ({
  type: DELETE_MEDICINE,
  index
});

export const changeMedicineSearch = (
  text = "",
  uniqueId = Date.now(),
  apiEndpoint = MEDICINE_API
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/search/${encodeURIComponent(text.trim())}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      FETCH_MEDICINES_REQUEST,
      FETCH_MEDICINES_RECEIVE,
      FETCH_MEDICINES_FAILURE
    ].map(type => ({ type, meta: { uniqueId, text } })),
    options: { addAuth: true }
  }
});

export const selectMedSuggestion = medicine => ({
  type: SELECT_MEDICINE,
  medicine
});

export const markContraceptive = (index, isContraceptive) => ({
  type: MARK_CONTRACEPTIVE,
  index,
  isContraceptive
});

export const createOrderFromRequest = (
  requestId,
  medicines,
  rxToken,
  apiEndpoint = ORDERS_API
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/request/${requestId}/convert`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      medicines: medicines.map(m => ({
        vmpId: m.vmpId,
        ampId: m.ampId,
        isContraceptive: m.isContraceptive,
        costUnits: m.costUnits
      })),
      rxToken
    }),
    types: [CREATE_ORDER_REQUEST, CREATE_ORDER_RECEIVE, CREATE_ORDER_FAILURE],
    options: { addAuth: true }
  }
});

export const createOrderForPatient = (
  patientId,
  medicines,
  apiEndpoint = ORDERS_API
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/orders`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      patientId,
      pharmacyId: "digital",
      medicines: medicines.map(m => ({
        vmpId: m.vmpId,
        ampId: m.ampId,
        isContraceptive: m.isContraceptive,
        costUnits: m.costUnits
      }))
    }),
    types: [CREATE_ORDER_REQUEST, CREATE_ORDER_RECEIVE, CREATE_ORDER_FAILURE],
    options: { addAuth: true }
  }
});

export const createOrder = (
  requestId,
  patientId,
  medicines,
  rxToken = null,
  apiEndpoint = ORDERS_API
) => {
  if (requestId != null)
    return createOrderFromRequest(requestId, medicines, rxToken, apiEndpoint);
  return createOrderForPatient(patientId, medicines, apiEndpoint);
};

export const changePatientSearch = (
  text,
  uniqueId = Date.now(),
  apiEndpoint = USERS_API
) => {
  if (text.length < 3)
    return { type: FETCH_PATIENTS_REQUEST, meta: { uniqueId, text } };
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name: text }),
      types: [
        FETCH_PATIENTS_REQUEST,
        FETCH_PATIENTS_RECEIVE,
        FETCH_PATIENTS_FAILURE
      ].map(type => ({ type, meta: { uniqueId, text } })),
      options: { addAuth: true }
    }
  };
};

export const selectPatientSuggestion = patient => ({
  type: SELECT_PATIENT,
  patient
});

export const clearSelectedPatient = () => ({ type: CLEAR_PATIENT });

export const clearPatientsSuggestions = () => ({
  type: CLEAR_PATIENTS_SUGGESTIONS
});

// SELECTORS
export const getRequestId = state => state.requestId;
export const getClickAndCollect = state => state.clickAndCollect;
export const getIsOpen = state => state.isOpen;
export const getIsEditingMedicine = state => state.isEditingMedicine;
export const getPatient = state => state.patient;
export const getMedicines = state => state.medicines;
export const getCanCreateOrder = state => {
  if (state.requestId && state.clickAndCollect) {
    return (
      state.rxToken != "" &&
      state.patient != null &&
      state.medicines.length > 0 &&
      !state.isCreatingOrder
    );
  } else {
    return (
      state.patient != null &&
      state.medicines.length > 0 &&
      !state.isCreatingOrder
    );
  }
};

export const getCanAddMedicine = state =>
  !state.isEditingMedicine && !state.isCreatingOrder;
export const getIsCreatingOrder = state => state.isCreatingOrder;
export const getCanUpdateMedicines = state => !state.isCreatingOrder;
export const getPatientsSuggestions = state =>
  state.patientsAutocomplete.suggestions;
export const getPatientsSearchText = state => state.patientsSearchText;
export const getCanChangePatient = state =>
  state.requestId === null && state.patient != null;
export const getRxToken = state => state.rxToken;
// REDUCERS
const isOpen = handleActions(
  {
    [OPEN_CREATE_ORDER]: () => true,
    [CLOSE_CREATE_ORDER]: () => false,
    [CREATE_ORDER_RECEIVE]: () => false
  },
  false
);

const rxToken = handleActions(
  {
    [RX_TOKEN_TEXT_CHANGE]: (state, action) => action.rxToken,
    [CREATE_ORDER_RECEIVE]: () => "",
    [CREATE_ORDER_FAILURE]: () => ""
  },
  ""
);
const requestId = handleActions(
  {
    [OPEN_CREATE_ORDER]: (state, action) => action.requestId
  },
  null
);

const clickAndCollect = handleActions(
  {
    [OPEN_CREATE_ORDER]: (state, action) => action.clickAndCollect
  },
  false
);

const isEditingMedicine = handleActions(
  {
    [ADD_MEDICINE_FIELD]: () => true,
    [SELECT_MEDICINE]: () => false,
    [OPEN_CREATE_ORDER]: () => false,
    [REMOVE_MEDICINE_FIELD]: () => false
  },
  false
);

const patient = handleActions(
  {
    [OPEN_CREATE_ORDER]: (state, action) => action.patient,
    [SELECT_PATIENT]: (state, action) => action.patient,
    [CLEAR_PATIENT]: () => null
  },
  null
);

export const normalizeMedicine = med => ({
  vmpId: med.vmpId,
  ampId: (med.packages || []).length ? med.packages[0].ampId : undefined,
  drugName: med.drugName,
  brandName: med.brandName,
  drugDescription: med.prodDesc,
  brandDescription: med.brandDesc,
  isContraceptive: (med.additional && med.additional.contraceptive) || false,
  costUnits: (med.additional && med.additional.costUnits) || 1,
  form: med.form
});

export const normalizeDmdResponse = data => data.map(normalizeMedicine);

const medicines = handleActions(
  {
    [OPEN_CREATE_ORDER]: (state, action) =>
      action.medicines.map(m => ({
        ...normalizeMedicine(m)
      })),
    [INCREMENT_COST]: (state, { index }) => {
      if (!state[index]) return state;
      const newState = [...state];
      newState[index].costUnits = Math.min(8, newState[index].costUnits + 1);
      return newState;
    },
    [DECREMENT_COST]: (state, { index }) => {
      if (!state[index]) return state;
      const newState = [...state];
      newState[index].costUnits = Math.max(0, newState[index].costUnits - 1);
      return newState;
    },
    [SELECT_MEDICINE]: (state, { medicine }) => [...state, { ...medicine }],
    [DELETE_MEDICINE]: (state, { index }) => [
      ...state.slice(0, Math.max(0, index)),
      ...state.slice(index + 1, state.length)
    ],
    [MARK_CONTRACEPTIVE]: (state, { index, isContraceptive }) => {
      if (!state[index]) return state;
      const newState = [...state];
      newState[index].isContraceptive = isContraceptive;
      return newState;
    }
  },
  []
);

const patientsAutocomplete = handleActions(
  {
    [OPEN_CREATE_ORDER]: () => ({ reqId: null, suggestions: [] }),
    [CLEAR_PATIENTS_SUGGESTIONS]: () => ({ reqId: null, suggestions: [] }),
    [SELECT_PATIENT]: () => ({ reqId: null, suggestions: [] }),
    [FETCH_PATIENTS_REQUEST]: (state, action) => ({
      ...state,
      reqId: action.meta.uniqueId
    }),
    [FETCH_PATIENTS_RECEIVE]: (state, action) =>
      action.meta.uniqueId === state.reqId
        ? {
            suggestions: action.payload.map(p => ({
              ...p,
              address: `${p.addressLine1}, ${p.postcode}`
            })),
            reqId: null
          }
        : { ...state },
    [FETCH_PATIENTS_FAILURE]: (state, action) =>
      action.meta.uniqueId === state.reqId
        ? { ...state, reqId: null }
        : { ...state }
  },
  { reqId: null, suggestions: [] }
);

const patientsSearchText = handleActions(
  {
    [CLEAR_PATIENTS_SUGGESTIONS]: () => "",
    [SELECT_PATIENT]: () => "",
    [OPEN_CREATE_ORDER]: () => "",
    [FETCH_PATIENTS_REQUEST]: (state, action) => action.meta.text || ""
  },
  ""
);

const isCreatingOrder = handleActions(
  {
    [CREATE_ORDER_REQUEST]: () => true,
    [CREATE_ORDER_RECEIVE]: () => false,
    [CREATE_ORDER_FAILURE]: () => false
  },
  false
);

// REDUCERS
export default combineReducers({
  isOpen,
  requestId,
  isEditingMedicine,
  medicines,
  patient,
  isCreatingOrder,
  patientsAutocomplete,
  patientsSearchText,
  rxToken,
  clickAndCollect
});
