import React, { FC } from "react";
import {
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { GP } from "../../redux/gp-nhs-refresh";
import s from "./gpcreate.module.scss";

export interface PreviewProps {
  gp: GP;
  completeCreateGP: (ods: string) => void;
  restart: () => void;
}

const valueOrNBSP = (value: JSX.Element[] | string): JSX.Element[] | string =>
  value !== "" ? value : "\u00A0";

export const Preview: FC<PreviewProps> = ({
  gp,
  completeCreateGP,
  restart
}) => {
  const confirm = () => {
    completeCreateGP(gp.id);
  };
  return (
    <Dialog open maxWidth={"sm"} fullWidth>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <h4 className={s.previewTitle}>GP details</h4>
          </Grid>
          <Grid item xs={6}>
            <Box display={"inline"} color={"text.secondary"}>
              GP Name
            </Box>
            <div className={s.previewDetail}>{valueOrNBSP(gp.name)}</div>
            <Box display={"inline"} color={"text.secondary"}>
              Phone number
            </Box>
            <div className={s.previewDetail}>{valueOrNBSP(gp.telephone)}</div>
            <Box display={"inline"} color={"text.secondary"}>
              Fax number
            </Box>
            <div className={s.previewDetail}>{valueOrNBSP(gp.fax)}</div>
          </Grid>
          <Grid item xs={6}>
            <Box display={"inline"} color={"text.secondary"}>
              Address
            </Box>
            <div className={s.previewDetail}>
              <div>{gp.addressLine1}</div>
              <div>{gp.addressLine2}</div>
              <div>{gp.city}</div>
              <div>{gp.postcode}</div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id={"t_gp_preview_cancel"} onClick={restart}>
          Cancel
        </Button>
        <Button id={"t_gp_preview_confirm"} onClick={confirm}>
          Create GP
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Preview;
