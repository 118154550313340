import { connect } from "react-redux";
import {
  addMCSItem,
  dmdAmpSearch,
  getMCSItems,
  removeMCSItem
} from "../../redux/mcs-medicine-list";
import {
  getMCSMedicineListAddingMCS,
  getMCSMedicineListGettingMCS,
  getMCSMedicineListReload,
  getMCSMedicineListRemovingMCS,
  getMCSMedicineListMedsByAmp,
  getMCSMedicineListAmpIds,
  getMCSMedicineListDMDAmpResult,
  getMCSMedicineListSearchingDMD
} from "../../redux/root-reducer";
import MCSMedList from "./Screen";

const mapStateToProps = state => ({
  medsByAmp: getMCSMedicineListMedsByAmp(state),
  ampIds: getMCSMedicineListAmpIds(state),
  adding: getMCSMedicineListAddingMCS(state),
  removing: getMCSMedicineListRemovingMCS(state),
  fetching: getMCSMedicineListGettingMCS(state),
  reload: getMCSMedicineListReload(state),
  medResult: getMCSMedicineListDMDAmpResult(state),
  searching: getMCSMedicineListSearchingDMD(state)
});

const mapDispatchToProps = {
  onReload: getMCSItems,
  onAddItem: addMCSItem,
  onRemoveItem: removeMCSItem,
  onSearch: dmdAmpSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(MCSMedList);
