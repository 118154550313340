import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    color: theme.palette.error.main
  },
  disabled: {
    color: theme.palette.action.disabled
  }
});

export default withStyles(styles)(Button);
