import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import {
  ACCESS_START_REQUEST,
  ACCESS_START_RECEIVE,
  ACCESS_START_FAILURE,
  ACCESS_COMPLETE_REQUEST,
  ACCESS_COMPLETE_FAILURE,
  BYPASS_AUTH_RECEIVE,
  BYPASS_AUTH_REQUEST,
  BYPASS_AUTH_FAILURE
} from "../auth";
import { validateAsEmail } from "../../helpers/validators";

const BYPASS_ENABLED = process.env.REACT_APP_BYPASS_AUTH_ENABLED === "1";

// ACTIONS
export const SET_LOGIN_EMAIL = "SET_LOGIN_EMAIL";
export const LOGIN_RESET = "LOGIN_RESET";
export const SHOW_VALIDATION = "SHOW_VALIDATION";

// ACTION CREATORS
export const setLoginEmail = email => ({
  type: SET_LOGIN_EMAIL,
  email
});

export const resetLogin = () => ({ type: LOGIN_RESET });
export const showValidation = () => ({ type: SHOW_VALIDATION });

// STATE SELECTORS
export const getEmail = state => state.email;
export const getIsEmailValid = state => state.isEmailValid;
export const getIsShowingValidation = state => state.isShowingValidation;
export const getIsSendingEmail = state => state.isSendingEmail;
export const getIsWaitingCompletion = state => state.isWaitingCompletion;
export const getIsCompleting = state => state.isCompleting;
export const getError = state => state.error;
export const getIsBypassEnabled = state => state.isBypassEnabled;

// REDUCERS
export const email = handleActions(
  {
    [SET_LOGIN_EMAIL]: (state, action) => action.email,
    [LOGIN_RESET]: () => ""
  },
  ""
);

export const isEmailValid = handleActions(
  {
    [SET_LOGIN_EMAIL]: (state, action) => validateAsEmail(action.email),
    [LOGIN_RESET]: () => false
  },
  false
);

export const isSendingEmail = handleActions(
  {
    [ACCESS_START_REQUEST]: () => true,
    [ACCESS_START_RECEIVE]: () => false,
    [ACCESS_START_FAILURE]: () => false,
    [BYPASS_AUTH_REQUEST]: () => true,
    [BYPASS_AUTH_RECEIVE]: () => false,
    [BYPASS_AUTH_FAILURE]: () => false
  },
  false
);

export const isWaitingCompletion = handleActions(
  {
    [ACCESS_START_RECEIVE]: () => true,
    [LOGIN_RESET]: () => false
  },
  false
);

export const isCompleting = handleActions(
  {
    [ACCESS_COMPLETE_REQUEST]: () => true,
    [ACCESS_COMPLETE_FAILURE]: () => false,
    [LOGIN_RESET]: () => false
  },
  false
);

export const isShowingValidation = handleActions(
  {
    [SHOW_VALIDATION]: () => true,
    [SET_LOGIN_EMAIL]: () => false,
    [LOGIN_RESET]: () => false
  },
  false
);

const getErrorMessage = ({ response: { error = {} } = {}, message } = {}) =>
  error.message || message || "Something went wrong";
export const error = handleActions(
  {
    [ACCESS_START_REQUEST]: () => null,
    [ACCESS_START_FAILURE]: (state, action) => getErrorMessage(action.payload),
    [ACCESS_COMPLETE_REQUEST]: () => null,
    [ACCESS_COMPLETE_FAILURE]: (state, action) =>
      getErrorMessage(action.payload)
  },
  null
);

export default combineReducers({
  email,
  isEmailValid,
  isSendingEmail,
  isWaitingCompletion,
  isCompleting,
  isShowingValidation,
  error,
  isBypassEnabled: () => BYPASS_ENABLED
});
