import { handleActions } from "redux-actions";
import { AnyAction, Action } from "redux";
import {
  CREATE_GP_START,
  CREATE_GP_ODS_UPDATE,
  GP_EXISTS_RECIEVE,
  GP_EXISTS_FAILURE,
  CREATE_GP_FETCH_NHS_RECIEVE,
  CREATE_GP_FETCH_NHS_FAILURE,
  createGPODSUpdate
} from "./actions";
import { isGPNotFound, isNonGP, ResponseGPAction } from "./types";
import { ResponseGP } from "../gp-nhs-refresh/types";

export const gpAlreadyExists = handleActions(
  {
    [CREATE_GP_START]: (): boolean => false,
    [GP_EXISTS_RECIEVE]: (): boolean => true,
    [GP_EXISTS_FAILURE]: (): boolean => false,
    [CREATE_GP_ODS_UPDATE]: (): boolean => false
  },
  false
);
export const gpChecked = handleActions(
  {
    [CREATE_GP_START]: (): boolean => false,
    // in this case it has been checked, but found lacking:
    [GP_EXISTS_RECIEVE]: (): boolean => false,
    [CREATE_GP_ODS_UPDATE]: (): boolean => false,
    [GP_EXISTS_FAILURE]: (_state: boolean, action: AnyAction): boolean => {
      if (!action.payload) {
        return false;
      }

      // "not found" is actually the positive outcome here, because we can create it.
      if (isGPNotFound(action.payload) && action.payload.gpNotFound) {
        return true;
      }

      return false;
    }
  },
  false
);

export const unknownNHSODS = handleActions(
  {
    [CREATE_GP_START]: (): boolean => false,
    [CREATE_GP_ODS_UPDATE]: (): boolean => false,
    [CREATE_GP_FETCH_NHS_RECIEVE]: (): boolean => false,
    [CREATE_GP_FETCH_NHS_FAILURE]: (
      _state: boolean,
      action: AnyAction
    ): boolean => {
      if (!action.payload) {
        return false;
      }

      if (isGPNotFound(action.payload) && action.payload.gpNotFound) {
        return true;
      }

      return false;
    }
  },
  false
);

export const notGP = handleActions(
  {
    [CREATE_GP_START]: (): boolean => false,
    [CREATE_GP_ODS_UPDATE]: (): boolean => false,
    [CREATE_GP_FETCH_NHS_RECIEVE]: (): boolean => false,
    [CREATE_GP_FETCH_NHS_FAILURE]: (
      _state: boolean,
      action: AnyAction
    ): boolean => {
      if (!action.payload) {
        return false;
      }

      if (isNonGP(action.payload) && action.payload.notGP) {
        return true;
      }
      return false;
    }
  },
  false
);

export const ods = handleActions(
  {
    [CREATE_GP_START]: (): string => "",
    [CREATE_GP_ODS_UPDATE]: (state: string, action: Action): string => {
      if (createGPODSUpdate.match(action)) {
        return action.payload;
      }
      return state;
    }
  },
  ""
);

export const gpPreview = handleActions(
  {
    [CREATE_GP_START]: (
      _state: ResponseGP | null,
      action: Action
    ): ResponseGP | null => null,
    [CREATE_GP_ODS_UPDATE]: (): ResponseGP | null => null,
    [CREATE_GP_FETCH_NHS_RECIEVE]: (
      _state: ResponseGP | null,
      action: ResponseGPAction
    ): ResponseGP => action.payload.practice
  },
  null
);
