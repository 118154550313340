import { combineReducers } from "redux";
import {
  ods,
  gpAlreadyExists,
  gpChecked,
  unknownNHSODS,
  notGP,
  gpPreview
} from "./reducers";

export * from "./actions";
export * from "./selectors";
export * from "./types";

export default combineReducers({
  ods,
  gpAlreadyExists,
  gpChecked,
  unknownNHSODS,
  notGP,
  gpPreview
});
