import React, { FC } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import s from "./gpcreate.module.scss";

export interface CreateFormProps {
  odsCode: string;
  odsUpdate: (ods: string) => void;
  gpAlreadyExists: boolean;
  unknownODS: boolean;
  notGP: boolean;
  openGPProfile: (ods: string) => void;
  callGPExists: (ods: string) => void;
}

export const CreateForm: FC<CreateFormProps> = ({
  odsCode,
  odsUpdate,
  gpAlreadyExists,
  openGPProfile,
  unknownODS,
  notGP,
  callGPExists
}) => {
  const isError = gpAlreadyExists || unknownODS || notGP;
  const createOnClick = (): void => {
    odsCode !== "" && callGPExists(odsCode);
  };
  const odsOnChange = (
    e: React.ChangeEvent<{
      value: string;
    }>
  ): void => odsUpdate(e.currentTarget.value);
  const viewOnClick = (): void => {
    openGPProfile(odsCode);
  };
  return (
    <div className={s.form}>
      <Grid container>
        <Grid item>
          <FormControl className={s.odsControl}>
            <InputLabel htmlFor={"t_odscode"}>GP ODS Code</InputLabel>
            <Input
              id={"t_odscode"}
              aria-describedby={"t_odscode_help"}
              value={odsCode}
              onChange={odsOnChange}
              placeholder={"Type in ODS code"}
            />
            <FormHelperText id={"t_odscode_help"} error={isError}>
              {gpAlreadyExists
                ? "GP already exists"
                : unknownODS
                ? "ODS code not recognised"
                : notGP
                ? "This ODS code is not for a GP or the GP is no longer active"
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid className={s.iconCell}>
          <Button
            id={"t_create_gp_create"}
            color={"primary"}
            variant={"contained"}
            onClick={createOnClick}
          >
            Create GP
          </Button>
        </Grid>
      </Grid>

      {gpAlreadyExists && (
        <div id={"t_gp_create_already_exists"} className={s.extraHelp}>
          <Typography>
            The GP with the ODS code {odsCode} already exists. Click "View GP"
            to view that record.
          </Typography>
          <div className={s.viewGP}>
            <Button
              id={"t_create_gp_view"}
              color={"primary"}
              variant={"contained"}
              onClick={viewOnClick}
            >
              View GP
            </Button>
          </div>
        </div>
      )}

      {unknownODS && (
        <div id={"t_gp_create_unknown_ods"} className={s.extraHelp}>
          Check the ODS code entered is correct.
        </div>
      )}

      {notGP && (
        <div id={"t_gp_create_not_gp"} className={s.extraHelp}>
          <Typography>
            Check the ODS code entered is correct and for an active GP.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CreateForm;
