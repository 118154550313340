import { SvgIcon } from "@material-ui/core";
import React from "react";

const PackageIcon = props => (
  <SvgIcon
    width={"48"}
    height={"48"}
    viewBox={"0 0 48 48"}
    fill={"none"}
    {...props}
  >
    <path
      d={
        "M22 43.45V25.15L6 15.9V31.95C6 32.6833 6.175 33.35 6.525 33.95C6.875 34.55 7.36667 35.0333 8 35.4L22 43.45ZM26 43.45L40 35.4C40.6333 35.0333 41.125 34.55 41.475 33.95C41.825 33.35 42 32.6833 42 31.95V15.9L26 25.15V43.45ZM33.95 15.95L39.85 12.5L26 4.55C25.3667 4.18333 24.7 4 24 4C23.3 4 22.6333 4.18333 22 4.55L18.05 6.8L33.95 15.95ZM24 21.7L29.95 18.3L14.1 9.1L8.1 12.55L24 21.7Z"
      }
    />
  </SvgIcon>
);

export default PackageIcon;
