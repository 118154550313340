import React, { FC } from "react";

import { FormattedMedSuggestion, MedicineSearchProps } from ".";
import AutoComplete from "../autocomplete";

const MedicineSearch: FC<MedicineSearchProps & {
  formattedSuggestions: FormattedMedSuggestion[];
}> = ({
  onMedsSuggestionTextChange,
  onCloseMedsSuggestions,
  onMedSuggestionSelect,
  medsSearchText,
  medsSuggestions,
  formattedSuggestions
}) => (
  <AutoComplete
    id="t_autocomplete_meds"
    placeholder="Medication ingredient or name"
    placement="bottom-start"
    onTextChange={onMedsSuggestionTextChange}
    onOuterClick={onCloseMedsSuggestions}
    onSuggestionSelect={index => onMedSuggestionSelect(medsSuggestions[index])}
    text={medsSearchText}
    suggestions={formattedSuggestions}
  />
);

export default MedicineSearch;
