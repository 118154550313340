import * as Joi from "joi-browser";

export const validateAsEmail = email =>
  Joi.validate(
    email,
    Joi.string()
      .required()
      .email()
  ).error === null;

// number check with whitespace test to guard against unwanted type coercion
export const validateAsNumber = value =>
  value !== null &&
  value !== true &&
  value !== false &&
  !/^\s*$/.test(value) &&
  !Number.isNaN(value) &&
  Number.isFinite(Number(value)); // we'll assume you always want a non-infinite number

export const validateAsPhoneNumber = value =>
  // must be a number, starting with a 0, and with 9 or 10 numeric digits after that.
  validateAsNumber(value) && value.toString().match(/^0[\d]{9,10}$/) !== null;

export const addressLineMaxLen = 40;

export const isTouchDevice = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;
