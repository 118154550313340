import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { renderEvidenceView } from "./evidence-view";
import { renderReasonsView } from "./rejection-view";
import { renderActions } from "./actions";
import {
  getEvidenceIsFetching,
  getEvidenceURL,
  getEvidenceError,
  getEvidenceInvoiceId,
  getEvidenceExpiration,
  getCanApproveOrRejectEvidence,
  getCanSetExpirationForEvidence,
  getIsEvidenceExpirationValid,
  getRejectionReasons,
  getSelectedRejectionReason,
  getReasonNotes,
  getShowRejectionReasons
} from "../../redux/root-reducer";
import {
  fetchEvidence,
  hideEvidenceDialog,
  approveEvidence,
  setExpiration,
  rejectEvidence,
  rejectionReasonSelected,
  reasonNotesEntered,
  showEvidenceRejectionDialog
} from "../../redux/evidence-dialog";

export const container = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70vh",
  width: "100%",
  backgroundColor: "black",
  maxWidth: "600px"
};

export const closeButtonStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  backgroundColor: "black",
  color: "white",
  zIndex: 100
};

const actionsStyle = {
  margin: "32px 4px"
};

export class EvidenceDialog extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    canApproveOrReject: PropTypes.bool.isRequired,
    canSetExpiration: PropTypes.bool.isRequired,
    isExpirationValid: PropTypes.bool.isRequired,

    imageSrc: PropTypes.string,
    expiration: PropTypes.string,
    invoiceId: PropTypes.number.isRequired,

    onApprove: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchEvidence: PropTypes.func.isRequired,
    onExpirationChange: PropTypes.func.isRequired,

    onReasonSelected: PropTypes.func.isRequired,
    onReasonNotesUpdated: PropTypes.func.isRequired,
    onStartReject: PropTypes.func.isRequired,
    rejectionReasons: PropTypes.array.isRequired,
    selectedReason: PropTypes.string,
    reasonNotes: PropTypes.string,
    showReasons: PropTypes.bool.isRequired,
    canSubmitRejection: PropTypes.func.isRequired
  };
  static defaultProps = {
    imageSrc: null,
    expiration: null
  };

  componentDidMount() {
    this.props.fetchEvidence(this.props.invoiceId);
  }

  render() {
    const {
      onClose,
      showReasons,
      onReasonSelected,
      selectedReason,
      isLoading,
      imageSrc,
      rejectionReasons,
      reasonNotes,
      onReasonNotesUpdated,
      canApproveOrReject,
      canSetExpiration,
      expiration,
      isExpirationValid,
      onExpirationChange,
      onApprove,
      onReject,
      onStartReject,
      invoiceId,
      canSubmitRejection
    } = this.props;
    return (
      <div>
        <Dialog maxWidth={false} open onBackdropClick={onClose}>
          <Grid container>
            {selectedReason !== "other" && (
              <IconButton
                id="t_evidence_close_button"
                style={closeButtonStyle}
                onClick={onClose}
              >
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            )}
            <Grid item xs={showReasons ? 3 : 12}>
              <DialogContent style={container}>
                {renderEvidenceView({ isLoading, imageSrc })}
              </DialogContent>
            </Grid>
            {showReasons && (
              <Grid item xs={9}>
                {renderReasonsView({
                  rejectionReasons,
                  onReasonNotesUpdated,
                  selectedReason,
                  reasonNotes,
                  onReasonSelected
                })}
              </Grid>
            )}
          </Grid>
          <DialogActions style={actionsStyle}>
            {renderActions({
              canApproveOrReject,
              canSetExpiration,
              expiration,
              isExpirationValid,
              onExpirationChange,
              onApprove,
              onReject,
              onStartReject,
              invoiceId,
              showReasons,
              reasonNotes,
              selectedReason,
              canSubmitRejection
            })}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export const canSubmitRejection = (selectedReason, reasonNotes) => {
  let optionIsValid = Boolean(selectedReason);
  let needNotes = Boolean(selectedReason === "other");
  let hasNotes = Boolean(reasonNotes);

  if (optionIsValid && !needNotes) {
    return true;
  }
  if (optionIsValid && needNotes && hasNotes) {
    return true;
  }
  return false;
};

export const mapDispatchToProps = {
  fetchEvidence,
  onApprove: approveEvidence,
  onReject: rejectEvidence,
  onClose: hideEvidenceDialog,
  onExpirationChange: setExpiration,
  onReasonSelected: rejectionReasonSelected,
  onReasonNotesUpdated: reasonNotesEntered,
  onStartReject: showEvidenceRejectionDialog
};

export const mapStateToProps = state => ({
  invoiceId: getEvidenceInvoiceId(state),
  isLoading: getEvidenceIsFetching(state),
  imageSrc: getEvidenceURL(state),
  error: getEvidenceError(state),
  expiration: getEvidenceExpiration(state),
  canApproveOrReject: getCanApproveOrRejectEvidence(state),
  canSetExpiration: getCanSetExpirationForEvidence(state),
  isExpirationValid: getIsEvidenceExpirationValid(state),
  rejectionReasons: getRejectionReasons(state),
  selectedReason: getSelectedRejectionReason(state),
  reasonNotes: getReasonNotes(state),
  showReasons: getShowRejectionReasons(state),
  canSubmitRejection
});

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceDialog);
