import { RSAA } from "redux-api-middleware";
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";

const PHARMACY_ORDERS_ENDPOINT = process.env.REACT_APP_ORDERS_API;
const PHARMACY_REQUESTS_ENDPOINT = process.env.REACT_APP_REQUESTS_API;

export const SET_LINE1 = "DELIVERY_ADDRESS/SET_LINE1";
export const SET_LINE2 = "DELIVERY_ADDRESS/SET_LINE2";
export const SET_CITY = "DELIVERY_ADDRESS/SET_CITY";
export const SET_POSTCODE = "DELIVERY_ADDRESS/SET_POSTCODE";
export const OPEN_DELIVERY_ADDRESS = "DELIVERY_ADDRESS/OPEN_DELIVERY_ADDRESS";
export const CLOSE_DELIVERY_ADDRESS = "DELIVERY_ADDRESS/CLOSE_DELIVERY_ADDRESS";

export const DELIVERY_ADDRESS_UPDATE_REQUEST =
  "DELIVERY_ADDRESS/DELIVERY_ADDRESS_UPDATE_REQUEST";
export const DELIVERY_ADDRESS_UPDATE_RECEIVE =
  "DELIVERY_ADDRESS/DELIVERY_ADDRESS_UPDATE_RECEIVE";
export const DELIVERY_ADDRESS_UPDATE_FAILURE =
  "DELIVERY_ADDRESS/DELIVERY_ADDRESS_UPDATE_FAILURE";

export const updateDeliveryAddress = ({
  requestId,
  orderId,
  line1,
  line2,
  city,
  postcode,
  apiOrdersEndpoint = PHARMACY_ORDERS_ENDPOINT,
  apiRequestsEndpoint = PHARMACY_REQUESTS_ENDPOINT
}) => {
  const endpoint = requestId
    ? `${apiRequestsEndpoint}/request/${requestId}/deliveryaddress`
    : `${apiOrdersEndpoint}/order/${orderId}/deliveryaddress`;
  return {
    [RSAA]: {
      endpoint,
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        address1: line1,
        address2: line2,
        city,
        postcode
      }),
      types: [
        DELIVERY_ADDRESS_UPDATE_REQUEST,
        {
          type: DELIVERY_ADDRESS_UPDATE_RECEIVE,
          meta: {
            orderId,
            requestId,
            address: {
              line1,
              line2,
              city,
              postcode
            }
          }
        },
        DELIVERY_ADDRESS_UPDATE_FAILURE
      ],
      options: { addAuth: true }
    }
  };
};

export const openDeliveryAddress = ({ address, requestId, orderId } = {}) => {
  const [line1, line2, city, postcode] = address.split("\n");
  return {
    type: OPEN_DELIVERY_ADDRESS,
    line1,
    line2,
    city,
    postcode,
    requestId: requestId || null,
    orderId: orderId || null
  };
};

export const closeDeliveryAddress = () => ({
  type: CLOSE_DELIVERY_ADDRESS
});

export const setLine1 = line1 => ({
  type: SET_LINE1,
  line1
});

export const setLine2 = line2 => ({
  type: SET_LINE2,
  line2
});

export const setCity = city => ({
  type: SET_CITY,
  city
});

export const setPostcode = postcode => ({
  type: SET_POSTCODE,
  postcode
});

export const getIsOpen = state => state.isOpen;
export const getLine1 = state => state.line1;
export const getLine2 = state => state.line2;
export const getCity = state => state.city;
export const getPostcode = state => state.postcode;
export const getRequestId = state => state.requestId;
export const getOrderId = state => state.orderId;
export const getIsLoading = state => state.isLoading;

const requestId = handleActions(
  {
    [OPEN_DELIVERY_ADDRESS]: (state, action) => action.requestId
  },
  null
);

const orderId = handleActions(
  {
    [OPEN_DELIVERY_ADDRESS]: (state, action) => action.orderId
  },
  null
);

const isOpen = handleActions(
  {
    [OPEN_DELIVERY_ADDRESS]: () => true,
    [CLOSE_DELIVERY_ADDRESS]: () => false,
    [DELIVERY_ADDRESS_UPDATE_RECEIVE]: () => false
  },
  false
);

const line1 = handleActions(
  {
    [OPEN_DELIVERY_ADDRESS]: (state, action) => action.line1,
    [SET_LINE1]: (state, action) => action.line1
  },
  ""
);

const line2 = handleActions(
  {
    [OPEN_DELIVERY_ADDRESS]: (state, action) => action.line2,
    [SET_LINE2]: (state, action) => action.line2
  },
  ""
);

const city = handleActions(
  {
    [OPEN_DELIVERY_ADDRESS]: (state, action) => action.city,
    [SET_CITY]: (state, action) => action.city
  },
  ""
);

const postcode = handleActions(
  {
    [OPEN_DELIVERY_ADDRESS]: (state, action) => action.postcode,
    [SET_POSTCODE]: (state, action) => action.postcode
  },
  ""
);

const isLoading = handleActions(
  {
    [DELIVERY_ADDRESS_UPDATE_REQUEST]: () => true,
    [DELIVERY_ADDRESS_UPDATE_RECEIVE]: () => false,
    [DELIVERY_ADDRESS_UPDATE_FAILURE]: () => false
  },
  false
);

export default combineReducers({
  isOpen,
  line1,
  line2,
  city,
  postcode,
  requestId,
  orderId,
  isLoading
});
