import { Medicine } from "redux/orders";

import { MedSuggestion } from "./meds-search.types";

export const normalizeMedicine = (med: Medicine): MedSuggestion => ({
  vmpId: med.vmpId,
  ampId: (med.packages || []).length ? med.packages[0].ampId : undefined,
  drugName: med.drugName,
  brandName: med.brandName,
  drugDescription: med.prodDesc,
  brandDescription: med.brandDesc,
  isContraceptive: (med.additional && med.additional.contraceptive) || false,
  costUnits: (med.additional && med.additional.costUnits) || 1,
  form: med.form
});

export const normalizeDmdResponse = (medicines: Medicine[]) =>
  medicines.map(normalizeMedicine);
