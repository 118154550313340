import { RSAA } from "redux-api-middleware";
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";

const GP_API_ENDPOINT = process.env.REACT_APP_REQUESTS_API;

// ACTIONS
export const SHOW_CONTACT_DETAILS_DIALOG = "SHOW_CONTACT_DETAILS_DIALOG";
export const HIDE_CONTACT_DETAILS_DIALOG = "HIDE_CONTACT_DETAILS_DIALOG";
export const SELECT_CONTACT_METHOD = "SELECT_CONTACT_METHOD";

export const CONTACT_REQUEST_REQUEST = "CONTACT_REQUEST_REQUEST";
export const CONTACT_REQUEST_RECEIVE = "CONTACT_REQUEST_RECEIVE";
export const CONTACT_REQUEST_FAILURE = "CONTACT_REQUEST_FAILURE";
// ACTION CREATORS
export const closeContactDialog = () => ({
  type: HIDE_CONTACT_DETAILS_DIALOG
});
export const showContactDialog = () => ({
  type: SHOW_CONTACT_DETAILS_DIALOG
});
export const selectContactMethod = method => ({
  type: SELECT_CONTACT_METHOD,
  method
});
export const sendGPContactRequest = (
  requestID,
  type,
  apiEndpoint = GP_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/request/${requestID}/send/prescriptionrequest${type}`,
    method: "POST",
    types: [
      CONTACT_REQUEST_REQUEST,
      CONTACT_REQUEST_RECEIVE,
      CONTACT_REQUEST_FAILURE
    ],
    options: { addAuth: true }
  }
});

// SELECTORS
export const getContactDialogSelectedMethod = state => state.selected;
export const getDialogIsOpen = state => state.isOpen;

const isOpen = handleActions(
  {
    [SHOW_CONTACT_DETAILS_DIALOG]: () => true,
    [HIDE_CONTACT_DETAILS_DIALOG]: () => false,
    [CONTACT_REQUEST_RECEIVE]: () => false
  },
  false
);

const selected = handleActions(
  {
    [SELECT_CONTACT_METHOD]: (state, action) => action.method
  },
  ""
);

export default combineReducers({
  isOpen,
  selected
});
