import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  createMuiTheme,
  ThemeProvider,
  ButtonBase,
  Paper
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        border: "1px solid #DADADA",
        textAlign: "center",
        color: "#757575 !important",
        fontSize: "4rem",
        fontWeight: 500,
        lineHeight: "140%"
      }
    }
  }
});

const ButtonCell = ({ onClick, children, colSpan, rowSpan }) => (
  <ButtonBase
    component={TableCell}
    onClick={onClick}
    colSpan={colSpan}
    rowSpan={rowSpan}
  >
    {children}
  </ButtonBase>
);

const renderNumberPadRows = onClick => {
  const rows = [
    [
      1,
      2,
      3,
      {
        type: "icon",
        icon: <BackspaceIcon htmlColor={"#757575"} fontSize={"large"} />,
        value: -1,
        rowSpan: 2
      }
    ],
    [4, 5, 6],
    [7, 8, 9, { type: "text", label: "Enter", value: 13, rowSpan: 2 }],
    [{ type: "text", label: 0, value: 0, colSpan: 3 }]
  ];

  return rows.map((row, idx) => (
    <TableRow key={idx}>
      {row.map((cell, index) =>
        typeof cell === "object" ? (
          <ButtonCell
            key={index}
            onClick={() => onClick(cell.value)}
            colSpan={cell.colSpan}
            rowSpan={cell.rowSpan}
          >
            {cell.type === "icon" ? cell.icon : cell.label}
          </ButtonCell>
        ) : (
          <ButtonCell key={cell} onClick={() => onClick(cell)}>
            {cell}
          </ButtonCell>
        )
      )}
    </TableRow>
  ));
};

const NumberPad = ({ onClick }) => (
  <ThemeProvider theme={theme}>
    <Paper component={Table} elevation={3}>
      <TableBody>{renderNumberPadRows(onClick)}</TableBody>
    </Paper>
  </ThemeProvider>
);

export default NumberPad;
