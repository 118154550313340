import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const expirationWrapperStyle = {
  position: "relative",
  top: "-10px",
  flexGrow: "1",
  marginRight: "150px",
  marginLeft: "16px",
  whiteSpace: "nowrap"
};

export const renderActions = props => {
  const {
    canApproveOrReject,
    canSetExpiration,
    expiration,
    isExpirationValid,
    onExpirationChange,
    onApprove,
    onReject,
    onStartReject,
    invoiceId,
    showReasons,
    reasonNotes,
    selectedReason,
    canSubmitRejection
  } = props;
  return [
    canSetExpiration && canApproveOrReject && !showReasons ? (
      <div style={expirationWrapperStyle} key="0">
        <TextField
          id="t_expiration"
          label="Expiration Date - dd/mm/yyyy"
          value={expiration}
          error={!isExpirationValid}
          fullWidth
          onChange={e => onExpirationChange(e.target.value)}
        />
      </div>
    ) : null,
    showReasons ? (
      <React.Fragment key="2">
        <Typography
          id="t_help_text"
          variant="h5"
          style={{
            position: "absolute",
            left: "10px"
          }}
        >
          Please check with a senior member of the team if you are not sure
          which option to select.
        </Typography>
        <Button
          key="1"
          id="t_action_submit_reject"
          onClick={() => onReject(invoiceId, selectedReason, reasonNotes)}
          disabled={!canSubmitRejection(selectedReason, reasonNotes)}
          color="secondary"
        >
          Submit Rejection
        </Button>
      </React.Fragment>
    ) : (
      <React.Fragment key="1">
        <Button
          key="2"
          id="t_action_reject"
          onClick={() => onStartReject()}
          disabled={!canApproveOrReject}
          color="secondary"
        >
          Reject
        </Button>
        <Button
          key="3"
          id="t_action_approve"
          onClick={() => onApprove(invoiceId, expiration)}
          disabled={!canApproveOrReject || !isExpirationValid}
          color="primary"
        >
          Approve
        </Button>
      </React.Fragment>
    )
  ];
};
