import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import Block from "@material-ui/icons/Block";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CloudDone from "@material-ui/icons/CloudDone";
import FlashOn from "@material-ui/icons/FlashOn";
import Healing from "@material-ui/icons/Healing";
import Label from "@material-ui/icons/Label";
import LabelOff from "@material-ui/icons/LabelOff";
import LocalPharmacy from "@material-ui/icons/LocalPharmacy";
import LocalShipping from "@material-ui/icons/LocalShipping";
import LocalHospital from "@material-ui/icons/LocalHospital";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import Print from "@material-ui/icons/Print";
import Receipt from "@material-ui/icons/Receipt";
import Send from "@material-ui/icons/Send";
import ShutterSpeed from "@material-ui/icons/ShutterSpeed";
import Store from "@material-ui/icons/Store";
import Style from "@material-ui/icons/Style";
import Visibility from "@material-ui/icons/Visibility";
import WatchLater from "@material-ui/icons/WatchLater";
import React from "react";
import RouteParser from "route-parser";
import GPProfile from "../components/gp-profile";
import MatchScripts from "../components/match-scripts";
import MatchUsers from "../components/match-users";
import MCSMedList from "../components/mcs-medicine-list";
import Orders from "../components/orders";
import PendingUsers from "../components/pending-users";
import Requests from "../components/requests";
import ShippingScanner from "../components/shipping-scanner";
// import UserProfile from "../components/user-profile";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import ClickAndCollect from "../components/click-and-collect";
import CreateGP from "../components/gp-nhs-create";
import GPContactCheck from "../components/gp-contact-check";
import { permGPRefresh } from "../permissions";
import ScannerWrapper from "../components/basket-scanner-wrapper";
import PackageIcon from "components/svgs/package-icon";
import ManageAccountIcon from "components/svgs/manage-account-icon";
import VariableRemoveIcon from "components/svgs/variable-remove-icon";
// import RobotLists from "components/robot-lists";

export default [
  {
    hideInMenu: true,
    isRedirect: true,
    fromUrl: "/access/complete/:rnd",
    toUrl: "/requests/open"
  },
  {
    hideInMenu: true,
    isRedirect: true,
    fromUrl: "/",
    toUrl: "/requests/open"
  },
  {
    hideInMenu: true,
    isRedirect: false,
    url: "/user/:id",
    component: <ScannerWrapper isUser />
  },
  {
    hideInMenu: true,
    isRedirect: false,
    url: "/basket/:basketId",
    component: <ScannerWrapper isBasketDetails />
  },
  {
    hideInMenu: true,
    isRedirect: false,
    url: "/gp/profile/:id",
    component: (
      <GPProfile
        getMatchedRoute={() => {
          const completionRoute = new RouteParser("/gp/profile/:id");
          return completionRoute.match(global.location.pathname);
        }}
      />
    )
  },
  {
    hideInMenu: true,
    isRedirect: true,
    fromUrl: "/requests",
    toUrl: "/requests/open"
  },
  {
    hideInMenu: true,
    isRedirect: true,
    fromUrl: "/orders",
    toUrl: "/orders/invoiced"
  },
  {
    hideInMenu: true,
    isRedirect: true,
    fromUrl: "/users",
    toUrl: "/pending-users"
  },
  {
    text: "Requests",
    icon: <Receipt />,
    component: <div />,
    childRoutes: [
      {
        text: "Open",
        icon: <Label />,
        url: "/requests/open",
        statuses: ["request_open"],
        component: (
          <Requests filters={["open"]} showGPStatus showNominatedStatus />
        )
      },
      {
        text: "Sent to GP",
        icon: <Healing />,
        url: "/requests/sent-to-gp",
        statuses: ["request_sent_to_gp"],
        component: (
          <Requests
            filters={["sent_to_gp"]}
            warningSettings={{
              threshold: 2,
              type: "WD"
            }}
          />
        )
      },
      {
        text: "Closed",
        icon: <Cancel />,
        url: "/requests/closed",
        statuses: ["request_closed"],
        component: (
          <Requests filters={["closed"]} hideDeliveryAddressEditButton />
        )
      }
    ]
  },
  {
    text: "Unmatched",
    icon: <People />,
    component: <div />,
    childRoutes: [
      {
        text: "Unmatched Users",
        icon: <Person />,
        url: "/match-users",
        component: <MatchUsers />
      },
      {
        text: "Unmatched Prescriptions",
        icon: <Style />,
        url: "/match-prescriptions",
        component: <MatchScripts />
      }
    ]
  },
  {
    text: "Orders",
    icon: <Assignment />,
    component: <div />,
    childRoutes: [
      {
        text: "Click & Collect",
        icon: <Store />,
        url: "/orders/click-and-collect",
        statuses: ["order_cc_accepted"],
        component: <ClickAndCollect />
      },
      {
        text: "Awaiting exemption/payment",
        icon: <Label />,
        url: "/orders/invoiced",
        statuses: ["order_invoiced"],
        component: (
          <Orders
            filters={["invoiced"]}
            ordersPerPage={20}
            warningSettings={{
              threshold: 48,
              type: "HR"
            }}
          />
        )
      },
      {
        text: "Approve exemption",
        icon: <Visibility />,
        url: "/orders/approve-exemption",
        statuses: ["order_exemption_provided"],
        component: (
          <Orders
            filters={["exemption_provided"]}
            ordersPerPage={20}
            showEvidenceColumn
            warningSettings={{
              threshold: 1,
              type: "WD"
            }}
          />
        )
      },
      {
        text: "Ready to process",
        icon: <FlashOn />,
        url: "/orders/settled",
        statuses: ["order_invoice_settled", "order_checkoutbuffer_completed"],
        component: <ScannerWrapper isReadyToProcess />
      },
      {
        text: "Ready to print",
        icon: <Print />,
        url: "/orders/cf-rejected",
        statuses: ["order_cf_rejected"],
        component: <ScannerWrapper />
      },
      // {
      //   text: "Orders Ready To Dispense",
      //   icon: <Print />,
      //   url: "/orders/robot-cf-rejected",
      //   statuses: ["order_cf_rejected"],
      //   component: <RobotLists />
      // },
      // {
      //   text: "Start manual dispensing",
      //   icon: <Shop />,
      //   url: "/orders/start-manual-dispensing",
      //   component: <ManualLineStart />
      // },
      {
        text: "Manual dispensing",
        icon: <LocalPharmacy />,
        url: "/orders/dispense",
        statuses: ["order_prescription_printed"],
        component: (
          <Orders
            filters={["prescription_printed"]}
            ordersPerPage={20}
            showEvidenceColumn
            showNotesColumn
            displaySettings={{
              showOrderId: true,
              showPatient: true,
              showGP: true,
              showLatestEvent: true,
              showNotes: true,
              showDeliveryAddress: true,
              showShippingClass: true,
              showBarcodeColumn: true,
              showBarcodeForOrder: () => true
            }}
            warningSettings={{
              threshold: 1,
              type: "WD"
            }}
          />
        )
      },
      {
        text: "CF Dispensing",
        icon: <ShutterSpeed />,
        url: "/orders/hm-accepted",
        statuses: ["order_hm_accepted"],
        component: (
          <Orders
            filters={["hm_accepted"]}
            ordersPerPage={20}
            showEvidenceColumn
            warningSettings={{
              threshold: 1,
              type: "WD"
            }}
          />
        )
      },
      {
        text: "CF Delivered",
        icon: <LocalShipping />,
        url: "/orders/hm-delivered",
        statuses: ["order_hm_delivered"],
        component: (
          <Orders
            filters={["hm_delivered"]}
            ordersPerPage={20}
            showEvidenceColumn
            displaySettings={{
              showOrderId: true,
              showPatient: true,
              showGP: true,
              showLatestEvent: true,
              showNotes: true,
              showDeliveryAddress: true,
              showShippingClass: true,
              showBarcodeColumn: true,
              showBarcodeForOrder: () => true
            }}
            warningSettings={{
              threshold: 1,
              type: "WD"
            }}
          />
        )
      },
      {
        text: "Manual dispatch",
        icon: <PackageIcon />,
        url: "/orders/basket",
        component: <ScannerWrapper isBasket />
      },
      {
        text: "Ready for pick-up",
        icon: <CheckCircle />,
        url: "/orders/pickup",
        statuses: ["order_dispensed"],
        component: (
          <Orders
            filters={["dispensed"]}
            ordersPerPage={20}
            canSelectMultiple
            showEvidenceColumn
            hideDeliveryAddressEditButton
          />
        )
      },
      {
        text: "Posted",
        icon: <Send />,
        url: "/orders/dispatched",
        statuses: ["order_dispatched"],
        component: (
          <Orders
            filters={["dispatched"]}
            ordersPerPage={20}
            showEvidenceColumn
            hideDeliveryAddressEditButton
          />
        )
      },
      {
        text: "Delivered",
        icon: <AssignmentTurnedIn />,
        url: "/orders/delivered",
        statuses: ["order_delivered"],
        component: (
          <Orders
            filters={["delivered"]}
            ordersPerPage={20}
            showEvidenceColumn
            hideDeliveryAddressEditButton
          />
        )
      },
      {
        text: "Archived Orders",
        icon: <Cancel />,
        url: "/orders/archived",
        component: (
          <Orders
            showExpired
            ordersPerPage={20}
            showEvidenceColumn
            hideDeliveryAddressEditButton
          />
        )
      },
      {
        text: "Shipping Scanner",
        icon: <LoyaltyIcon />,
        hideInMenu: true,
        // TODO: Change path when visible in the menu - /shipping-scanner
        url: "/orders/shipping-scanner",
        component: <ShippingScanner />
      }
    ]
  },
  {
    text: "Exceptions",
    icon: <Assignment />,
    component: <div />,
    childRoutes: [
      {
        text: "Prescriptions returned",
        icon: <CloudDone />,
        url: "/orders/returned",
        statuses: ["order_prescription_returned"],
        component: (
          <Orders
            filters={["prescription_returned"]}
            ordersPerPage={20}
            hideDeliveryAddressEditButton
          />
        )
      },
      {
        text: "Customer Services",
        icon: <Block />,
        url: "/orders/cs",
        statuses: ["order_customer_service"],
        component: (
          <Orders
            filters={["customer_service"]}
            ordersPerPage={20}
            hideDeliveryAddressEditButton
          />
        )
      },
      {
        text: "MCS orders on hold",
        icon: <Block />,
        url: "/orders/mcs",
        statuses: ["order_mcs"],
        component: (
          <Orders
            filters={["mcs"]}
            ordersPerPage={20}
            hideDeliveryAddressEditButton
          />
        )
      },
      {
        text: "MCS orders waiting for stock",
        icon: <Block />,
        url: "/orders/mcs-waiting",
        statuses: ["order_mcs_pending"],
        component: (
          <Orders
            filters={["mcs_pending"]}
            ordersPerPage={60}
            displaySettings={{
              showOrderId: true,
              showPatient: true,
              showGP: true,
              showLatestEvent: true,
              showNotes: true,
              showDeliveryAddress: true,
              showShippingClass: true,
              showBarcodeColumn: true,
              showBarcodeForOrder: () => true
            }}
            showEvidenceColumn
            warningSettings={{
              threshold: 1,
              type: "WD"
            }}
          />
        )
      },
      {
        text: "HRT Mix Orders",
        icon: <Block />,
        url: "/orders/hrt-mix-orders",
        statuses: ["order_manual_check"],
        component: (
          <Orders
            filters={["manual_check"]}
            ordersPerPage={20}
            hideDeliveryAddressEditButton
            displaySettings={{
              showOrderId: true,
              showPatient: true,
              showGP: true,
              showLatestEvent: true,
              showNotes: true,
              showDeliveryAddress: true,
              showShippingClass: false,
              showBarcodeColumn: false,
              showBarcodeForOrder: () => false
            }}
          />
        )
      },
      {
        text: "MCS Meds",
        icon: <LabelOff />,
        hideInMenu: true,
        url: "/mcs-medicines",
        component: <MCSMedList />
      }
    ]
  },
  {
    text: "GP Practices",
    component: <div />,
    childRoutes: [
      {
        text: "Create GP",
        icon: <LocalHospital />,
        url: "/create-gp",
        component: <CreateGP />,
        needsPermission: permGPRefresh
      },
      {
        text: "Pending GP details",
        icon: <WatchLater />,
        url: "/pending-gps",
        statuses: ["gpContactReview"],
        component: <GPContactCheck />
      }
    ]
  },
  {
    text: "Users",
    icon: <People />,
    component: <div />,
    childRoutes: [
      {
        text: "Pending nomination",
        icon: <WatchLater />,
        url: "/pending-users",
        statuses: ["user_pending"],
        component: <PendingUsers />
      }
    ]
  },
  {
    text: "Admin",
    component: <div />,
    childRoutes: [
      {
        text: "Manage baskets",
        icon: <ManageAccountIcon />,
        url: "/admin/basket",
        component: <ScannerWrapper isBasket />
      },
      {
        text: "Remove order from basket",
        icon: <VariableRemoveIcon />,
        url: "/admin/basket/remove-order",
        component: <ScannerWrapper isBasket />
      }
    ]
  }
];
