export const getOrders = state => state.allIds.map(id => state.byId[id]);
export const getSelectedOrderIds = state => state.selectedIds;
export const getSelectedOrder = state => state.byId[state.selectedIds];
export const getOrdersNeedsUpdate = state => state.needsRefresh;
export const getFetching = state => state.fetching;
export const getFetchingAdditional = state => state.fetchingAdditional;
export const getError = state => state.error;
export const getResultsPage = state => state.resultsPage;
export const getLastPageSize = state => state.lastPageSize;
export const getSelectedTabIndex = state => state.selectedTabIndex;
export const getSelectedSubTabIndex = state => state.selectedSubTabIndex;
export const getSelectedOrderNumber = state => state.orders.selectedOrderNumber;
export const getSeqCount = state => state.orders.seqCount;
export const getActingOnAll = state => state.willActOnAll;
export const getMedicinesFilter = state => state.filters.medicines;
export const getIsFiltering = state => state.filters.medicines.length > 0;
export const getshowFilters = state => state.showFilters;

// SINGLE ORDER SELECTORS
export const getEvidenceIsApproved = order => {
  const { invoice, status } = order;
  return (
    invoice.exemptionCode !== "" &&
    !invoice.exemptionEvidenceSkipped &&
    status !== "exemption_provided"
  );
};
