import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { Box, Typography, Button, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import {
  getBasketAdminDialogIsOpen,
  getBasketId,
  getBasketStatus
} from "redux/root-reducer";
import { addOrRemoveBasket, hideAdminDialog } from "redux/scanner-wrapper";

const BasketAdminDialog = ({
  basketId,
  addOrRemoveBasket,
  hideAdminDialog,
  open,
  status
}) => {
  const title = {
    available: "Remove basket",
    not_found: "Add basket", // eslint-disable-line
    unavailable: "Basket has orders or not released yet"
  };

  const handleActionClick = () => {
    if (status !== "unavailable") {
      addOrRemoveBasket(basketId, status);
    }
  };

  return (
    <Dialog id={"t_dialog"} open={open} fullWidth maxWidth={"xl"}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          variant={"h3"}
          style={{ fontWeight: 500, padding: "2.5rem 0 4.19rem" }}
        >
          {`${title[status] || ""} ${basketId}`}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gridGap={"3.75rem"}
          width={"44rem"}
          pb={"2.5rem"}
        >
          {status !== "unavailable" && (
            <Button
              style={{ height: "16.25rem" }}
              id={"t_agree"}
              color={"primary"}
              variant={"contained"}
              onClick={handleActionClick}
            >
              <Typography variant={"h2"} style={{ fontWeight: 500 }}>
                {title[status]}
              </Typography>
            </Button>
          )}
          <Button
            style={{ padding: "2.5rem" }}
            id={"t_cancel"}
            color={"primary"}
            variant={"outlined"}
            onClick={hideAdminDialog}
          >
            <Typography variant={"h2"} style={{ fontWeight: 500 }}>
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  basketId: getBasketId(state),
  status: getBasketStatus(state),
  open: getBasketAdminDialogIsOpen(state)
});

const mapDispatchToProps = {
  addOrRemoveBasket,
  hideAdminDialog
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(BasketAdminDialog));
