import { useState } from "react";
import useEventListener from "@use-it/event-listener";

/*
 * The useKeyStroke hook emits the `key` value of all `keydown` events to consumers
 * An object is returned so reference equality will always fail during the react lifecycle,
 * otherwise two of the same letter consecutively will not trigger two renders.
 */
const useKeyStroke = function() {
  const [key, setKey] = useState(null);

  const handleKeyDown = function(e) {
    setKey({ key: e.key });
  };
  // Set up event listeners
  useEventListener("keydown", handleKeyDown);
  return key;
};

export default useKeyStroke;
