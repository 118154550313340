import { RSAA } from "redux-api-middleware";
import { fetchWithErrorMiddleware } from "./utilities";

// ACTION TYPES
export const OPEN_REFRESH_GP = "OPEN_REFRESH_GP";
export const CLOSE_REFRESH_GP = "CLOSE_REFRESH_GP";
export const GET_GP_PROFILE_REQUEST = "GET_GP_PROFILE_REQUEST";
export const GET_CAN_NHSREFRESH_REQUEST = "GET_CAN_NHSREFRESH_REQUEST";
export const GET_CAN_NHSREFRESH_RECIEVE = "GET_CAN_NHSREFRESH_RECIEVE";
export const GET_CAN_NHSREFRESH_FAILURE = "GET_CAN_NHSREFRESH_FAILURE";
export const FETCH_NHSREFRESH_REQUEST = "FETCH_NHSREFRESH_REQUEST";
export const FETCH_NHSREFRESH_RECIEVE = "FETCH_NHSREFRESH_RECIEVE";
export const FETCH_NHSREFRESH_FAILURE = "FETCH_NHSREFRESH_FAILURE";
export const COMPLETE_NHSREFRESH_REQUEST = "COMPLETE_NHSREFRESH_REQUEST";
export const COMPLETE_NHSREFRESH_RECIEVE = "COMPLETE_NHSREFRESH_RECIEVE";
export const COMPLETE_NHSREFRESH_FAILURE = "COMPLETE_NHSREFRESH_FAILURE";

// ACTION CREATORS
export const openRefreshGP = ({ odsCode }) => {
  return {
    type: OPEN_REFRESH_GP,
    payload: { odsCode: odsCode }
  };
};

export const closeRefreshGP = () => ({
  type: CLOSE_REFRESH_GP
});
const PROFILE_API_ENDPOINT = process.env.REACT_APP_GP_PROFILE_API;
export const fetchCanRefreshGP = (apiEndpoint = PROFILE_API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/nhsrefresh/can`,
    method: "GET",
    types: [
      GET_CAN_NHSREFRESH_REQUEST,
      GET_CAN_NHSREFRESH_RECIEVE,
      GET_CAN_NHSREFRESH_FAILURE
    ],
    options: { addAuth: true },
    fetch: fetchWithErrorMiddleware
  }
});

export const fetchNHSUpdatedGP = (id, apiEndpoint = PROFILE_API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practice/${id}/nhsrefresh`,
    method: "GET",
    types: [
      FETCH_NHSREFRESH_REQUEST,
      FETCH_NHSREFRESH_RECIEVE,
      FETCH_NHSREFRESH_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const completeNHSUpdateGP = (
  id,
  apiEndpoint = PROFILE_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practice/${id}/nhsrefresh`,
    method: "PUT",
    types: [
      COMPLETE_NHSREFRESH_REQUEST,
      COMPLETE_NHSREFRESH_RECIEVE,
      COMPLETE_NHSREFRESH_FAILURE
    ],
    options: { addAuth: true }
  }
});
