import { combineReducers } from "redux";

import {
  statusCounts,
  robotCounts,
  needsRefresh,
  needsGPRefresh
} from "./navigation.reducers";

export const STATUS_API_ENDPOINT = process.env.REACT_APP_STATUS_API;
export const GP_API_ENDPOINT = process.env.REACT_APP_GP_PROFILE_API || "";

export * from "./navigation.actions";
export * from "./navigation.selectors";

export default combineReducers({
  statusCounts,
  needsRefresh,
  robotCounts,
  needsGPRefresh
});
