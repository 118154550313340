import moment from "moment";
import momentBusinessDays from "moment-business-days";
import Holidays from "date-holidays";

export const formatDateTimeString = d => moment(d).format("DD/MM/YY HH:mm:ss");
export const formatDateString = d => moment(d).format("DD/MM/YYYY");
export const formatDBDateString = d => moment(d).format("YYYY-MM-DD");

export const getUKDateTime = (dateString = null) => {
  const dateTime = dateString === null ? moment() : moment(dateString);
  dateTime.locale("GB");
  return dateTime.toDate();
};

export const getUKWorkingDaysSince = (lastModifiedDate, currentDateString) => {
  const currentMoment = currentDateString
    ? moment(getUKDateTime(currentDateString))
    : moment(getUKDateTime());

  const lastModdedMoment = moment(getUKDateTime(lastModifiedDate));

  // Configure with this year's list of holidays.
  const hd = new Holidays("GB");
  momentBusinessDays.updateLocale("GB", {
    holidays: hd.getHolidays(currentMoment.year()).map(i => i.date)
  });

  // Get the number of working days.
  // If we're in typical business hours, don't count today.
  const modifier =
    currentMoment.hour() < 18 && momentBusinessDays().isBusinessDay() ? -1 : 0;
  return (
    momentBusinessDays(currentMoment).businessDiff(lastModdedMoment) + modifier
  );
};
