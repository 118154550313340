import { connect } from "react-redux";

import {
  getOrdersIsFiltering,
  getShowOrdersFilters
} from "../../../../redux/root-reducer";
import { toggleOrdersFilter } from "../../../../redux/orders";

import Container from "./filter-button.container";

export interface FilterButtonProps {
  isFiltering: boolean;
  isOpen: boolean;
  toggleOrdersFilter(): void;
}

const mapStateToProps = state => ({
  isFiltering: getOrdersIsFiltering(state),
  isOpen: getShowOrdersFilters(state)
});

const mapDispatchToProps = {
  toggleOrdersFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
