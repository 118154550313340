import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RouteParser from "route-parser";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImagePlus from "../svgs/img-plus";
import LogoDark from "../svgs/logo-dark";
import LoadingButton from "../common/loading-button";
import {
  getLoginEmail,
  getLoginIsEmailValid,
  getLoginIsSendingEmail,
  getLoginIsWaitingCompletion,
  getLoginIsCompleting,
  getLoginIsShowingValidation,
  getLoginError,
  getVersionNumber,
  getLoginBypassEnabled
} from "../../redux/root-reducer";
import s from "./style.module.scss";
import { setLoginEmail, resetLogin, showValidation } from "../../redux/login";
import { accessStart, accessComplete, bypassAuth } from "../../redux/auth";

export class LoginComponent extends Component {
  componentDidMount() {
    this.props.reset();
    this.checkForCompleteRoute();
  }

  checkForCompleteRoute() {
    const completionRoute = new RouteParser("/access/complete/:rnd");
    const match = completionRoute.match(global.location.pathname);
    if (match) {
      this.props.completeLogin(match.rnd);
    }
  }

  renderContent() {
    if (this.props.emailSent) {
      return this.renderEmailSent();
    }
    if (this.props.completing) {
      return this.renderLoading();
    }

    return this.renderForm();
  }

  renderForm() {
    const isError = this.props.showValidation && !this.props.emailValid;
    return (
      <div>
        <h1>Sign in</h1>
        <p>
          Enter your email address and we&#39;ll email you a link that you can
          use to sign in.
        </p>
        <FormControl id="t_emailform" fullWidth error={isError}>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input
            id="email"
            value={this.props.email}
            fullWidth
            onChange={e => this.props.onEmailChange(e.target.value)}
          />
        </FormControl>
        <div className={s.btn}>
          <LoadingButton
            id="t_send_button"
            variant="contained"
            type="submit"
            color="primary"
            loading={this.props.sendingEmail}
            onClick={this.props.onSendEmail}
          >
            Request Link
          </LoadingButton>
        </div>
        {this.renderBypassButton()}
      </div>
    );
  }

  renderBypassButton() {
    if (!this.props.showBypassButton) return null;
    return (
      <div className={s.btnLeft}>
        <LoadingButton
          id="t_bypass_button"
          variant="contained"
          type="submit"
          color="primary"
          loading={this.props.sendingEmail}
          onClick={this.props.onBypass}
        >
          Bypass
        </LoadingButton>
      </div>
    );
  }

  renderEmailSent() {
    return (
      <div>
        <h1>Email sent</h1>
        <p>
          We have just sent you an email with a magic link. Click on the link to
          log in.
        </p>
        <div className={s.btn}>
          <Button
            id="t_emailsent_action"
            variant="contained"
            type="submit"
            color="primary"
            onClick={this.props.reset}
          >
            Go back
          </Button>
        </div>
      </div>
    );
  }

  renderLoading = () => (
    <div className={s.loadingContainer}>
      <CircularProgress className={s.loading} />
    </div>
  );

  renderError() {
    const { error } = this.props;
    if (!error) return null;
    return (
      <p id="t_error" className={s.apiError}>
        {error}
      </p>
    );
  }

  render() {
    return (
      <div className={s.root}>
        <span className={s.backImg}>
          <ImagePlus />
        </span>
        <Paper className={s.dialog} style={{ height: 420, width: 350 }}>
          <span className={s.topImg}>
            <LogoDark />
          </span>
          {this.renderContent()}
          {this.renderError()}
        </Paper>
        <div className={s.version}>v{this.props.version}</div>
      </div>
    );
  }
}

LoginComponent.propTypes = {
  email: PropTypes.string,
  emailValid: PropTypes.bool,
  sendingEmail: PropTypes.bool,
  emailSent: PropTypes.bool,
  completing: PropTypes.bool,
  showValidation: PropTypes.bool,
  error: PropTypes.string,
  version: PropTypes.string,
  onEmailChange: PropTypes.func,
  reset: PropTypes.func,
  completeLogin: PropTypes.func,
  onSendEmail: PropTypes.func,
  showBypassButton: PropTypes.bool,
  onBypass: PropTypes.func
};
LoginComponent.defaultProps = {
  email: "",
  emailValid: false,
  sendingEmail: false,
  emailSent: false,
  completing: false,
  showValidation: false,
  error: "",
  version: "0",
  showBypassButton: false,
  onEmailChange: () => {},
  reset: () => {},
  completeLogin: () => {},
  onSendEmail: () => {},
  onBypass: () => {}
};

const mapStateToProps = state => ({
  email: getLoginEmail(state),
  emailValid: getLoginIsEmailValid(state),
  sendingEmail: getLoginIsSendingEmail(state),
  emailSent: getLoginIsWaitingCompletion(state),
  completing: getLoginIsCompleting(state),
  showValidation: getLoginIsShowingValidation(state),
  error: getLoginError(state),
  version: getVersionNumber(state),
  showBypassButton: getLoginBypassEnabled(state)
});

const mapDispatchToProps = {
  onEmailChange: setLoginEmail,
  sendEmail: accessStart,
  reset: resetLogin,
  completeLogin: accessComplete,
  showValidationAction: showValidation,
  bypassAuth
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...stateProps,
  ...ownProps,
  onSendEmail: () => {
    if (stateProps.emailValid) return dispatchProps.sendEmail(stateProps.email);
    return dispatchProps.showValidationAction();
  },
  onBypass: () => {
    if (stateProps.emailValid)
      return dispatchProps.bypassAuth(stateProps.email);
    return dispatchProps.showValidationAction();
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LoginComponent);
