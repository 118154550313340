import { getCurrentUrl } from "../../redux/root-reducer";

const descriptions = [
  { url: "/", description: "requests" },
  { url: "/requests", description: "requests" },
  { url: "/requests/open", description: "open requests" },
  { url: "/requests/sent-to-gp", description: "requests sent to a GP" },
  { url: "/requests/closed", description: "closed requests" },
  { url: "/orders", description: "orders" },
  { url: "/orders/invoiced", description: "orders awaiting payment" },
  { url: "/orders/dispense", description: "orders ready to process" },
  { url: "/orders/pickup", description: "orders ready to pick up" },
  { url: "/orders/dispatched", description: "posted orders" },
  { url: "/orders/delivered", description: "delivered orders" },
  { url: "/orders/print-pmr", description: "orders ready to dispense" },
  {
    url: "/orders/approve-exemption",
    description: "exemptions awaiting approval"
  }
];

export const getDescription = (state, routes = descriptions) => {
  const url = getCurrentUrl(state);
  const found = routes.find(route => route.url === url);

  return found ? found.description : "";
};

export default descriptions;
