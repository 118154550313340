import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ADD_ITEM_OP, confirmOpIsValid, REMOVE_ITEM_OP } from "./data";
import s from "./style.module.scss";
import ConfirmActionDialog from "./_ConfirmActionDialog";

const Screen = ({ reload, onReload, ...props }) => {
  // On demand
  useEffect(() => {
    onReload();
  }, [reload, onReload]);

  // Manage display and data for ConfirmMCSActionDialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogAmpId, setConfirmDialogAmpId] = useState(null);
  const [confirmDialogOp, setConfirmDialogOp] = useState(null);

  return (
    <div className={s.root}>
      <Grid container justify="center">
        <Grid item xs={8}>
          <AddItemInput
            addEnabled={!props.adding}
            onAdd={id => {
              setShowConfirmDialog(true);
              setConfirmDialogAmpId(id);
              setConfirmDialogOp(ADD_ITEM_OP);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          {props.fetching ? (
            <Loading />
          ) : (
            <MedList
              ampIds={props.ampIds}
              medsByAmp={props.medsByAmp}
              removing={props.removing}
              setShowConfirmDialog={setShowConfirmDialog}
              setConfirmDialogAmpId={setConfirmDialogAmpId}
              setConfirmDialogOp={setConfirmDialogOp}
            />
          )}
        </Grid>
      </Grid>
      {showConfirmDialog && confirmOpIsValid(confirmDialogOp) ? (
        <ConfirmActionDialog
          op={confirmDialogOp}
          ampId={confirmDialogAmpId}
          loading={props.searching}
          onConfirm={getOnConfirm(
            confirmDialogOp,
            confirmDialogAmpId,
            () => setShowConfirmDialog(false),
            props
          )}
          onCancel={() => setShowConfirmDialog(false)}
          onSearch={props.onSearch}
          medicine={props.medResult}
        />
      ) : null}
    </div>
  );
};

const MedList = props => {
  if (!props.ampIds.length) {
    return null;
  }
  return props.ampIds.map((id, i) => {
    // ampIds and medsByAmp are separate reducers and update slightly out sync so test for med medsByAmp[id]
    if (!props.medsByAmp[id]) {
      return null;
    }
    return (
      <Grid
        key={id}
        item
        xs={12}
        className="t_mcs_item"
        id={`t_amp_remoove_button_${i}`}
      >
        <MCSItem
          ampId={id}
          medicine={props.medsByAmp[id]}
          onClick={id => {
            props.setShowConfirmDialog(true);
            props.setConfirmDialogAmpId(id);
            props.setConfirmDialogOp(REMOVE_ITEM_OP);
          }}
          buttonDisabled={props.removing}
        />
      </Grid>
    );
  });
};

const MCSItem = ({ ampId, medicine, onClick, buttonDisabled }) => (
  <Paper key={ampId} className={s.mcsItem}>
    <Grid container justify="space-between">
      <Grid item xs={10}>
        <Typography variant="subtitle1">{`AMPID: ${ampId}`}</Typography>
        <Typography variant="subtitle1">
          {medicine.brandDesc || medicine.prodDesc}
        </Typography>
      </Grid>
      <Grid container item direction="column" justify="center" xs={2}>
        <Button
          className={`t_amp_remoove_button ${s.removeButton}`}
          disabled={buttonDisabled}
          onClick={() => onClick(ampId)}
          variant="contained"
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  </Paper>
);

const Loading = () => (
  <Grid item container xs={12} justify="center" className={s.dialogLoading}>
    <CircularProgress />
  </Grid>
);

const AddItemInput = props => {
  const [ampId, setAmpId] = useState("");

  return (
    <Paper className={s.mcsItem}>
      <Grid container justify="space-between">
        <Grid item xs={10}>
          <TextField
            id="t_amp_input"
            variant="outlined"
            placeholder={"Enter a numeric AMP ID - eg. 9107811000001105"}
            fullWidth
            onChange={e => setAmpId(e.target.value)}
            className={s.helpText}
          />
        </Grid>
        <Grid container item justify="flex-end" xs={2}>
          <Button
            id="t_amp_input_add_button"
            disabled={!ampId || !props.addEnabled}
            onClick={() => props.onAdd(ampId)}
            variant="contained"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={s.helpText}>
          <Typography>
            {"Please use the "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.nhsbsa.nhs.uk/DMDBrowser/DMDBrowser.do#product"
            >
              NHS DM+D Browser
            </a>{" "}
            to find an AMP ID
          </Typography>
        </div>
      </Grid>
    </Paper>
  );
};

const getOnConfirm = (op, ampId, closeDialog, props) => {
  switch (op) {
    case ADD_ITEM_OP:
      return () => {
        closeDialog();
        props.onAddItem(ampId);
      };
    case REMOVE_ITEM_OP:
      return () => {
        closeDialog();
        props.onRemoveItem(ampId);
      };
    default:
      throw new Error("unrecognised MCS item operation");
  }
};

Screen.propTypes = {
  medsByAmp: PropTypes.object,
  ampIds: PropTypes.arrayOf(PropTypes.string),
  medResult: PropTypes.object,
  adding: PropTypes.bool,
  removing: PropTypes.bool,
  searching: PropTypes.bool,
  reload: PropTypes.bool,
  fetching: PropTypes.bool,
  onReload: PropTypes.func,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onSearch: PropTypes.func
};
Screen.defaultProps = {
  medsByAmp: null,
  ampIds: [],
  medResult: null,
  adding: false,
  removing: false,
  searching: false,
  reload: false,
  fetching: false,
  onReload: () => {},
  onAddItem: () => {},
  onRemoveItem: () => {},
  onSearch: () => {}
};

export default Screen;
