import React, { FC } from "react";

import { OrdersFilterProps } from ".";
import OrdersFilter from "./orders-filter.ui";

const OrdersFilterContainer: FC<OrdersFilterProps> = props => {
  return <OrdersFilter {...props} />;
};

export default OrdersFilterContainer;
