import { sendNotification } from "../../notification";

const getErrorMessage = payload => {
  let errorMessage = "Something went wrong.";
  // Try to get the error mesage from the server respons
  if (payload.response && payload.response.error)
    errorMessage = payload.response.error.message;
  return `Error: ${errorMessage}`;
};

const shouldSkipNotification = action =>
  action.meta != null && action.meta.skipErrorNotification;

export default () => next => action => {
  if (
    action.payload &&
    action.payload.status >= 400 &&
    !shouldSkipNotification(action)
  ) {
    next(sendNotification({ message: getErrorMessage(action.payload) }));
  }
  return next(action);
};
