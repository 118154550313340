import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import s from "./style.module.scss";
import CostButton from "./cost-button";
import DeleteButton from "./delete-button";
import Cell from "./cell";
import AutoComplete from "../common/autocomplete";
import {
  getCreateOrderIsOpen,
  getCreateOrderIsEditingMedicine,
  getCreateOrderMedicines,
  getMedicineName,
  getMedicineDescription,
  getMedicineCost,
  getCreateOrderCanCreateOrder,
  getCreateOrderRequestId,
  getCreateOrderIsCreatingOrder,
  getCreateOrderCanAddMedicine,
  getCreateOrderCanUpdateMedicines,
  getCreateOrderPatient,
  getCreateOrderPatientsSearchText,
  getCreateOrderPatientsSuggestions,
  getCreateOrderCanChangePatient,
  getCreateOrderRxToken,
  getCreateOrderClickAndCollect
} from "../../redux/root-reducer";
import {
  addMedicineField,
  selectMedSuggestion,
  incrementCost,
  decrementCost,
  deleteMedicine,
  markContraceptive,
  closeCreateOrder,
  createOrder,
  removeMedicineField,
  changePatientSearch,
  clearPatientsSuggestions,
  selectPatientSuggestion,
  clearSelectedPatient,
  onRxTokenTextChange
} from "../../redux/create-order";
import { showConfirmDialog } from "../../redux/confirm-dialog";
import LoadingButton from "../common/loading-button";
import Patient from "./patient";
import { formatDateString } from "../../helpers/date-helpers";
import MedicineSearch from "components/common/medicine-search";

export class CreateOrder extends Component {
  renderMedicineRow = (medicine, index) => (
    <TableRow
      id={`t_medicine_row_${index}`}
      className={"t_medicine_row"}
      key={index}
    >
      <Cell>
        <div>
          <p className={s.name}>{getMedicineName(medicine)}</p>
          <p className={s.description}>{getMedicineDescription(medicine)}</p>
        </div>
      </Cell>
      {this.renderFunctionCells({
        index,
        medicine,
        disabled: !this.props.canUpdateMedicines,
        deleteDisabled: !this.props.canUpdateMedicines,
        deleteAction: () => this.props.deleteMedicine(index),
        cost: getMedicineCost(medicine),
        checked: medicine.isContraceptive
      })}
    </TableRow>
  );

  renderEditingRowIfNeeded() {
    const { isEditingMedicine, onMedSuggestionSelect } = this.props;
    if (!isEditingMedicine) return null;

    return (
      <TableRow id={"t_editing_row"}>
        <Cell key={"editing"}>
          <MedicineSearch onMedSuggestionSelect={onMedSuggestionSelect} />
        </Cell>
        {this.renderFunctionCells({
          disabled: true,
          cost: "0.00",
          deleteDisabled: false,
          deleteAction: this.props.onDeleteAddingMedicine
        })}
      </TableRow>
    );
  }

  renderFunctionCells = ({
    disabled,
    deleteDisabled,
    deleteAction,
    checked = false,
    index = "editing",
    cost
  }) => [
    <Cell className={s.checkboxCell} key={index}>
      <Checkbox
        id={`t_checkbox_${index}`}
        checked={checked}
        disabled={disabled}
        onChange={(e, isChecked) =>
          this.props.markContraceptive(index, isChecked)
        }
      />
    </Cell>,
    <Cell className={s.costCell} key={index + 1}>
      <CostButton
        id={`t_cost_down_${index}`}
        icon={"minus"}
        disabled={disabled}
        onClick={() => this.props.decrementMedicineCost(index)}
      />
      <span>£ {cost}</span>
      <CostButton
        id={`t_cost_up_${index}`}
        icon={"plus"}
        disabled={disabled}
        onClick={() => this.props.incrementMedicineCost(index)}
      />
    </Cell>,
    <Cell className={s.deleteCell} key={index + 2}>
      <DeleteButton
        id={`t_delete_${index}`}
        disabled={deleteDisabled}
        onClick={deleteAction}
      >
        Delete
      </DeleteButton>
    </Cell>
  ];

  renderActionButtons = () => [
    <Button
      id={"t_add_medicine_button"}
      disabled={!this.props.canAddMedicine}
      key={"add"}
      color={"secondary"}
      onClick={this.props.onAddMedicine}
      className={s.leftAction}
    >
      Add Medicine
    </Button>,
    <Button
      id={"t_cancel_button"}
      key={"cancel"}
      color={"secondary"}
      disabled={this.props.isCreatingOrder}
      onClick={this.props.onCancel}
    >
      Cancel
    </Button>,
    <LoadingButton
      id={"t_create_order_button"}
      key={"order"}
      color={"primary"}
      loading={this.props.isCreatingOrder}
      onClick={this.props.onCreateOrder}
      disabled={!this.props.canCreateOrder}
    >
      Create Order
    </LoadingButton>
  ];

  renderPatient() {
    const { patient, canChangePatient, onChangePatient } = this.props;
    return (
      <div>
        {patient ? <Patient {...patient} /> : null}
        {canChangePatient ? (
          <Button
            id={"t_change_patient_button"}
            style={{ paddingLeft: 0 }}
            onClick={onChangePatient}
            color={"secondary"}
          >
            Change customer
          </Button>
        ) : null}
        {!patient ? this.renderPatientAutocomplete() : null}
      </div>
    );
  }

  renderPatientAutocomplete() {
    const {
      onPatientsSuggestionTextChange,
      onClosePatientsSuggestions,
      onPatientSuggestionSelect,
      patientsSuggestions,
      patientsSearchText
    } = this.props;
    const suggestions = patientsSuggestions.map(p => ({
      name: `${p.firstName} ${p.lastName}`,
      description: `${formatDateString(p.dob)}, ${p.addressLine1}, ${
        p.postcode
      }`
    }));
    return (
      <div style={{ marginBottom: 145, width: "50%" }}>
        <AutoComplete
          id={"t_autocomplete_patients"}
          placeholder={"Customer name"}
          onTextChange={t => onPatientsSuggestionTextChange(t)}
          onOuterClick={() => onClosePatientsSuggestions()}
          onSuggestionSelect={index =>
            onPatientSuggestionSelect(patientsSuggestions[index])
          }
          text={patientsSearchText}
          suggestions={suggestions}
        />
      </div>
    );
  }

  render() {
    const { medicines, isOpen, isEditingMedicine, onRxTextChange } = this.props;
    return (
      <Dialog
        id={"t_dialog"}
        open={isOpen}
        fullWidth
        maxWidth={"md"}
        className={s.dialog}
      >
        <DialogTitle>Create Order</DialogTitle>
        <DialogContent>
          {this.renderPatient()}
          <Table>
            <TableHead>
              <TableRow>
                <Cell>Medication</Cell>
                <Cell className={s.centered}>Contraceptive</Cell>
                <Cell className={s.centered}>Cost</Cell>
                <Cell />
              </TableRow>
            </TableHead>
            <TableBody>
              {medicines.map(this.renderMedicineRow)}
              {this.renderEditingRowIfNeeded()}
            </TableBody>
          </Table>
          {medicines.length === 0 && !isEditingMedicine ? (
            <EmptyContent id={"t_empty"} />
          ) : null}
          {this.props.requestId && this.props.clickAndCollect && (
            <div style={{ paddingTop: "16px" }}>
              <p className={s.description} style={{ marginBottom: "12px" }}>
                This is a Click and Collect request. You must enter the
                prescription code to create the order.
              </p>
              <p className={s.name}>Prescription code</p>
              <TextField
                id={"t_input"}
                fullWidth
                disabled={false}
                defaultValue={""}
                onChange={e => onRxTextChange(e.currentTarget.value)}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>{this.renderActionButtons()}</DialogActions>
      </Dialog>
    );
  }
}

const EmptyContent = () => {
  const textStyle = { width: "50%", textAlign: "center", margin: "auto" };
  const rootStyle = { marginTop: "40px", marginBottom: "80px", width: "100%" };
  return (
    <div style={rootStyle}>
      <Typography style={textStyle} variant={"h6"}>
        Add medications
      </Typography>
      <Typography style={textStyle}>
        You need to add medication before you can create this order.
      </Typography>
    </div>
  );
};

CreateOrder.propTypes = {
  // - Meds suggestions
  onMedSuggestionSelect: PropTypes.func.isRequired,
  // - Patients suggestions
  onPatientsSuggestionTextChange: PropTypes.func.isRequired,
  onClosePatientsSuggestions: PropTypes.func.isRequired,
  onPatientSuggestionSelect: PropTypes.func.isRequired,
  patientsSearchText: PropTypes.string.isRequired,
  patientsSuggestions: PropTypes.array.isRequired,
  // - Meds actions
  onAddMedicine: PropTypes.func.isRequired,
  incrementMedicineCost: PropTypes.func.isRequired,
  decrementMedicineCost: PropTypes.func.isRequired,
  deleteMedicine: PropTypes.func.isRequired,
  onDeleteAddingMedicine: PropTypes.func.isRequired,
  // - Dialog
  markContraceptive: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreateOrder: PropTypes.func.isRequired,
  // Data
  patient: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  isEditingMedicine: PropTypes.bool.isRequired,
  medicines: PropTypes.array.isRequired,
  canCreateOrder: PropTypes.bool.isRequired,
  canAddMedicine: PropTypes.bool.isRequired,
  isCreatingOrder: PropTypes.bool.isRequired,
  canUpdateMedicines: PropTypes.bool.isRequired,
  canChangePatient: PropTypes.bool.isRequired,
  onChangePatient: PropTypes.func.isRequired
};

CreateOrder.defaultProps = {
  patient: null
};

export const mapDispatchToProps = {
  onAddMedicine: addMedicineField,
  onDeleteAddingMedicine: removeMedicineField,
  onMedSuggestionSelect: selectMedSuggestion,
  onPatientsSuggestionTextChange: changePatientSearch,
  onClosePatientsSuggestions: clearPatientsSuggestions,
  onPatientSuggestionSelect: selectPatientSuggestion,
  incrementMedicineCost: incrementCost,
  decrementMedicineCost: decrementCost,
  onChangePatient: clearSelectedPatient,
  deleteMedicine,
  markContraceptive,
  onCancel: closeCreateOrder,
  onRxTextChange: onRxTokenTextChange,
  confirmDialog: next =>
    showConfirmDialog({
      title: "Are you sure?",
      message:
        "Creating an order will send a notification to the customer to let them know that they need to provide their exemption confirmation or pay. Are you sure you want to create this order?",
      positiveActionTitle: "Create Order",
      agreeActionCreator: () => next()
    })
};

export const mapStateToProps = state => ({
  patient: getCreateOrderPatient(state),
  isOpen: getCreateOrderIsOpen(state),
  isEditingMedicine: getCreateOrderIsEditingMedicine(state),
  medicines: getCreateOrderMedicines(state),
  canCreateOrder: getCreateOrderCanCreateOrder(state),
  requestId: getCreateOrderRequestId(state),
  isCreatingOrder: getCreateOrderIsCreatingOrder(state),
  canAddMedicine: getCreateOrderCanAddMedicine(state),
  canUpdateMedicines: getCreateOrderCanUpdateMedicines(state),
  patientsSearchText: getCreateOrderPatientsSearchText(state),
  patientsSuggestions: getCreateOrderPatientsSuggestions(state),
  canChangePatient: getCreateOrderCanChangePatient(state),
  rxToken: getCreateOrderRxToken(state),
  clickAndCollect: getCreateOrderClickAndCollect(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onCreateOrder: () =>
    dispatchProps.confirmDialog(() =>
      createOrder(
        stateProps.requestId,
        stateProps.patient.userId,
        stateProps.medicines,
        stateProps.rxToken
      )
    ),
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateOrder);
