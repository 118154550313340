import { handleActions } from "redux-actions";
import { AnyAction } from "redux";
import {
  GP_CONTACT_REVIEW_START,
  GP_CONTACT_REVIEW_FETCH_REQUEST,
  GP_CONTACT_REVIEW_FETCH_RECEIVE,
  GP_CONTACT_REVIEW_FETCH_FAILURE
} from "./actions";
import { SET_GP_PROFILE_RECEIVE } from "../gp-profile";
import { PagedGPPayload } from "./types";
import { GP } from "../gp-nhs-refresh/types";

export const isPageLoaded = handleActions(
  {
    [GP_CONTACT_REVIEW_START]: () => true,
    [SET_GP_PROFILE_RECEIVE]: () => false
  },
  false
);

export const gps = handleActions(
  {
    [GP_CONTACT_REVIEW_FETCH_RECEIVE]: (state: GP[], action: AnyAction) => {
      const gppa = action.payload as PagedGPPayload;
      if (!gppa || !gppa.practices) {
        return state;
      }

      return [...state, ...gppa.practices];
    },
    [SET_GP_PROFILE_RECEIVE]: () => []
  },
  []
);

export const isLoadingGPs = handleActions(
  {
    [GP_CONTACT_REVIEW_FETCH_REQUEST]: () => true,
    [GP_CONTACT_REVIEW_FETCH_RECEIVE]: () => false,
    [GP_CONTACT_REVIEW_FETCH_FAILURE]: () => false
  },
  false
);

export const gpCursor = handleActions(
  {
    [GP_CONTACT_REVIEW_FETCH_RECEIVE]: (state: string, action: AnyAction) => {
      const gppa = action.payload as PagedGPPayload;
      if (!gppa) {
        return state;
      }

      return gppa.cursor;
    }
  },
  ""
);
