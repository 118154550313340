import { Button } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import s from "./common-styles.module.scss";
import cx from "clsx";

const TrackingInfo = ({ courier, trackingUrl, tracked, trackingCode }) => (
  <div style={{ marginTop: 30 }}>
    <p className="t_courier">{mapCourierName(courier)}</p>
    {(!tracked || !trackingUrl) && trackingCode && <><br /> <p className="t_courier">{trackingCode}</p></>}
    {tracked && trackingUrl && <Button
      target="_blank"
      href={trackingUrl}
      onClick={event => {
        if (event) {
          event.stopPropagation();
        }
      }}
      className={cx(s.editButton, "t_trackingUrl")}
      variant="text"
      color="secondary"
    >
      Tracking
    </Button>}
  </div>
);

TrackingInfo.propTypes = {
  courier: PropTypes.string.isRequired,
  trackingUrl: PropTypes.string.isRequired
};

function mapCourierName(courier) {
  switch (courier) {
    case "royal_mail":
      return "RoyalMail";
    case "whistl":
      return "Whistl";
    default:
      return courier;
  }
}

export default TrackingInfo;
