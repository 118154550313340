import React from "react";
import PropTypes from "prop-types";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import s from "./common-styles.module.scss";

const GenericStatusIconCell = props => {
  const { status } = props;
  return (
    <div className={s.statusCell}>
      {status ? (
        <span className={s.greenIcon}>
          <CheckCircle />
        </span>
      ) : (
        <span className={s.redIcon}>
          <CancelIcon />
        </span>
      )}
    </div>
  );
};

GenericStatusIconCell.propTypes = {
  status: PropTypes.bool
};

export default GenericStatusIconCell;
