export const MCSAllCustomerResponse = {
  1: { code: 1, copy: "Return to spine " },
  2: {
    code: 2,
    copy:
      "Keep trying to source (dispense any other available items in script) "
  },
  3: { code: 3, copy: "Ask GP to prescribe an alternative " },
  4: { code: 4, copy: "Mark item not dispensed " },
  5: { code: 5, copy: "Keep trying to source " }
};

export const MCSCustomerResponse = [1, 2, 3].map(
  code => MCSAllCustomerResponse[code]
);
export const MCSPendingNestedOrderCustomerResponse = [4, 5, 3].map(
  code => MCSAllCustomerResponse[code]
);
export const MCSPendingSingleOrderCustomerResponse = [1, 5, 3].map(
  code => MCSAllCustomerResponse[code]
);

export const MCSActionResponse = [
  { code: 1, copy: "Yes" },
  {
    code: 2,
    copy: "No"
  }
];
export const MCSRTSTitle = "Return prescription to spine";
export const MCSGPAltTitle = "GP to prescribe an alternative";
export const getDate = event => event.eventDate || null;
export const getNotes = event => event.notes || null;
export const getMCSCustomerResponse = event =>
  event.mcsCustomerResponse
    ? MCSAllCustomerResponse[event.mcsCustomerResponse]?.copy
    : "";
export const getUserName = event => event.userName || null;
export const getFormattedName = event => {
  if (!event.type) return null;
  const names = event.type
    .split("_")
    .map(p => p.charAt(0).toUpperCase() + p.slice(1).toLowerCase())
    .join(" ");
  const source = event.forRequest ? "Request" : "Order";
  return `${source} ${names}`;
};
