import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const PHARMACY_ENDPOINT = process.env.REACT_APP_PHARMACY_API;
const DMD_ENDPOINT = process.env.REACT_APP_MEDICINES_API;

// ACTIONS
export const GET_MCS_ITEMS = "MCS_MED_LIST/GET_MCS_ITEMS";
export const GET_MCS_ITEMS_REQUEST = "MCS_MED_LIST/GET_MCS_ITEMS_REQUEST";
export const GET_MCS_ITEMS_RESPONSE = "MCS_MED_LIST/GET_MCS_ITEMS_RESPONSE";
export const GET_MCS_ITEMS_FAILURE = "MCS_MED_LIST/GET_MCS_ITEMS_FAILURE";

export const ADD_MCS_ITEM = "MCS_MED_LIST/ADD_MCS_ITEM";
export const ADD_MCS_ITEM_REQUEST = "MCS_MED_LIST/ADD_MCS_ITEM_REQUEST";
export const ADD_MCS_ITEM_RESPONSE = "MCS_MED_LIST/ADD_MCS_ITEM_RESPONSE";
export const ADD_MCS_ITEM_FAILURE = "MCS_MED_LIST/ADD_MCS_ITEM_FAILURE";

export const REMOVE_MCS_ITEM = "MCS_MED_LIST/REMOVE_MCS_ITEM";
export const REMOVE_MCS_ITEM_REQUEST = "MCS_MED_LIST/REMOVE_MCS_ITEM_REQUEST";
export const REMOVE_MCS_ITEM_RESPONSE = "MCS_MED_LIST/REMOVE_MCS_ITEM_RESPONSE";
export const REMOVE_MCS_ITEM_FAILURE = "MCS_MED_LIST/REMOVE_MCS_ITEM_FAILURE";

export const DMD_AMP_SEARCH = "MCS_MED_LIST/DMD_AMP_SEARCH";
export const DMD_AMP_SEARCH_REQUEST = "MCS_MED_LIST/DMD_AMP_SEARCH_REQUEST";
export const DMD_AMP_SEARCH_RESPONSE = "MCS_MED_LIST/DMD_AMP_SEARCH_RESPONSE";
export const DMD_AMP_SEARCH_FAILURE = "MCS_MED_LIST/DMD_AMP_SEARCH_FAILURE";

export const getMCSItems = (apiEndpoint = PHARMACY_ENDPOINT) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/hsc/mcs`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        GET_MCS_ITEMS_REQUEST,
        GET_MCS_ITEMS_RESPONSE,
        GET_MCS_ITEMS_FAILURE
      ].map(type => ({
        type
      })),
      options: { addAuth: true }
    }
  };
};
export const addMCSItem = (ampId, apiEndpoint = PHARMACY_ENDPOINT) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/hsc/mcs/${ampId}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        ADD_MCS_ITEM_REQUEST,
        ADD_MCS_ITEM_RESPONSE,
        ADD_MCS_ITEM_FAILURE
      ].map(type => ({
        type,
        meta: { ampId }
      })),
      options: { addAuth: true }
    }
  };
};

export const removeMCSItem = (ampId, apiEndpoint = PHARMACY_ENDPOINT) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/hsc/mcs/${ampId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        REMOVE_MCS_ITEM_REQUEST,
        REMOVE_MCS_ITEM_RESPONSE,
        REMOVE_MCS_ITEM_FAILURE
      ].map(type => ({
        type,
        meta: { ampId }
      })),
      options: { addAuth: true }
    }
  };
};

export const dmdAmpSearch = (ampId, apiEndpoint = DMD_ENDPOINT) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/amp/${ampId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        DMD_AMP_SEARCH_REQUEST,
        DMD_AMP_SEARCH_RESPONSE,
        DMD_AMP_SEARCH_FAILURE
      ].map(type => ({
        type,
        meta: { ampId }
      })),
      options: { addAuth: true }
    }
  };
};

// SELECTORS
export const getMedsByAmp = state => state.medsByAmp;
export const getMCSAmpIds = state => state.mcsAmpIds;
export const getAddingMCS = state => state.addingMCS;
export const getGettingMCS = state => state.gettingMCS;
export const getRemovingMCS = state => state.removingMCS;
export const getSearchingDMD = state => state.searchingDMD;
export const getDMDAmpResult = state => state.dmdAmpResult;
export const getReload = state => state.reload;

// REDUCERS
const medsByAmp = handleActions(
  {
    [GET_MCS_ITEMS_RESPONSE]: (_, action) => action.payload.medsByAmp
  },
  null
);

const mcsAmpIds = handleActions(
  {
    [GET_MCS_ITEMS_RESPONSE]: (_, action) =>
      action.payload.ampIds === null ? [] : action.payload.ampIds,
    [ADD_MCS_ITEM_RESPONSE]: (state, action) => [...state, action.meta.ampId],
    [REMOVE_MCS_ITEM_RESPONSE]: (state, action) =>
      state.filter(id => id !== action.meta.ampId)
  },
  []
);

const addingMCS = handleActions(
  {
    [ADD_MCS_ITEM_REQUEST]: () => true,
    [ADD_MCS_ITEM_RESPONSE]: () => false,
    [ADD_MCS_ITEM_FAILURE]: () => false
  },
  false
);

const removingMCS = handleActions(
  {
    [REMOVE_MCS_ITEM_REQUEST]: () => true,
    [REMOVE_MCS_ITEM_RESPONSE]: () => false,
    [REMOVE_MCS_ITEM_FAILURE]: () => false
  },
  false
);

const gettingMCS = handleActions(
  {
    [GET_MCS_ITEMS_REQUEST]: () => true,
    [GET_MCS_ITEMS_RESPONSE]: () => false,
    [GET_MCS_ITEMS_FAILURE]: () => false
  },
  false
);

const searchingDMD = handleActions(
  {
    [DMD_AMP_SEARCH_REQUEST]: () => true,
    [DMD_AMP_SEARCH_RESPONSE]: () => false,
    [DMD_AMP_SEARCH_FAILURE]: () => false
  },
  false
);

const dmdAmpResult = handleActions(
  {
    [DMD_AMP_SEARCH_REQUEST]: () => null,
    [DMD_AMP_SEARCH_RESPONSE]: (_, action) => action.payload
  },
  null
);

const reload = handleActions(
  {
    [REMOVE_MCS_ITEM_RESPONSE]: () => true,
    [ADD_MCS_ITEM_RESPONSE]: () => true,
    [GET_MCS_ITEMS_REQUEST]: () => false
  },
  false
);

export default combineReducers({
  mcsAmpIds,
  medsByAmp,
  addingMCS,
  removingMCS,
  searchingDMD,
  dmdAmpResult,
  gettingMCS,
  reload
});
