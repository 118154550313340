import { connect } from "react-redux";
import {
  deleteMedicineFilter,
  clearOrderFilters,
  selectMedicineFilter
} from "redux/orders";

import { MedSuggestion } from "../../../../redux/meds-search";
import {
  getCurrentUrl,
  getOrdersMedicineFilters,
  getShowOrdersFilters
} from "../../../../redux/root-reducer";

import Container from "./orders-filter.container";

export interface OrdersFilterProps {
  isOpen: boolean;
  medicines: MedSuggestion[];
  currentUrl: string;
  selectMedicine(medicine: MedSuggestion): void;
  deleteMedicine(medicine: MedSuggestion): void;
  resetFilters(): void;
}

const mapStateToProps = state => ({
  isOpen: getShowOrdersFilters(state),
  medicines: getOrdersMedicineFilters(state),
  currentUrl: getCurrentUrl(state)
});

const mapDispatchToProps = {
  selectMedicine: selectMedicineFilter,
  deleteMedicine: deleteMedicineFilter,
  resetFilters: clearOrderFilters
};

export const FILTERS_WIDTH = 280;

export default connect(mapStateToProps, mapDispatchToProps)(Container);
