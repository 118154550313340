import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

// List of available package
export const PACKAGES = [
  { name: "Large Letter", value: "largeLetter", weight: 99 },
  { name: "Parcel", value: "parcel", weight: 250 }
];

const DEFAULT_PACKAGE_INDEX = 0;

// List of available services
export const SERVICES = [
  { name: "Second Class 48h", value: "48" },
  { name: "Second Class 24h", value: "24" },
  { name: "Tracked 24h", value: "tracked24" },
  { name: "Tracked 48h", value: "tracked48" },
  { name: "Guaranteed Next Day 1pm", value: "guaranteed1pm" }
];

// Actions
export const SHOW_CREATE_DISPATCH_LABEL = "SHOW_CREATE_DISPATCH_LABEL";
export const HIDE_CREATE_DISPATCH_LABEL = "HIDE_CREATE_DISPATCH_LABEL";
export const SELECT_PACKAGE = "SELECT_PACKAGE";
export const UPDATE_WEIGHT = "UPDATE_WEIGHT";
export const SELECT_SERVICE = "SELECT_SERVICE";
export const TOGGLE_SIGNED_DELIVERY = "TOGGLE_SIGNED_DELIVERY";
export const CREATE_DISPATCH_LABEL_REQUEST = "CREATE_DISPATCH_LABEL_REQUEST";
export const CREATE_DISPATCH_LABEL_RECEIVE = "CREATE_DISPATCH_LABEL_RECEIVE";
export const CREATE_DISPATCH_LABEL_FAILURE = "CREATE_DISPATCH_LABEL_FAILURE";
export const SHIPPING_SCANNER_RESET = "SHIPPING_SCANNER_RESET";

// Action Creators
export const showCreateDispatchLabel = order => ({
  type: SHOW_CREATE_DISPATCH_LABEL,
  deliveryAddress: order.deliveryAddress,
  orderId: order.id,
  service: SERVICES.map(s => s.value).indexOf(order.shippingClass),
  fullName: `${order.patient.firstName} ${order.patient.lastName}`
});

export const hideCreateDispatchLabel = () => ({
  type: HIDE_CREATE_DISPATCH_LABEL
});

export const selectPackage = index => ({
  type: SELECT_PACKAGE,
  index
});

export const selectService = index => ({
  type: SELECT_SERVICE,
  index
});

export const toggleSignedDelivery = () => ({
  type: TOGGLE_SIGNED_DELIVERY
});

export const updateWeight = value => ({
  type: UPDATE_WEIGHT,
  value
});

export const createDispatchLabel = (
  orderId,
  weight,
  selectedPackage,
  selectedService,
  signedDelivery,
  whistlEnabled,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${encodeURIComponent(
      orderId
    )}/dispatchlabel`,
    method: "POST",
    body: JSON.stringify({
      format: selectedPackage.value,
      service: selectedService.value,
      signed: signedDelivery,
      weight,
      preferWhistl: whistlEnabled
    }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      CREATE_DISPATCH_LABEL_REQUEST,
      CREATE_DISPATCH_LABEL_RECEIVE,
      CREATE_DISPATCH_LABEL_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const shippingScannerReset = () => ({
  type: SHIPPING_SCANNER_RESET
});

// Selectors
export const getIsOpen = state => state.isOpen;
export const getFullName = state => state.fullName;
export const getAddress = state => state.address;
export const getWeight = state => state.weight;
export const getLabelUrl = state => state.labelUrl;
export const getSignedDelivery = state => {
  if (!state || !state.createDispatchLabel) {
    return false;
  }
  if (getNeedsSignedDelivery(state)) {
    return true;
  }
  return !!state.createDispatchLabel.signedDelivery;
};
export const getNeedsSignedDelivery = state => {
  if (
    !state.orders ||
    !state.orders.byId ||
    !state.createDispatchLabel.orderId
  ) {
    return false;
  }
  const order = state.orders.byId[state.createDispatchLabel.orderId];
  if (!order || !order.medicines) {
    return false;
  }
  const hasCD = order.medicines.some(
    m =>
      m.isRestricted ||
      m.additional.controlled_schedule === "1" ||
      m.additional.controlled_schedule === "2" ||
      m.additional.controlled_schedule === "3"
  );
  return hasCD;
};
export const getSelectedPackageIndex = state => state.selectedPackageIndex;
export const getSelectedPackage = state => PACKAGES[state.selectedPackageIndex];
export const getPackageNames = () => PACKAGES.map(p => p.name);
export const getServiceNames = () => SERVICES.map(p => p.name);
export const getSelectedServiceIndex = state => state.selectedServiceIndex;
export const getSelectedService = state => SERVICES[state.selectedServiceIndex];
export const getOrderId = state => state.orderId;
export const getIsCreatingLabel = state => state.isCreatingLabel;
export const getCanCreateLabel = state =>
  state.selectedPackageIndex != null &&
  state.selectedServiceIndex != null &&
  state.weight > 0;
export const getError = state => state.error;
export const getExemptionCodes = state => state.exemptionCodes;

// Reducers
const isOpen = handleActions(
  {
    [SHOW_CREATE_DISPATCH_LABEL]: () => true,
    [HIDE_CREATE_DISPATCH_LABEL]: () => false,
    [CREATE_DISPATCH_LABEL_RECEIVE]: () => false
  },
  false
);

const orderId = handleActions(
  {
    [SHOW_CREATE_DISPATCH_LABEL]: (state, action) => action.orderId,
    [HIDE_CREATE_DISPATCH_LABEL]: () => null
  },
  null
);

const address = handleActions(
  {
    [SHOW_CREATE_DISPATCH_LABEL]: (state, action) => action.deliveryAddress
  },
  ""
);

const fullName = handleActions(
  {
    [SHOW_CREATE_DISPATCH_LABEL]: (state, action) => action.fullName
  },
  ""
);

const selectedPackageIndex = handleActions(
  {
    [SELECT_PACKAGE]: (state, action) => action.index,
    [SHOW_CREATE_DISPATCH_LABEL]: () => 0
  },
  0
);

const isCreatingLabel = handleActions(
  {
    [CREATE_DISPATCH_LABEL_REQUEST]: () => true,
    [CREATE_DISPATCH_LABEL_RECEIVE]: () => false,
    [CREATE_DISPATCH_LABEL_FAILURE]: () => false
  },
  false
);

const labelUrl = handleActions(
  {
    [SHOW_CREATE_DISPATCH_LABEL]: () => null,
    [HIDE_CREATE_DISPATCH_LABEL]: () => null,
    [CREATE_DISPATCH_LABEL_REQUEST]: () => null,
    [CREATE_DISPATCH_LABEL_RECEIVE]: (state, action) => action.payload.labelUrl,
    [CREATE_DISPATCH_LABEL_FAILURE]: () => null
  },
  null
);

const weight = handleActions(
  {
    [SHOW_CREATE_DISPATCH_LABEL]: () => PACKAGES[DEFAULT_PACKAGE_INDEX].weight,
    [SELECT_PACKAGE]: (state, action) => PACKAGES[action.index].weight,
    [UPDATE_WEIGHT]: (state, action) => {
      if (action.value === "") return null;
      const parsedValue = Number.parseInt(action.value, 10);
      if (Number.isNaN(parsedValue) || parsedValue <= 0) return state;
      return parsedValue;
    }
  },
  null
);

const selectedServiceIndex = handleActions(
  {
    [SELECT_SERVICE]: (state, action) => action.index,
    [SHOW_CREATE_DISPATCH_LABEL]: (state, action) => action.service
  },
  0
);

const signedDelivery = handleActions(
  {
    [TOGGLE_SIGNED_DELIVERY]: state => !state,
    [SHOW_CREATE_DISPATCH_LABEL]: () => false
  },
  false
);

const error = handleActions(
  {
    [CREATE_DISPATCH_LABEL_REQUEST]: () => null,
    [CREATE_DISPATCH_LABEL_RECEIVE]: () => null,
    [SHIPPING_SCANNER_RESET]: () => null,
    [CREATE_DISPATCH_LABEL_FAILURE]: (_, action) =>
      action.payload.response.error.message || "unknown error creating label"
  },
  null
);

const exemptionCodes = handleActions(
  {
    [CREATE_DISPATCH_LABEL_REQUEST]: () => null,
    [CREATE_DISPATCH_LABEL_RECEIVE]: (_, action) =>
      action.payload.exemptionCodes,
    [CREATE_DISPATCH_LABEL_FAILURE]: () => null,
    [SHIPPING_SCANNER_RESET]: () => null
  },
  null
);

export default combineReducers({
  isOpen,
  isCreatingLabel,
  weight,
  address,
  orderId,
  fullName,
  labelUrl,
  selectedPackageIndex,
  selectedServiceIndex,
  signedDelivery,
  error,
  exemptionCodes
});
