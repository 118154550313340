import React, { FC } from "react";
import { Grid, Box } from "@material-ui/core";
import RefreshedField from "./refreshed-field";
import RefreshedMultiField from "./refreshed-multifield";
import s from "./refreshed.module.scss";
import { GP, UnverifiedFields } from "../../redux/gp-nhs-refresh/types";
import { MakerReturn, MatchStatus } from "./map-maker";

export interface GPDetailsProps {
  gp: GP;
  title: string;
  updatedFields?: MakerReturn<GP>;
  unverifiedFields?: UnverifiedFields;
  showUpToDate?: boolean;
}

const yesNo = (value: boolean): string => (value ? "Yes" : "No");
const GpDetails: FC<GPDetailsProps> = ({
  gp,
  title,
  updatedFields,
  showUpToDate
}) => {
  const {
    name,
    contactPreference,
    status,
    email,
    telephone,
    fax,
    addressLine1,
    addressLine2,
    city,
    postcode,
    country,
    // eps,
    againstDigitalPharmacy,
    surgeryType
  } = gp;

  const addressUpdated = [
    updatedFields?.addressLine1,
    updatedFields?.addressLine2,
    updatedFields?.city,
    updatedFields?.postcode,
    updatedFields?.country
  ];

  const hasAddressUpdated = addressUpdated.some(
    value => value === MatchStatus.UPDATED
  );

  return (
    <Grid className={s.gpDetails} container spacing={3}>
      <Grid item xs={12}>
        <h4>
          {title}
          {showUpToDate ? (
            <Box
              className={s.upToDate}
              display={"inline"}
              color={"secondary.main"}
            >
              {" "}
              <span className={"upToDateMessage"}> Up to date</span>
            </Box>
          ) : null}
        </h4>
      </Grid>
      <Grid item xs={6}>
        <RefreshedField
          label={"GP name"}
          value={name}
          status={updatedFields?.name}
        />
        <RefreshedMultiField
          label={"Address"}
          values={[addressLine1, addressLine2, city, postcode, country]}
          updated={
            updatedFields && gp.id !== ""
              ? hasAddressUpdated
                ? MatchStatus.UPDATED
                : MatchStatus.VERIFIED
              : MatchStatus.EMPTY
          }
        />
        <RefreshedField
          label={"Phone number"}
          value={telephone}
          status={updatedFields?.telephone}
        />
        <RefreshedField
          label={"Fax number"}
          value={fax}
          status={updatedFields?.fax}
        />
      </Grid>
      <Grid item xs={6}>
        <RefreshedField
          label={"Preferred method of contact"}
          value={contactPreference}
        />
        <RefreshedField
          label={"Wants to work with us"}
          value={yesNo(!againstDigitalPharmacy)}
        />
        {/* DPB-563 Remove eps */}
        {/* <RefreshedField label={"EPS enabled"} value={yesNo(eps)} />/ */}
        <RefreshedField
          label={"Surgery type"}
          value={surgeryType}
          status={updatedFields?.surgeryType}
        />
        <RefreshedField
          label={"Email address"}
          value={email}
          status={updatedFields?.email}
        />
        <RefreshedField
          label={"Status"}
          value={status}
          status={updatedFields?.status}
        />
      </Grid>
    </Grid>
  );
};
export default GpDetails;
