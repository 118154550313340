import React from "react";
import PropTypes from "prop-types";

const NeedsAttention = ({ lastModifiedDate }) => (
  <svg width={"18px"} height={"18px"} viewBox={"0 0 18 18"}>
    {lastModifiedDate && (
      <title>This item has not been updated since {lastModifiedDate}</title>
    )}
    <defs />
    <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
      <path
        d={
          "M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z M9.89488636,13.2230114 L9.89488636,11.4332386 L8.10511364,11.4332386 L8.10511364,13.2230114 L9.89488636,13.2230114 Z M9.89977238,10.1395454 L9.89977238,4.55999994 L8.10999966,4.55999994 L8.10999966,10.1395454 L9.89977238,10.1395454 Z"
        }
        id={"Combined-Shape"}
        fill={"#000000"}
      />
    </g>
  </svg>
);
NeedsAttention.propTypes = {
  lastModifiedDate: PropTypes.string
};

export default NeedsAttention;
