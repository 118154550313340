import React from "react";
import PropTypes from "prop-types";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Unknown from "@material-ui/icons/HelpOutline";
import DoNotContact from "@material-ui/icons/NotInterested";
import s from "./common-styles.module.scss";

const getIcon = (dontContact, hasContactPreference) => {
  if (dontContact) {
    return (
      <span className={s.gpDontContactIcon}>
        <DoNotContact />
      </span>
    );
  }
  if (!hasContactPreference) {
    return (
      <span className={s.gpUnknownIcon}>
        <Unknown />
      </span>
    );
  }
  return (
    <span className={s.gpOKIcon}>
      <CheckCircle />
    </span>
  );
};

const GPStatusCell = ({ dontContact, hasContactPreference }) => (
  <div className={s.statusCell}>
    {getIcon(dontContact, hasContactPreference)}
  </div>
);
GPStatusCell.propTypes = {
  dontContact: PropTypes.bool,
  hasContactPreference: PropTypes.bool
};
GPStatusCell.defaultProps = {
  dontContact: false,
  hasContactPreference: false
};

export default GPStatusCell;
