import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import GPMap from "./gp-map";
import ProfileForm from "./profile-form";
import { fetchCanRefreshGP, openRefreshGP } from "../../redux/gp-nhs-refresh";
import {
  fetchGPProfile,
  setGPProfileOnServer,
  hideGPProfileDialog,
  updateGPField
} from "../../redux/gp-profile";
import {
  getGPFieldValue,
  getGPFieldIsValid,
  getGPId,
  getGPLatitude,
  getGPLongitude,
  getGPCanSave,
  getGPAPISetObject,
  getCanGPRefresh,
  getGPLastUpdated
} from "../../redux/root-reducer";
import s from "./profile.module.scss";

export class Profile extends Component {
  static propTypes = {
    organisationCode: PropTypes.string.isRequired,
    isDeepLink: PropTypes.bool,
    fetchGPProfile: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    getMatchedRoute: PropTypes.func,
    canSave: PropTypes.bool,
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fields: PropTypes.object.isRequired,
    fetchCanRefreshGP: PropTypes.func.isRequired,
    offerGPRefresh: PropTypes.bool,
    openRefreshGP: PropTypes.func.isRequired
  };
  static defaultProps = {
    isDeepLink: false,
    canSave: false,
    offerGPRefresh: false,
    getMatchedRoute: () => true,
    latitude: null,
    longitude: null
  };

  componentDidMount() {
    const matchedRoute = this.props.getMatchedRoute();
    if (matchedRoute) {
      this.props.fetchGPProfile(matchedRoute.id);
      this.props.fetchCanRefreshGP();
    }
  }

  render() {
    const {
      organisationCode,
      latitude,
      longitude,
      onClose,
      onChange,
      onSubmit,
      fields,
      canSave,
      offerGPRefresh,
      openRefreshGP,
      lastUpdated,
      im1Provider
    } = this.props;
    return (
      <div className={s.root}>
        <div style={{ height: "25%" }}>
          <GPMap lat={latitude} lng={longitude} />
        </div>
        <ProfileForm
          organisationCode={organisationCode}
          onClose={onClose}
          onChange={onChange}
          onSubmit={onSubmit}
          canSave={canSave}
          offerGPRefresh={offerGPRefresh}
          openRefreshGP={openRefreshGP}
          lastUpdated={lastUpdated}
          {...fields}
        />
      </div>
    );
  }
}

export const mapDispatchToProps = {
  fetchGPProfile,
  onClose: hideGPProfileDialog,
  onChange: updateGPField,
  setGPProfileOnServer,
  fetchCanRefreshGP,
  openRefreshGP
};

export const mapStateToProps = state => ({
  organisationCode: getGPId(state),
  latitude: getGPLatitude(state),
  longitude: getGPLongitude(state),
  fields: {
    name: getGPFieldValue("name", state),
    nameValid: getGPFieldIsValid("name", state),
    email: getGPFieldValue("email", state),
    emailValid: getGPFieldIsValid("email", state),
    telephone: getGPFieldValue("telephone", state),
    telephoneValid: getGPFieldIsValid("telephone", state),
    fax: getGPFieldValue("fax", state),
    faxValid: getGPFieldIsValid("fax", state),
    addressLine1: getGPFieldValue("addressLine1", state),
    addressLine1Valid: getGPFieldIsValid("addressLine1", state),
    addressLine2: getGPFieldValue("addressLine2", state),
    addressLine2Valid: getGPFieldIsValid("addressLine2", state),
    city: getGPFieldValue("city", state),
    cityValid: getGPFieldIsValid("city", state),
    postcode: getGPFieldValue("postcode", state),
    postcodeValid: getGPFieldIsValid("postcode", state),
    country: getGPFieldValue("country", state),
    countryValid: getGPFieldIsValid("country", state),
    eps: getGPFieldValue("eps", state),
    epsValid: getGPFieldIsValid("eps", state),
    againstDigitalPharmacy: getGPFieldValue("againstDigitalPharmacy", state),
    againstDigitalPharmacyValid: getGPFieldIsValid(
      "againstDigitalPharmacy",
      state
    ),
    contactPreference: getGPFieldValue("contactPreference", state),
    contactPreferenceValid: getGPFieldIsValid("contactPreference", state),
    notes: getGPFieldValue("notes", state),
    notesValid: getGPFieldIsValid("notes", state),
    surgeryType: getGPFieldValue("surgeryType", state),
    surgeryTypeValid: getGPFieldIsValid("surgeryType", state),
    status: getGPFieldValue("status", state),
    statusValid: getGPFieldIsValid("status", state),
    im1Provider: getGPFieldValue("im1Provider", state)
  },
  canSave: getGPCanSave(state),
  offerGPRefresh: getCanGPRefresh(state),
  lastUpdated: getGPLastUpdated(state),
  apiObject: getGPAPISetObject(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => dispatchProps.setGPProfileOnServer(stateProps.apiObject)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Profile);
