import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";
import moment from "moment";
import { getEvidenceNeedsExpiration } from "../selectors/order";

const API_ENDPOINT = process.env.REACT_APP_INVOICES_API;

export const REJECTION_REASONS = [
  {
    code: "exemption_evidence_mismatch",
    copy:
      "The exemption reason selected doesn't match the exemption evidence uploaded (Please also use if UC evidence has been uploaded and customer hasn't selected Income-based Jobseeker’s Allowance (JSA))"
  },
  {
    code: "exemption_not_original",
    copy:
      "We need to see a copy of the actual certificate (In most cases, the NHS no longer issues plastic cards, so you need to see an image of the letter/email confirmation from NHSBSA, or a screengrab from the NHS checker)"
  },
  {
    code: "patient_name_mismatch",
    copy:
      "The name on the exemption evidence doesn't match the name on the account"
  },
  { code: "exemption_expired", copy: "The exemption evidence has expired" },
  {
    code: "exemption_jsa_uc_expired",
    copy: " We can't approve evidence for JSA/UC dated more than 3 months ago"
  },
  {
    code: "exemption_image_illegible",
    copy: "The image doesn't clearly show the exemption evidence"
  },
  { code: "patient_name_not_shown", copy: "The image doesn't show the name" },
  {
    code: "exemption_expiry_not_shown",
    copy: "The image doesn't show the expiry date"
  },
  { code: "invalid_pip_exemption", copy: "Personal Independence Payment " },
  { code: "other", copy: "Other (free text field)" }
];

// Actions
export const FETCH_EVIDENCE_REQUEST = "FETCH_EVIDENCE_REQUEST";
export const FETCH_EVIDENCE_RECEIVE = "FETCH_EVIDENCE_RECEIVE";
export const FETCH_EVIDENCE_FAILURE = "FETCH_EVIDENCE_FAILURE";
export const APPROVE_EVIDENCE_REQUEST = "APPROVE_EVIDENCE_REQUEST";
export const APPROVE_EVIDENCE_RECEIVE = "APPROVE_EVIDENCE_RECEIVE";
export const APPROVE_EVIDENCE_FAILURE = "APPROVE_EVIDENCE_FAILURE";
export const REJECT_EVIDENCE_REQUEST = "REJECT_EVIDENCE_REQUEST";
export const REJECT_EVIDENCE_RECEIVE = "REJECT_EVIDENCE_RECEIVE";
export const REJECT_EVIDENCE_FAILURE = "REJECT_EVIDENCE_FAILURE";
export const SHOW_EVIDENCE_DIALOG = "SHOW_EVIDENCE_DIALOG";
export const HIDE_EVIDENCE_DIALOG = "HIDE_EVIDENCE_DIALOG";
export const SET_EXPIRATION = "SET_EXPIRATION";
export const SHOW_EVIDENCE_REJECTION_DIALOG = "SHOW_EVIDENCE_REJECTION_DIALOG";
export const SELECT_REJECTION_REASON = "SELECT_REJECTION_REASON";
export const REASON_NOTES_ENTERED = "REASON_NOTES_ENTERED";

// Action Creators
export const showEvidenceDialog = order => ({
  type: SHOW_EVIDENCE_DIALOG,
  order
});

export const hideEvidenceDialog = () => ({
  type: HIDE_EVIDENCE_DIALOG
});

export const showEvidenceRejectionDialog = () => ({
  type: SHOW_EVIDENCE_REJECTION_DIALOG
});

export const rejectionReasonSelected = code => ({
  type: SELECT_REJECTION_REASON,
  code
});

export const reasonNotesEntered = notes => ({
  type: REASON_NOTES_ENTERED,
  notes
});

export const fetchEvidence = (invoiceId, apiEndpoint = API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/invoice/${invoiceId}/payments/exemption/evidence`,
    method: "GET",
    types: [
      FETCH_EVIDENCE_REQUEST,
      FETCH_EVIDENCE_RECEIVE,
      FETCH_EVIDENCE_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const approveEvidence = (
  invoiceId,
  expirationDate,
  apiEndpoint = API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/invoice/${invoiceId}/payments/exemption/approve`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      APPROVE_EVIDENCE_REQUEST,
      APPROVE_EVIDENCE_RECEIVE,
      APPROVE_EVIDENCE_FAILURE
    ],
    options: { addAuth: true },
    body: JSON.stringify({
      expirationDate: moment.utc(expirationDate, "DD/MM/YYYY")
    })
  }
});

export const rejectEvidence = (
  invoiceId,
  code,
  notes,
  apiEndpoint = API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/invoice/${invoiceId}/payments/exemption/reject`,
    method: "POST",
    body: JSON.stringify({
      exemptionReason: code,
      exemptionNotes: notes
    }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      REJECT_EVIDENCE_REQUEST,
      REJECT_EVIDENCE_RECEIVE,
      REJECT_EVIDENCE_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const setExpiration = date => ({
  type: SET_EXPIRATION,
  date
});

// Selectors
export const getError = state => state.error;
export const getExpiration = state => state.expiration;
export const getIsFetching = state => state.fetchingEvidence;
export const getURL = state => state.evidenceURL;
export const getDialogVisible = state => state.order != null;
export const getRejectionReasons = () => REJECTION_REASONS;
export const getShowRejectionReasons = state => state.showRejectionReasons;
export const getSelectedRejectionReason = state =>
  state.selectedRejectionReason;
export const getReasonNotes = state => state.reasonNotes;
export const getInvoiceId = state =>
  state.order ? state.order.invoice.invoiceId : null;
export const getCanApproveOrReject = state =>
  !!(
    state.order &&
    state.order.status === "exemption_provided" &&
    state.evidenceURL != null
  );
export const getCanSetExpiration = state =>
  getEvidenceNeedsExpiration(state.order);
export const getIsExpirationValid = state => {
  if (state.expiration === "") {
    return true;
  }
  if (!moment.utc(state.expiration, "DD/MM/YYYY").isValid()) {
    return false;
  }
  return !moment.utc(state.expiration, "DD/MM/YYYY").isBefore(Date.now());
};

// Reducers
const order = handleActions(
  {
    [SHOW_EVIDENCE_DIALOG]: (state, action) => action.order,
    [HIDE_EVIDENCE_DIALOG]: () => null,
    [APPROVE_EVIDENCE_RECEIVE]: () => null,
    [REJECT_EVIDENCE_RECEIVE]: () => null
  },
  null
);

const error = handleActions(
  {
    [FETCH_EVIDENCE_REQUEST]: () => null,
    [FETCH_EVIDENCE_RECEIVE]: () => null,
    [FETCH_EVIDENCE_FAILURE]: (state, action) => action.payload
  },
  null
);

const fetchingEvidence = handleActions(
  {
    [FETCH_EVIDENCE_REQUEST]: () => true,
    [FETCH_EVIDENCE_RECEIVE]: () => false,
    [FETCH_EVIDENCE_FAILURE]: () => false
  },
  false
);

const evidenceURL = handleActions(
  {
    [FETCH_EVIDENCE_REQUEST]: () => null,
    [FETCH_EVIDENCE_RECEIVE]: (state, action) => action.payload.url,
    [FETCH_EVIDENCE_FAILURE]: () => null
  },
  null
);

const approving = handleActions(
  {
    [APPROVE_EVIDENCE_REQUEST]: () => true,
    [APPROVE_EVIDENCE_RECEIVE]: () => false,
    [APPROVE_EVIDENCE_FAILURE]: () => false
  },
  false
);

const expiration = handleActions(
  {
    [SET_EXPIRATION]: (state, action) => action.date,
    [FETCH_EVIDENCE_REQUEST]: () => ""
  },
  ""
);

const selectedRejectionReason = handleActions(
  {
    [SELECT_REJECTION_REASON]: (state, action) => action.code,
    [SHOW_EVIDENCE_REJECTION_DIALOG]: () => null,
    [HIDE_EVIDENCE_DIALOG]: () => null,
    [REJECT_EVIDENCE_RECEIVE]: () => null
  },
  null
);

const showRejectionReasons = handleActions(
  {
    [SHOW_EVIDENCE_REJECTION_DIALOG]: () => true,
    [HIDE_EVIDENCE_DIALOG]: () => false,
    [REJECT_EVIDENCE_RECEIVE]: () => false
  },
  false
);

const reasonNotes = handleActions(
  {
    [REASON_NOTES_ENTERED]: (state, action) => action.notes,
    [SHOW_EVIDENCE_REJECTION_DIALOG]: state => state.reasonNotes,
    [HIDE_EVIDENCE_DIALOG]: () => "",
    [REJECT_EVIDENCE_RECEIVE]: () => "",
    [SELECT_REJECTION_REASON]: () => ""
  },
  ""
);

export default combineReducers({
  error,
  fetchingEvidence,
  evidenceURL,
  order,
  approving,
  expiration,
  selectedRejectionReason,
  showRejectionReasons,
  reasonNotes
});
