import { handleActions } from "redux-actions";

// ACTIONS
export const SHOW_CONFIRM_DIALOG = "SHOW_CONFIRM_DIALOG";
export const HIDE_CONFIRM_DIALOG = "HIDE_CONFIRM_DIALOG";
export const CHANGE_INPUT_MESSAGE = "CHANGE_INPUT_MESSAGE";
export const CHANGE_CHECK_BOX_VALUE = "CHANGE_CHECK_BOX_VALUE";
export const CHANGE_RADIO_BUTTON_VALUE = "CHANGE_RADIO_BUTTON_VALUE";
export const CHANGE_SECONDARY_RADIO_BUTTON_VALUE =
  "CHANGE_SECONDARY_RADIO_BUTTON_VALUE";
export const RETURN_SCRIPT_FAILURE_UPDATE_MCS =
  "RETURN_SCRIPT/FAILURE_UPDATE_MCS";
export const ADD_MEDICINE_ITEMS = "ADD_MEDICINE_ITEMS";
export const REMOVE_MEDICINE_ITEMS = "REMOVE_MEDICINE_ITEMS";

// ACTION CREATORS
export const showConfirmDialog = ({
  title,
  message,
  positiveActionTitle,
  agreeActionCreator,
  cancelActionCreator,
  requiresInput,
  requiresRadioButton,
  radioButtonOptions,
  inputPlaceholder,
  requiresCheck,
  checkBoxLabel,
  mcsItems,
  nonMcsItems,
  rxToken,
  requiresMultiCheckbox,
  medicines
}) => ({
  type: SHOW_CONFIRM_DIALOG,
  dialog: {
    title,
    message,
    positiveActionTitle,
    agreeActionCreator,
    cancelActionCreator,
    requiresInput: Boolean(requiresInput),
    requiresRadioButton: Boolean(requiresRadioButton),
    radioButtonOptions,
    inputPlaceholder,
    requiresCheck: Boolean(requiresCheck),
    checkBoxLabel,
    mcsItems,
    nonMcsItems,
    rxToken,
    requiresMultiCheckbox,
    medicines
  }
});

export const addMedicineItems = (medicineIndex, selectedMedicines) => ({
  type: ADD_MEDICINE_ITEMS,
  medicineIndex,
  selectedMedicines
});

export const removeMedicineItems = (medicineIndex, selectedMedicines) => ({
  type: REMOVE_MEDICINE_ITEMS,
  medicineIndex,
  selectedMedicines
});

export const hideConfirmDialog = () => ({
  type: HIDE_CONFIRM_DIALOG
});

export const setInputMessage = message => ({
  type: CHANGE_INPUT_MESSAGE,
  message
});

export const setCheckBoxValue = value => ({
  type: CHANGE_CHECK_BOX_VALUE,
  value
});

export const setRadioButtonValue = value => ({
  type: CHANGE_RADIO_BUTTON_VALUE,
  value
});
export const setSecondaryRadioButtonValue = value => ({
  type: CHANGE_SECONDARY_RADIO_BUTTON_VALUE,
  value
});
// SELECTORS
const getField = field => state => state.dialog[field];
export const getIsVisible = state => state.visible;
export const getInputText = state => state.inputText;
export const getTitle = getField("title");
export const getMessage = getField("message");
export const getRequiresInput = getField("requiresInput");
export const getRequiresRadioButton = getField("requiresRadioButton");
export const getInputPlaceholder = getField("inputPlaceholder");
export const getPositiveActionTitle = getField("positiveActionTitle");
export const getAgreeActionCreator = getField("agreeActionCreator");
export const getCancelActionCreator = getField("cancelActionCreator");
export const getRequiresCheck = getField("requiresCheck");
export const getCheckBox = state => Boolean(state.checkBox);
export const getRadioButton = state => state.radioButton;
export const getRadioButtonOptions = getField("radioButtonOptions");
export const getCheckBoxLabel = getField("checkBoxLabel");
export const getRequiresMultiCheckbox = getField("requiresMultiCheckbox");
export const getCanSubmit = state =>
  (!getRequiresInput(state) &&
    !getRequiresRadioButton(state) &&
    !getRequiresMultiCheckbox(state)) ||
  Boolean(
    (state.inputText &&
      state.inputText.length > 0 &&
      !getRequiresRadioButton(state)) ||
      state.radioButton ||
      (state.selectedMedicines && state.selectedMedicines.length > 0)
  );
export const getMcsItems = getField("mcsItems");
export const getNonMcsItems = getField("nonMcsItems");
export const getRxToken = getField("rxToken");
export const getSecondaryRadioButton = state => state.secondaryRadioButton;
export const getShowRTSError = state => state.showRTSError;
export const getRTSErrorMessage = state => state.rtsErrorMessage;
export const getSelectedMedicines = state => state.selectedMedicines;
export const getMedicines = getField("medicines");

// REDUCERS
const INITIAL_STATE = {
  visible: false,
  dialog: {
    title: null,
    message: null,
    positiveActionTitle: null,
    agreeActionCreator: null,
    cancelActionCreator: null,
    requiresInput: false,
    requiresRadioButton: false,
    radioButtonOptions: null,
    inputPlaceholder: null,
    requiresCheck: false,
    checkBoxLabel: null,
    mcsItems: null,
    nonMcsItems: null,
    rxToken: null,
    medicines: [],
    requiresMultiCheckbox: false
  },
  selectedMedicines: null
};

function selectedMedicinesLocalRemove(medicines, action) {
  const newMedicines = [...medicines];
  return newMedicines.filter(med => med !== action.medicineIndex).sort();
}
function selectedMedicinesLocalAdd(medicines, action) {
  const newMedicines = [...medicines];
  newMedicines.push(action.medicineIndex);
  return newMedicines.sort();
}

export default handleActions(
  {
    [CHANGE_INPUT_MESSAGE]: (state, action) => ({
      ...state,
      visible: true,
      inputText: action.message
    }),
    [CHANGE_CHECK_BOX_VALUE]: (state, action) => ({
      ...state,
      visible: true,
      checkBox: action.value
    }),
    [CHANGE_RADIO_BUTTON_VALUE]: (state, action) => ({
      ...state,
      visible: true,
      radioButton: action.value
    }),
    [RETURN_SCRIPT_FAILURE_UPDATE_MCS]: (state, action) => ({
      ...state,
      visible: true,
      showRTSError: true,
      rtsErrorMessage: action.payload.response.error
        ? action.payload.response.error.message
        : "Could not get reason from server. Contact dev team."
    }),
    [CHANGE_SECONDARY_RADIO_BUTTON_VALUE]: (state, action) => ({
      ...state,
      visible: true,
      secondaryRadioButton: action.value
    }),
    [SHOW_CONFIRM_DIALOG]: (state, action) => ({
      visible: true,
      dialog: action.dialog,
      inputText: null,
      showRTSError: false,
      rtsErrorMessage: null,
      selectedMedicines: []
    }),
    [HIDE_CONFIRM_DIALOG]: state => ({
      ...state,
      visible: false,
      inputText: null
    }),
    [ADD_MEDICINE_ITEMS]: (state, action) => ({
      ...state,
      visible: true,
      dialog: state.dialog,
      selectedMedicines: selectedMedicinesLocalAdd(
        state.selectedMedicines,
        action
      )
    }),
    [REMOVE_MEDICINE_ITEMS]: (state, action) => ({
      ...state,
      visible: true,
      dialog: state.dialog,
      selectedMedicines: selectedMedicinesLocalRemove(
        state.selectedMedicines,
        action
      )
    })
  },
  INITIAL_STATE
);
