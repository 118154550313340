import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { closeNotification } from "../../redux/notification";
import {
  getIsNotificationVisible,
  getNotificationMessage,
  getNotificationDuration,
  getAnchorOrigin,
  getBgColor
} from "../../redux/root-reducer";

export const Notification = ({
  closeHandler,
  message,
  visible,
  anchorOrigin,
  duration,
  bgColor
}) => (
  <Snackbar
    id={"t_id"}
    anchorOrigin={anchorOrigin}
    open={visible}
    onClose={closeHandler}
    autoHideDuration={duration}
    message={message}
    ContentProps={{ style: { backgroundColor: bgColor } }}
  />
);

Notification.propTypes = {
  anchorOrigin: PropTypes.object,
  duration: PropTypes.number,
  visible: PropTypes.bool,
  message: PropTypes.string,
  closeHandler: PropTypes.func
};

Notification.defaultProps = {
  visible: false,
  duration: 3000,
  message: "",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  closeHandler: () => {}
};

export const mapDispatchToProps = {
  closeHandler: closeNotification
};

export const mapStateToProps = state => ({
  visible: getIsNotificationVisible(state),
  message: getNotificationMessage(state),
  duration: getNotificationDuration(state),
  anchorOrigin: getAnchorOrigin(state),
  bgColor: getBgColor(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
