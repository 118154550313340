import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import {
  LINK_BASKET_FAILURE,
  LINK_BASKET_RECEIVE,
  LINK_BASKET_REQUEST
} from "redux/consolidate-orders-dialog";
import { RSAA } from "redux-api-middleware";
import { NEXT_ACTION } from "redux/middlewares/next";
import {
  FETCH_DISPATCH_LABEL_FAILURE,
  FETCH_DISPATCH_LABEL_REQUEST,
  printDispatchLabel
} from "redux/dispatch-label/print";
import { DELIVERY_ADDRESS_UPDATE_RECEIVE } from "redux/delivery-address";
import { PRINT_IMAGE } from "redux/middlewares/print";
import { FETCH_ORDERS_REQUEST } from "redux/orders";
import { RETURN_SCRIPT_RECEIVE } from "redux/return-prescription-dialog";
import { GET_GP_PROFILE_RECEIVE } from "redux/gp-profile";
import { UPDATE_SHIPPING_CLASS_RECEIVE } from "redux/shipping-class-dialog";
import { GET_USER_PROFILE_RECEIVE } from "redux/user-profile";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

// ACTIONS
export const SET_BASKET_ID = "SCANNER_WRAPPER/SET_BASKET_ID";
export const SET_BASKET_WEIGHT = "SCANNER_WRAPPER/SET_BASKET_WEIGHT";
export const HIDE_BASKET_DETAILS = "SCANNER_WRAPPER/HIDE_BASKET_DETAILS";
export const HIDE_ADMIN_DIALOG = "SCANNER_WRAPPER/HIDE_ADMIN_DIALOG";
export const HIDE_ORDER_LIST_TAP_DIALOG =
  "SCANNER_WRAPPER/HIDE_ORDER_LIST_TAP_DIALOG";
export const HIDE_EDIT_WEIGHT_DIALOG =
  "SCANNER_WRAPPER/HIDE_EDIT_WEIGHT_DIALOG";
export const SET_ADMIN_DIALOG_OPEN = "SCANNER_WRAPPER/SET_ADMIN_DIALOG_OPEN";
export const SET_SCAN_FROM_REMOVE_BASKET =
  "SCANNER_WRAPPER/SET_SCAN_FROM_REMOVE_BASKET";
export const SET_EDIT_WEIGHT_DIALOG_OPEN =
  "SCANNER_WRAPPER/SET_EDIT_WEIGHT_DIALOG_OPEN";
export const SET_ORDER_LIST_TAP_DIALOG_OPEN =
  "SCANNER_WRAPPER/SET_ORDER_LIST_TAP_DIALOG_OPEN";
export const HIDE_TRANSFER_BASKET_DIALOG =
  "SCANNER_WRAPPER/HIDE_TRANSFER_BASKET_DIALOG";
export const SET_TRANSFER_BASKET_DIALOG_OPEN =
  "SCANNER_WRAPPER/SET_TRANSFER_BASKET_DIALOG_OPEN";
export const BASKET_DISPATCH_REQUEST = "BASKET_DISPATCH/REQUEST";
export const BASKET_DISPATCH_RECEIVE = "BASKET_DISPATCH/RECEIVE";
export const BASKET_DISPATCH_FAILURE = "BASKET_DISPATCH/FAILURE";
export const FETCH_BASKET_ORDERS_REQUEST = "FETCH_BASKET_ORDERS/REQUEST";
export const FETCH_BASKET_ORDERS_RECEIVE = "FETCH_BASKET_ORDERS/RECEIVE";
export const FETCH_BASKET_ORDERS_FAILURE = "FETCH_BASKET_ORDERS/FAILURE";
export const FETCH_BASKET_STATUS_REQUEST = "FETCH_BASKET_STATUS/REQUEST";
export const FETCH_BASKET_STATUS_RECEIVE = "FETCH_BASKET_STATUS/RECEIVE";
export const FETCH_BASKET_STATUS_FAILURE = "FETCH_BASKET_STATUS/FAILURE";
export const BASKET_ADD_OR_REMOVE_REQUEST = "BASKET_ADD_OR_REMOVE/REQUEST";
export const BASKET_ADD_OR_REMOVE_RECEIVE = "BASKET_ADD_OR_REMOVE/RECEIVE";
export const BASKET_ADD_OR_REMOVE_FAILURE = "BASKET_ADD_OR_REMOVE/FAILURE";
export const REMOVE_BASKET_ORDER_REQUEST = "REMOVE_BASKET_ORDER/REQUEST";
export const REMOVE_BASKET_ORDER_RECEIVE = "REMOVE_BASKET_ORDER/RECEIVE";
export const REMOVE_BASKET_ORDER_FAILURE = "REMOVE_BASKET_ORDER/FAILURE";
export const LINK_BASKET_REMOVE_ORDER_REQUEST =
  "LINK_BASKET_REMOVE_ORDER/REQUEST";
export const LINK_BASKET_REMOVE_ORDER_RECEIVE =
  "LINK_BASKET_REMOVE_ORDER/RECEIVE";
export const LINK_BASKET_REMOVE_ORDER_FAILURE =
  "LINK_BASKET_REMOVE_ORDER/FAILURE";
export const CLEAR_BASKET_DATA = "SCANNER_WRAPPER/CLEAR_BASKET_DATA";
export const SCANNER_WRAPPER_CANCEL_ORDERS_REQUEST =
  "SCANNER_WRAPPER/CANCEL_ORDERS_REQUEST";
export const SCANNER_WRAPPER_CANCEL_ORDERS_RECEIVE =
  "SCANNER_WRAPPER/CANCEL_ORDERS_RECEIVE";
export const SCANNER_WRAPPER_CANCEL_ORDERS_FAILURE =
  "SCANNER_WRAPPER/CANCEL_ORDERS_FAILURE";
export const SCANNER_WRAPPER_MARK_ORDER_MCS_REQUEST =
  "SCANNER_WRAPPER/MARK_ORDER_MCS_REQUEST";
export const SCANNER_WRAPPER_MARK_ORDER_MCS_RECEIVE =
  "SCANNER_WRAPPER/MARK_ORDER_MCS_RECEIVE";
export const SCANNER_WRAPPER_MARK_ORDER_MCS_FAILURE =
  "SCANNER_WRAPPER/MARK_ORDER_MCS_FAILURE";
// ACTION CREATORS
export const setBasketId = basketId => ({
  type: SET_BASKET_ID,
  basketId
});

export const setBasketWeight = basketWeight => ({
  type: SET_BASKET_WEIGHT,
  basketWeight
});

export const hideBasketDetails = () => ({
  type: HIDE_BASKET_DETAILS
});

export const setScanFromRemoveBasket = () => ({
  type: SET_SCAN_FROM_REMOVE_BASKET
});

export const hideAdminDialog = () => ({
  type: HIDE_ADMIN_DIALOG
});

export const setAdminDialogOpen = () => ({
  type: SET_ADMIN_DIALOG_OPEN
});

export const hideEditBasketDialog = () => ({
  type: HIDE_EDIT_WEIGHT_DIALOG
});

export const setEditBasketDialogOpen = () => ({
  type: SET_EDIT_WEIGHT_DIALOG_OPEN
});

export const hideOrderListTapDialog = () => ({
  type: HIDE_ORDER_LIST_TAP_DIALOG
});

export const setOrderListTapDialogOpen = isopen => ({
  type: SET_ORDER_LIST_TAP_DIALOG_OPEN,
  isopen
});

export const hideTransferBasketDialog = basketId => ({
  type: HIDE_TRANSFER_BASKET_DIALOG,
  basketId
});

export const setTransferBasketDialogOpen = () => ({
  type: SET_TRANSFER_BASKET_DIALOG_OPEN
});

export const smallScreenBack = () => ({
  type: CLEAR_BASKET_DATA
});

export const basketDispatch = (
  format,
  weight,
  basketId,
  endpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${endpoint}/basket/${basketId}/dispatch`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ format, weight }),
    types: [
      BASKET_DISPATCH_REQUEST,
      {
        type: BASKET_DISPATCH_RECEIVE,
        payload: (action, state, res) =>
          res.json().then(json => ({
            [NEXT_ACTION]: printDispatchLabel(json.labelUrl)
          }))
      },
      BASKET_DISPATCH_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const fetchBasketData = (
  basketId,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/basket/${basketId}`,
    method: "GET",
    types: [
      FETCH_BASKET_ORDERS_REQUEST,
      FETCH_BASKET_ORDERS_RECEIVE,
      FETCH_BASKET_ORDERS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const fetchBasketStatus = (
  basketId,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/basket/${basketId}/status`,
    method: "GET",
    types: [
      FETCH_BASKET_STATUS_REQUEST,
      FETCH_BASKET_STATUS_RECEIVE,
      FETCH_BASKET_STATUS_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const addOrRemoveBasket = (
  basketId,
  status,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/basket/${basketId}`,
    method: status === "not_found" ? "POST" : "DELETE",
    types: [
      BASKET_ADD_OR_REMOVE_REQUEST,
      BASKET_ADD_OR_REMOVE_RECEIVE,
      BASKET_ADD_OR_REMOVE_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const removeOrderFromBasket = (
  basketId,
  orderIds,
  markReadyToPrint,
  labelUrl,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/basket/${basketId}/remove`,
    method: "PUT",
    body: JSON.stringify({ orderIds, markReadyToPrint }),
    types: [
      REMOVE_BASKET_ORDER_REQUEST,
      labelUrl
        ? {
            type: "TRIGGER_PRINT",
            payload: (action, state, res) =>
              res.json().then(() => ({
                [NEXT_ACTION]: printDispatchLabel(labelUrl)
              }))
          }
        : REMOVE_BASKET_ORDER_RECEIVE,
      REMOVE_BASKET_ORDER_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const createSingleOrderDispatchLabel = (
  orderId,
  weight,
  format,
  basketId,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${encodeURIComponent(
      orderId
    )}/dispatchlabel`,
    method: "POST",
    body: JSON.stringify({
      format,
      weight
    }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      FETCH_DISPATCH_LABEL_REQUEST,
      {
        type: "TRIGGER_REMOVE_ORDER",
        payload: (action, state, res) =>
          res.json().then(json => ({
            [NEXT_ACTION]: removeOrderFromBasket(
              basketId,
              [orderId],
              undefined,
              json.labelUrl
            )
          }))
      },
      FETCH_DISPATCH_LABEL_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const linkBasketFromRemoveOrder = (
  orderIds,
  basketId,
  endpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${endpoint}/basket/${basketId}/link`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ orderIds }),
    types: [
      LINK_BASKET_REMOVE_ORDER_REQUEST,
      LINK_BASKET_REMOVE_ORDER_RECEIVE,
      LINK_BASKET_REMOVE_ORDER_FAILURE
    ],
    options: { addAuth: true }
  }
});

// SELECTORS
export const getBasketId = state => state.basketId;
export const getWeight = state => state.basketWeight;
export const getIsLoading = state => state.isLoading;
export const getIsOpen = state => state.isOpen;
export const getBasketData = state => state.basketData;
export const getAdminDialogIsOpen = state => state.isAdminDialogOpen;
export const getEditWeightDialogIsOpen = state => state.isEditWeightDialogOpen;
export const getOrderListTapDialogIsOpen = state =>
  state.isOrderListTapDialogOpen;
export const getStatus = state => state.basketStatus;
export const getRefreshBasketData = state => state.refreshBasketData;
export const getScanFromRemoveBasket = state => state.scanFromRemoveBasket;
export const getTransferDialogIsOpen = state => state.isTransferDialogOpen;

// REDUCERS
const basketId = handleActions(
  {
    [SET_BASKET_ID]: (state, action) => action.basketId,
    [LINK_BASKET_RECEIVE]: () => "",
    [BASKET_DISPATCH_RECEIVE]: () => "",
    [HIDE_ADMIN_DIALOG]: () => "",
    [BASKET_ADD_OR_REMOVE_RECEIVE]: () => "",
    [SET_TRANSFER_BASKET_DIALOG_OPEN]: () => "",
    [GET_USER_PROFILE_RECEIVE]: () => "",
    [FETCH_BASKET_ORDERS_FAILURE]: () => "",
    [CLEAR_BASKET_DATA]: () => "",
    ["@@router/LOCATION_CHANGE"]: () => "",
    [HIDE_TRANSFER_BASKET_DIALOG]: (state, action) => action.basketId
  },
  ""
);

const basketWeight = handleActions(
  {
    [SET_BASKET_WEIGHT]: (state, action) => action.basketWeight,
    [HIDE_ORDER_LIST_TAP_DIALOG]: () => "",
    [SET_ORDER_LIST_TAP_DIALOG_OPEN]: () => "",
    [HIDE_BASKET_DETAILS]: () => "",
    [BASKET_DISPATCH_RECEIVE]: () => "",
    [GET_GP_PROFILE_RECEIVE]: () => "",
    [PRINT_IMAGE]: () => ""
  },
  ""
);

const isLoading = handleActions(
  {
    [BASKET_DISPATCH_REQUEST]: () => true,
    [BASKET_DISPATCH_RECEIVE]: () => false,
    [BASKET_DISPATCH_FAILURE]: () => false,
    [FETCH_BASKET_ORDERS_REQUEST]: () => true,
    [FETCH_BASKET_ORDERS_RECEIVE]: () => false,
    [FETCH_BASKET_ORDERS_FAILURE]: () => false,
    [FETCH_BASKET_STATUS_REQUEST]: () => true,
    [FETCH_BASKET_STATUS_RECEIVE]: () => false,
    [FETCH_BASKET_STATUS_FAILURE]: () => false,
    [FETCH_DISPATCH_LABEL_REQUEST]: () => true,
    [FETCH_DISPATCH_LABEL_FAILURE]: () => false,
    [REMOVE_BASKET_ORDER_REQUEST]: () => true,
    [REMOVE_BASKET_ORDER_RECEIVE]: () => false,
    [REMOVE_BASKET_ORDER_FAILURE]: () => false,
    [LINK_BASKET_REMOVE_ORDER_REQUEST]: () => true,
    [LINK_BASKET_REMOVE_ORDER_RECEIVE]: () => false,
    [LINK_BASKET_REMOVE_ORDER_FAILURE]: () => false,
    [PRINT_IMAGE]: () => false,
    [LINK_BASKET_REQUEST]: () => true,
    [LINK_BASKET_RECEIVE]: () => false,
    [LINK_BASKET_FAILURE]: () => false
  },
  false
);

const basketData = handleActions(
  {
    [FETCH_BASKET_ORDERS_RECEIVE]: (state, action) => {
      const res = action.payload;
      let isFirstOrder, isAddressUpdated;
      const updatedOrders = res?.orders?.map(order => {
        if (!isFirstOrder && order.orderSeqNumber === 1) {
          isFirstOrder = true;
        }
        if (
          !isAddressUpdated &&
          order.deliveryAddress !== res.deliveryAddress
        ) {
          isAddressUpdated = true;
        }
        return {
          ...order,
          isAddressUpdated: order.deliveryAddress !== res.deliveryAddress
        };
      });
      return { ...res, orders: updatedOrders, isFirstOrder, isAddressUpdated };
    },
    [BASKET_DISPATCH_RECEIVE]: () => null,
    [CLEAR_BASKET_DATA]: () => null,
    // [SET_BASKET_ID]: (state, action) => (action.basketId === "" ? null : state),
    [FETCH_BASKET_ORDERS_FAILURE]: () => null,
    ["@@router/LOCATION_CHANGE"]: () => null
  },
  null
);

export const markCancelledFromScanner = (
  order,
  notes,
  sendNotification,
  apiEndpoint = ORDERS_API_ENDPOINT
) => {
  const basketId = order.basketId;
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/order/${order.id}/cancel`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        notes,
        sendNotification,
        basketId
      }),
      types: [
        SCANNER_WRAPPER_CANCEL_ORDERS_REQUEST,
        SCANNER_WRAPPER_CANCEL_ORDERS_RECEIVE,
        SCANNER_WRAPPER_CANCEL_ORDERS_FAILURE
      ],
      options: { addAuth: true }
    }
  };
};

export const markMCSFromScanner = (
  orderId,
  medicines,
  selectedMedicineIndexes,
  basketId,
  apiEndpoint = ORDERS_API_ENDPOINT
) => {
  const selectedMedicines = medicines
    .filter((med, i) => selectedMedicineIndexes.includes(i))
    .map(med => ({ medicineItemId: med.medicineItemId }));
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/order/${orderId}/markmcs`,
      method: "POST",
      body: JSON.stringify({ medicines: selectedMedicines, basketId }),
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        SCANNER_WRAPPER_MARK_ORDER_MCS_REQUEST,
        SCANNER_WRAPPER_MARK_ORDER_MCS_RECEIVE,
        SCANNER_WRAPPER_MARK_ORDER_MCS_FAILURE
      ],
      options: { addAuth: true }
    }
  };
};

const isDetailsOpen = handleActions(
  {
    [FETCH_BASKET_ORDERS_RECEIVE]: () => true,
    [HIDE_BASKET_DETAILS]: () => false,
    [BASKET_DISPATCH_RECEIVE]: () => false,
    [FETCH_BASKET_ORDERS_FAILURE]: () => false,
    [GET_GP_PROFILE_RECEIVE]: () => false,
    [FETCH_ORDERS_REQUEST]: () => false,
    ["@@router/LOCATION_CHANGE"]: () => false
  },
  false
);

const isAdminDialogOpen = handleActions(
  {
    [SET_ADMIN_DIALOG_OPEN]: () => true,
    [HIDE_ADMIN_DIALOG]: () => false,
    [BASKET_ADD_OR_REMOVE_RECEIVE]: () => false
  },
  false
);

const isEditWeightDialogOpen = handleActions(
  {
    [SET_EDIT_WEIGHT_DIALOG_OPEN]: () => true,
    [HIDE_EDIT_WEIGHT_DIALOG]: () => false
  },
  false
);

const isOrderListTapDialogOpen = handleActions(
  {
    [SET_ORDER_LIST_TAP_DIALOG_OPEN]: (state, action) => Boolean(action.isopen),
    [HIDE_ORDER_LIST_TAP_DIALOG]: () => false,
    [REMOVE_BASKET_ORDER_RECEIVE]: () => false,
    [LINK_BASKET_REMOVE_ORDER_RECEIVE]: () => false,
    [GET_GP_PROFILE_RECEIVE]: () => false,
    [PRINT_IMAGE]: () => false
  },
  false
);

const basketStatus = handleActions(
  {
    [FETCH_BASKET_STATUS_RECEIVE]: (state, action) => action.payload.status,
    [HIDE_ADMIN_DIALOG]: () => "",
    [BASKET_ADD_OR_REMOVE_RECEIVE]: () => ""
  },
  ""
);

const refreshBasketData = handleActions(
  {
    [DELIVERY_ADDRESS_UPDATE_RECEIVE]: () => true,
    [UPDATE_SHIPPING_CLASS_RECEIVE]: () => true,
    [REMOVE_BASKET_ORDER_RECEIVE]: () => true,
    [LINK_BASKET_REMOVE_ORDER_RECEIVE]: () => true,
    [SCANNER_WRAPPER_CANCEL_ORDERS_RECEIVE]: () => true,
    [RETURN_SCRIPT_RECEIVE]: () => true,
    [SCANNER_WRAPPER_MARK_ORDER_MCS_RECEIVE]: () => true,
    [LINK_BASKET_RECEIVE]: () => true,
    [PRINT_IMAGE]: () => true,
    [FETCH_BASKET_ORDERS_RECEIVE]: () => false
  },
  false
);

const scanFromRemoveBasket = handleActions(
  {
    [SET_SCAN_FROM_REMOVE_BASKET]: () => true,
    [HIDE_ORDER_LIST_TAP_DIALOG]: () => false
  },
  false
);

const isTransferDialogOpen = handleActions(
  {
    [SET_TRANSFER_BASKET_DIALOG_OPEN]: () => true,
    [HIDE_TRANSFER_BASKET_DIALOG]: () => false,
    [LINK_BASKET_RECEIVE]: () => false
  },
  false
);

export default combineReducers({
  basketId,
  isLoading,
  basketData,
  isOpen: isDetailsOpen,
  isAdminDialogOpen,
  basketStatus,
  refreshBasketData,
  isEditWeightDialogOpen,
  isOrderListTapDialogOpen,
  scanFromRemoveBasket,
  isTransferDialogOpen,
  basketWeight
});
