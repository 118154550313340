import { createAction } from "@reduxjs/toolkit";
import { RSAA } from "redux-api-middleware";

const PROFILE_API_ENDPOINT: string = process.env.REACT_APP_GP_PROFILE_API || "";

// types
export const GP_CONTACT_REVIEW_START = "GP_CONTACT_REVIEW_START";
export const GP_CONTACT_REVIEW_FETCH_REQUEST =
  "GP_CONTACT_REVIEW_FETCH_REQUEST";
export const GP_CONTACT_REVIEW_FETCH_RECEIVE =
  "GP_CONTACT_REVIEW_FETCH_RECEIVE";
export const GP_CONTACT_REVIEW_FETCH_FAILURE =
  "GP_CONTACT_REVIEW_FETCH_FAILURE";

// creators
export const contactReviewStart = createAction<undefined>(
  GP_CONTACT_REVIEW_START
);

export const fetchContactReviewGPs = (
  cursor = "",
  apiEndpoint: string = PROFILE_API_ENDPOINT
): object => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practices/contactreview?cursor=${cursor}`,
    method: "GET",
    types: [
      GP_CONTACT_REVIEW_FETCH_REQUEST,
      GP_CONTACT_REVIEW_FETCH_RECEIVE,
      GP_CONTACT_REVIEW_FETCH_FAILURE
    ],
    options: { addAuth: true }
  }
});
