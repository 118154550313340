import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const container = {
  display: "flex",
  justifyContent: "center",
  padding: 10
};

export default () => (
  <div style={container}>
    <CircularProgress color="primary" />
  </div>
);
