import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Badge from "@material-ui/core/Badge";
import cx from "clsx";

const decorateWithCounts = (r, statusCounts) => {
  const route = { ...r, count: 0 };
  if (route.statuses && statusCounts !== null) {
    route.count = route.statuses.reduce(
      (count, status) =>
        count + (statusCounts[status] ? statusCounts[status] : 0),
      0
    );
  }
  return route;
};

const ChildRouteList = props => {
  const { style, route, currentUrl, changePage, statusCounts, perms } = props;

  return route.childRoutes ? (
    <List id={"t_child_routes"} component={"nav"}>
      {route.childRoutes.map((item, j) => {
        if (item.hideInMenu) return null;

        if (
          item.needsPermission &&
          (!perms || !perms.some(e => item.needsPermission === e))
        ) {
          return null;
        }

        const currentSubClass =
          currentUrl === item.url ? style.activeRoute : style.route;
        const childRoute = decorateWithCounts(item, statusCounts);
        return (
          <ListItem
            title={`${childRoute.count} in ${childRoute.text}`}
            id={`t_child_route_${j}`}
            key={j}
            className={cx(currentSubClass, "t_child_route")}
            onClick={() => changePage(childRoute.url)}
            button
          >
            <ListItemIcon id={`t_child_route_icon_${j}`}>
              {childRoute.icon}
            </ListItemIcon>
            <ListItemText
              className={style.text}
              id={`t_child_route_text_${j}`}
              primary={childRoute.text}
            />
            {childRoute.count ? (
              <ListItemSecondaryAction className={style.listItemTrailing}>
                <Badge
                  id={`t_child_route_badge_${j}`}
                  color={"primary"}
                  max={9999}
                  className={style.badge}
                  badgeContent={childRoute.count}
                />
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        );
      })}
    </List>
  ) : null;
};

export default ChildRouteList;
