import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { Box, Typography, Grid, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getBasketId, getBasketIsLoading } from "redux/root-reducer";
import {
  fetchBasketData,
  fetchBasketStatus,
  setBasketId
} from "redux/scanner-wrapper";

import NextPageLoader from "../common/next-page-loader";
import AlphaNumericPad from "components/alphaNumeric-pad";

const ScanBasket = ({
  basketId,
  fetchBasketData,
  setBasketId,
  fetchBasketStatus,
  isLoading,
  theme,
  location,
  smallScreen,
  setSmallScreen,
  isRemoveOrder
}) => {
  const handleNumberClick = number => {
    switch (number) {
      case -1:
        setBasketId(basketId?.slice(0, -1));
        break;
      case 13:
        if (
          location?.pathname === "/orders/basket" ||
          location?.pathname === "/admin/basket/remove-order"
        ) {
          fetchBasketData(basketId);
        } else if (location?.pathname === "/admin/basket") {
          fetchBasketStatus(basketId);
        }
        break;
      default:
        setBasketId(`${basketId}${number}`);
        break;
    }
  };

  const renderBasketPrompt = () =>
    isRemoveOrder
      ? "Scan basket or enter basket ID to remove an order"
      : "Scan basket or enter basket ID";

  const renderBasketIdInput = () => (
    <Box display={"flex"} flexDirection={"column"} flex={1}>
      <Typography variant={"h4"} color={"textSecondary"}>
        Basket ID:
      </Typography>
      <TextField
        fullWidth
        id={"t_basket_id"}
        onChange={e => setBasketId(e.target.value)}
        onKeyDown={e => e.key === "Enter" && handleNumberClick(13)}
        value={basketId}
        autoFocus
        inputProps={{ style: { fontSize: "25px" } }}
      />
    </Box>
  );

  const renderInputSection = () => (
    <Box
      component={Grid}
      container
      gridRowGap={"2.5rem"}
      flexDirection={"column"}
      px={"5rem"}
      pt={"3.28rem"}
    >
      {renderBasketIdInput()}
      <AlphaNumericPad onClick={handleNumberClick} />
    </Box>
  );

  const toggleMockTouchScreen = () => {
    setSmallScreen(!smallScreen);
    window.ontouchstart = !window.ontouchstart;
  };

  return (
    <Box display={"flex"} flexDirection={"column"} flex={1}>
      {["dev", "uat"].includes(process.env.REACT_APP_NAV_BANNER || "") &&
        isRemoveOrder && (
          <Button
            onClick={toggleMockTouchScreen}
            color={"primary"}
            variant={"contained"}
          >
            Toggle mock touch screen setup (Dev & Uat).
          </Button>
        )}
      <Grid
        container
        style={{ padding: theme.spacing(3), overflowY: "auto" }}
        direction={"column"}
        wrap={"nowrap"}
      >
        {((!smallScreen && isRemoveOrder) || !isRemoveOrder) && (
          <Typography
            variant={"body1"}
            style={{ fontSize: "1.875rem", fontWeight: 500 }}
          >
            {renderBasketPrompt()}
          </Typography>
        )}

        {!isLoading && smallScreen && isRemoveOrder && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mt={"1rem"}
          >
            <Box display={"flex"} flexDirection={"column"} gridGap={"5.47rem"}>
              <Typography
                variant={"body1"}
                style={{ fontSize: "1.125rem", fontWeight: 500 }}
              >
                {renderBasketPrompt()}
              </Typography>
              {renderBasketIdInput()}
            </Box>
          </Box>
        )}

        {!isLoading &&
          (!isRemoveOrder || (isRemoveOrder && !smallScreen)) &&
          renderInputSection()}

        {isLoading && (
          <Box marginTop={theme.spacing(1)}>
            <NextPageLoader id={"t_nextPageLoader"} />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

const mapStateToProps = state => ({
  basketId: getBasketId(state),
  isLoading: getBasketIsLoading(state)
});

const mapDispatchToProps = {
  fetchBasketData,
  fetchBasketStatus,
  setBasketId
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(ScanBasket))
);
