import { RSAA } from "redux-api-middleware";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import * as gpSelectors from "../selectors/gp";
import {
  validateAsEmail,
  validateAsPhoneNumber
} from "../../helpers/validators";
import { COMPLETE_NHSREFRESH_RECIEVE } from "../gp-nhs-refresh/actions.ts";

const PROFILE_API_ENDPOINT = process.env.REACT_APP_GP_PROFILE_API;

export const VALID_CONTACT_PREFERENCES = [
  gpSelectors.CONTACT_PREFERENCE_FAX,
  gpSelectors.CONTACT_PREFERENCE_EMAIL,
  gpSelectors.CONTACT_PREFERENCE_NONE
];

const gpFromRefresh = action => action.payload.practice;

// ACTIONS
export const HIDE_GP_PROFILE_DIALOG = "HIDE_GP_PROFILE_DIALOG";

export const GET_GP_PROFILE_REQUEST = "GET_GP_PROFILE_REQUEST";
export const GET_GP_PROFILE_RECEIVE = "GET_GP_PROFILE_RECEIVE";
export const GET_GP_PROFILE_FAILURE = "GET_GP_PROFILE_FAILURE";

export const SET_GP_PROFILE_REQUEST = "SET_GP_PROFILE_REQUEST";
export const SET_GP_PROFILE_RECEIVE = "SET_GP_PROFILE_RECEIVE";
export const SET_GP_PROFILE_FAILURE = "SET_GP_PROFILE_FAILURE";

export const UPDATE_GP_FIELD = "UPDATE_GP_FIELD";

// ACTION CREATORS

export const hideGPProfileDialog = () => ({
  type: HIDE_GP_PROFILE_DIALOG
});

export const fetchGPProfile = (id, apiEndpoint = PROFILE_API_ENDPOINT) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practice/${id}`,
    method: "GET",
    types: [
      GET_GP_PROFILE_REQUEST,
      GET_GP_PROFILE_RECEIVE,
      GET_GP_PROFILE_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const setGPProfileOnServer = (
  profile,
  apiEndpoint = PROFILE_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/practices`,
    method: "POST",
    types: [
      SET_GP_PROFILE_REQUEST,
      SET_GP_PROFILE_RECEIVE,
      SET_GP_PROFILE_FAILURE
    ],
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(profile),
    options: { addAuth: true }
  }
});

export const updateGPField = (field, value) => ({
  type: UPDATE_GP_FIELD,
  field,
  value
});

// SELECTORS
export const getIsLoading = state => state.isLoading;
export const getId = state => state.id;
export const getLatitude = state => state.latitude;
export const getLongitude = state => state.longitude;
export const getFieldValue = (name, state) =>
  state.fields ? state.fields[name].value : null;
export const getFieldIsValid = (name, state) =>
  state.fields ? state.fields[name].valid : false;
export const getGPProfileDialogIsOpen = state => state.isOpen;
export const getGPNameEditable = state => state.isNameEditable;
export const getCanSave = ({ fields = {} }) =>
  Object.keys(fields).reduce(
    (valid, key) => valid && fields[key] && fields[key].valid,
    true
  );
export const getSkipGeocode = ({ fields = {} }) =>
  Object.keys(fields).filter(
    key =>
      gpSelectors.ADDRESS_FIELDS_GEO_VALIDATE.includes(key) &&
      fields[key].changed
  ).length === 0;
export const getFlag = state => state.flag;
export const getFlagApplied = state => state.flagApplied;
export const getAPISetObject = state => ({
  id: getId(state),
  name: getFieldValue("name", state),
  addressLine1: getFieldValue("addressLine1", state),
  addressLine2: getFieldValue("addressLine2", state),
  postcode: getFieldValue("postcode", state),
  city: getFieldValue("city", state),
  country: getFieldValue("country", state),
  eps: getFieldValue("eps", state),
  againstDigitalPharmacy: getFieldValue("againstDigitalPharmacy", state),
  fax: getFieldValue("fax", state),
  email: getFieldValue("email", state),
  telephone: getFieldValue("telephone", state),
  contactPreference: getFieldValue("contactPreference", state),
  notes: getFieldValue("notes", state),
  lat: getLatitude(state),
  lng: getLongitude(state),
  skipGeocode: getSkipGeocode(state),
  surgeryType: getFieldValue("surgeryType", state),
  status: getFieldValue("status", state),
  updateLock: getFieldValue("updateLock", state),
  im1Provider: getFieldValue("im1Provider", state),
  ...(getFlag(state) ? { flag: getFlag(state) } : {}),
  ...(getFlagApplied(state) ? { flagApplied: getFlagApplied(state) } : {})
});

export const getLastUpdated = state => state.lastUpdated;
export const getError = state => state.error;

// REDUCERS
const isOpen = handleActions(
  {
    [GET_GP_PROFILE_REQUEST]: () => true,
    [HIDE_GP_PROFILE_DIALOG]: () => false,
    [SET_GP_PROFILE_RECEIVE]: () => false
  },
  false
);

const id = handleActions(
  {
    [GET_GP_PROFILE_RECEIVE]: (state, action) =>
      gpSelectors.getId(action.payload) || "",
    [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
      gpSelectors.getId(gpFromRefresh(action)) || ""
  },
  ""
);
const latitude = handleActions(
  {
    [GET_GP_PROFILE_RECEIVE]: (state, action) =>
      gpSelectors.getLatitude(action.payload) || null,
    [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
      gpSelectors.getLatitude(gpFromRefresh(action)) || null
  },
  0
);
const longitude = handleActions(
  {
    [GET_GP_PROFILE_RECEIVE]: (state, action) =>
      gpSelectors.getLongitude(action.payload) || null,
    [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
      gpSelectors.getLongitude(gpFromRefresh(action)) || null
  },
  0
);
export const lastUpdated = handleActions(
  {
    [GET_GP_PROFILE_RECEIVE]: (state, action) =>
      gpSelectors.getLastUpdated(action.payload) || null,
    [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
      gpSelectors.getLastUpdated(gpFromRefresh(action)) || null,
    [SET_GP_PROFILE_RECEIVE]: (_state, action) =>
      gpSelectors.getLastUpdated(action.payload) || null
  },
  ""
);
export const flag = handleActions(
  {
    [GET_GP_PROFILE_RECEIVE]: (state, action) =>
      gpSelectors.getFlag(action.payload) || null,
    [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
      gpSelectors.getFlag(gpFromRefresh(action)) || null,
    [SET_GP_PROFILE_RECEIVE]: (_state, action) =>
      gpSelectors.getFlag(action.payload) || null
  },
  ""
);
export const flagApplied = handleActions(
  {
    [GET_GP_PROFILE_RECEIVE]: (state, action) =>
      gpSelectors.getFlagApplied(action.payload) || null,
    [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
      gpSelectors.getFlagApplied(gpFromRefresh(action)) || null,
    [SET_GP_PROFILE_RECEIVE]: (_state, action) =>
      gpSelectors.getFlagApplied(action.payload) || null
  },
  ""
);

const error = handleActions(
  {
    [GET_GP_PROFILE_REQUEST]: () => null,
    [SET_GP_PROFILE_REQUEST]: () => null,
    [GET_GP_PROFILE_FAILURE]: (state, action) => action.payload,
    [SET_GP_PROFILE_FAILURE]: (state, action) => action.payload
  },
  null
);

const fieldValueReducer = (fieldName, getFieldFromAPI, initialValue = "") =>
  handleActions(
    {
      [UPDATE_GP_FIELD]: (state, action) => {
        if (action.field !== fieldName) {
          return state;
        }
        return action.value;
      },
      [GET_GP_PROFILE_RECEIVE]: (state, action) =>
        getFieldFromAPI(action.payload) || initialValue,
      [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
        getFieldFromAPI(gpFromRefresh(action)) || initialValue
    },
    initialValue
  );

/**
 * The `currentValue` is used to re-evaluate the validity of the field
 * when action is related to another field. The currentValue is optional,
 * but you need to set it when the validity of the field depends of other fields.
 *
 * @param {String} fieldName name of the field
 * @param {Profile => T} getFieldFromAPI get the field from the api response
 * @param {(T) => Boolean} isValid whether the field is valid
 * @param {T?} currentValue the last known value of the field.
 */
const fieldValidReducer = (fieldName, getFieldFromAPI, isValid, currentValue) =>
  handleActions(
    {
      [UPDATE_GP_FIELD]: (state, action) => {
        if (action.field !== fieldName) {
          return currentValue != null ? isValid(currentValue) : state;
        }
        return isValid(action.value);
      },
      [GET_GP_PROFILE_RECEIVE]: (state, action) =>
        isValid(getFieldFromAPI(action.payload) || ""),
      [COMPLETE_NHSREFRESH_RECIEVE]: (state, action) =>
        isValid(getFieldFromAPI(gpFromRefresh(action)) || "")
    },
    false
  );

const nonEmptyFieldValidReducer = (fieldName, getFieldFromAPI) =>
  fieldValidReducer(
    fieldName,
    getFieldFromAPI,
    val => val != null && val !== ""
  );

const nonEmptyFieldReducer = (fieldName, getFieldFromAPI, defaultValue = "") =>
  combineReducers({
    changed: hasFieldChangedReducer(fieldName),
    value: fieldValueReducer(fieldName, getFieldFromAPI, defaultValue),
    valid: nonEmptyFieldValidReducer(fieldName, getFieldFromAPI)
  });

const alwaysValidFieldReducer = (
  fieldName,
  getFieldFromAPI,
  defaultValue = ""
) =>
  combineReducers({
    changed: hasFieldChangedReducer(fieldName),
    value: fieldValueReducer(fieldName, getFieldFromAPI, defaultValue),
    valid: () => true
  });

const oneOfValuesFieldReducer = (fieldName, getFieldFromAPI, values) =>
  combineReducers({
    changed: hasFieldChangedReducer(fieldName),
    value: fieldValueReducer(fieldName, getFieldFromAPI, ""),
    valid: fieldValidReducer(fieldName, getFieldFromAPI, val =>
      values.includes(val)
    )
  });

const optionallyRequiredFieldReducer = (
  fieldName,
  getFieldFromAPI,
  isRequired,
  isValid,
  currentValue
) =>
  combineReducers({
    changed: hasFieldChangedReducer(fieldName),
    value: fieldValueReducer(fieldName, getFieldFromAPI, ""),
    valid: fieldValidReducer(
      fieldName,
      getFieldFromAPI,
      val => isValid(val) || (!isRequired && !val),
      currentValue
    )
  });

const hasFieldChangedReducer = fieldName =>
  handleActions(
    {
      [UPDATE_GP_FIELD]: (state, action) => {
        if (action.field !== fieldName) {
          return state;
        }
        return true;
      },
      [GET_GP_PROFILE_RECEIVE]: () => false,
      [COMPLETE_NHSREFRESH_RECIEVE]: () => false
    },
    false
  );

const fieldsReducer = (state = {}, action) => {
  // This is a reducer and it is called every time an action is dispatched
  // and therefore has to return the state.
  // We can't use a standard `combineReducer` as the fax and email
  // fields depend on the selected contact preference.
  // Therefore, we need to get the updated contactPreference and pass it to those reducers.
  const oldContactPreference = state.contactPreference || {};
  const contactPreference = oneOfValuesFieldReducer(
    "contactPreference",
    gpSelectors.getContactPreference,
    VALID_CONTACT_PREFERENCES
  )(oldContactPreference, action);

  const otherState = { ...state };
  delete otherState.contactPreference;
  return {
    contactPreference,
    ...combineReducers({
      name: nonEmptyFieldReducer("name", gpSelectors.getName),
      addressLine1: nonEmptyFieldReducer(
        "addressLine1",
        gpSelectors.getAddressLine1
      ),
      addressLine2: alwaysValidFieldReducer(
        "addressLine2",
        gpSelectors.getAddressLine2
      ),
      postcode: nonEmptyFieldReducer("postcode", gpSelectors.getPostcode),
      city: nonEmptyFieldReducer("city", gpSelectors.getCity),
      country: alwaysValidFieldReducer("country", gpSelectors.getCountry),
      eps: alwaysValidFieldReducer("eps", gpSelectors.getHasEPS, false),
      againstDigitalPharmacy: alwaysValidFieldReducer(
        "againstDigitalPharmacy",
        gpSelectors.getIsAgainstDigitalPharmacy,
        false
      ),
      fax: optionallyRequiredFieldReducer(
        "fax",
        gpSelectors.getFax,
        contactPreference.value === gpSelectors.CONTACT_PREFERENCE_FAX,
        validateAsPhoneNumber,
        state.fax ? state.fax.value : ""
      ),
      email: optionallyRequiredFieldReducer(
        "email",
        gpSelectors.getEmail,
        contactPreference.value === gpSelectors.CONTACT_PREFERENCE_EMAIL,
        validateAsEmail,
        state.email ? state.email.value : ""
      ),
      telephone: optionallyRequiredFieldReducer(
        "telephone",
        gpSelectors.getTelephone,
        false,
        validateAsPhoneNumber,
        state.telephone ? state.telephone.value : ""
      ),
      notes: alwaysValidFieldReducer("notes", gpSelectors.getNotes),
      surgeryType: alwaysValidFieldReducer(
        "surgeryType",
        gpSelectors.getSurgeryType,
        ""
      ),
      status: alwaysValidFieldReducer("status", gpSelectors.getStatus),
      updateLock: alwaysValidFieldReducer(
        "updateLock",
        gpSelectors.getUpdateLock,
        false
      ),
      im1Provider: alwaysValidFieldReducer(
        "im1Provider",
        gpSelectors.getIM1Provider,
        ""
      )
    })(otherState, action)
  };
};

const isLoading = handleActions(
  {
    [GET_GP_PROFILE_REQUEST]: () => true,
    [GET_GP_PROFILE_RECEIVE]: () => false,
    [GET_GP_PROFILE_FAILURE]: () => false
  },
  false
);

export default combineReducers({
  id,
  isLoading,
  latitude,
  longitude,
  lastUpdated,
  flag,
  flagApplied,
  isOpen,
  fields: fieldsReducer,
  error
});
