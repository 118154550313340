import { combineReducers } from "redux";
import {
  offerGPRefresh,
  refreshedGP,
  isOpen,
  odsCode,
  unverifiedFields,
  gpIsRefreshed
} from "./reducers";

export * from "./actions";
export * from "./selectors";
export * from "./types";

export default combineReducers({
  offerGPRefresh,
  refreshedGP,
  isOpen,
  odsCode,
  unverifiedFields,
  gpIsRefreshed
});
