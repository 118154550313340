export const NEXT_ACTION = "@NEXT/ACTION";

// Dispatches an action defined in `action.payload[NEXT_ACTION]`
// after the current action.
export default ({ dispatch }) => next => action => {
  if (!action.payload) return next(action);
  if (!action.payload[NEXT_ACTION]) return next(action);
  next(action);
  return dispatch(action.payload[NEXT_ACTION]);
};
