import { MakerReturn, MatchStatus } from "./map-maker";

function noUpdated<T>(updatedFields?: MakerReturn<T>): boolean {
  if (updatedFields === undefined) {
    return true;
  }
  const idx = Object.values(updatedFields).lastIndexOf(MatchStatus.UPDATED);
  return idx === -1;
}

export default noUpdated;
