import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import React from "react";

const ScanInfo = ({ values }) => (
  <Grid container direction="column" justify="center">
    {values.map((v, i) => (
      <Grid
        key={i}
        item
        container
        direction="column"
        justify="center"
        style={{
          flex: 1
        }}
      >
        {Array.isArray(v.text) ? (
          v.text.map((t, i) => (
            <Typography key={i} {...v.typographyProps} component="span">
              {t}
            </Typography>
          ))
        ) : (
          <Typography {...v.typographyProps} component="span">
            {v.text}
          </Typography>
        )}
      </Grid>
    ))}
  </Grid>
);

export default ScanInfo;
