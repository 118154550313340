import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const imageStyle = {
  flex: "none",
  maxWidth: "100%",
  maxHeight: "100%"
};

export const renderEvidenceView = props => {
  const { isLoading, imageSrc } = props;
  if (isLoading) {
    return <CircularProgress id="t_isLoading" style={{ color: "white" }} />;
  } else if (imageSrc) {
    return (
      <img id="t_image" style={imageStyle} src={imageSrc} alt="Evidence" />
    );
  }
  return (
    <div>
      <Typography id="t_label" variant="subtitle1" style={{ color: "white" }}>
        Error
      </Typography>
    </div>
  );
};
