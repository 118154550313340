// Actions
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;
export const SHOW_MARK_ORDER_CUSTOMER_SERVICE =
  "SHOW_MARK_ORDER_CUSTOMER_SERVICE";
export const HIDE_MARK_ORDER_CUSTOMER_SERVICE =
  "HIDE_MARK_ORDER_CUSTOMER_SERVICE";
export const ADD_MEDICINE = "ADD_MEDICINE";
export const REMOVE_MEDICINE = "REMOVE_MEDICINE";
export const MARK_ORDER_CUSTOMER_SERVICE_REQUEST =
  "MARK_ORDER_CUSTOMER_SERVICE_REQUEST";
export const MARK_ORDER_CUSTOMER_SERVICE_RECEIVE =
  "MARK_ORDER_CUSTOMER_SERVICE_RECEIVE";
export const MARK_ORDER_CUSTOMER_SERVICE_FAILURE =
  "MARK_ORDER_CUSTOMER_SERVICE_FAILURE";

// Action Creators
export const showMarkOrderCustomerService = (orderId, medicines) => ({
  type: SHOW_MARK_ORDER_CUSTOMER_SERVICE,
  orderId,
  medicines
});

export const hideMarkOrderCustomerService = () => ({
  type: HIDE_MARK_ORDER_CUSTOMER_SERVICE
});

export const addMedicine = (medicineIndex, selectedMedicines) => ({
  type: ADD_MEDICINE,
  medicineIndex,
  selectedMedicines
});

export const removeMedicine = (medicineIndex, selectedMedicines) => ({
  type: REMOVE_MEDICINE,
  medicineIndex,
  selectedMedicines
});

export const markCustomerService = (
  orderId,
  medicines,
  selectedMedicineIndexes,
  apiEndpoint = ORDERS_API_ENDPOINT
) => {
  const selectedMedicines = medicines
    .filter((med, i) => selectedMedicineIndexes.includes(i))
    .map(med => ({ ampId: med.ampId }));
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/order/${orderId}/markcs`,
      method: "POST",
      body: JSON.stringify({ medicines: selectedMedicines }),
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        MARK_ORDER_CUSTOMER_SERVICE_REQUEST,
        MARK_ORDER_CUSTOMER_SERVICE_RECEIVE,
        MARK_ORDER_CUSTOMER_SERVICE_FAILURE
      ],
      options: { addAuth: true }
    }
  };
};

// Selectors
export const getIsOpen = state => state.isOpen;
export const getSelectedMedicines = state => state.selectedMedicines;
export const getCanSubmit = state => state.canSubmit;
export const getIsUpdating = state => state.isUpdating;
export const getMedicines = state => state.medicines;
export const getOrderId = state => state.orderId;

// Reducers
const isOpen = handleActions(
  {
    [SHOW_MARK_ORDER_CUSTOMER_SERVICE]: () => true,
    [HIDE_MARK_ORDER_CUSTOMER_SERVICE]: () => false,
    [MARK_ORDER_CUSTOMER_SERVICE_RECEIVE]: () => false
  },
  false
);

const selectedMedicines = handleActions(
  {
    [ADD_MEDICINE]: (medicines, action) => {
      const newMedicines = [...medicines];
      newMedicines.push(action.medicineIndex);
      return newMedicines.sort();
    },
    [REMOVE_MEDICINE]: (medicines, action) => {
      const newMedicines = [...medicines];
      return newMedicines.filter(med => med !== action.medicineIndex).sort();
    },
    [SHOW_MARK_ORDER_CUSTOMER_SERVICE]: () => [],
    [HIDE_MARK_ORDER_CUSTOMER_SERVICE]: () => []
  },
  []
);

const medicines = handleActions(
  {
    [SHOW_MARK_ORDER_CUSTOMER_SERVICE]: (_, action) =>
      action.medicines.map(med => ({
        desc: med.brandDesc ? med.brandDesc : med.prodDesc,
        ampId: med.packages && med.packages[0].ampId
      })),
    [HIDE_MARK_ORDER_CUSTOMER_SERVICE]: () => []
  },
  []
);

const canSubmit = handleActions(
  {
    [ADD_MEDICINE]: () => true,
    [REMOVE_MEDICINE]: (state, action) =>
      action.selectedMedicines.length - 1 > 0,
    [SHOW_MARK_ORDER_CUSTOMER_SERVICE]: () => false,
    [HIDE_MARK_ORDER_CUSTOMER_SERVICE]: () => false
  },
  false
);

const isUpdating = handleActions(
  {
    [MARK_ORDER_CUSTOMER_SERVICE_REQUEST]: () => true,
    [MARK_ORDER_CUSTOMER_SERVICE_RECEIVE]: () => false,
    [MARK_ORDER_CUSTOMER_SERVICE_FAILURE]: () => false,
    [SHOW_MARK_ORDER_CUSTOMER_SERVICE]: () => false,
    [HIDE_MARK_ORDER_CUSTOMER_SERVICE]: () => false
  },
  false
);

const orderId = handleActions(
  {
    [SHOW_MARK_ORDER_CUSTOMER_SERVICE]: (_, action) => action.orderId,
    [HIDE_MARK_ORDER_CUSTOMER_SERVICE]: () => 0
  },
  0
);

export default combineReducers({
  isOpen,
  selectedMedicines,
  medicines,
  canSubmit,
  isUpdating,
  orderId
});
