import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getDateFilterDialogCount,
  getIsDateFilterCountLoading
} from "../../redux/root-reducer";
import {
  FetchDateFilterCounts,
  getDateFiltersCheckbox,
  selectDateFilters,
  selectDateFiltersCheckbox
} from "redux/date-filter-dialog";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  makeStyles
} from "@material-ui/core";

import TodayIcon from "@material-ui/icons/Today";
import ErrorIcon from "@material-ui/icons/Error";
import { formatDateString, formatDBDateString } from "helpers/date-helpers";
import moment from "moment";

const useStyles = makeStyles(() => ({
  datefilter: {
    margin: "0px 15px",
    display: "flex"
  },
  datefilterlabel: {
    marginLeft: "8px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center"
  }
}));

export const DateFilterDialog = ({
  onFetchDateFilterCounts,
  FilterCounts,
  loading,
  onSelectDateFilters,
  onSelectDateFiltersCheckbox,
  DateFiltersCheckbox
}) => {
  const classes = useStyles();
  const [todayDate, setTodayDate] = useState("");
  const [yesterdayDate, setYesterdayDate] = useState("");
  const [beforeYesterdayDate, setbeforeYesterdayDate] = useState("");
  const [todayDateCount, setTodayDateCount] = useState(0);
  const [yesterdayDateCount, setYesterdayDateCount] = useState(0);
  const [beforeYesterdayDateCount, setBeforeYesterdayDateCount] = useState(0);
  const [outOfSLACount, setOutOfSLACount] = useState(0);
  const [outOfSLADates, setOutOfSLADates] = useState([]);
  const [priorityCount, setPriorityCount] = useState(0);

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false
  });
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (DateFiltersCheckbox) {
      setState({ ...DateFiltersCheckbox });
    }
  }, []);

  //effect only for, when checkbox changes
  useEffect(() => {
    let selectedDates = [];
    const selectedPriority = state.checkedE;

    if (state.checkedA) {
      outOfSLADates.map(ele => {
        selectedDates = AddDateFunc(selectedDates, formatDBDateString(ele));
      });
    }
    if (!state.checkedA) {
      outOfSLADates.map(ele => {
        selectedDates = RemoveDateFunc(selectedDates, formatDBDateString(ele));
      });
    }

    if (state.checkedB) {
      selectedDates = AddDateFunc(selectedDates, formatDBDateString(todayDate));
    }
    if (!state.checkedB) {
      selectedDates = RemoveDateFunc(
        selectedDates,
        formatDBDateString(todayDate)
      );
    }

    if (state.checkedC) {
      selectedDates = AddDateFunc(
        selectedDates,
        formatDBDateString(yesterdayDate)
      );
    }
    if (!state.checkedC) {
      selectedDates = RemoveDateFunc(
        selectedDates,
        formatDBDateString(yesterdayDate)
      );
    }

    if (state.checkedD) {
      selectedDates = AddDateFunc(
        selectedDates,
        formatDBDateString(beforeYesterdayDate)
      );
    }
    if (!state.checkedD) {
      selectedDates = RemoveDateFunc(
        selectedDates,
        formatDBDateString(beforeYesterdayDate)
      );
    }
    onSelectDateFilters({ selectedDates, selectedPriority });
    onSelectDateFiltersCheckbox(state);
  }, [state]);

  const AddDateFunc = (Alldates, date) => {
    return Alldates.concat(date);
  };

  const RemoveDateFunc = (Alldates, date) => {
    const index = Alldates.findIndex(item => item === date);
    if (index !== -1) {
      Alldates.pop(date);
    }
    return Alldates;
  };
  useEffect(() => {
    onFetchDateFilterCounts();
  }, []);

  useEffect(() => {
    if (FilterCounts) {
      const Todaydate = moment(new Date()).locale("GB");
      setTodayDate(Todaydate.toDate());

      const YesterdayDate = moment(new Date())
        .locale("GB")
        .subtract(1, "day");
      setYesterdayDate(YesterdayDate.toDate());

      const BeforeYesterdayDate = moment(new Date())
        .locale("GB")
        .subtract(2, "day");
      setbeforeYesterdayDate(BeforeYesterdayDate.toDate());

      const TodayFormatdate = formatDBDateString(Todaydate);
      const YesterdayFormatdate = formatDBDateString(YesterdayDate);
      const BeforeYesterdayFormatDate = formatDBDateString(BeforeYesterdayDate);

      let OutOfSLACount = 0;
      const OutOfSLADates = [];

      FilterCounts.dates.map(ele => {
        if (ele.OrderDate.startsWith(TodayFormatdate)) {
          setTodayDateCount(ele.OrdersCount);
        } else if (ele.OrderDate.startsWith(YesterdayFormatdate)) {
          setYesterdayDateCount(ele.OrdersCount);
        } else if (ele.OrderDate.startsWith(BeforeYesterdayFormatDate)) {
          setBeforeYesterdayDateCount(ele.OrdersCount);
        } else {
          OutOfSLACount = OutOfSLACount + Number(ele.OrdersCount);
          OutOfSLADates.push(ele.OrderDate);
        }
      });
      setOutOfSLACount(OutOfSLACount);
      setOutOfSLADates(OutOfSLADates);
      setPriorityCount(FilterCounts.priorityCount || 0);
    }
  }, [FilterCounts]);

  return (
    <div className={classes.datefilter}>
      {loading || todayDate == "" ? (
        <CircularProgress size={20} />
      ) : (
        <FormGroup>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <TodayIcon />
            <div className={classes.datefilterlabel}>Filter by date</div>
          </div>

          {FilterCounts ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedA}
                    onChange={handleChange}
                    name={"checkedA"}
                    color={"default"}
                  />
                }
                label={`Out of SLA (${outOfSLACount})`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedD}
                    onChange={handleChange}
                    name={"checkedD"}
                    color={"default"}
                  />
                }
                label={
                  beforeYesterdayDate != ""
                    ? `${formatDateString(
                        beforeYesterdayDate
                      )} (${beforeYesterdayDateCount})`
                    : ""
                }
              />
            </>
          ) : (
            ""
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedC}
                onChange={handleChange}
                name={"checkedC"}
                color={"default"}
              />
            }
            label={
              yesterdayDate != ""
                ? `${formatDateString(yesterdayDate)} (${yesterdayDateCount})`
                : ""
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedB}
                onChange={handleChange}
                name={"checkedB"}
                color={"default"}
              />
            }
            label={
              todayDate != ""
                ? `${formatDateString(todayDate)} (${todayDateCount})`
                : ""
            }
          />
          <div style={{ display: "flex", marginTop: "15px" }}>
            <ErrorIcon />
            <div className={classes.datefilterlabel}>Priority</div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedE}
                onChange={handleChange}
                name={"checkedE"}
                color={"default"}
              />
            }
            label={`Priority (${priorityCount})`}
          />
        </FormGroup>
      )}
    </div>
  );
};

DateFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  hideDateFilterDialog: PropTypes.func.isRequired
};

export const mapDispatchToProps = dispatch => ({
  onFetchDateFilterCounts: () => {
    dispatch(FetchDateFilterCounts());
  },
  onSelectDateFilters: dateFilters => {
    dispatch(selectDateFilters(dateFilters));
  },
  onSelectDateFiltersCheckbox: dateFiltersCheckbox => {
    dispatch(selectDateFiltersCheckbox(dateFiltersCheckbox));
  }
});

export const mapStateToProps = state => ({
  FilterCounts: getDateFilterDialogCount(state),
  loading: getIsDateFilterCountLoading(state),
  DateFiltersCheckbox: getDateFiltersCheckbox(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFilterDialog);
