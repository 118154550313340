import RadioGroup from "@material-ui/core/RadioGroup";
import s from "../match-users/style.module.scss";
import Card from "@material-ui/core/Card";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import PropTypes from "prop-types";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

class CardList extends React.Component {
  handleRadioChange = event => {
    const { onCardSelectionChanged, cards } = this.props;
    const card = cards.find(c => c.id === event.target.value);
    const selected = card.selected || false;
    onCardSelectionChanged(card, !selected);
  };

  renderCards() {
    const { cards, render, onCardSelectionChanged, selectorType } = this.props;

    const noCardsSelected = cards.find(c => c.selected) === undefined;

    return cards.map(card => {
      const classNames = `${s.clCard} ${
        noCardsSelected || card.selected ? "" : s.clTransparent
      }`;

      const selected = card.selected || false;

      return (
        <div key={card.id}>
          <Card
            className={classNames}
            onClick={() => onCardSelectionChanged(card.data, !selected)}
          >
            {selectorType === "checkbox" && (
              <div>
                <Checkbox
                  checked={selected}
                  onChange={this.handleRadioChange}
                  value={`${card.id}`}
                  classes={{ root: s.clCheckbox }}
                  color="primary"
                />
              </div>
            )}

            {selectorType === "radio" && (
              <div>
                <FormControlLabel
                  label=""
                  classes={{ root: s.clRadioLabel }}
                  value={card.id}
                  control={
                    <Radio checked={selected} classes={{ root: s.clRadio }} />
                  }
                />
              </div>
            )}

            {render(card.data, card.selected)}
          </Card>
        </div>
      );
    });
  }

  renderContent() {
    const { cards, selectorType } = this.props;

    if (cards === undefined) {
      return null;
    }

    const renderedCards = this.renderCards();

    if (selectorType === "radio") {
      const selectedCard = cards.find(c => c.selected);

      return (
        <RadioGroup
          name="cardListRadioGroup"
          value={selectedCard ? selectedCard.id : undefined}
          onChange={this.handleRadioChange}
        >
          {renderedCards}
        </RadioGroup>
      );
    }
    return renderedCards;
  }

  render() {
    return <div className={s.cardList}>{this.renderContent()}</div>;
  }
}

CardList.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      data: PropTypes.object.isRequired,
      selected: PropTypes.bool
    })
  ),

  render: PropTypes.func.isRequired,
  onCardSelectionChanged: PropTypes.func,
  selectorType: PropTypes.oneOf(["radio", "checkbox", undefined])
};

CardList.defaultProps = {
  cards: undefined,
  selectorType: undefined,
  onCardSelectionChanged: () => undefined
};

export default CardList;
