import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);
NoMatch.propTypes = {
  location: PropTypes.object.isRequired
};

const getRoutesfromData = data => {
  const childRoutes = [];

  const routes = data.map((item, i) => {
    if (item.isRedirect) {
      return (
        <Route
          key={i}
          path={item.fromUrl}
          exact
          render={() => <Redirect to={item.toUrl} />}
        />
      );
    }
    if (item.childRoutes && item.childRoutes.length) {
      item.childRoutes.forEach(child => {
        const childRoute = (
          <Route key={i} path={child.url} component={() => child.component} />
        );
        childRoutes.push(childRoute);
      });
    }
    if (item.renderComponent) {
      return <Route key={i} path={item.url} render={item.renderComponent} />;
    }
    return <Route key={i} path={item.url} component={() => item.component} />;
  });
  return childRoutes.concat(routes);
};

const GenerateRoutes = ({ data }) => (
  <Switch>
    {getRoutesfromData(data)}
    <Route component={NoMatch} />
  </Switch>
);

GenerateRoutes.propTypes = {
  data: PropTypes.array.isRequired
};

export default GenerateRoutes;
