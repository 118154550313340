import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Assignment from "@material-ui/icons/Assignment";
import ChildFriendly from "@material-ui/icons/ChildFriendly";
import Email from "@material-ui/icons/Email";
import Healing from "@material-ui/icons/Healing";
import Home from "@material-ui/icons/Home";
import LooksOne from "@material-ui/icons/LooksOne";
import LooksTwo from "@material-ui/icons/LooksTwo";
import Phone from "@material-ui/icons/Phone";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { openCreateOrder } from "../../redux/create-order";
import { createDispatchLabel } from "../../redux/dispatch-label/create-dialog";
import { nominateUser } from "../../redux/pending-users";
import {
  getUserProfileAddressCity,
  getUserProfileAddressLine1,
  getUserProfileAddressLine2,
  getUserProfileAddressPostcode,
  getUserProfileDOB,
  getUserProfileDOBDay,
  getUserProfileDOBMonth,
  getUserProfileDOBYear,
  getUserProfileEmail,
  getUserProfileExemptions,
  getUserProfileGPSearchText,
  getUserProfileGPSuggestions,
  getUserProfileIsFetchingProfile,
  getUserProfileIsLoadingGPs,
  getUserProfileIsNominating,
  getUserProfileIsSaving,
  getUserProfileNotes,
  getUserProfilePhone,
  getUserProfileSelectedGP,
  getUserProfileShowNominationButton,
  getUserProfileTab,
  getUserProfileUserFirstName,
  getUserProfileUserId,
  getUserProfileUserLastName,
  getUserProfileUserToCreateOrder
} from "../../redux/root-reducer";
import * as gpSel from "../../redux/selectors/gp";
import { getIntercomLink } from "../../redux/selectors/user";
import {
  changeGPSearchText,
  clearExemptions,
  clearGPSuggestions,
  fetchUserProfile,
  selectGP,
  setAddressCity,
  setAddressLine1,
  setAddressLine2,
  setAddressPostcode,
  setDOBDay,
  setDOBMonth,
  setDOBYear,
  setNotes,
  setPhone,
  setUserFirstName,
  setUserLastName,
  setUserTabIndex,
  updateUserProfile
} from "../../redux/user-profile";
import AutoComplete from "../common/autocomplete";
import LoadingButton from "../common/loading-button";
import ContextMenu from "../context-menu";
import Orders from "../orders";
import Requests from "../requests";
import Search from "../search";
import Cell from "./cell";
import s from "./user.module.scss";
import { addressLineMaxLen } from "helpers/validators";

export class UserProfileComponent extends Component {
  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetch();
    }
  }

  fetch() {
    const {
      fetchUserProfile // eslint-disable-line no-shadow
    } = this.props;
    const userId = this.getUserId();
    fetchUserProfile(userId);
  }

  getUserId() {
    return this.props.match.params.id;
  }

  renderLoading = () => (
    <div
      id={"t_loading"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
      }}
    >
      <CircularProgress size={20} />
    </div>
  );

  renderProfile() {
    const {
      userFirstName,
      userLastName,
      userEmail,
      userDOBDay,
      userDOBMonth,
      userDOBYear,
      userPhone,
      loadingGPs,
      gpSearchText,
      gpSuggestions,
      addressLine1,
      addressLine2,
      addressCity,
      addressPostcode,
      notes,
      saving,
      onGPSuggestionTextChange,
      onCloseGPSuggestions,
      onGPSuggestionSelect,
      onAddressLine1Change,
      onAddressLine2Change,
      onAddressCityChange,
      onAddressPostcodeChange,
      onFirstNameChange,
      onLastNameChange,
      onNotesChange,
      onDOBDayChange,
      onDOBMonthChange,
      onDOBYearChange,
      onSave,
      onViewGPProfile,
      onTabSelected,
      selectedTabIndex,
      onCreateNewOrder,
      showNominationButton,
      onNominate,
      nominating,
      userExemptions,
      onClearExemption,
      onPhoneChange,
      onShippingButtonPress
    } = this.props;
    const userId = this.getUserId();
    return (
      <div className={s.root}>
        <div className={s.leftColumn}>
          <DialogContent>
            <Cell icon={<LooksOne />}>
              <TextField
                fullWidth
                id={"t_firstname"}
                label={"First Name"}
                type={"text"}
                value={userFirstName}
                onChange={e => onFirstNameChange(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell icon={<LooksTwo />}>
              <TextField
                fullWidth
                id={"t_lastname"}
                label={"Last Name"}
                type={"text"}
                value={userLastName}
                onChange={e => onLastNameChange(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell icon={<ChildFriendly />}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  id={"t_dob_day"}
                  style={{ width: "20%" }}
                  label={"Day"}
                  type={"text"}
                  value={userDOBDay}
                  onChange={e => onDOBDayChange(e.currentTarget.value)}
                  margin={"normal"}
                />
                <TextField
                  id={"t_dob_month"}
                  style={{ width: "20%" }}
                  label={"Month"}
                  type={"text"}
                  value={userDOBMonth}
                  onChange={e => onDOBMonthChange(e.currentTarget.value)}
                  margin={"normal"}
                />
                <TextField
                  id={"t_dob_year"}
                  style={{ width: "20%" }}
                  label={"Year"}
                  type={"text"}
                  value={userDOBYear}
                  onChange={e => onDOBYearChange(e.currentTarget.value)}
                  margin={"normal"}
                />
              </div>
            </Cell>
            <Cell icon={<Assignment />}>
              <TextField
                fullWidth
                id={"t_notes"}
                label={"Notes"}
                multiline
                value={notes}
                onChange={e => onNotesChange(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell
              iconStyle={{ paddingTop: 20 }}
              icon={<Healing />}
              loading={loadingGPs}
            >
              <AutoComplete
                id={"t_autocomplete_gp"}
                classes={{ container: s.autoComplete }}
                placeholder={"GP"}
                placement={"right-start"}
                onTextChange={t => onGPSuggestionTextChange(t)}
                onOuterClick={() => onCloseGPSuggestions()}
                onSuggestionSelect={index => onGPSuggestionSelect(index)}
                text={gpSearchText}
                suggestions={gpSuggestions}
              />
              <Button
                id={"t_viewgp_button"}
                variant={"text"}
                color={"secondary"}
                style={{
                  padding: 0,
                  minWidth: 0,
                  marginTop: 10
                }}
                onClick={() => onViewGPProfile()}
              >
                View
              </Button>
            </Cell>
            <br />
            <Cell icon={<Home />}>
              <TextField
                fullWidth
                id={"t_address1"}
                label={"1st Line of Address"}
                type={"text"}
                value={addressLine1}
                error={addressLine1?.length > addressLineMaxLen}
                helperText={addressLine1?.length > addressLineMaxLen ? 'Character limit exceeded. Please continue address on line 2.' : ''}
                inputProps={{ maxLength: addressLineMaxLen + 1}}
                onChange={e => onAddressLine1Change(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell>
              <TextField
                fullWidth
                id={"t_address2"}
                label={"2nd Line of Address"}
                type={"text"}
                value={addressLine2}
                inputProps={{ maxLength: addressLineMaxLen + 1}}
                error={addressLine2?.length > addressLineMaxLen}
                helperText={addressLine2?.length > addressLineMaxLen ? 'Character limit exceeded' : ''}
                onChange={e => onAddressLine2Change(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell>
              <TextField
                fullWidth
                id={"t_address_city"}
                label={"City"}
                type={"text"}
                value={addressCity}
                error={addressCity?.length > addressLineMaxLen}
                helperText={addressCity?.length > addressLineMaxLen ? 'Character limit exceeded' : ''}
                inputProps={{ maxLength: addressLineMaxLen + 1}}
                onChange={e => onAddressCityChange(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell>
              <TextField
                fullWidth
                id={"t_address_postcode"}
                label={"Postcode"}
                type={"text"}
                value={addressPostcode}
                onChange={e => onAddressPostcodeChange(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell icon={<Phone />}>
              <TextField
                fullWidth
                id={"t_phone_number"}
                label={"Phone"}
                type={"text"}
                value={userPhone}
                onChange={e => onPhoneChange(e.currentTarget.value)}
                margin={"normal"}
              />
            </Cell>
            <Cell icon={<Email />}>
              <TextField
                fullWidth
                multiline
                id={"t_email"}
                label={"Email"}
                type={"text"}
                value={userEmail}
                margin={"normal"}
                disabled
              />
            </Cell>
          </DialogContent>
        </div>
        <div className={s.rightColumn}>
          <div className={s.tabs}>
            <Tabs
              value={selectedTabIndex}
              indicatorColor={"primary"}
              textColor={"primary"}
              onChange={(e, v) => {
                onTabSelected(v);
              }}
            >
              <Tab label={"Requests"} value={0} />
              <Tab label={"Orders"} value={1} />
            </Tabs>
          </div>
          <div className={s.content}>
            {selectedTabIndex === 0 && userId ? (
              <Requests
                contentDescription={`requests for ${userFirstName} ${userLastName}`}
                userId={userId}
                hideNewOrderBtn
                displaySettings={{
                  showRequestStatus: true,
                  showDeliveryAddress: true
                }}
              />
            ) : null}
            {selectedTabIndex === 1 && userId ? (
              <Orders
                contentDescription={`orders for ${userFirstName} ${userLastName}`}
                userId={userId}
                showEvidenceColumn
                hideNewOrderBtn
                onShippingButtonPress={onShippingButtonPress}
                ordersPerPage={20}
                displaySettings={{
                  showOrderId: true,
                  showOrderStatus: true,
                  showDeliveryAddress: true,
                  showBarcodeColumn: true,
                  showBarcodeForOrder: status =>
                    [
                      "hm_delivered",
                      "cf_rejected",
                      "prescription_printed"
                    ].includes(status),
                  showShippingClass: true,
                  showShippingButtonsColumn: true,
                  showShippingButtonsForOrder: status =>
                    [
                      "hm_delivered",
                      "cf_rejected",
                      "prescription_printed"
                    ].includes(status)
                }}
              />
            ) : null}
          </div>
        </div>
        <div className={s.footer}>
          {userExemptions && userExemptions.hasExemption && (
            <Button
              id={"t_clear_exemptions_button"}
              variant={"text"}
              color={"secondary"}
              style={{ marginRight: 20 }}
              onClick={() => onClearExemption(userId)}
            >
              Clear Exemptions
            </Button>
          )}
          {showNominationButton && (
            <LoadingButton
              id={"t_nominate_button"}
              variant={"text"}
              color={"secondary"}
              loading={nominating}
              style={{ marginRight: 20 }}
              onClick={() => onNominate(userId)}
            >
              Nominate
            </LoadingButton>
          )}

          <Button
            id={"t_neworder_button"}
            variant={"text"}
            color={"secondary"}
            style={{ marginRight: 20 }}
            onClick={() => onCreateNewOrder()}
          >
            New Order
          </Button>
          <Button
            id={"t_chat_button"}
            variant={"text"}
            color={"secondary"}
            style={{ marginRight: 20 }}
            href={getIntercomLink(this.getUserId())}
            target={"_blank"}
          >
            Chat
          </Button>
          <LoadingButton
            id={"t_save_button"}
            variant={"contained"}
            color={"primary"}
            loading={saving}
            disabled={addressCity?.length > addressLineMaxLen || addressLine2?.length > addressLineMaxLen || addressLine1?.length > addressLineMaxLen}
            onClick={() => onSave()}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    );
  }

  render() {
    const { loadingProfile } = this.props;
    return (
      <Dialog id={"t_dialog"} fullScreen open>
        <header>
          <ContextMenu>
            <Search />
          </ContextMenu>
        </header>
        {loadingProfile ? this.renderLoading() : this.renderProfile()}
      </Dialog>
    );
  }
}

UserProfileComponent.propTypes = {
  loadingProfile: PropTypes.bool,
  fetchUserProfile: PropTypes.func,
  // User
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userEmail: PropTypes.string,
  userDOBDay: PropTypes.string,
  userDOBMonth: PropTypes.string,
  userDOBYear: PropTypes.string,
  userPhone: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressCity: PropTypes.string,
  addressPostcode: PropTypes.string,
  onAddressLine1Change: PropTypes.func,
  onAddressLine2Change: PropTypes.func,
  onAddressCityChange: PropTypes.func,
  onAddressPostcodeChange: PropTypes.func,
  onFirstNameChange: PropTypes.func,
  onLastNameChange: PropTypes.func,
  onNotesChange: PropTypes.func,
  onTabSelected: PropTypes.func,
  selectedTabIndex: PropTypes.number,
  onDOBDayChange: PropTypes.func,
  onDOBMonthChange: PropTypes.func,
  onDOBYearChange: PropTypes.func,
  onPhoneChange: PropTypes.func,
  // Nomination
  showNominationButton: PropTypes.bool,
  onNominate: PropTypes.func, // called with user id
  nominating: PropTypes.bool,
  // GP
  onGPSuggestionTextChange: PropTypes.func,
  onCloseGPSuggestions: PropTypes.func,
  onGPSuggestionSelect: PropTypes.func,
  onViewGPProfile: PropTypes.func,
  gpSearchText: PropTypes.string,
  gpSuggestions: PropTypes.array,
  loadingGPs: PropTypes.bool,
  notes: PropTypes.string,
  // Save
  saving: PropTypes.bool,
  onSave: PropTypes.func,
  // New order
  onCreateNewOrder: PropTypes.func,
  // Testing
  getPathName: PropTypes.func,
  onShippingButtonPress: PropTypes.func
};

UserProfileComponent.defaultProps = {
  loadingProfile: false,
  fetchUserProfile: () => {},
  // User
  userFirstName: "",
  userLastName: "",
  userEmail: "",
  userDOBDay: "",
  userDOBMonth: "",
  userDOBYear: "",
  userPhone: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressPostcode: "",
  onAddressLine1Change: () => {},
  onAddressLine2Change: () => {},
  onAddressCityChange: () => {},
  onAddressPostcodeChange: () => {},
  onNotesChange: () => {},
  onFirstNameChange: () => {},
  onLastNameChange: () => {},
  onTabSelected: () => {},
  onDOBDayChange: () => {},
  onDOBMonthChange: () => {},
  onDOBYearChange: () => {},
  selectedTabIndex: 0,
  onPhoneChange: () => {},
  // Nomination
  showNominationButton: false,
  onNominate: () => {},
  nominating: false,
  // GP
  onGPSuggestionTextChange: () => {},
  onCloseGPSuggestions: () => {},
  onGPSuggestionSelect: () => {},
  onViewGPProfile: () => {},
  gpSearchText: "",
  gpSuggestions: [],
  loadingGPs: false,
  notes: "",
  // Save
  saving: false,
  onSave: () => {},
  // New order
  onCreateNewOrder: () => {},
  // Testing
  getPathName: () => global.location.pathname,
  onShippingButtonPress: () => {}
};

const mapStateToProps = state => ({
  // User
  userId: getUserProfileUserId(state),
  loadingProfile: getUserProfileIsFetchingProfile(state),
  userFirstName: getUserProfileUserFirstName(state),
  userLastName: getUserProfileUserLastName(state),
  userDOB: getUserProfileDOB(state),
  userDOBDay: getUserProfileDOBDay(state),
  userDOBMonth: getUserProfileDOBMonth(state),
  userDOBYear: getUserProfileDOBYear(state),
  userPhone: getUserProfilePhone(state),
  userEmail: getUserProfileEmail(state),
  addressLine1: getUserProfileAddressLine1(state),
  addressLine2: getUserProfileAddressLine2(state),
  addressCity: getUserProfileAddressCity(state),
  addressPostcode: getUserProfileAddressPostcode(state),
  userToCreateOrder: getUserProfileUserToCreateOrder(state),
  // GP
  selectedGP: getUserProfileSelectedGP(state),
  loadingGPs: getUserProfileIsLoadingGPs(state),
  gps: getUserProfileGPSuggestions(state),
  gpSearchText: getUserProfileGPSearchText(state),
  gpSuggestions: getUserProfileGPSuggestions(state).map(gp => ({
    name: gpSel.getName(gp),
    description: gpSel.getFullAddress(gp)
  })),
  notes: getUserProfileNotes(state),
  // Save
  saving: getUserProfileIsSaving(state),
  selectedTabIndex: getUserProfileTab(state),
  // Nominate
  showNominationButton: getUserProfileShowNominationButton(state),
  nominating: getUserProfileIsNominating(state),
  // Exemptions
  userExemptions: getUserProfileExemptions(state)
});

const mapDispatchToProps = {
  onGPSuggestionTextChange: changeGPSearchText,
  onCloseGPSuggestions: clearGPSuggestions,
  selectGP,
  updateUserProfile,
  fetchUserProfile,
  onTabSelected: setUserTabIndex,
  onAddressLine1Change: setAddressLine1,
  onAddressLine2Change: setAddressLine2,
  onAddressCityChange: setAddressCity,
  onAddressPostcodeChange: setAddressPostcode,
  onFirstNameChange: setUserFirstName,
  onLastNameChange: setUserLastName,
  onNotesChange: setNotes,
  onDOBDayChange: setDOBDay,
  onDOBMonthChange: setDOBMonth,
  onDOBYearChange: setDOBYear,
  openGPProfile: id => push(`/gp/profile/${id}`),
  openCreateOrder,
  onNominate: nominateUser,
  onClearExemption: clearExemptions,
  onPhoneChange: setPhone,
  onShippingButtonPress: ({ orderId, shippingPackage, whistlEnabled }) => {
    return createDispatchLabel(
      orderId,
      0,
      shippingPackage,
      { value: null },
      null,
      whistlEnabled
    );
  }
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onGPSuggestionSelect: index => dispatchProps.selectGP(stateProps.gps[index]),
  onSave: () =>
    dispatchProps.updateUserProfile(stateProps.userId, {
      gpId: stateProps.selectedGP.id,
      firstName: stateProps.userFirstName,
      lastName: stateProps.userLastName,
      addressLine1: stateProps.addressLine1,
      addressLine2: stateProps.addressLine2,
      addressCity: stateProps.addressCity,
      addressPostcode: stateProps.addressPostcode,
      dob: stateProps.userDOB,
      notes: stateProps.notes,
      phone: stateProps.userPhone
    }),
  onViewGPProfile: () => dispatchProps.openGPProfile(stateProps.selectedGP.id),
  onCreateNewOrder: () =>
    dispatchProps.openCreateOrder({ patient: stateProps.userToCreateOrder }),
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserProfileComponent)
);
