import { getField } from "./utils";

const INTERCOM_APP = process.env.REACT_APP_INTERCOM_APP_ID;

export const getId = getField("user.id");
export const getFirstName = getField("user.firstName");
export const getLastName = getField("user.lastName");
export const getAddresses = user => getField("addresses")(user);
export const getHomeAddress = user =>
  getField("addresses")(user).filter(
    ({ addressType }) => addressType === "home"
  )[0];
export const getAddressLine1 = user => getHomeAddress(user).address1;
export const getAddressLine2 = user => getHomeAddress(user).address2;
export const getAddressCity = user => getHomeAddress(user).address3;
export const getAddressPostcode = user => getHomeAddress(user).postcode;
export const getGP = getField("practice");
export const getPhone = getField("user.phone");
export const getEmail = getField("user.email");
export const getDOB = getField("user.dob");
export const getFullName = user => `${getFirstName(user)} ${getLastName(user)}`;
export const getNotes = getField("user.notes");
export const getIsNominated = user =>
  Boolean(getField("user.nominatedPharmacyId")(user));
export const getFullAddress = user =>
  [
    getAddressLine1(user),
    getAddressLine2(user),
    getAddressCity(user),
    getAddressPostcode(user)
  ]
    .filter(a => a !== "")
    .join("\n");
export const getIntercomLink = userId =>
  `https://app.intercom.com/apps/${INTERCOM_APP}/users/show?user_id=${userId}`;
export const getExemption = payload => payload.exemption;
