import { handleActions } from "redux-actions";
import {
  OPEN_CREATE_ORDER,
  REMOVE_MEDICINE_FIELD,
  SELECT_MEDICINE
} from "redux/create-order";
import { SELECT_MEDICINE_FILTER } from "redux/orders";

import {
  FETCH_MEDICINES_REQUEST,
  FETCH_MEDICINES_RECEIVE,
  FETCH_MEDICINES_FAILURE,
  CLEAR_MEDS_SUGGESTIONS
} from "./meds-search.actions";
import { normalizeDmdResponse } from "./meds-search.helpers";
import { MedSuggestion } from "./meds-search.types";

const defaultAutoCompleteState: {
  reqId: string | null;
  suggestions: MedSuggestion[];
} = {
  reqId: null,
  suggestions: []
};
export const autoComplete = handleActions(
  {
    [OPEN_CREATE_ORDER]: () => ({ reqId: null, suggestions: [] }),
    [REMOVE_MEDICINE_FIELD]: () => ({ reqId: null, suggestions: [] }),
    [CLEAR_MEDS_SUGGESTIONS]: () => ({ reqId: null, suggestions: [] }),
    [SELECT_MEDICINE]: () => ({ reqId: null, suggestions: [] }),
    [SELECT_MEDICINE_FILTER]: () => ({ reqId: null, suggestions: [] }),
    [FETCH_MEDICINES_REQUEST]: (state, action: any) => ({
      ...state,
      reqId: action.meta.uniqueId
    }),
    [FETCH_MEDICINES_RECEIVE]: (state, action: any) =>
      action.meta.uniqueId === state.reqId
        ? {
            suggestions: normalizeDmdResponse(action.payload.data),
            reqId: null
          }
        : { ...state },
    [FETCH_MEDICINES_FAILURE]: (state, action: any) =>
      action.meta.uniqueId === state.reqId
        ? { ...state, reqId: null }
        : { ...state }
  },
  defaultAutoCompleteState
);

export const searchText = handleActions(
  {
    [CLEAR_MEDS_SUGGESTIONS]: () => "",
    [SELECT_MEDICINE]: () => "",
    [SELECT_MEDICINE_FILTER]: () => "",
    [OPEN_CREATE_ORDER]: () => "",
    [FETCH_MEDICINES_REQUEST]: (_state, action: any) => action.meta.text || ""
  },
  ""
);
