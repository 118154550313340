import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { EXEMPTION_DESCRIPTION, ORDER_IDENTIFIER_TYPES } from "./data";
import s from "./style.module.scss";
import ScanCheck from "./_ScanCheck";
import ScanInfo from "./_ScanInfo";

const getTitle = (have, types) => {
  const type = types.find(t => t.type === have.type);
  if (type) {
    return type.copy;
  }
};

const getValue = (have, types) => {
  return types.map(t => t.type).includes(have.type) ? have.type : undefined;
};

const ExemptionInfo = ({ codes, hasOrder }) => {
  const getExemptionMessage = function() {
    if (!hasOrder || !codes || codes.length === 0) return "-";
    return codes.map((c, i) => {
      const s =
        EXEMPTION_DESCRIPTION[c.toUpperCase()] ||
        `Unknown exemption type: Code ${c}`;
      if (i === codes.length - 1) return s;
      return `${s},`;
    });
  };

  const infoItemValues = [
    { text: "" },
    {
      text: "Exemption code",
      typographyProps: {
        variant: "h6"
      }
    },
    { text: getExemptionMessage() }
  ];
  return <ScanInfo values={infoItemValues} />;
};
const Order = ({ order, exemptionData }) => {
  return (
    <Grid container>
      <Paper className={s.scanInfo}>
        <ScanCheck
          scanned={!!getValue(order, ORDER_IDENTIFIER_TYPES)}
          type={getValue(order, ORDER_IDENTIFIER_TYPES)}
          title={"Order barcode"}
          value={getTitle(order, ORDER_IDENTIFIER_TYPES)}
        >
          <ExemptionInfo hasOrder={!!order.type} codes={exemptionData.codes} />
        </ScanCheck>
      </Paper>
    </Grid>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
    exemptionData: PropTypes.shape({
      codes: PropTypes.string
    })
  })
};
Order.defaultProps = {
  order: {},
  exemptionData: {}
};

export default Order;
