import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  createMuiTheme,
  ThemeProvider,
  ButtonBase,
  Paper,
  Box,
  Button,
  Typography
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        border: "1px solid #DADADA",
        textAlign: "center",
        color: "#757575 !important",
        fontSize: "4rem",
        fontWeight: 500,
        lineHeight: "140%"
      }
    }
  }
});

const ALPHABET_ROWS = [
  ["A", "B", "C", "D", "E", "F", "G"],
  ["H", "I", "J", "K", "L", "M", "N"],
  ["O", "P", "Q", "R", "S", "T", "U"],
  ["V", "W", "X", "Y", "Z"]
];

const NUMBER_ROWS = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [0]];

const ButtonCell = ({ onClick, children, colSpan }) => (
  <ButtonBase component={TableCell} onClick={onClick} colSpan={colSpan}>
    {children}
  </ButtonBase>
);

const renderTableRows = (data, onClick) =>
  data.map((row, idx) => (
    <TableRow key={idx}>
      {row.map(item => (
        <ButtonCell
          key={item}
          onClick={() => onClick(item)}
          colSpan={item === 0 ? 3 : ""}
        >
          {item}
        </ButtonCell>
      ))}
    </TableRow>
  ));

const AlphaNumericPad = ({ onClick }) => (
  <>
    <ThemeProvider theme={theme}>
      <Box display={"flex"} gridGap={"2.5rem"}>
        <Paper component={Table} elevation={3}>
          <TableBody>{renderTableRows(ALPHABET_ROWS, onClick)}</TableBody>
        </Paper>
        <Paper component={Table} elevation={3} style={{ width: "30rem" }}>
          <TableBody>{renderTableRows(NUMBER_ROWS, onClick)}</TableBody>
        </Paper>
      </Box>
    </ThemeProvider>
    <Box display={"flex"} style={{ gap: "2.5rem" }} justifyContent={"end"}>
      <Paper
        elevation={3}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "22.6875rem"
        }}
      >
        <Button
          fullWidth
          variant={"text"}
          style={{ padding: "4.4375rem 4.125rem" }}
          onClick={() => onClick(-1)}
        >
          <BackspaceIcon fontSize={"large"} style={{ color: "#757575" }} />
        </Button>
      </Paper>
      <Button
        color={"primary"}
        variant={"contained"}
        onClick={() => onClick(13)}
        style={{
          width: "45.375rem",
          textTransform: "none",
          padding: "4.4375rem 4.125rem"
        }}
      >
        <Typography
          style={{ fontSize: "2.5rem", fontWeight: 500, lineHeight: "140%" }}
        >
          Enter
        </Typography>
      </Button>
    </Box>
  </>
);

export default AlphaNumericPad;
