import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { withRouter } from "react-router-dom";
// UI Elements
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// commenting as part of 8692zauxy
// import WhistlSwitch from "./whistl-switch";
import Banner from "./env-banner";
import LogoLight from "../svgs/logo-light";
// Icons
import iconConfig from "./icon-config";
// Redux
import {
  setGroupedActionsVisibility,
  setAnchorElement
} from "../../redux/context-menu";
import {
  getContextOpenState,
  getContextMenuGroupedActions,
  getContextMenuAnchorElement,
  getContextMenuActions
} from "../../redux/root-reducer";
import s from "./context-menu.module.scss";
import { makeStyles } from "@material-ui/core";

const STYLE = {
  boxShadow: "rgba(0, 0, 0, 0.23) 0px 4px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
  backgroundColor: "rgb(38, 166, 154)",
  maxHeight: 62
};

const useMenuActionsStyle = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.primary.contrastText,
    height: 32,
    alignSelf: "center",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(1)
  }
}));

const MenuActions = ({ actions, dispatchAction }) => {
  const classes = useMenuActionsStyle();
  if (actions.length) {
    return (
      <>
        <Divider
          orientation={"vertical"}
          variant={"middle"}
          className={classes.divider}
        />
        {actions.map((action, index) => {
          const { name, enabled } = action;
          const Icon = iconConfig[name];
          return (
            <Tooltip key={`${index}-${action.tooltip}`} title={action.tooltip}>
              <div className={s.menuAction}>
                <IconButton
                  className={`t_actionbutton t_actionbutton_${index}`}
                  onClick={() => dispatchAction(action)}
                  disabled={!enabled}
                >
                  <Icon
                    className={`t_actionicon t_actionicon_${index}`}
                    opacity={enabled ? 1 : 0.4}
                  />
                </IconButton>
              </div>
            </Tooltip>
          );
        })}
      </>
    );
  }

  return null;
};

const GroupedActions = ({
  groupedActions = [],
  dispatchAction,
  isOpen,
  setVisibility,
  anchorElement
}) => (
  <div>
    <IconButton onClick={e => setVisibility(e, true)}>
      <MoreVertIcon />
    </IconButton>
    <Menu
      anchorEl={anchorElement}
      open={isOpen}
      onClose={e => setVisibility(e, false)}
    >
      {groupedActions.map((action, index) => {
        const { name, enabled } = action;
        const Icon = iconConfig[name];
        return (
          <MenuItem
            className={`t_groupedaction t_groupedaction_${index}`}
            key={index}
            disabled={!enabled}
            onClick={() => dispatchAction(action)}
          >
            <Icon style={{ marginRight: 20 }} />
            {action.title}
          </MenuItem>
        );
      })}
    </Menu>
  </div>
);

export const ContextMenu = ({
  children,
  actions,
  groupedActions,
  dispatchAction,
  isGroupedMenuOpen,
  setGroupedVisibility,
  anchorElement,
  pushRoute
}) => {
  return (
    <div className={s.menuTop}>
      <AppBar style={STYLE}>
        <Toolbar className={s.toolBar}>
          <button className={s.title} onClick={e => pushRoute("/", e)}>
            <LogoLight />
          </button>
          {children}
          <div className={s.actions}>
            <Banner />
            <div id={"screen-action-buttons"} style={{ marginRight: 4 }} />
            {/* commenting as part of 8692zauxy */}
            {/* <WhistlSwitch /> */}
            <MenuActions actions={actions} dispatchAction={dispatchAction} />
            <GroupedActions
              groupedActions={groupedActions}
              dispatchAction={dispatchAction}
              isOpen={isGroupedMenuOpen}
              setVisibility={setGroupedVisibility}
              anchorElement={anchorElement}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
ContextMenu.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.array.isRequired,
  groupedActions: PropTypes.array.isRequired,
  dispatchAction: PropTypes.func,
  setGroupedVisibility: PropTypes.func,
  isGroupedMenuOpen: PropTypes.bool,
  anchorElement: PropTypes.object,
  pushRoute: PropTypes.func
};
ContextMenu.defaultProps = {
  children: null,
  dispatchAction: () => {},
  isGroupedMenuOpen: false,
  setGroupedVisibility: () => {},
  anchorElement: null,
  pushRoute: () => {}
};

const mapStateToProps = state => ({
  actions: getContextMenuActions(state),
  anchorElement: getContextMenuAnchorElement(state),
  groupedActions: getContextMenuGroupedActions(state),
  isGroupedMenuOpen: getContextOpenState(state)
});

const mapDispatchToProps = dispatch => ({
  setGroupedVisibility: (e, v) => {
    dispatch(setAnchorElement(e.currentTarget));
    dispatch(setGroupedActionsVisibility(v === true));
  },
  dispatchAction: item => {
    if (item.enabled === true) {
      dispatch(item.actionCreator());
    }
    dispatch(setAnchorElement(null));
  },
  pushRoute: (r, e) => {
    e.preventDefault();
    dispatch(push(r));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContextMenu)
);
