import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

// List of available services
export const SERVICES = [
  { name: "Second Class 48h", value: "48" },
  { name: "Second Class 24h", value: "24" },
  { name: "Tracked 24h", value: "tracked24" },
  { name: "Tracked 48h", value: "tracked48" },
  { name: "Guaranteed Next Day 1pm", value: "guaranteed1pm" }
];

// Actions
export const SHOW_UPDATE_SHIPPING_CLASS = "SHOW_UPDATE_SHIPPING_CLASS";
export const HIDE_UPDATE_SHIPPING_CLASS = "HIDE_UPDATE_SHIPPING_CLASS";
export const SHIPPING_CLASS_SELECT_SERVICE = "SHIPPING_CLASS/SELECT_SERVICE";
export const SHIPPING_CLASS_TOGGLE_SIGNED = "SHIPPING_CLASS_TOGGLE_SIGNED";
export const UPDATE_SHIPPING_CLASS_REQUEST = "UPDATE_SHIPPING_CLASS_REQUEST";
export const UPDATE_SHIPPING_CLASS_RECEIVE = "UPDATE_SHIPPING_CLASS_RECEIVE";
export const UPDATE_SHIPPING_CLASS_FAILURE = "UPDATE_SHIPPING_CLASS_FAILURE";

// Action Creators
export const showUpdateShippingClass = ({
  orderId,
  shippingClass,
  shippingSigned
}) => ({
  type: SHOW_UPDATE_SHIPPING_CLASS,
  orderId,
  shippingClass,
  shippingSigned
});

export const hideUpdateShippingClass = () => ({
  type: HIDE_UPDATE_SHIPPING_CLASS
});

export const selectService = index => ({
  type: SHIPPING_CLASS_SELECT_SERVICE,
  index
});

export const toggleshippingSigned = index => ({
  type: SHIPPING_CLASS_TOGGLE_SIGNED,
  index
});

export const updateShippingClass = (
  orderId,
  selectedService,
  shippingSigned,
  apiEndpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${apiEndpoint}/order/${orderId}/shippingclass`,
    method: "PUT",
    body: JSON.stringify({
      shippingClass: selectedService.value,
      shippingSigned: shippingSigned
    }),
    headers: {
      "Content-Type": "application/json"
    },
    types: [
      UPDATE_SHIPPING_CLASS_REQUEST,
      UPDATE_SHIPPING_CLASS_RECEIVE,
      UPDATE_SHIPPING_CLASS_FAILURE
    ],
    options: { addAuth: true }
  }
});

// Selectors
export const getIsOpen = state => state.isOpen;
export const getServiceNames = () => SERVICES.map(p => p.name);
export const getSelectedServiceIndex = state => state.selectedServiceIndex;
export const getSelectedService = state => SERVICES[state.selectedServiceIndex];
export const getshippingSigned = state => state.shippingSigned;
export const getOrderId = state => state.orderId;
export const getIsUpdating = state => state.isUpdating;
export const getShowError = state => state.showError;
export const getErrorMessage = state => state.errorMessage;

// Reducers
const isOpen = handleActions(
  {
    [SHOW_UPDATE_SHIPPING_CLASS]: () => true,
    [HIDE_UPDATE_SHIPPING_CLASS]: () => false,
    [UPDATE_SHIPPING_CLASS_RECEIVE]: () => false
  },
  false
);

const orderId = handleActions(
  {
    [SHOW_UPDATE_SHIPPING_CLASS]: (state, action) => action.orderId,
    [HIDE_UPDATE_SHIPPING_CLASS]: () => null
  },
  null
);

const shippingSigned = handleActions(
  {
    [SHIPPING_CLASS_TOGGLE_SIGNED]: (state, action) => !state,
    [SHOW_UPDATE_SHIPPING_CLASS]: (state, action) => action.shippingSigned,
    [HIDE_UPDATE_SHIPPING_CLASS]: () => false
  },
  false
);

const selectedServiceIndex = handleActions(
  {
    [SHIPPING_CLASS_SELECT_SERVICE]: (state, action) => action.index,
    [SHOW_UPDATE_SHIPPING_CLASS]: (state, action) => {
      return SERVICES.map(service => service.value).indexOf(
        action.shippingClass
      );
    },
    [HIDE_UPDATE_SHIPPING_CLASS]: () => 0
  },
  0
);

const isUpdating = handleActions(
  {
    [UPDATE_SHIPPING_CLASS_REQUEST]: () => true,
    [UPDATE_SHIPPING_CLASS_RECEIVE]: () => false,
    [UPDATE_SHIPPING_CLASS_FAILURE]: () => false
  },
  false
);

const showError = handleActions(
  {
    [UPDATE_SHIPPING_CLASS_FAILURE]: () => true,
    [SHOW_UPDATE_SHIPPING_CLASS]: () => false
  },
  false
);

const errorMessage = handleActions(
  {
    [SHOW_UPDATE_SHIPPING_CLASS]: () => "",
    [UPDATE_SHIPPING_CLASS_FAILURE]: (state, action) =>
      action.payload.response.error
        ? action.payload.response.error.message
        : "Could not get reason from server. Contact dev team."
  },
  ""
);

export default combineReducers({
  isOpen,
  orderId,
  selectedServiceIndex,
  shippingSigned,
  isUpdating,
  showError,
  errorMessage
});
