import React, { useMemo } from "react";
import { Box, Typography, Button, Dialog, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getBasketIsLoading,
  getBasketOrderListTapDialogIsOpen,
  getBasketData,
  getSelectedOrderIds,
  getBasketWeight
} from "redux/root-reducer";
import {
  createSingleOrderDispatchLabel,
  hideOrderListTapDialog,
  setBasketWeight,
  setEditBasketDialogOpen
} from "redux/scanner-wrapper";
import Orders from "components/orders";
import { formatDateString } from "helpers/date-helpers";
import LoadingButton from "components/common/loading-button";

const SingleOrderDispatchDialog = ({
  basketData,
  basketWeight,
  hideOrderListTapDialog,
  isLoading,
  selectedOrderIds,
  setEditBasketDialogOpen,
  createSingleOrderDispatchLabel,
  open,
  location
}) => {
  const selectedOrder = useMemo(
    () =>
      basketData?.orders?.filter(order => selectedOrderIds.includes(order.id)),
    [basketData, selectedOrderIds]
  );
  const renderOrderDetails = () => (
    <Box display={"flex"} flexDirection={"column"} flex={3} gridGap={"1.5rem"}>
      <Typography
        variant={"h3"}
        style={{ fontWeight: 500, textAlign: "center" }}
      >
        Dispatch this order separately
      </Typography>
      {selectedOrder && selectedOrder.length > 0 && (
        <Orders
          orders={selectedOrder}
          showEvidenceColumn
          hideNewOrderBtn
          displaySettings={{
            showOrderId: true,
            showNotes: true,
            showPatient: true,
            showGP: true,
            showOrderStatus: true,
            showDeliveryAddress: true,
            showBarcodeColumn: true,
            showLatestEvent: true,
            showBarcodeForOrder: status =>
              ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
                status
              ),
            showShippingClass: true,
            showShippingButtonsForOrder: status =>
              ["hm_delivered", "cf_rejected", "prescription_printed"].includes(
                status
              )
          }}
          requiredFetch={false}
          disableContextMenu
        />
      )}
    </Box>
  );

  const renderBasketInfo = () => (
    <Box
      display={"flex"}
      flexDirection={"column"}
      maxWidth={"40rem"}
      gridGap={"0.75rem"}
      p={"2.5rem 2.5rem 2.5rem 1.25rem"}
      borderRadius={"0.5rem"}
      border={"1px solid #DADADA"}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography color={"textSecondary"} style={{ fontSize: "1.875rem" }}>
            Basket
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontSize: "1.875rem" }}>NONE</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={"textSecondary"} style={{ fontSize: "1.875rem" }}>
            Patient
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontSize: "1.875rem" }}>
            {basketData.patient}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={"textSecondary"} style={{ fontSize: "1.875rem" }}>
            DOB
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontSize: "1.875rem" }}>
            {formatDateString(basketData.dob)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={"textSecondary"} style={{ fontSize: "1.875rem" }}>
            Order ID
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontSize: "1.875rem" }}>
            {selectedOrder?.[0].id}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={"textSecondary"} style={{ fontSize: "1.875rem" }}>
            Delivery address
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {(selectedOrder?.[0].deliveryAddress || "")
            .split("\n")
            .map((address, index) =>
              address.trim() !== "" ? (
                <Typography key={index} style={{ fontSize: "1.875rem" }}>
                  {address}
                </Typography>
              ) : null
            )}
        </Grid>
        <Grid item xs={4}>
          <Typography color={"textSecondary"} style={{ fontSize: "1.875rem" }}>
            Shipping
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontSize: "1.875rem" }}>
            {`${selectedOrder?.[0].shippingClass || ""} ${
              selectedOrder?.[0].shippingSigned ? "signed" : "unsigned"
            }`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={"textSecondary"} style={{ fontSize: "1.875rem" }}>
            Weight
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontSize: "1.875rem" }}>
            {basketWeight ? `${basketWeight}g` : "99g / 250g"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const renderActionButtons = () => (
    <Box display={"flex"} flexDirection={"column"} gridGap={"3.75rem"}>
      <Box display={"flex"}>
        <Button
          id={"t_emailsent_action"}
          variant={"text"}
          color={"secondary"}
          onClick={() => setEditBasketDialogOpen()}
          style={{ padding: 0 }}
        >
          <Typography
            style={{ fontSize: "4rem", fontWeight: 500, lineHeight: "4rem" }}
          >
            EDIT WEIGHT
          </Typography>
        </Button>
      </Box>
      <LoadingButton
        id={"t_letter_action"}
        variant={"contained"}
        color={"primary"}
        onClick={() =>
          createSingleOrderDispatchLabel(
            selectedOrderIds[0],
            basketWeight,
            "largeLetter",
            basketData.id
          )
        }
        loading={isLoading}
        fullWidth
      >
        <Typography style={{ textTransform: "capitalize", fontSize: "4rem" }}>
          Letter
        </Typography>
      </LoadingButton>
      <LoadingButton
        id={"t_parcel_action"}
        variant={"contained"}
        color={"primary"}
        onClick={() =>
          createSingleOrderDispatchLabel(
            selectedOrderIds[0],
            basketWeight,
            "parcel",
            basketData.id
          )
        }
        loading={isLoading}
        fullWidth
      >
        <Typography style={{ textTransform: "capitalize", fontSize: "4rem" }}>
          Parcel
        </Typography>
      </LoadingButton>
    </Box>
  );

  return (
    <Dialog
      id={"t_dialog"}
      fullWidth
      maxWidth={"xl"}
      open={open && location?.pathname === "/orders/basket"}
    >
      <Box
        display={"flex"}
        py={"1.5rem"}
        px={"1.25rem"}
        flexDirection={"column"}
      >
        {renderOrderDetails()}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flex={1}
          paddingX={"5rem"}
          mt={"2.5rem"}
        >
          <Box display={"flex"} flexDirection={"column"} gridGap={"3.75rem"}>
            {selectedOrder &&
              selectedOrder.length > 0 &&
              renderBasketInfo(selectedOrder)}
            <Button
              fullWidth
              style={{ padding: "2.5rem" }}
              id={"t_cancel"}
              color={"primary"}
              variant={"outlined"}
              onClick={() => hideOrderListTapDialog()}
            >
              <Typography variant={"h2"} style={{ fontWeight: 500 }}>
                Cancel
              </Typography>
            </Button>
          </Box>
          {renderActionButtons()}
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  isLoading: getBasketIsLoading(state),
  basketWeight: Number(getBasketWeight(state)) || undefined,
  selectedOrderIds: getSelectedOrderIds(state),
  basketData: getBasketData(state) ?? {},
  open: getBasketOrderListTapDialogIsOpen(state)
});

const mapDispatchToProps = {
  setBasketWeight,
  hideOrderListTapDialog,
  setEditBasketDialogOpen,
  createSingleOrderDispatchLabel
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleOrderDispatchDialog)
);
