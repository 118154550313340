import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import s from "./user.module.scss";

const Cell = ({ icon, iconStyle, children, loading, cellStyle }) => (
  <div className={s.cell} style={cellStyle}>
    <div className={s.icon} style={iconStyle}>
      {loading ? <CircularProgress /> : icon}
    </div>
    <div className={s.field}>{children}</div>
  </div>
);

Cell.propTypes = {
  icon: PropTypes.element,
  iconStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  cellStyle: PropTypes.object
};

Cell.defaultProps = {
  icon: null,
  iconStyle: {},
  loading: false,
  cellStyle: {}
};

export default Cell;
