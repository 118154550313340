import React, { FC } from "react";
import { Tooltip, Badge, IconButton } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";

import { FilterButtonProps } from "./index";

const FilterButton: FC<FilterButtonProps> = ({
  toggleOrdersFilter,
  isFiltering,
  isOpen
}) => (
  <Tooltip
    title={`${isOpen ? "Close" : "Open"} order filters${
      isFiltering ? " — Filters applied" : ""
    }`}
  >
    <IconButton data-testid={"btn/filter"} onClick={toggleOrdersFilter}>
      <Badge variant={"dot"} color={"error"} invisible={!isFiltering}>
        <FilterIcon />
      </Badge>
    </IconButton>
  </Tooltip>
);

export default FilterButton;
