import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import s from "./style.module.scss";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  getScriptMatchIsLoading,
  getScriptMatchNeedsUpdate,
  getScriptMatchRequests,
  getScriptMatchSelectedScript,
  getScriptMatchSelectedUser,
  getScriptMatchUnmatchedScripts,
  getScriptMatchUnmatchedUsers,
  getScriptMatchIsMatching
} from "../../redux/root-reducer";
import {
  clearSelectedScript,
  clearSelectedUser,
  confirmCreateOrder,
  confirmScriptMatch,
  fetchUnmatchedScripts,
  selectScript,
  selectUser
} from "../../redux/match-scripts";
import Grid from "@material-ui/core/Grid";
import CardList from "../common/card-list";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { formatUserDescription } from "../match-users";
import { fetchRequests } from "../../redux/requests";
import CircularProgress from "@material-ui/core/CircularProgress";

export function MatchScriptsStepper({ activeStep }) {
  const steps = ["Select user", "Select prescription", "Match with request"];
  return (
    <Stepper
      classes={{ root: s.scriptsStepperRoot }}
      activeStep={activeStep}
      alternativeLabel
    >
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

MatchScriptsStepper.propTypes = {
  activeStep: PropTypes.number
};

MatchScriptsStepper.defaultProps = {
  activeStep: 1
};

export class MatchScripts extends Component {
  renderUserCard = user => {
    return (
      <div className={s.userCardDetails}>
        <p className={"name"}>{user.name}</p>
        <p>{formatUserDescription(user)}</p>
      </div>
    );
  };

  renderScriptCard = script => {
    return (
      <ul className={s.scriptMedicinesList}>
        {script.medicines.map((medicine, i) => (
          <li key={i}>{medicine.desc}</li>
        ))}
      </ul>
    );
  };

  renderRequestCard = (pmrUser, script, request) => {
    const { onMatchClick, onCreateOrderClick, matching } = this.props;

    if (request.id === "CREATE_ORDER_CARD") {
      return (
        <div className={s.requestCard}>
          <Typography variant="h6">Create new order</Typography>

          <Typography>
            Select this option if no requests match the prescription.
          </Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={s.matchButton}
            disabled={matching}
            onClick={() => onCreateOrderClick(pmrUser, script)}
          >
            Create new order
          </Button>
        </div>
      );
    }

    return (
      <div className={s.requestCard}>
        {request.medicines.map((medicine, i) => (
          <div key={medicine} className={s.requestMedicineItem}>
            <p className={s.primary} style={{ marginBottom: "4px" }}>
              {medicine.brandName || medicine.drugName}
            </p>
            <p className={s.small}>{medicine.brandDesc || medicine.prodDesc}</p>
          </div>
        ))}

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={s.matchButton}
          disabled={matching}
          onClick={() => onMatchClick(script, request)}
        >
          Match
        </Button>
      </div>
    );
  };

  getUserCards() {
    const { users = [], selectedUser } = this.props;
    return users.map(user => ({
      id: user.pmrId,
      data: user,
      selected: selectedUser && selectedUser.pmrId === user.pmrId
    }));
  }

  getScriptCards() {
    const { scripts = [], selectedScript } = this.props;
    return scripts.map(script => ({
      id: script.id,
      data: script,
      selected: selectedScript && selectedScript.id === script.id
    }));
  }

  getRequestCards() {
    const { requests = [] } = this.props;
    const requestCards = requests.map(request => ({
      id: request.id,
      data: request
    }));

    requestCards.push({
      id: "CREATE_ORDER_CARD",
      data: { id: "CREATE_ORDER_CARD" }
    });

    return requestCards;
  }

  getSelectedStep() {
    const { selectedUser, selectedScript } = this.props;
    if (selectedScript) {
      return 2;
    }
    if (selectedUser) {
      return 1;
    }
    return 0;
  }

  componentDidMount() {
    this.props.getUnmatchedScripts();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedUser,
      fetchRequests,
      needsUpdate,
      getUnmatchedScripts
    } = this.props;

    if (selectedUser && prevProps.selectedUser !== selectedUser) {
      fetchRequests(["sent_to_gp"], selectedUser.pharmacyId);
    }

    if (!prevProps.needsUpdate && needsUpdate) {
      getUnmatchedScripts();
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedUser();
    this.props.clearSelectedScript();
  }

  render() {
    const {
      users,
      selectedUser,
      selectedScript,
      selectUser,
      selectScript,
      isLoading
    } = this.props;

    if (isLoading) {
      return (
        <div className={s.root}>
          <div className={s.progress}>
            <CircularProgress id="t_progress" size={50} />
          </div>
        </div>
      );
    }

    if (users === undefined || users.length === 0) {
      return (
        <div id="#m_noData" className={s.noData}>
          <h2>There are currently no unmatched prescriptions.</h2>
        </div>
      );
    }

    return (
      <div className={s.pageWrapper}>
        <MatchScriptsStepper activeStep={this.getSelectedStep()} />

        <div>
          <Grid container spacing={32} classes={{ container: s.pageGrid }}>
            <Grid item xs={4}>
              <CardList
                selectorType="radio"
                cards={this.getUserCards()}
                onCardSelectionChanged={selectUser}
                render={this.renderUserCard}
              />
            </Grid>
            <Grid item xs={4}>
              {selectedUser && (
                <CardList
                  selectorType="radio"
                  cards={this.getScriptCards()}
                  onCardSelectionChanged={selectScript}
                  render={this.renderScriptCard}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {selectedScript && (
                <CardList
                  cards={this.getRequestCards()}
                  render={req =>
                    this.renderRequestCard(selectedUser, selectedScript, req)
                  }
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const pmrUserShape = PropTypes.shape({
  pmrId: PropTypes.string.isRequired,
  pharmacyId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired
});

const scriptShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  medicines: PropTypes.arrayOf(
    PropTypes.shape({
      vmpId: PropTypes.string.isRequired,
      ampId: PropTypes.string,
      desc: PropTypes.string.isRequired
    })
  ).isRequired
});

const requestShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  medicines: PropTypes.arrayOf(
    PropTypes.shape({
      vmpId: PropTypes.string.isRequired,
      ampId: PropTypes.string,
      brandName: PropTypes.string,
      brandDesc: PropTypes.string,
      prodDesc: PropTypes.string,
      drugName: PropTypes.string
    })
  ).isRequired
});

MatchScripts.propTypes = {
  users: PropTypes.arrayOf(pmrUserShape),
  scripts: PropTypes.arrayOf(scriptShape),
  requests: PropTypes.arrayOf(requestShape),
  needsUpdate: PropTypes.bool,
  isLoading: PropTypes.bool,
  matching: PropTypes.bool,
  selectedUser: pmrUserShape,
  selectedScript: scriptShape,
  selectUser: PropTypes.func.isRequired,
  onMatchClick: PropTypes.func.isRequired,
  onCreateOrderClick: PropTypes.func.isRequired,
  clearSelectedUser: PropTypes.func.isRequired,
  clearSelectedScript: PropTypes.func.isRequired
};
MatchScripts.defaultProps = {
  users: undefined,
  scripts: undefined,
  requests: undefined,
  needsUpdate: false,
  isLoading: false,
  matching: false,
  selectedUser: undefined,
  selectedScript: undefined
};

export const mapDispatchToProps = {
  selectUser,
  selectScript,
  onMatchClick: confirmScriptMatch,
  onCreateOrderClick: confirmCreateOrder,
  getUnmatchedScripts: fetchUnmatchedScripts,
  fetchRequests,
  clearSelectedUser,
  clearSelectedScript
};

export const mapStateToProps = state => {
  const selectedUser = getScriptMatchSelectedUser(state);

  return {
    selectedUser,
    selectedScript: getScriptMatchSelectedScript(state),
    unmatchedScripts: getScriptMatchUnmatchedScripts(state),
    users: getScriptMatchUnmatchedUsers(state),
    needsUpdate: getScriptMatchNeedsUpdate(state),
    isLoading: getScriptMatchIsLoading(state),
    matching: getScriptMatchIsMatching(state),
    scripts:
      selectedUser && getScriptMatchUnmatchedScripts(state, selectedUser.pmrId),
    requests:
      selectedUser && getScriptMatchRequests(state, selectedUser.pharmacyId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchScripts);
